import { isNil } from 'lodash';
import {
  InternalTestTaskResolutionReviewOptions,
  TestTaskStatus,
} from '@constants';
import {
  CandidateTestTask,
  CandidateTestTaskFormValues,
  CandidateTestTaskPayload,
  SubmissionTestTask,
} from '@types';

export const isTestTaskPassed = (statuses: TestTaskStatus[]) =>
  !!statuses
    .at(-1)
    ?.toLowerCase()
    .includes(TestTaskStatus.PASSED.toLowerCase());

export const isTestTaskDone = (statuses: TestTaskStatus[]) =>
  statuses.some((status) =>
    status.toLowerCase().includes(TestTaskStatus.DONE.toLowerCase()),
  );

export const isTestTaskFailed = (statuses: TestTaskStatus[]) =>
  statuses.some((status) =>
    status.toLowerCase().includes(TestTaskStatus.FAILED.toLowerCase()),
  );

export const getInternalTTResolutionByScore = (
  score: number | null,
): string => {
  if (isNil(score)) return '';

  const resolution = InternalTestTaskResolutionReviewOptions.find(
    (resolution) =>
      score >= resolution.minScore && score <= resolution.maxScore,
  );

  return resolution ? resolution.value : '';
};

export const getInternalTTScoreByResolution = (value: string): number => {
  const resolution = InternalTestTaskResolutionReviewOptions.find(
    (resolution) => value === resolution.value,
  );

  return resolution ? resolution.maxScore : 0;
};

export const getInitialCandidateTestTaskFormValues = (
  candidateTestTask: CandidateTestTask | null,
): CandidateTestTaskFormValues => {
  return {
    testTaskStatus: candidateTestTask?.status || [],
    testTaskTechnology: candidateTestTask?.stack || null,
    testTaskSentDate: candidateTestTask?.sentDate || null,
    testTaskSentBy: candidateTestTask?.sentBy || null,
    testTaskScore: candidateTestTask?.score || null,
    testTaskLink: candidateTestTask?.link || null,
    testTaskResultDate: candidateTestTask?.resultDate || null,
    testTaskFeedback: candidateTestTask?.reviewerFeedback || null,
    resolutionFileNames: candidateTestTask?.resolutionFileNames || [],
    resolutionFiles: [],
    summaryFileName: candidateTestTask?.summaryFileName || null,
    summaryFile: null,
  };
};

export const transformCandidateTestTaskFormValuesToPayload = (
  updatedFields: CandidateTestTaskFormValues,
): CandidateTestTaskPayload => {
  return {
    testTaskStatus: updatedFields.testTaskStatus,
    testTaskTechnology: updatedFields.testTaskTechnology,
    testTaskSentDate: updatedFields.testTaskSentDate,
    testTaskSentBy: updatedFields.testTaskSentBy,
    testTaskScore: updatedFields.testTaskScore,
    testTaskLink: updatedFields.testTaskLink,
    testTaskResultDate: updatedFields.testTaskResultDate,
    testTaskFeedback: updatedFields.testTaskFeedback,
    resolutionFileNames: updatedFields.resolutionFileNames,
    summaryFileName: updatedFields.summaryFileName,
  };
};

export const getTTScoreColor = (tt: SubmissionTestTask): string =>
  tt.status === TestTaskStatus.PASSED ? '#4CAF50' : '#FF4C4C';
