import * as yup from 'yup';

export const candidateLPInviteValidationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  country: yup.string().required('Counrty is required'),
  city: yup.string().required('City is required'),
  specialization: yup.string().required('Specialization is required'),
  skills: yup.array(yup.string()).max(3, 'Maximun 3 primary skills').nullable(),
  yearsOfExperience: yup.number().required('Years of expirience is required'),
  seniority: yup.string().required('Seniority is required'),
  email: yup.string().email().required('Email is required'),
  emailSubject: yup.string().required('Eamils subject is required'),
  emailMessage: yup.string().required('Email message is required'),
});
