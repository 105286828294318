import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@redux/hooks';
import { useNavigate } from 'react-router-dom';

import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuProps,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { CustomButton } from '@components';

import { authSelectors, logout } from '@redux/auth';

import { AppRoutes } from '@constants';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    {...props}
  />
))({
  '& .MuiPaper-root': {
    padding: '12px 20px',
    minWidth: '170px',
  },
});

export const HMProfileCard: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useAppSelector(authSelectors.getProfileData);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (!profile) return null;

  const handleLogout = () => {
    dispatch(logout());
    navigate(`/${AppRoutes.SIGN_IN}`);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              open ? (
                <KeyboardArrowUpRoundedIcon
                  fontSize="small"
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    width: '15px',
                    height: '15px',
                  }}
                />
              ) : (
                <KeyboardArrowDownRoundedIcon
                  fontSize="small"
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    width: '15px',
                    height: '15px',
                  }}
                />
              )
            }
          >
            <Avatar
              sx={{ borderRadius: '35%', height: '40px', width: '40px' }}
              src={profile.profilePicture}
              variant="rounded"
            />
          </Badge>
        </IconButton>
      </Box>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          gap={2}
          maxWidth={240}
        >
          <Typography
            sx={{
              display: 'block',
              overflow: 'hidden',
              wordWrap: 'break-word',
              whiteSpace: 'normal',
            }}
          >
            {`${profile.firstName} ${profile.lastName}`}
            <Typography
              variant="body2"
              color="secondary"
              sx={{ whiteSpace: 'normal' }}
            >
              {profile.roles}
            </Typography>
          </Typography>
          <Stack direction="row" justifyContent="space-between" gap="0.25rem">
            <CustomButton
              size="small"
              color="secondary"
              label="Logout"
              onClick={handleLogout}
            />
          </Stack>
        </Box>
      </StyledMenu>
    </>
  );
};
