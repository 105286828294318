import { useState } from 'react';

import ReactPlayer from 'react-player';
import { HiddenBlockWrapper } from '@components';
import { Box, Typography } from '@mui/material';

import { SubmissionFormik } from '@types';
import { apiEndpoints } from '@constants';

export const VideoInterviewQuestions: React.FC<{
  formik: SubmissionFormik;
  onHideFieldToggle: (field: string, isHidden: boolean) => void;
}> = ({ formik, onHideFieldToggle }) => {
  const [selectedRecordFileName, setSelectedRecordFileName] = useState<
    string | null
  >(null);

  return (
    <Box>
      <Box
        sx={{
          borderRadius: '0.25rem',
          backgroundColor: 'common.black',
          paddingTop: '56.25%',
          position: 'relative',
          mb: 3,

          '& .react-player': {
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          },
          '& video': {
            borderRadius: '0.25rem',
          },
        }}
      >
        {selectedRecordFileName ? (
          <ReactPlayer
            controls
            playing
            className="react-player"
            width="100%"
            height="100%"
            url={
              selectedRecordFileName
                ? apiEndpoints.videoInterviewFileUrl(selectedRecordFileName)
                : undefined
            }
          />
        ) : (
          <Typography variant="body2" color="text.invertedSecondary">
            Please select a question to play
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        {formik.values.videoInterview.map((question, idx) => {
          const isHidden =
            formik.values.hiddenFields.includes(
              `videoInterview.${question.recordFileName}`,
            ) || formik.values.hiddenFields.includes('videoInterview');

          return (
            <HiddenBlockWrapper
              hidden={isHidden}
              onHideToggle={(isHidden) => {
                if (selectedRecordFileName === question.recordFileName) {
                  setSelectedRecordFileName(null);
                }
                onHideFieldToggle(
                  `videoInterview.${question.recordFileName}`,
                  isHidden,
                );
              }}
              key={question.recordFileName}
            >
              <Box
                onClick={() =>
                  !isHidden &&
                  setSelectedRecordFileName(question.recordFileName)
                }
                sx={(theme) => ({
                  padding: '0.5rem 1rem 1rem 1rem',
                  cursor: !isHidden ? 'pointer' : 'default',
                  borderRadius: '0.25rem',
                  flex: 1,
                  background:
                    selectedRecordFileName === question.recordFileName
                      ? theme.palette.highlight.accent
                      : !isHidden
                      ? theme.palette.highlight.actionable
                      : theme.palette.highlight.neutral,
                })}
              >
                <Typography variant="body2" color="text.secondary">
                  Question {idx + 1}
                </Typography>
                <Typography variant="body1">{question.question}</Typography>
              </Box>
            </HiddenBlockWrapper>
          );
        })}
      </Box>
    </Box>
  );
};
