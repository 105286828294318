import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { optionsStoreKey } from './options.const';
import { JobOpeningState, apiEndpoints } from '@constants';
import {
  CandidateFieldsOptions,
  IDWHJobOpening,
  IDWHJobOpeningRaw,
  IEmployee,
  LPTestTasks,
  ILPSpecializations,
} from '@types';
import { transformJobOpening } from '@utils';

export const fetchJobOpeningsOptions = createAsyncThunk(
  `${optionsStoreKey}/fetchJobOpeningsOptions`,
  async ({ states }: { states: JobOpeningState[] }, { rejectWithValue }) => {
    try {
      const params: any = {};

      params.states = states.join(',');

      const response = await axios.get<IDWHJobOpeningRaw[]>(
        apiEndpoints.jobOpeningsOptionsAPI(),
        {
          params,
        },
      );

      const data: IDWHJobOpening[] = response.data.map(transformJobOpening);

      return data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchClosedJobOpeningsOptions = createAsyncThunk(
  `${optionsStoreKey}/fetchClosedJobOpeningsOptions`,
  async ({ search }: { search: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get<IDWHJobOpeningRaw[]>(
        apiEndpoints.closedJobOpeningsOptionsAPI(),
        {
          params: { search },
        },
      );

      const data: IDWHJobOpening[] = response.data.map(transformJobOpening);

      return data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchJobOpeningFieldsOptions = createAsyncThunk(
  `${optionsStoreKey}/fetchJobOpeningFieldsOptions`,
  async () => {
    try {
      const response = await axios.get<CandidateFieldsOptions>(
        apiEndpoints.jobOpeningFieldsOptionsAPI(),
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchJobOpeningSpecializations = createAsyncThunk(
  `${optionsStoreKey}/fetchJobOpeningSpecializations`,
  async () => {
    try {
      const response = await axios.get(
        apiEndpoints.getJobOpeningSpecializationsAPI(),
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchJobOpeningClients = createAsyncThunk(
  `${optionsStoreKey}/fetchJobOpeningClients`,
  async () => {
    try {
      const response = await axios.get(apiEndpoints.getJobOpeningClientsAPI());

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchJobOpeningSubteams = createAsyncThunk(
  `${optionsStoreKey}/fetchJobOpeningSubteams`,
  async ({ clientName }: { clientName?: string }) => {
    try {
      const response = await axios.get(
        apiEndpoints.getJobOpeningSubteamsAPI(),
        {
          params: {
            clientName: clientName,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchJobOpeningCountries = createAsyncThunk(
  `${optionsStoreKey}/fetchJobOpeningCountries`,
  async () => {
    try {
      const response = await axios.get(
        apiEndpoints.getJobOpeningCountriesAPI(),
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchCandidateFieldsOptions = createAsyncThunk(
  `${optionsStoreKey}/fetchCandidateFieldsOptions`,
  async () => {
    try {
      const response = await axios.get<CandidateFieldsOptions>(
        apiEndpoints.candidateFieldsOptionsAPI(),
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchCandidateResponsibleRecruiters = createAsyncThunk(
  `${optionsStoreKey}/fetchCandidateResponsibleRecruiters`,
  async () => {
    try {
      const response = await axios.get<string[]>(
        apiEndpoints.candidateResponsibleRecruitersAPI(),
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchCandidateAddedByOptions = createAsyncThunk(
  `${optionsStoreKey}/fetchCandidateAddedByOptions`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<string[]>(
        apiEndpoints.candidateAddedByOptionsAPI(),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchLPTestTasks = createAsyncThunk(
  `${optionsStoreKey}/fetchLPTestTasks`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<LPTestTasks>(
        apiEndpoints.lpTestTasksAPI(),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchClientsForSubmission = createAsyncThunk(
  `${optionsStoreKey}/fetchClientsForSubmission`,
  async () => {
    try {
      const response = await axios.get(apiEndpoints.submissionClientsAPI());

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchLaunchpodSpecializations = createAsyncThunk(
  `${optionsStoreKey}/fetchLaunchpodSpecializations`,
  async () => {
    try {
      const response = await axios.get<ILPSpecializations>(
        apiEndpoints.launchpodSpecializationsAPIPath(),
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchEmployees = createAsyncThunk(
  `${optionsStoreKey}/fetchEmployees`,
  async (search: string) => {
    try {
      const response = await axios.get<IEmployee[]>(
        apiEndpoints.getEmployees(),
        {
          params: {
            search,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);
