export enum PageTitle {
  JOB_OPENINGS = 'Job openings',
  CREATE_JOB_OPENING = 'Create job opening',
  ACTION_REPORT = 'Action needed',
  CANDIDATES = 'All candidates',
  SIGN_IN = 'Sign in',
  SUBMISSION = 'Submission',
  NOT_FOUND = 'Page Not found',
  EDIT_JOB_OPENING = 'Edit job opening',
}
