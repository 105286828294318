import React, { useCallback, useEffect, useState } from 'react';
import { Slider, debounce } from '@mui/material';

export const CandidatePayRateSliderFilter: React.FC<{
  isLoading: boolean;
  value: number[] | null;
  onChange: (value: number[] | null) => void;
}> = ({ isLoading, value, onChange }) => {
  const [localPayRate, setLocalPayRate] = useState<number[]>(value || [0, 100]);

  useEffect(() => {
    if (!value) {
      setLocalPayRate([0, 100]);
    }
  }, [value]);

  const debouncedPayRate = useCallback((values: number[]) => {
    debounce(onChange, 500)(values);
  }, []);

  const handleChangePayRate = (event: Event, values: number | number[]) => {
    if (Array.isArray(values)) {
      debouncedPayRate(values);
      setLocalPayRate(values);
    }
  };

  return (
    <Slider
      getAriaLabel={() => 'Pay rate'}
      aria-labelledby="pay-rate-slider"
      valueLabelDisplay="on"
      value={localPayRate}
      onChange={handleChangePayRate}
      getAriaValueText={(value) => `$${value}`}
      valueLabelFormat={(value) => `$${value}`}
      disabled={isLoading}
    />
  );
};
