import { useCallback } from 'react';

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

type TimelineOption = {
  value: string;
  label: string;
};

type TimelineFilterProps = {
  options: TimelineOption[];
  selectedValue: string;
  onSelect: (value: string) => void;
};

const getLabelByValue = (options: TimelineOption[], value: string) =>
  options.find((option) => option.value === value)?.label || '';

export const CandidateTimelineFilter = ({
  options,
  selectedValue,
  onSelect,
}: TimelineFilterProps) => {
  const onSelectFilter = useCallback(
    (e: SelectChangeEvent<string>) => {
      onSelect(e.target.value);
    },
    [onSelect],
  );

  return (
    <Select
      disableUnderline
      id="timeline-filter"
      value={selectedValue}
      onChange={onSelectFilter}
      sx={{
        backgroundColor: 'highlight.section',
        color: 'text.link',
        padding: '0.25rem 0.625rem',
        borderRadius: '0.25rem',
        '& .MuiSelect-select': {
          padding: '0 !important',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        maxWidth: {
          xs: '10rem',
          sm: '20rem',
        },
      }}
      variant="standard"
      size="small"
      renderValue={(value) => `Show: ${getLabelByValue(options, value)}`}
      IconComponent={() => (
        <KeyboardArrowDownRoundedIcon sx={{ color: 'text.link' }} />
      )}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
