import { differenceInWeeks, startOfWeek, subWeeks } from 'date-fns';

export const DEFAULT_SHOWN_WEEKS_NUMBER = 5;

export const formatDateMonthDay = (date: Date) => {
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};

export const getMondayOfWeek = (date: Date | string) => {
  const formatDate = new Date(date);
  return startOfWeek(formatDate, { weekStartsOn: 1 });
};

export const getMondaysFromNowToDate = (date?: string | null) => {
  if (!date) return [];

  const now = new Date();
  const mondayOfCurrentWeek = getMondayOfWeek(now);

  const startDate = new Date(date);
  const mondayOfStartWeek = getMondayOfWeek(startDate);

  const diff = differenceInWeeks(mondayOfCurrentWeek, mondayOfStartWeek);
  const recentMondays = [];

  for (let i = 0; i <= diff; i++) {
    recentMondays.push(subWeeks(mondayOfCurrentWeek, i));
  }

  return recentMondays;
};
