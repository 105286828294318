import { IDWHCandidate } from '@types';
import { jobOpeningDetailsStoreKey } from './jobOpeningDetails.const';
import { IJobOpeningDetailsSliceState } from './jobOpeningDetails.types';

interface IState {
  [jobOpeningDetailsStoreKey]: IJobOpeningDetailsSliceState;
}

const selectJobOpeningDetailsState = (state: IState) => {
  return state[jobOpeningDetailsStoreKey];
};

const getJobOpeningDetailsId = (state: IState) => {
  return selectJobOpeningDetailsState(state).id;
};

const getJobOpeningDetailsApiStatus = (state: IState) => {
  return selectJobOpeningDetailsState(state).apiStatus;
};

const getJobOpeningDetailsData = (state: IState) => {
  return selectJobOpeningDetailsState(state).detailsData;
};

const getJobOpeningCandidatesApiStatus = (state: IState) => {
  return selectJobOpeningDetailsState(state).candidatesData.apiStatus;
};

const getJobOpeningCandidatesData = (state: IState): IDWHCandidate[] => {
  return selectJobOpeningDetailsState(state).candidatesData.data;
};

const getJobOpeningIsPerformingAction = (state: IState) => {
  return selectJobOpeningDetailsState(state).isPerformingAction;
};

export const jobOpeningDetailsSelectors = {
  getJobOpeningDetailsId,
  getJobOpeningDetailsApiStatus,
  getJobOpeningDetailsData,
  getJobOpeningCandidatesApiStatus,
  getJobOpeningCandidatesData,
  getJobOpeningIsPerformingAction,
};
