import { Box, Typography, Grid, Paper } from '@mui/material';
import { JobOpeningField } from '@components';

import { JobOpeningFormik } from '@types';

export const LocationDetails: React.FC<{
  formik: JobOpeningFormik;
}> = ({ formik }) => {
  const locationsError =
    formik.touched.City_Location && formik.errors.City_Location;

  return (
    <Paper elevation={1} sx={{ mb: '1px', pt: 4, pb: 6, paddingX: 5 }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h2"
            color={locationsError ? 'text.danger' : 'text.primary'}
            marginBottom={locationsError ? 0 : '15px'}
          >
            Locations
          </Typography>
          {locationsError && (
            <Typography variant="body2" color="text.danger" marginBottom="15px">
              {locationsError}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item>
          <JobOpeningField
            field="City_Location"
            value={formik.values.City_Location}
            touched={formik.touched.City_Location}
            error={formik.errors.City_Location}
            onChange={(val) =>
              formik.setFieldValue('City_Location', val || null)
            }
            onBlur={() => formik.setFieldTouched('City_Location')}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box sx={{ maxWidth: '480px' }}>
            <JobOpeningField
              field="Timezone_Requirements"
              value={formik.values.Timezone_Requirements}
              touched={formik.touched.Timezone_Requirements}
              error={formik.errors.Timezone_Requirements}
              onChange={(val) =>
                formik.setFieldValue('Timezone_Requirements', val || null)
              }
              onBlur={() => formik.setFieldTouched('Timezone_Requirements')}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
