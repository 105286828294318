import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { uniqBy, orderBy as lodashOrderBy } from 'lodash';

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from '@mui/material';
import { LoaderSkeleton, ForbiddenView } from '@components';
import { CandidateTimelineFilter } from './components/CandidateTimelineFilter';
import { CandidateTimelineActivityRow } from './components/CandidateTimelineActivityRow';

import {
  candidateDetailsSelectors,
  fetchCandidateProcessActivity,
} from '@redux/candidateDetails';

import {
  isStatusForbidden,
  isStatusIdle,
  isStatusLoading,
  isTruthy,
  formatJobOpeningName,
} from '@utils';
import { ICandidateProcess } from '@types';
import { OrderDirection } from '@constants';

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  ':first-of-type': {
    borderRadius: '0.5rem 0 0 0.5rem',
    paddingLeft: '1rem',
  },
  ':last-of-type': {
    borderRadius: '0 0.5rem 0.5rem 0',
  },
  backgroundColor: theme.palette.highlight.section,
  padding: '0.5rem',
  borderBottom: 'none',
}));

export const CandidateTimeline: React.FC = () => {
  const dispatch = useAppDispatch();
  const [filterValue, setFilterValue] = useState('all');
  const [order, setOrder] = useState<OrderDirection>(OrderDirection.DESC);
  const [orderBy, setOrderBy] = useState<keyof ICandidateProcess>('createdAt');

  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const { processActivityAPIStatus, processActivity } = useAppSelector(
    candidateDetailsSelectors.getProcessActivity,
  );
  const activityData = useMemo(() => {
    const res = lodashOrderBy(processActivity, orderBy, order);
    if (filterValue === 'all') return res;
    if (filterValue === 'profile') return res.filter((e) => !e.jobOpeningId);

    return res.filter((e) => e.jobOpeningId === filterValue);
  }, [filterValue, processActivity, order, orderBy]);

  const showOptions = useMemo(() => {
    const jobOpenings = uniqBy(processActivity, 'jobOpeningId')
      .map((e) => e.jobOpening)
      .filter(isTruthy);

    return [
      { value: 'all', label: 'All events' },
      { value: 'profile', label: 'Profile' },
      ...jobOpenings.map((e) => ({
        value: e.id,
        label: formatJobOpeningName(
          e?.Job_Opening_Name,
          e?.Job_Opening_Id,
          e?.Client_Name,
        ),
      })),
    ];
  }, [processActivity]);

  useEffect(() => {
    if (isStatusIdle(processActivityAPIStatus) && candidateDetails?.id) {
      dispatch(fetchCandidateProcessActivity(candidateDetails.id));
    }
  }, [processActivityAPIStatus, candidateDetails?.id]);

  const handleRequestSort = (property: keyof ICandidateProcess) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? OrderDirection.DESC : OrderDirection.ASC);
    setOrderBy(property);
  };

  if (isStatusLoading(processActivityAPIStatus)) {
    return <LoaderSkeleton />;
  }

  if (isStatusForbidden(processActivityAPIStatus)) {
    return <ForbiddenView />;
  }

  return (
    <Stack my={'0.25rem'} gap={'1rem'}>
      <Stack
        gap={'0.5rem'}
        flexDirection={'row'}
        sx={{
          mx: {
            xs: '1rem',
            sm: '0.25rem',
          },
        }}
      >
        <Typography variant="h3" flex={1} my={'auto'}>
          Candidate timeline
        </Typography>
        <CandidateTimelineFilter
          selectedValue={filterValue}
          onSelect={(value) => setFilterValue(value)}
          options={showOptions}
        />
      </Stack>
      <Stack
        sx={{
          backgroundColor: {
            xs: 'none',
            sm: 'background.backgroundPrimary',
          },
          borderRadius: '0.5rem',
          padding: {
            xs: '0',
            sm: '1rem',
          },
          mx: {
            xs: '0',
            sm: '-1rem',
          },
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: '82rem', tableLayout: 'fixed' }} size="small">
            <TableHead
              sx={{
                ':after': {
                  content: '"@"',
                  display: 'block',
                  lineHeight: '0.5rem',
                  textIndent: '-99999px',
                },
              }}
            >
              <TableRow>
                <StyledHeaderCell
                  width="14%"
                  sortDirection={orderBy === 'createdAt' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'createdAt'}
                    direction={orderBy === 'createdAt' ? order : 'asc'}
                    onClick={() => handleRequestSort('createdAt')}
                  >
                    Date and time
                  </TableSortLabel>
                </StyledHeaderCell>
                <StyledHeaderCell width="10%">Event type</StyledHeaderCell>
                <StyledHeaderCell width="16%">
                  Changes or statuses
                </StyledHeaderCell>
                <StyledHeaderCell width="36px"></StyledHeaderCell>
                <StyledHeaderCell width="auto"></StyledHeaderCell>
                <StyledHeaderCell width="21%">
                  Affected positions
                </StyledHeaderCell>
                <StyledHeaderCell width="17%">Changed by</StyledHeaderCell>
                <StyledHeaderCell width="36px"></StyledHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '0.25rem',
                boxShadow:
                  '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
              }}
            >
              {activityData.map((activity, idx) => {
                const previousActivity = activityData
                  .slice(idx + 1)
                  .find(
                    (e) =>
                      e.id !== activity.id &&
                      e.activityType === activity.activityType &&
                      e.jobOpeningId === activity.jobOpeningId,
                  );

                return (
                  <CandidateTimelineActivityRow
                    key={activity.id}
                    activity={activity}
                    previousActivity={previousActivity || null}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!activityData.length && (
          <Typography textAlign="center" variant="h2">
            No data
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
