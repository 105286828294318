import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { mapValues, values, groupBy as groupByFn } from 'lodash';

import { Fragment, ReactNode, useMemo } from 'react';

interface IProps<T extends { id: string }> {
  data: T[];
  groupBy?: string | null;
  tableHeadersCount: number;
  renderRowComponent: (value: T) => ReactNode;
  onRowClick: (value: string) => void;
}

export const EntityMainTableBody = <T extends { id: string }>({
  data,
  groupBy,
  tableHeadersCount,
  onRowClick,
  renderRowComponent,
}: IProps<T>) => {
  const groupedData = useMemo<Record<string, T[]>>(
    () => (groupBy ? groupByFn(data, groupBy) : {}),
    [data, groupBy],
  );

  return (
    <TableBody>
      {groupBy
        ? values(
            mapValues(groupedData, (groupedRows, groupTitle) => (
              <Fragment key={groupTitle}>
                <TableRow
                  sx={(theme) => ({
                    background: theme.palette.highlight.sectionLight,
                  })}
                >
                  <TableCell
                    colSpan={tableHeadersCount + 1}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.highlight.neutral,
                      padding: '4px 1rem',
                      position: 'sticky',
                      top: '32px',
                      zIndex: 0,
                      border: 'none',
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {groupTitle}
                    </Typography>
                  </TableCell>
                </TableRow>

                {groupedRows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={(theme) => ({
                      cursor: 'pointer',
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': {
                        background: theme.palette.highlight.actionable,
                      },
                    })}
                    onClick={() => onRowClick(row.id)}
                  >
                    {renderRowComponent(row)}
                  </TableRow>
                ))}
              </Fragment>
            )),
          )
        : data?.map((row) => (
            <TableRow
              key={row.id}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:last-child td, &:last-child th': { border: 0 },
                '&:hover': { background: theme.palette.highlight.actionable },
              })}
              onClick={() => onRowClick(row.id)}
            >
              {renderRowComponent(row)}
            </TableRow>
          ))}
    </TableBody>
  );
};
