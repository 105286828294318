import React, { useEffect, useMemo } from 'react';

import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  ListSubheader,
  MenuItem,
  TextField,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  fetchJobOpeningSpecializations,
  optionsSelectors,
} from '@redux/options';

import { isStatusIdle } from '@utils';

type TechStackValue = {
  value: string;
  label: string;
  group: string;
};

const LabelsByType = {
  tech: 'Engineering',
  'non-tech': 'Non-engineering',
};

export const CandidateStackFilter: React.FC<{
  isLoading: boolean;
  value: string[] | null;
  onChange: (value: string[] | null) => void;
}> = ({ isLoading, value, onChange }) => {
  const dispatch = useAppDispatch();

  const { data: specializations, apiStatus: specializationAPIStatus } =
    useAppSelector(optionsSelectors.getJobOpeningSpecializations);

  useEffect(() => {
    if (isStatusIdle(specializationAPIStatus))
      dispatch(fetchJobOpeningSpecializations());
  }, [specializationAPIStatus]);

  const techFlowOptions = useMemo(
    () =>
      specializations
        .reduce((acc, curr) => {
          if (curr.skills.length > 0) {
            const specializationSkills = curr.skills.map(
              ({ title, value }) => ({
                label: title,
                value,
                group: curr.title,
              }),
            );
            return acc.concat(specializationSkills);
          } else {
            acc.push({
              label: curr.title,
              value: curr.value,
              group: LabelsByType[curr.type] || curr.type,
            });
            return acc;
          }
        }, [] as TechStackValue[])
        .sort((a, b) => a.group.localeCompare(b.group)),
    [specializations],
  );
  const selectedStackValue = techFlowOptions.filter((c) =>
    value?.includes(c.value),
  );

  const handleChangeStack = (
    e: any,
    values: { label: string; value: string; group: string }[],
  ) => {
    const newValues = values.map((stack) => stack.value);
    onChange(newValues.length ? newValues : null);
  };

  const handleGroupAllClick = (groupName: string) => {
    const newValues = Array.from(
      new Set([
        ...(value ?? []),
        ...techFlowOptions
          ?.filter((tF) => tF.group === groupName)
          ?.map((c) => c.value),
      ]),
    );
    onChange(newValues.length ? newValues : null);
  };

  return (
    <Autocomplete
      disablePortal
      multiple
      id="combo-box-stack"
      options={techFlowOptions}
      onChange={handleChangeStack}
      groupBy={(option) => option.group}
      sx={{ mb: '1rem', width: '300px' }}
      value={selectedStackValue}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} label="Stack" />}
      disabled={isLoading}
      renderGroup={(params: AutocompleteRenderGroupParams) => (
        <li key={params.key}>
          <ListSubheader id="nested-list-subheader" component="div">
            {params.group}
          </ListSubheader>
          <ul style={{ paddingLeft: 0 }}>
            {React.Children.count(params.children) > 1 ? (
              <MenuItem
                key={`all-${params.group}`}
                onClick={() => handleGroupAllClick(params.group)}
              >{`All ${params.group}`}</MenuItem>
            ) : null}

            {params.children}
          </ul>
        </li>
      )}
      ChipProps={{
        size: 'small',
      }}
    />
  );
};
