import { useAppSelector } from '@redux/hooks';

import { Grid, Paper, Typography } from '@mui/material';
import { JobOpeningField } from '@components';

import { optionsSelectors } from '@redux/options';

import {
  JobOpeningFormMode,
  PositionType,
  JobOpeningSourceOptions,
} from '@constants';
import { JobOpeningFormValues, JobOpeningFormik } from '@types';

export const JobOpeningDetails: React.FC<{
  formik: JobOpeningFormik;
  mode: JobOpeningFormMode;
}> = ({ formik, mode }) => {
  const currentSourceOption = JobOpeningSourceOptions.find(
    (source) => source.value === formik.values.Position_type,
  );
  const isBillablePosition =
    currentSourceOption?.positionType === PositionType.BILLABLE;

  const { data: clients } = useAppSelector(
    optionsSelectors.getJobOpeningClients,
  );

  return (
    <Paper elevation={1} sx={{ mb: '1px', pt: 4, pb: 6, paddingX: 5 }}>
      <Typography variant="h2" mb={2}>
        New position
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <JobOpeningField
            field="Job_Opening_Name"
            value={formik.values.Job_Opening_Name}
            required
            touched={formik.touched.Job_Opening_Name}
            error={formik.errors.Job_Opening_Name}
            onChange={(val) =>
              formik.setFieldValue('Job_Opening_Name', val || null)
            }
            onBlur={() => formik.setFieldTouched('Job_Opening_Name')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobOpeningField
            field="Priority"
            value={formik.values.Priority}
            required
            touched={formik.touched.Priority}
            error={formik.errors.Priority}
            onChange={(val) => formik.setFieldValue('Priority', val || null)}
            onBlur={() => formik.setFieldTouched('Priority')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JobOpeningField
            field="Position_type"
            value={formik.values.Position_type}
            required
            touched={formik.touched.Position_type}
            error={formik.errors.Position_type}
            onChange={(val) => {
              const currentSourceOption = JobOpeningSourceOptions.find(
                (source) => source.value === formik.values.Position_type,
              );
              const selectedSourceOption = JobOpeningSourceOptions.find(
                (source) => source.value === val,
              );

              const updateObj: Partial<JobOpeningFormValues> = {
                Position_type: val,
              };

              // Clear client section in case position type is changed
              if (
                selectedSourceOption?.positionType !==
                currentSourceOption?.positionType
              ) {
                updateObj.Client_Name_New =
                  selectedSourceOption?.positionType === PositionType.BILLABLE
                    ? null
                    : 'AgileEngine';
                updateObj.Subteam_Name = null;
                updateObj.PDM = null;
                updateObj.Delivery_Manager = null;
                updateObj.Client_submission_emails = null;
              }

              formik.setValues({
                ...formik.values,
                ...updateObj,
              });
            }}
            onBlur={() => formik.setFieldTouched('Position_type')}
          />
        </Grid>
        {mode !== JobOpeningFormMode.Edit && (
          <Grid item xs={12} md={3}>
            <JobOpeningField
              field="Number_of_Positions"
              value={formik.values.Number_of_Positions}
              required
              touched={formik.touched.Number_of_Positions}
              error={formik.errors.Number_of_Positions}
              onChange={(val) =>
                formik.setFieldValue('Number_of_Positions', val || null)
              }
              onBlur={() => formik.setFieldTouched('Number_of_Positions')}
            />
          </Grid>
        )}
        {isBillablePosition && (
          <>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6}>
              <JobOpeningField
                field="Client_Name_New"
                value={formik.values.Client_Name_New}
                required
                touched={formik.touched.Client_Name_New}
                error={formik.errors.Client_Name_New}
                onChange={(val) => {
                  const client = clients.find((c) => c.name === val);

                  const updateObj: Partial<JobOpeningFormValues> = {
                    Client_Name_New: val,
                    Subteam_Name: null,
                  };

                  client?.deliveryManager &&
                    (updateObj.Delivery_Manager = client?.deliveryManager);
                  client?.pdm && (updateObj.PDM = client?.pdm);

                  formik.setValues({
                    ...formik.values,
                    ...updateObj,
                  });
                }}
                onBlur={() => formik.setFieldTouched('Client_Name_New')}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};
