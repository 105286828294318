import { useEffect, useRef } from 'react';
import { useIntersectionObserver } from '@hooks/useIntersectionObserver';

import { Box, CircularProgress } from '@mui/material';

export const EntityMainTablePagination: React.FC<{
  totalCount: number;
  currentCount: number;
  isLoading: boolean;
  onFetchNextPage: () => void;
}> = ({ totalCount, currentCount, isLoading, onFetchNextPage }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});

  useEffect(() => {
    if (!!entry?.isIntersecting) {
      onFetchNextPage();
    }
  }, [entry?.isIntersecting]);

  return (
    <>
      {totalCount > currentCount && <div style={{ height: '1px' }} ref={ref} />}
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" py={6}>
          <CircularProgress sx={{ color: 'rgb(255, 122, 0)' }} />
        </Box>
      )}
    </>
  );
};
