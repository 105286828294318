import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';
import { IconButton, Typography } from '@mui/material';

export const Attachment: React.FC<{
  label: string;
  error?: string;
  clearable?: boolean;
  disabled?: boolean;
  onClear?: () => void;
}> = ({ label, error, clearable, disabled, onClear }) => {
  return (
    <Flex flexDirection="column" alignItems="start">
      <Flex
        justifyContent="space-between"
        width="100%"
        gap={3}
        p={2}
        sx={(theme) => ({
          border: `1px solid ${
            !disabled
              ? theme.palette.brand.accent
              : theme.palette.background.disabled
          }`,
          borderRadius: '4px',
        })}
      >
        <Flex gap={1}>
          <AttachFileIcon
            sx={(theme) => ({
              color: !disabled
                ? theme.palette.text.secondary
                : theme.palette.text.disabled,
            })}
          />
          <Typography
            sx={(theme) => ({
              color: !disabled
                ? theme.palette.text.primary
                : theme.palette.text.disabled,
            })}
          >
            {label}
          </Typography>
        </Flex>
        {clearable && (
          <IconButton onClick={onClear} disabled={disabled}>
            <CloseIcon
              sx={(theme) => ({
                color: disabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.secondary,
              })}
            />
          </IconButton>
        )}
      </Flex>
      {!!error && (
        <Typography variant="body2" color="text.danger">
          {error}
        </Typography>
      )}
    </Flex>
  );
};
