import React from 'react';

import { Typography } from '@mui/material';
import { JobOpeningLink, RowLayout } from '@components';

import { useAppSelector } from '@redux/hooks';
import { candidateDetailsSelectors } from '@redux/candidateDetails';

import { CandidateFieldsLabels } from '@constants';

export const CandidateMarketingInfo: React.FC = () => {
  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );

  return (
    <>
      <RowLayout
        label={CandidateFieldsLabels.LeadGen_owner}
        isEmpty={!candidateDetails?.LeadGen_owner}
        Component={
          <Typography variant="body1">
            {candidateDetails?.LeadGen_owner}
          </Typography>
        }
      />
      <RowLayout
        label={CandidateFieldsLabels.Leads_reply_2018}
        isEmpty={!candidateDetails?.Leads_reply_2018}
        Component={
          <Typography variant="body1">
            {candidateDetails?.Leads_reply_2018}
          </Typography>
        }
      />
      <RowLayout
        label={CandidateFieldsLabels.LP_application_match_Positions_IDs}
        isEmpty={!candidateDetails?.matchedJobOpenings?.length}
        Component={
          <Typography variant="body1">
            {candidateDetails?.matchedJobOpenings.map((jo) => (
              <span key={jo.id}>
                <JobOpeningLink jobOpeningId={jo.jobId} jobId={jo.id} />{' '}
                {jo.client}: {jo.name}
              </span>
            ))}
          </Typography>
        }
      />
      <RowLayout
        label={CandidateFieldsLabels.Type_of_channel}
        isEmpty={!candidateDetails?.Type_of_channel}
        Component={
          <Typography variant="body1">
            {candidateDetails?.Type_of_channel}
          </Typography>
        }
      />
      <RowLayout
        label={CandidateFieldsLabels.Type_of_Inbound_channel}
        isEmpty={!candidateDetails?.Type_of_Inbound_channel}
        Component={
          <Typography variant="body1">
            {candidateDetails?.Type_of_Inbound_channel}
          </Typography>
        }
      />
      <RowLayout
        label={CandidateFieldsLabels.Type_of_Outbound_channel}
        isEmpty={!candidateDetails?.Type_of_Outbound_channel}
        Component={
          <Typography variant="body1">
            {candidateDetails?.Type_of_Outbound_channel}
          </Typography>
        }
      />
      <RowLayout
        label={CandidateFieldsLabels.Lead_month_2018}
        isEmpty={!candidateDetails?.Lead_month_2018}
        Component={
          <Typography variant="body1">
            {candidateDetails?.Lead_month_2018}
          </Typography>
        }
      />
      <RowLayout
        label={CandidateFieldsLabels.Self_gen_Touch_Date}
        isEmpty={!candidateDetails?.Self_gen_Touch_Date}
        Component={
          <Typography variant="body1">
            {candidateDetails?.Self_gen_Touch_Date}
          </Typography>
        }
      />
    </>
  );
};
