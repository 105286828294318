import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

import { CustomButton } from '@components';

import { authSelectors } from '@redux/auth';
import { useAppSelector } from '@redux/hooks';

import { checkUserCanGenerateTTLink } from '@utils';

export const EngineeringTTButton = ({ zohoId }: { zohoId: string }) => {
  const profile = useAppSelector(authSelectors.getProfileData);

  const url = zohoId
    ? `https://script.google.com/a/macros/agileengine.com/s/AKfycbyRx8WzWVGX8hTXHehhYXbnKaUrK7ksWJgBfzK2UactygOTSOfyDpamtAxWqutKfelm/exec?longZohoId=Zrecruit_${zohoId}`
    : undefined;

  const isDisabled = !checkUserCanGenerateTTLink(profile);

  return (
    <CustomButton
      isDisabled={isDisabled}
      size="small"
      color="secondary"
      label={'Generate TT'}
      startIcon={<NoteAddOutlinedIcon fontSize="small" />}
      href={url}
    />
  );
};
