import { pageTitleStoreKey } from './pageTitle.const';
import { IPageTitle } from './pageTitle.types';

interface IState {
  [pageTitleStoreKey]: IPageTitle;
}
const selectPageTitleState = (state: IState) => {
  return state[pageTitleStoreKey];
};

const titleSelector = (state: IState) => {
  return selectPageTitleState(state).title;
};

export const pageTitleSelectors = {
  titleSelector,
};
