import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { actionReportSelectors, fetchActionReport } from '@redux/action-report';

export const useFetchActionReport = () => {
  const dispatch = useAppDispatch();

  const {
    search,
    sortBy,
    order,
    country,
    seniority,
    payRate,
    recruiter,
    addedBy,
    techFlow,
    state,
    stage,
    groupBy,
  } = useAppSelector(actionReportSelectors.getActionReportOptions);

  useEffect(() => {
    if (
      [
        search,
        sortBy,
        order,
        country,
        seniority,
        payRate,
        recruiter,
        addedBy,
        techFlow,
        state,
        stage,
        groupBy,
      ].every(isEmpty)
    )
      return;

    dispatch(
      fetchActionReport({
        search,
        sortBy,
        order,
        country,
        seniority,
        payRate,
        recruiter,
        addedBy,
        techFlow,
        state,
        groupBy,
        stage,
      }),
    );
  }, [
    search,
    sortBy,
    order,
    country,
    seniority,
    payRate,
    recruiter,
    addedBy,
    techFlow,
    state,
    stage,
    groupBy,
  ]);
};
