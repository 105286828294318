import { useCallback } from 'react';

import { Link, Typography } from '@mui/material';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';

import { transormCandidateStatus } from '@utils';
import { IDWHCandidate } from '@types';
import { Flex } from '@components';

export const DuplicateCandidate: React.FC<{ data: IDWHCandidate }> = ({
  data,
}) => {
  const onCandidateClick = useCallback(() => {
    window.open(`/candidates/${data.id}/profile`, '_blank', 'noreferrer');
  }, [data.id]);

  return (
    <Flex
      sx={(theme) => ({
        width: '100%',
        background: theme.palette.highlight.accent,
        padding: '6px 16px 6px 16px',
        borderRadius: '4px',
      })}
    >
      <Flex gap={2}>
        <PortraitOutlinedIcon
          fontSize="small"
          sx={(theme) => ({ color: theme.palette.text.secondary })}
        />
        <Flex justifyContent="start">
          <Link
            onClick={onCandidateClick}
            sx={{ cursor: 'pointer' }}
            color="text.link"
          >
            {data.First_Name} {data.Last_Name}
          </Link>{' '}
          <Typography variant="body2" color="text.secondary">
            ∙ {transormCandidateStatus(data.Candidate_Status)}
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};
