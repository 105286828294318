import { Box } from '@mui/material';
import { CandidateField, FileInput } from '@components';

import { CandidateTestTaskFormik } from '@types';
import { SUMMARY_RESOLUTION_FILE_ACCEPTED_EXTENSIONS } from '@constants';

export const CandidateTestTaskFormFields: React.FC<{
  formik: CandidateTestTaskFormik;
}> = ({ formik }) => (
  <Box display="flex" flexDirection="column" gap={2} py={2}>
    <CandidateField
      field="Test_Task"
      name={'testTaskStatus'}
      value={formik.values.testTaskStatus}
      touched={!!formik.touched.testTaskStatus || !!formik.submitCount}
      error={formik.errors.testTaskStatus as string}
      onChange={(value) => formik.setFieldValue('testTaskStatus', value)}
      onBlur={() => formik.setFieldTouched('testTaskStatus')}
    />
    <CandidateField
      field="Test_Task_Technology"
      name={'testTaskTechnology'}
      value={formik.values.testTaskTechnology}
      touched={!!formik.touched.testTaskTechnology || !!formik.submitCount}
      error={formik.errors.testTaskTechnology}
      onChange={(value) => formik.setFieldValue('testTaskTechnology', value)}
      onBlur={() => formik.setFieldTouched('testTaskTechnology')}
    />
    <CandidateField
      field="Test_Task_sent_date"
      name={'testTaskSentDate'}
      value={formik.values.testTaskSentDate}
      touched={!!formik.touched.testTaskSentDate || !!formik.submitCount}
      error={formik.errors.testTaskSentDate}
      onChange={(value) => formik.setFieldValue('testTaskSentDate', value)}
      onBlur={() => formik.setFieldTouched('testTaskSentDate')}
    />
    <CandidateField
      field="Test_Task_sent_by"
      name={'testTaskSentBy'}
      value={formik.values.testTaskSentBy}
      touched={!!formik.touched.testTaskSentBy || !!formik.submitCount}
      error={formik.errors.testTaskSentBy}
      onChange={(value) => formik.setFieldValue('testTaskSentBy', value)}
      onBlur={() => formik.setFieldTouched('testTaskSentBy')}
    />
    <CandidateField
      field="Codility_Result"
      name={'testTaskScore'}
      value={formik.values.testTaskScore}
      touched={!!formik.touched.testTaskScore || !!formik.submitCount}
      error={formik.errors.testTaskScore}
      onChange={(value) => formik.setFieldValue('testTaskScore', value)}
      onBlur={() => formik.setFieldTouched('testTaskScore')}
    />
    <CandidateField
      field="Test_Task_Link"
      name="testTaskLink"
      value={formik.values.testTaskLink}
      touched={!!formik.touched.testTaskLink || !!formik.submitCount}
      error={formik.errors.testTaskLink}
      onChange={(value) => formik.setFieldValue('testTaskLink', value)}
      onBlur={() => formik.setFieldTouched('testTaskLink')}
    />
    <CandidateField
      field="Test_Task_Date"
      name={'testTaskResultDate'}
      value={formik.values.testTaskResultDate}
      touched={!!formik.touched.testTaskResultDate || !!formik.submitCount}
      error={formik.errors.testTaskResultDate}
      onChange={(value) => formik.setFieldValue('testTaskResultDate', value)}
      onBlur={() => formik.setFieldTouched('testTaskResultDate')}
    />
    <CandidateField
      field="Test_Task_Feedback"
      name={'testTaskFeedback'}
      value={formik.values.testTaskFeedback || ''}
      touched={!!formik.touched.testTaskFeedback || !!formik.submitCount}
      error={formik.errors.testTaskFeedback}
      onChange={(value) => formik.setFieldValue('testTaskFeedback', value)}
      onBlur={() => formik.setFieldTouched('testTaskFeedback')}
    />
    <FileInput
      label="Resolution Files"
      name="resolutionFiles"
      acceptedExtensions={SUMMARY_RESOLUTION_FILE_ACCEPTED_EXTENSIONS}
      onChange={({
        fileNames,
        files,
      }: {
        fileNames: string[];
        files: File[];
      }) =>
        formik.setValues({
          ...formik.values,
          resolutionFiles: files,
          resolutionFileNames: fileNames,
        })
      }
      multiple
      value={formik.values.resolutionFileNames}
    />
    <FileInput
      label="Summary File"
      name="summaryFile"
      acceptedExtensions={SUMMARY_RESOLUTION_FILE_ACCEPTED_EXTENSIONS}
      onChange={({
        fileNames,
        files,
      }: {
        fileNames: string[];
        files: File[];
      }) =>
        formik.setValues({
          ...formik.values,
          summaryFile: files[0] || null,
          summaryFileName: fileNames[0] || null,
        })
      }
      value={
        formik.values.summaryFileName ? [formik.values.summaryFileName] : null
      }
    />
  </Box>
);
