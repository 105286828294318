import React, { useCallback, useMemo, useState } from 'react';
import { Stack, Typography, Link } from '@mui/material';
import { SplitButton } from '@components/common';
import { CloseJobOpeningModal } from '@components/jobOpeningDetails';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  jobOpeningDetailsSelectors,
  putOnHoldJobOpening,
  reopenJobOpening,
} from '@redux/jobOpeningDetails';
import { authSelectors } from '@redux/auth';
import { AppRoutes, JobOpeningReasonOfClosing, SearchParams } from '@constants';
import { checkUserCanOpenEditJobOpening } from '@utils';
import { createSearchParams, useNavigate } from 'react-router-dom';

export const JobOpeningNavbarLinks: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpenClosePositionModal, setIsOpenClosePositionModal] =
    useState(false);
  const onCloseClosePositionModal = useCallback(
    () => setIsOpenClosePositionModal(false),
    [],
  );

  const joIsPerformingAction = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningIsPerformingAction,
  );

  const profile = useAppSelector(authSelectors.getProfileData);
  const userHasPermissionsToEditJO = checkUserCanOpenEditJobOpening(profile);

  const jobOpeningDetails = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsData,
  );
  const isClosed = ['Cancelled2', 'Filled'].includes(
    jobOpeningDetails?.Job_Opening_Status || '',
  );
  const isOnHold =
    jobOpeningDetails?.Job_Opening_Status === 'Cancelled2' &&
    jobOpeningDetails.Reason_of_Closing === JobOpeningReasonOfClosing.OnHold;

  const canEditJobOpening = !isClosed || isOnHold;

  const id = useAppSelector(jobOpeningDetailsSelectors.getJobOpeningDetailsId);

  const editButtonOptions = useMemo(
    () => [
      {
        label: 'Edit position',
        onClick: () => navigate(`/${AppRoutes.JOB_OPENINGS}/${id}/edit`),
        disabled: !userHasPermissionsToEditJO || !canEditJobOpening,
      },
      {
        label: 'Close position',
        onClick: () => setIsOpenClosePositionModal(true),
        disabled: !userHasPermissionsToEditJO || !canEditJobOpening,
      },
      {
        label: isOnHold ? 'Reopen' : 'Put on hold',
        onClick: async () => {
          if (!id) return;
          if (isOnHold) {
            await dispatch(reopenJobOpening(id));
          } else {
            await dispatch(putOnHoldJobOpening(id));
          }
          navigate(`/${AppRoutes.JOB_OPENINGS}`);
        },
        disabled: !userHasPermissionsToEditJO || !canEditJobOpening,
      },
      {
        label: 'Duplicate position',
        onClick: () => {
          if (!id) return;
          navigate({
            pathname: `/${AppRoutes.JOB_OPENINGS}/create`,
            search: createSearchParams({
              [SearchParams.DUPLICATE_JOB_OPENING_ID]: id,
            }).toString(),
          });
        },
        disabled: !userHasPermissionsToEditJO,
      },
    ],
    [id, isOnHold, userHasPermissionsToEditJO],
  );

  if (!jobOpeningDetails) return null;

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Link
          href={`https://recruit.zoho.com/recruit/org30986285/EntityInfo.do?module=JobOpenings&id=${jobOpeningDetails?.id}&submodule=JobOpenings`}
          component={'a'}
          target="_blank"
          color="text.link"
          sx={{
            cursor: 'pointer',
            textWrap: 'nowrap',
          }}
        >
          JO at Zoho ↗
        </Link>
        {jobOpeningDetails?.Careers_site_link ? (
          <Link
            href={jobOpeningDetails.Careers_site_link}
            component={'a'}
            target="_blank"
            color="text.link"
            sx={{
              cursor: 'pointer',
              textWrap: 'nowrap',
            }}
          >
            JO at Careers ↗
          </Link>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No JO at Careers site
          </Typography>
        )}
        {jobOpeningDetails?.Google_Drive_Folder ? (
          <Link
            href={jobOpeningDetails.Google_Drive_Folder}
            component={'a'}
            target="_blank"
            color="text.link"
            sx={{
              cursor: 'pointer',
              textWrap: 'nowrap',
            }}
          >
            JD doc ↗
          </Link>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No JD document
          </Typography>
        )}
        {jobOpeningDetails?.TI_Requirements_Link ? (
          <Link
            href={jobOpeningDetails.TI_Requirements_Link}
            component={'a'}
            target="_blank"
            color="text.link"
            sx={{
              cursor: 'pointer',
              textWrap: 'nowrap',
            }}
          >
            TI requirements ↗
          </Link>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No TI requirements
          </Typography>
        )}

        <CloseJobOpeningModal
          isOpen={isOpenClosePositionModal}
          onModalClose={onCloseClosePositionModal}
        />
        <SplitButton
          options={editButtonOptions}
          loading={joIsPerformingAction}
        />
      </Stack>
      <Stack direction="row" alignItems="center"></Stack>
    </Stack>
  );
};
