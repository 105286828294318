import React, { Fragment } from 'react';

import { Chip, useTheme } from '@mui/material';

import { ActionReportSeverity, IActionReport } from '@types';

const getBackgroundColor = (severity: ActionReportSeverity) => {
  const theme = useTheme();
  const color = {
    [ActionReportSeverity.None]: 'black',
    [ActionReportSeverity.Low]: theme.palette.highlight.lowSeverity,
    [ActionReportSeverity.Medium]: theme.palette.highlight.mediumSeverity,
    [ActionReportSeverity.High]: theme.palette.highlight.highSeverity,
  };
  return color[severity];
};

export const ActionReportChip: React.FC<{
  actionReport: IActionReport;
}> = ({ actionReport }) => {
  if (!actionReport || actionReport?.severity === ActionReportSeverity.None) {
    return <Fragment>-</Fragment>;
  }
  return (
    <Chip
      label={actionReport.actionTemplate}
      sx={{
        padding: '0.5rem 0',
        height: 'auto',
        backgroundColor: getBackgroundColor(actionReport.severity),
        maxWidth: '201px',
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
        },
      }}
    />
  );
};
