import { VIResolution, ReviewLevels } from '@constants';
import * as yup from 'yup';

export const validationSchema = yup.object({
  presentationSkillsLevel: yup
    .string()
    .oneOf([ReviewLevels.POOR, ReviewLevels.GOOD, ReviewLevels.GREAT])
    .required('Presentational skills level is required'),
  functionalExpertiseLevel: yup
    .string()
    .oneOf([ReviewLevels.POOR, ReviewLevels.GOOD, ReviewLevels.GREAT])
    .required('Functional expertise level is required'),
  englishLevel: yup
    .string()
    .oneOf([ReviewLevels.POOR, ReviewLevels.GOOD, ReviewLevels.GREAT])
    .required('English level is required'),
  resolution: yup
    .string()
    .oneOf(Object.values(VIResolution))
    .required('Resolution is required'),
  feedback: yup.string().optional(),
  reviewer: yup.string().required('Reviewer is required'),
});
