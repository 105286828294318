import upperFirst from 'lodash/upperFirst';

export const toUpperFirstWithSpace = (text: string) => {
  const formatted = text.replace(/_|-/g, ' ');
  return upperFirst(formatted);
};

export const pluralize = (singular: string, times = 1) => {
  if (times < 2) return singular;
  else return singular + 's';
};
