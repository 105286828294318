export enum AuthStatus {
  FAILED = 'failed',
  LOADING = 'loading',
  LOGGEDIN = 'loggedin',
  ANONYMOUS = 'anonymous',
}

export enum AppRoles {
  SuperUser = 'SuperUser',
  DepartmentLead = 'DepartmentLead',
  TOM = 'TOM',
  DeliveryManager = 'DeliveryManager',
  PortfolioDeliveryManager = 'PortfolioDeliveryManager',
  ClientPartner = 'ClientPartner',
  ClientPartnerAdmin = 'ClientPartnerAdmin',
  Recruiter = 'Recruiter',
  Marketing = 'Marketing',
  Interviewers = 'Interviewer',
  ProjectManager = 'ProjectManager',
  DesignStudioLead = 'DesignStudioLead',
  DesignStudioMember = 'DesignStudioMember',
  HiringManager = 'HiringManager',
}
