import { uniq } from 'lodash';

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

import { JOB_OPENING_SENIORITY_LEVELS } from '@constants';

export const PositionSeniorityField: React.FC<{
  value: string[] | null;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: any) => void;
}> = ({ value, error, touched, onChange }) => {
  const seniorityError = touched && error ? error : '';

  return (
    <Box>
      <Typography
        variant="h4"
        mt={3}
        mb={seniorityError ? 0 : 1}
        color={seniorityError ? 'text.danger' : 'text.primary'}
      >
        Seniority <sup>*</sup>
      </Typography>
      {seniorityError && (
        <Typography variant="body2" color="text.danger" marginBottom="15px">
          {seniorityError}
        </Typography>
      )}
      <FormGroup
        sx={{
          flexDirection: 'column',
          flexWrap: 'wrap',
          width: 'min-content',
          maxHeight: '80px',
          marginBottom: '40px',
        }}
      >
        <FormControlLabel
          label="All"
          sx={{ marginBottom: -1.5, marginRight: 4 }}
          control={
            <Checkbox
              checked={JOB_OPENING_SENIORITY_LEVELS.every((seniority: string) =>
                value?.includes(seniority),
              )}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  onChange([...JOB_OPENING_SENIORITY_LEVELS]);
                } else {
                  onChange([]);
                }
              }}
            />
          }
        />
        {JOB_OPENING_SENIORITY_LEVELS.map((seniorityOption) => (
          <FormControlLabel
            key={seniorityOption}
            name={seniorityOption}
            label={seniorityOption}
            sx={{ marginBottom: -1.5, marginRight: 4 }}
            control={
              <Checkbox
                checked={value?.includes(seniorityOption) || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    onChange(uniq([...(value || []), event.target.name]));
                  } else {
                    onChange(value?.filter((e) => e !== event.target.name));
                  }
                }}
              />
            }
          />
        ))}
      </FormGroup>
    </Box>
  );
};
