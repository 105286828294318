import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { Stack, Typography } from '@mui/material';
import { LoaderSkeleton, ForbiddenView } from '@components';
import { VideoInterviewQuestions } from './components/VideoInterviewQuestions';
import { VideoInterviewReviewForm } from './components/VideoInterviewReviewForm/VideoInterviewReviewForm';
import { VideoInterviewSendForm } from './components/VideoInterviewSendForm/VideoInterviewSendForm';
import { VideoInterviewResetModal } from './components/VideoInterviewResetModal';
import { VideoInterviewShareWithClient } from './components/VideoInterviewShareWithClient';
import { VideoInterviewStatus } from './components/VideoInterviewStatus';

import {
  candidateDetailsSelectors,
  changeCandidateStatus,
  fetchAvailableVideoInterview,
  fetchCandidateUserVideoInterview,
  generateClientVideoLink,
  postCandidateResetVideoInterview,
  postUserVideoInterviewReview,
} from '@redux/candidateDetails';

import {
  isStatusComplete,
  isStatusForbidden,
  isStatusIdle,
  isStatusLoading,
} from '@utils';
import { CandidateStatusFormValues, ReviewVideoInterviewPayload } from '@types';
import {
  CandidateStatusByVIResolution,
  CandidateStatusFormConfig,
  CandidateStatusStage,
  VIResolution,
} from '@constants';

export const CandidateVideoInterview = () => {
  const dispatch = useAppDispatch();

  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const videoInterviewAPIStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateVideoInterviewApiStatus,
  );
  const isPerformingVideoInterviewAction = useAppSelector(
    candidateDetailsSelectors.getCandidateVideoInterviewActionPerforming,
  );
  const videoInterviewData = useAppSelector(
    candidateDetailsSelectors.getCandidateVideoInterviewData,
  );
  const resetVideoInterviewApiStatus = useAppSelector(
    candidateDetailsSelectors.getResetVideoInterviewAPIStatus,
  );
  const { availableVideoInterview, availableVideoInterviewAPIStatus } =
    useAppSelector(candidateDetailsSelectors.getAvailableVideoInterview);

  const generateClientVideoLinkAPIStatus = useAppSelector(
    candidateDetailsSelectors.getGenerateClientVideoLinkApiStatus,
  );

  const [videoInterviewResetModal, setVideoInterviewResetModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (candidateId && isStatusIdle(videoInterviewAPIStatus)) {
      dispatch(fetchCandidateUserVideoInterview(candidateId));
    }
  }, [candidateId, videoInterviewAPIStatus]);

  useEffect(() => {
    if (candidateId && isStatusIdle(availableVideoInterviewAPIStatus)) {
      dispatch(fetchAvailableVideoInterview(candidateId));
    }
  }, [candidateId, availableVideoInterviewAPIStatus]);

  useEffect(() => {
    if (isStatusComplete(resetVideoInterviewApiStatus)) {
      handleCloseVideoInterviewResetModal();
    }
  }, [resetVideoInterviewApiStatus]);

  const currentVideoInterview = videoInterviewData[0];

  const reviewVideoInterview = useCallback(
    async (payload: ReviewVideoInterviewPayload) => {
      if (!currentVideoInterview || !candidateId) return;

      await dispatch(
        postUserVideoInterviewReview({
          candidateId,
          videoInterviewId: currentVideoInterview.id,
          ...payload,
        }),
      );

      const nextCandidateStatus =
        CandidateStatusByVIResolution[payload.resolution as VIResolution];

      if (nextCandidateStatus) {
        const getFormData = CandidateStatusFormConfig[nextCandidateStatus]!;
        const { autoupdatedFields } = getFormData(
          CandidateStatusStage.VideoInterview,
          {} as CandidateStatusFormValues,
        );

        await dispatch(
          changeCandidateStatus({
            candidateId,
            payload: {
              transitions: [
                [
                  {
                    stage: CandidateStatusStage.VideoInterview,
                    status: nextCandidateStatus,
                    jobOpeningId: null,
                  },
                ],
              ],
              statusesData: [
                {
                  stage: CandidateStatusStage.VideoInterview,
                  status: nextCandidateStatus,
                  Stage: CandidateStatusStage.VideoInterview,
                  ...autoupdatedFields,
                },
              ],
            },
          }),
        );
      }
    },
    [candidateId, currentVideoInterview],
  );

  const resetVideoInterview = useCallback(() => {
    if (candidateId) {
      dispatch(
        postCandidateResetVideoInterview({
          candidateId,
          videoInterviewId: currentVideoInterview.id,
        }),
      );
    }
  }, [candidateId, currentVideoInterview]);

  const handleGenerateClick = useCallback(() => {
    if (candidateId && currentVideoInterview) {
      dispatch(
        generateClientVideoLink({
          candidateId,
          videoInterviewId: currentVideoInterview.id,
        }),
      );
    }
  }, [candidateId, currentVideoInterview]);

  const handleOpenVideoInterviewResetModal = useCallback(
    () => setVideoInterviewResetModal(true),
    [],
  );

  const handleCloseVideoInterviewResetModal = useCallback(
    () => setVideoInterviewResetModal(false),
    [],
  );

  if (
    isStatusLoading(videoInterviewAPIStatus) ||
    isStatusLoading(availableVideoInterviewAPIStatus)
  ) {
    return <LoaderSkeleton />;
  }

  if (isStatusForbidden(videoInterviewAPIStatus)) {
    return <ForbiddenView />;
  }

  if (!videoInterviewData.length) {
    return (
      <VideoInterviewSendForm
        availableVideoInterview={availableVideoInterview}
      />
    );
  }

  return (
    <React.Fragment>
      <Stack
        mx={{ xs: '1rem', sm: '0.25rem' }}
        mb={'0.5rem'}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="h3">Candidate video intro</Typography>
        <VideoInterviewStatus videoInterviewData={currentVideoInterview} />
      </Stack>
      <Stack
        gap={'1.5rem'}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        maxWidth={{
          sm: '29rem',
          md: '100%',
        }}
        mb={'1rem'}
      >
        <Stack
          gap={'1rem'}
          width={{
            xs: 'auto',
            md: '24.25rem',
          }}
        >
          <VideoInterviewQuestions videoInterviewData={currentVideoInterview} />
          <VideoInterviewShareWithClient
            isLoading={isStatusLoading(generateClientVideoLinkAPIStatus)}
            currentVideoInterview={currentVideoInterview}
            handleGenerateClick={handleGenerateClick}
          />
        </Stack>
        <Stack flex={1} mx={{ xs: '1rem', sm: 0 }}>
          <VideoInterviewReviewForm
            isPerformingVideoInterviewAction={isPerformingVideoInterviewAction}
            videoInterviewData={currentVideoInterview}
            postVideoInterviewReview={reviewVideoInterview}
            handleResetVIClick={handleOpenVideoInterviewResetModal}
          />
        </Stack>
      </Stack>
      <VideoInterviewResetModal
        isOpen={videoInterviewResetModal}
        isLoading={isStatusLoading(resetVideoInterviewApiStatus)}
        handleClose={handleCloseVideoInterviewResetModal}
        handleReset={resetVideoInterview}
      />
    </React.Fragment>
  );
};
