import { useAppSelector } from '@redux/hooks';
import { useIsTabletView } from '@hooks/useIsTabletView';

import { Typography, Link, Stack, Box, StackProps } from '@mui/material';
import { CandidateScore } from '@components';
import { ReactComponent as AeRocketIcon } from '@assets/ae-rocket-icon.svg';

import { candidateDetailsSelectors } from '@redux/candidateDetails';

import { getCandidateName } from '@utils';

type ICandidateNameProps = StackProps;

export const CandidateName: React.FC<ICandidateNameProps> = ({ ...rest }) => {
  const isTabletView = useIsTabletView();

  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );

  if (!candidateDetails) return null;

  const isNewHomer = candidateDetails.Type_of_Inbound_channel === 'New Home';

  return (
    <Stack {...rest}>
      <Stack direction={'row'}>
        <Typography
          variant="h2"
          color="text.primary"
          noWrap
          sx={{
            fontSize: {
              xs: '1.5rem',
              md: '1.125rem',
            },
            lineHeight: '120%',
            marginRight: '0.25rem',
          }}
        >
          {getCandidateName({
            fullName: candidateDetails.Full_Name,
            firstName: candidateDetails.First_Name,
            lastName: candidateDetails.Last_Name,
            englishFirstName: candidateDetails.English_First_Name,
            englishLastName: candidateDetails.English_Last_Name,
          })}
        </Typography>
        <CandidateScore
          score={candidateDetails.score}
          size={isTabletView ? 'medium' : 'small'}
        />
        {isNewHomer && (
          <Box component="span" mx={1}>
            <AeRocketIcon />
          </Box>
        )}
      </Stack>
      <Link
        color="text.secondary"
        href={`mailto:${candidateDetails.Email}`}
        fontSize="0.688rem"
        lineHeight={'166%'}
      >
        {candidateDetails.Email}
      </Link>
    </Stack>
  );
};
