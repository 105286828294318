import { IZohoCandidate } from '@types';
import { TestTaskStatus } from '@constants';

export const TestTaskColor = {
  [TestTaskStatus.TODO]: '#3F8CFF',
  [TestTaskStatus.SENT]: '#3F8CFF',
  [TestTaskStatus.DONE]: '#3F8CFF',
  [TestTaskStatus.PASSED]: '#4CAF50',
  [TestTaskStatus.FAILED]: '#FF4C4C',
};

const checkStatus = (candidateStatus: string[], status: TestTaskStatus) =>
  candidateStatus.some((currentStatus) =>
    currentStatus.toLowerCase().includes(status.toLowerCase()),
  );

export const getUserTestTaskInfo = (candidateData: IZohoCandidate | null) => {
  if (!candidateData?.Test_Task) return null;

  const isPassed = checkStatus(candidateData.Test_Task, TestTaskStatus.PASSED);
  const isFailed = checkStatus(candidateData.Test_Task, TestTaskStatus.FAILED);
  const isDone = checkStatus(candidateData.Test_Task, TestTaskStatus.DONE);
  const isSent = checkStatus(candidateData.Test_Task, TestTaskStatus.SENT);

  const testStatus = isPassed
    ? TestTaskStatus.PASSED
    : isFailed
    ? TestTaskStatus.FAILED
    : isDone
    ? TestTaskStatus.DONE
    : isSent
    ? TestTaskStatus.SENT
    : undefined;

  return {
    testStatus,
    testDate: candidateData?.Test_Task_Date,
    testScore: candidateData?.Codility_Result,
    testTechnology: candidateData?.Test_Task_Technology,
    testPassed: isPassed,
    color: TestTaskColor[testStatus!] ?? '#3F8CFF',
  };
};
