import { ReactNode } from 'react';

import { Flex } from '@components';
import { Typography } from '@mui/material';

export const ModalSectionLayout: React.FC<{
  title: string;
  children: ReactNode;
}> = ({ title, children }) => {
  return (
    <Flex flexDirection="column" gap={2} alignItems="start">
      <Typography variant="h3" color="text.primary" textAlign="left">
        {title}
      </Typography>
      {children}
    </Flex>
  );
};
