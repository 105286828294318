import React, { useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { CandidateState } from '@constants';

export const CandidateStateFilter: React.FC<{
  isLoading: boolean;
  value: CandidateState | null;
  onChange: (value: CandidateState | null) => void;
}> = ({ isLoading, value, onChange }) => {
  const stateOptions = useMemo(() => Object.values(CandidateState), []);

  const handleChangeState = (e: any, state: CandidateState | null) => {
    onChange(state || null);
  };

  return (
    <Autocomplete
      disabled={isLoading}
      disablePortal
      id="combo-box-status"
      options={stateOptions}
      onChange={handleChangeState}
      value={value}
      sx={{ mb: '1rem' }}
      renderInput={(params) => <TextField {...params} label="Status" />}
    />
  );
};
