import { CandidateProcessActivityType } from '@constants';
import {
  CandidateProcessEventScheduledDataType,
  CandidateProcessEventStatusChangeDataType,
  ICandidateProcess,
} from '@types';
import { isString } from 'formik';
import { isArray } from 'lodash';
import { transormCandidateStatus } from './candidates';
import { formatJobOpeningName } from './jobOpenings';

export const getActivityHeaderDetails = (
  activity: ICandidateProcess,
  previousActivity: ICandidateProcess | null,
): string[] => {
  if (
    [
      CandidateProcessActivityType.Associated,
      CandidateProcessActivityType.Dissociated,
    ].includes(activity.activityType)
  )
    return [
      formatJobOpeningName(
        activity.jobOpening?.Job_Opening_Name,
        activity.jobOpening?.Job_Opening_Id,
        activity.jobOpening?.Client_Name,
      ),
    ];

  if (activity.activityType === CandidateProcessActivityType.StatusChange) {
    const data = activity.data as CandidateProcessEventStatusChangeDataType;
    const previousData =
      previousActivity &&
      (previousActivity.data as CandidateProcessEventStatusChangeDataType);

    return [
      `${previousData?.stage ? `[${previousData?.stage}] ` : ''}${
        transormCandidateStatus(previousData?.status) || ''
      }`,
      `[${data.stage || ''}] ${transormCandidateStatus(data.status)}`,
    ];
  }

  if (
    [
      CandidateProcessActivityType.VideoInterviewScheduled,
      CandidateProcessActivityType.TestTaskScheduled,
    ].includes(activity.activityType)
  ) {
    const data = activity.data as CandidateProcessEventScheduledDataType;

    return [data.scheduledAt];
  }

  return [];
};

export const getActivityHeaderSubDetails = (
  activity: ICandidateProcess,
): string | null => {
  if (
    activity.activityType === CandidateProcessActivityType.StatusChange &&
    activity.jobOpening
  ) {
    return formatJobOpeningName(
      activity.jobOpening.Job_Opening_Name,
      activity.jobOpening.Job_Opening_Id,
      activity.jobOpening.Client_Name,
    );
  }

  return null;
};

const EmptyValue = 'Empty';

export const getDisplayValue = (
  value: string | string[] | number | boolean | null,
) =>
  isString(value)
    ? value || EmptyValue
    : isArray(value)
    ? value.length
      ? value.join(', ')
      : EmptyValue
    : value?.toString();
