import { useCallback, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import {
  SubmissionFeedbackStep,
  SubmissionFormLayout,
  SubmissionInterviewStep,
  SubmissionProfileStep,
  SubmissionRecipientsStep,
  SubmissionResumeStep,
  SubmissionReviewStep,
  SubmissionTestTaskStep,
  SubmissionVideoInterviewStep,
} from '@components';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  clearSubmissionState,
  createSubmission,
  fetchSubmissionCandidateDetails,
  fetchSubmissionDetails,
  fetchSubmissionTestTasks,
  fetchSubmissionVideoInterview,
  submissionSelectors,
  updateSubmission,
} from '@redux/submission';
import { authSelectors } from '@redux/auth';
import { fetchJobOpeningsOptions } from '@redux/options';

import {
  isStatusFailed,
  isStatusForbidden,
  isStatusLoading,
  getInitialSubmissionFormValues,
  getSubmissionPayloadData,
} from '@utils';
import { SubmissionFormValues } from '@types';
import {
  AppRoutes,
  CandidateDetailsTabs,
  JobOpeningState,
  SearchParams,
  SubmissionStatus,
  SubmissionStep,
  SubmissionStepsOrdered,
  TestTaskStatus,
} from '@constants';

import { submisionFormValidationSchema } from './validationSchema';

export const SubmissionFormPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { submissionId } = useParams();
  const [searchParams] = useSearchParams();

  const profile = useAppSelector(authSelectors.getProfileData);
  const { apiStatus: submissionDetailsAPIStatus, data: submissionDetails } =
    useAppSelector(submissionSelectors.getSubmissionDetails);
  const { apiStatus: candidateDetailsAPIStatus, data: candidateDetails } =
    useAppSelector(submissionSelectors.getSubmissionCandidateDetails);
  const { apiStatus: videoInterviewAPIStatus, data: videoInterview } =
    useAppSelector(submissionSelectors.getSubmissionVideoInterview);
  const { apiStatus: testTasksAPIStatus, data: testTasks } = useAppSelector(
    submissionSelectors.getSubmissionTestTask,
  );
  const isPerformingAction = useAppSelector(
    submissionSelectors.getSubmissionIsPerformingAction,
  );

  const candidateId =
    searchParams.get(SearchParams.SELECTED_CANDIDATE) ||
    submissionDetails?.candidateId;

  useEffect(() => () => dispatch(clearSubmissionState()), []);

  useEffect(() => {
    if (submissionId) {
      dispatch(fetchSubmissionDetails(submissionId));
    }
  }, [submissionId]);

  useEffect(() => {
    if (candidateId) {
      dispatch(fetchSubmissionCandidateDetails(candidateId));
      dispatch(fetchSubmissionVideoInterview(candidateId));
      dispatch(fetchSubmissionTestTasks(candidateId));
      dispatch(
        fetchJobOpeningsOptions({
          states: [
            JobOpeningState.Open,
            JobOpeningState.PreOpen,
            JobOpeningState.OnHold,
          ],
        }),
      );
    }
  }, [candidateId]);

  const passedTestTasks = testTasks.filter((tt) =>
    tt.status.some((status) => [TestTaskStatus.PASSED].includes(status)),
  );

  const formik = useFormik<SubmissionFormValues>({
    initialValues: getInitialSubmissionFormValues({
      candidateDetails,
      submissionDetails,
      videoInterview,
      testTasks: passedTestTasks,
      profile,
    }),
    validationSchema: submisionFormValidationSchema,
    onSubmit: async (values) => {
      const payload = getSubmissionPayloadData(
        candidateId!,
        values,
        submissionDetails?.step || values.step,
      );

      let res;
      if (submissionId) {
        res = await dispatch(
          updateSubmission({
            submissionId,
            payload,
            resumeFile: values.resumeFile,
          }),
        );
      } else {
        res = await dispatch(
          createSubmission({ payload, resumeFile: values.resumeFile }),
        );
      }

      if (!res?.type?.includes('rejected')) {
        navigate(
          `/${AppRoutes.CANDIDATES}/${candidateId}/${CandidateDetailsTabs.SUBMISSIONS}`,
        );
      }
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  const onPreviousStepClick = useCallback(() => {
    const previousStepIdx =
      SubmissionStepsOrdered.findIndex((e) => e === formik.values.step) - 1;

    formik.setFieldValue('step', SubmissionStepsOrdered[previousStepIdx]);
  }, [formik.values.step]);

  const onNextStepClick = useCallback(() => {
    const nextStepIdx =
      SubmissionStepsOrdered.findIndex((e) => e === formik.values.step) + 1;

    formik.setFieldValue('step', SubmissionStepsOrdered[nextStepIdx]);
  }, [formik.values.step]);

  const onSelectStepClick = useCallback(
    (selectedStep: SubmissionStep) => {
      formik.setFieldValue('step', selectedStep);
    },
    [formik.values.step],
  );

  const onSubmitClick = useCallback(() => {
    formik.setFieldValue('status', SubmissionStatus.Submitted);
    formik.handleSubmit();
  }, [formik.values.step]);

  const onSaveDraftClick = useCallback(() => {
    formik.setFieldValue('status', SubmissionStatus.Draft);
    formik.handleSubmit();
  }, [formik.values.step]);

  const onHideFieldToggle = useCallback(
    (field: string, isHidden: boolean) => {
      formik.setFieldValue(
        'hiddenFields',
        isHidden
          ? [...formik.values.hiddenFields, field]
          : formik.values.hiddenFields.filter((e) => e !== field),
      );
    },
    [formik.values.hiddenFields],
  );

  const isLoading =
    isStatusLoading(submissionDetailsAPIStatus) ||
    isStatusLoading(candidateDetailsAPIStatus) ||
    isStatusLoading(videoInterviewAPIStatus) ||
    isStatusLoading(testTasksAPIStatus);
  const isLoadingFailed =
    isStatusFailed(candidateDetailsAPIStatus) || !candidateId;
  const isForbidden = isStatusForbidden(candidateDetailsAPIStatus);

  return (
    <SubmissionFormLayout
      candidateDetails={candidateDetails}
      activeStep={formik.values.step}
      errors={formik.errors}
      isLoading={isLoading}
      isLoadingFailed={isLoadingFailed}
      isForbidden={isForbidden}
      isPerformingAction={isPerformingAction}
      submissionStatus={submissionDetails?.status || null}
      onPreviousClick={onPreviousStepClick}
      onNextClick={onNextStepClick}
      onSelectStep={onSelectStepClick}
      onSubmitClick={onSubmitClick}
      onSaveDraftClick={onSaveDraftClick}
    >
      {formik.values.step === SubmissionStep.Profile && (
        <SubmissionProfileStep
          formik={formik}
          onHideFieldToggle={onHideFieldToggle}
        />
      )}
      {formik.values.step === SubmissionStep.Resume && (
        <SubmissionResumeStep
          formik={formik}
          defaultResumeName={
            submissionDetails?.profile?.resumeName ||
            candidateDetails?.CV_File_Name ||
            null
          }
          onHideFieldToggle={onHideFieldToggle}
        />
      )}
      {formik.values.step === SubmissionStep.VideoInterview && (
        <SubmissionVideoInterviewStep
          formik={formik}
          onHideFieldToggle={onHideFieldToggle}
        />
      )}
      {formik.values.step === SubmissionStep.TestTask && (
        <SubmissionTestTaskStep
          formik={formik}
          testTasks={passedTestTasks}
          onHideFieldToggle={onHideFieldToggle}
        />
      )}
      {formik.values.step === SubmissionStep.Feedback && (
        <SubmissionFeedbackStep
          formik={formik}
          onHideFieldToggle={onHideFieldToggle}
        />
      )}
      {formik.values.step === SubmissionStep.Interview && (
        <SubmissionInterviewStep formik={formik} />
      )}
      {formik.values.step === SubmissionStep.Recipients && (
        <SubmissionRecipientsStep formik={formik} />
      )}
      {formik.values.step === SubmissionStep.Review && (
        <SubmissionReviewStep candidateId={candidateId!} formik={formik} />
      )}
    </SubmissionFormLayout>
  );
};
