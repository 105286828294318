import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';

import { Box, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { TextButton, AutocompleteTextfield } from '@components';
import { PositionSourceField } from './customFields/PositionSourceField';
import { PositionLocationField } from './customFields/PositionLocationField';
import { PositionTechnicalFlowField } from './customFields/PositionTechnicalFlowField';
import { PositionHiringManagerField } from './customFields/PositionHiringManagerField';
import { PositionClientField } from './customFields/PositionClientField';
import { PositionSubteamField } from './customFields/PositionSubteamField';
import { PositionPDMField } from './customFields/PositionPDMField';
import { PositionDeliveryManagerField } from './customFields/PositionDeliveryManagerField';
import { PositionInterviewerField } from './customFields/PositionInterviewerField';
import { PositionSeniorityField } from './customFields/PositionSeniorityField';
import { PositionInterviewStepsField } from './customFields/PositionInterviewStepsField';
import { PositionQuantityField } from './customFields/PositionQuantityField';
import { PositionClosingReasonField } from './customFields/PositionClosingReasonField';
import { PositionSpecializationField } from './customFields/PositionSpecializationField';

import {
  TextfieldJobOpeningFields,
  TextareaJobOpeningFields,
  NumberfieldJobOpeningFields,
  AutocompleteSingleselectJobOpeningFields,
  AutocompltereMultiselectJobOpeningFields,
  DateJobOpeningFields,
  JobOpeningFieldsLabels,
  JobOpeningFieldsHelpText,
} from '@constants';
import { JobOpeningFields } from '@types';

import { requestJobOpeningFieldsFetch, optionsSelectors } from '@redux/options';

export const JobOpeningField: React.FC<{
  field: keyof JobOpeningFields;
  name?: string;
  value: any;
  required?: boolean;
  touched?: boolean;
  disabled?: boolean;
  error?: string;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
  filterOptions?: (options: any[]) => any[];
}> = ({
  field,
  name,
  value,
  required,
  touched,
  disabled,
  error,
  onChange,
  onBlur,
  filterOptions,
}) => {
  const dispatch = useAppDispatch();

  const { data } = useAppSelector(optionsSelectors.getJobOpeningFieldsOptions);

  useEffect(() => {
    if (
      [
        ...AutocompleteSingleselectJobOpeningFields,
        ...AutocompltereMultiselectJobOpeningFields,
      ].includes(field)
    ) {
      dispatch(requestJobOpeningFieldsFetch());
    }
  }, []);

  const label = JobOpeningFieldsLabels[field];
  const options = data?.[field] || [];

  if (field === 'Position_type') {
    return (
      <PositionSourceField
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'City_Location') {
    return (
      <PositionLocationField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }

  if (field === 'Specialization') {
    return (
      <PositionSpecializationField
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Technical_Flow') {
    return (
      <PositionTechnicalFlowField
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        filterOptions={filterOptions}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Hiring_Manager_email') {
    return (
      <PositionHiringManagerField
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Client_Name_New') {
    return (
      <PositionClientField
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Subteam_Name') {
    return (
      <PositionSubteamField
        name={name || (field as unknown as string)}
        value={value}
        disabled={disabled}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'PDM') {
    return (
      <PositionPDMField
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Delivery_Manager') {
    return (
      <PositionDeliveryManagerField
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Technical_Reviewer') {
    return (
      <PositionInterviewerField
        label="Technical interviewer"
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Additional_Technical_Interviewer') {
    return (
      <PositionInterviewerField
        label="Additional interviewer"
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Seniority_level') {
    return (
      <PositionSeniorityField
        value={value}
        onChange={onChange}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Multi_Select_13') {
    return (
      <PositionInterviewStepsField
        value={value}
        onChange={onChange}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Number_of_Positions') {
    return (
      <PositionQuantityField
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (field === 'Reason_of_Closing') {
    return (
      <PositionClosingReasonField
        name={name || (field as unknown as string)}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }

  if (TextfieldJobOpeningFields.includes(field)) {
    return (
      <TextField
        name={name || (field as unknown as string)}
        label={label}
        variant="outlined"
        fullWidth
        value={value || ''}
        required={required}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        error={touched && !!error}
        disabled={disabled}
        helperText={
          <Stack>
            {error && touched && (
              <Typography variant="caption">{error}</Typography>
            )}
            {JobOpeningFieldsHelpText[field] && (
              <Typography variant="caption" color="text.link">
                {JobOpeningFieldsHelpText[field]}
              </Typography>
            )}
          </Stack>
        }
      />
    );
  }

  if (TextareaJobOpeningFields.includes(field)) {
    return (
      <TextField
        name={name || (field as unknown as string)}
        label={label}
        variant="outlined"
        fullWidth
        value={value || ''}
        required={required}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        helperText={touched ? error : undefined}
        error={touched && !!error}
        disabled={disabled}
        multiline
        minRows={3}
      />
    );
  }

  if (NumberfieldJobOpeningFields.includes(field)) {
    return (
      <TextField
        name={name || (field as unknown as string)}
        label={label}
        variant="outlined"
        type="number"
        fullWidth
        required={required}
        value={isNumber(value) ? value : ''}
        onChange={(e) =>
          onChange(e.target.value ? Number(e.target.value) : null)
        }
        onBlur={onBlur}
        helperText={touched ? error : undefined}
        error={touched && !!error}
        disabled={disabled}
      />
    );
  }

  if (AutocompleteSingleselectJobOpeningFields.includes(field)) {
    return (
      <AutocompleteTextfield
        name={name || (field as unknown as string)}
        label={label}
        options={filterOptions ? filterOptions(options) : options}
        value={value}
        required={required}
        onChange={(_, val) => onChange(val)}
        onBlur={onBlur}
        fullWidth
        error={touched && !!error}
        helperText={touched ? error : undefined}
        disabled={disabled}
      />
    );
  }

  if (AutocompltereMultiselectJobOpeningFields.includes(field)) {
    return (
      <AutocompleteTextfield
        name={name || (field as unknown as string)}
        label={label}
        options={filterOptions ? filterOptions(options) : options}
        value={value || []}
        required={required}
        onChange={(_, val) => onChange(val)}
        onBlur={onBlur}
        fullWidth
        multiple
        filterSelectedOptions
        error={touched && !!error}
        helperText={touched ? error : undefined}
        disabled={disabled}
      />
    );
  }

  if (DateJobOpeningFields.includes(field)) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" gap={2}>
          <DatePicker
            label={
              <>
                {label} {!!required && <sup>*</sup>}
              </>
            }
            value={value ? dayjs(value) : null}
            sx={{ width: '100%' }}
            onChange={(newValue) => {
              onChange(newValue?.format('YYYY-MM-DD') || '');
            }}
            disabled={disabled}
          />
          <TextButton
            onClick={() => onChange(format(new Date(), 'yyyy-MM-dd'))}
            variant="primary"
            underlined
            sx={{ fontSize: '14px' }}
            disabled={disabled}
          >
            Today
          </TextButton>
        </Box>
        {touched && !!error && (
          <Typography
            variant="body2"
            color="text.danger"
            sx={{ marginLeft: '14px', marginTop: '3px' }}
          >
            {error}
          </Typography>
        )}
      </Box>
    );
  }

  return null;
};
