import React, { useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { isStatusLoading } from '@utils';
import { optionsSelectors, requestJobOpeningFieldsFetch } from '@redux/options';

export const JobOpeningTechnicalFlowFilter: React.FC<{
  isLoading: boolean;
  value: string[] | null;
  onChange: (value: string[] | null) => void;
}> = ({ isLoading, value, onChange }) => {
  const dispatch = useAppDispatch();
  const { data, apiStatus } = useAppSelector(
    optionsSelectors.getJobOpeningFieldsOptions,
  );

  useEffect(() => {
    dispatch(requestJobOpeningFieldsFetch());
  }, []);

  const techFlowOptions = data?.Technical_Flow || [];

  const handleChange = (e: any, values: string[]) => {
    onChange(values.length ? values : null);
  };

  return (
    <Autocomplete
      disablePortal
      multiple
      id="combo-box-tech-flow"
      options={techFlowOptions}
      onChange={handleChange}
      value={value || []}
      filterSelectedOptions
      sx={{ mb: '1rem', width: '300px' }}
      renderInput={(params) => <TextField {...params} label="Technical flow" />}
      disabled={isLoading || isStatusLoading(apiStatus)}
      ChipProps={{
        size: 'small',
      }}
    />
  );
};
