import {
  DefaultInterviewerLabel,
  DefaultInterviewerValue,
  GroupedJobOpeningSeniorities,
  JobOpeningSourceOptions,
  JobOpeningStatus,
  SpecializationType,
  ZohoNeedUpdateFieldByFormField,
} from '@constants';
import {
  CreateJobOpeningFormPayload,
  EditJobOpeningFormPayload,
  IClient,
  ICountry,
  IProfile,
  ISpecialization,
  IJobOpeningDetails,
  JobOpeningFormValues,
} from '@types';
import { format } from 'date-fns';
import { removeUndefinedAttributes } from './object';
import { groupBy, isNumber, mapValues } from 'lodash';

export const getJobOpeningFormInitialValues = (
  profile: IProfile | null,
  jobOpening: IJobOpeningDetails | null,
): JobOpeningFormValues => ({
  Job_Opening_Name: jobOpening?.Job_Opening_Name || null,
  Priority: jobOpening?.Priority || null,
  Position_type: jobOpening?.Position_type || null,
  Number_of_Positions: jobOpening?.Number_of_Positions || null,

  City_Location: jobOpening?.City_Location || null,
  Timezone_Requirements: jobOpening?.Timezone_Requirements || null,

  Specialization: jobOpening?.Specialization || null,
  Technical_Flow: jobOpening?.Technical_Flow || null,
  Seniority_level: jobOpening?.Seniority_level || null,

  Must_Haves1: jobOpening?.Must_Haves1 || null,
  Multi_Line_4: jobOpening?.Multi_Line_4 || null,
  Multi_Line_5: jobOpening?.Multi_Line_5 || null,
  Google_Drive_Folder: jobOpening?.Google_Drive_Folder || null,
  TI_Requirements_Link: jobOpening?.TI_Requirements_Link || null,

  Test_Task: jobOpening?.Test_Task || null,
  Hiring_Manager_email:
    jobOpening?.Hiring_Manager_email || profile?.email || null,
  Hiring_M:
    jobOpening?.Hiring_Manager_email ||
    `${profile?.firstName} ${profile?.lastName}` ||
    null,
  Client_Name_New: jobOpening?.Client_Name_New || null,
  Responsible_Recruiter: jobOpening?.Responsible_Recruiter || null,
  Subteam_Name: jobOpening?.Subteam_Name || null,
  PDM: jobOpening?.PDM || null,
  Delivery_Manager: jobOpening?.Delivery_Manager || null,
  Client_submission_emails: jobOpening?.Client_submission_emails || null,

  Technical_Reviewer:
    jobOpening?.Technical_Reviewer === DefaultInterviewerValue
      ? DefaultInterviewerLabel
      : jobOpening?.Technical_Reviewer || null,
  Additional_Technical_Interviewer:
    jobOpening?.Additional_Technical_Interviewer === DefaultInterviewerValue
      ? DefaultInterviewerLabel
      : jobOpening?.Additional_Technical_Interviewer || null,
  Other_Comments: jobOpening?.Other_Comments || null,
  Technical_Interviewers_Not_in_list:
    jobOpening?.Technical_Interviewers_Not_in_list || null,
  Multi_Select_13: jobOpening?.Multi_Select_13 || null,
  Project_interview_type: jobOpening?.Project_interview_type || null,
  Interview_process: jobOpening?.Interview_process || null,
  Candidates_spreadsheet: jobOpening?.Candidates_spreadsheet || null,
  maxCalls: !jobOpening
    ? 30
    : isNumber(jobOpening?.maxCalls)
    ? jobOpening?.maxCalls
    : null,
});

const baseMapper = (
  values: Partial<JobOpeningFormValues>,
  clients: IClient[],
  specializations: ISpecialization[],
): Partial<
  Pick<
    CreateJobOpeningFormPayload,
    | 'isCustomClientName'
    | 'Type'
    | 'Stack_for_tech_positions'
    | 'Client_Name'
    | 'Client_Name_for_Report'
  >
> => {
  const isCustomClientName =
    !!clients.length &&
    !clients.some((client) => client.name === values.Client_Name_New);
  const currentSourceOption = JobOpeningSourceOptions.find(
    (source) => source.value === values.Position_type,
  );
  const selectedSpecialization = specializations.find(
    (specialization) => values.Specialization === specialization.value,
  );

  return removeUndefinedAttributes({
    isCustomClientName: values.Client_Name_New ? isCustomClientName : undefined,
    Client_Name: values.Client_Name_New ? values.Client_Name_New : undefined,
    Client_Name_for_Report: values.Client_Name_New
      ? values.Client_Name_New
      : undefined,
    Type: values.Position_type ? currentSourceOption?.positionType : undefined,
    Stack_for_tech_positions: values.Technical_Flow
      ? selectedSpecialization?.type === SpecializationType.TECH
        ? values.Technical_Flow?.split('/').map((e) => e.trim())
        : ['Non-developer']
      : undefined,
  });
};

export const getSystemFieldOnJobCreate = (
  values: JobOpeningFormValues,
  clients: IClient[],
  specializations: ISpecialization[],
): Pick<
  CreateJobOpeningFormPayload,
  | 'isCustomClientName'
  | 'Type'
  | 'Stack_for_tech_positions'
  | 'Client_Name'
  | 'Client_Name_for_Report'
  | 'Technical_Reviewer'
  | 'Additional_Technical_Interviewer'
  | 'Date_Opened'
  | 'Date_Opened_Official'
  | 'Date_Pre_opened'
  | 'Job_Opening_Status'
  | 'Account_Manager'
  | 'Recruiter'
> => {
  const isPreOpenPosition = values.Priority === 'Pre-open';

  const formatedToday = format(new Date(), 'yyyy-MM-dd');

  return {
    ...(baseMapper(values, clients, specializations) as Pick<
      CreateJobOpeningFormPayload,
      | 'isCustomClientName'
      | 'Type'
      | 'Stack_for_tech_positions'
      | 'Client_Name'
      | 'Client_Name_for_Report'
    >),
    Technical_Reviewer:
      values.Technical_Reviewer === DefaultInterviewerLabel
        ? DefaultInterviewerValue
        : values.Technical_Reviewer,
    Additional_Technical_Interviewer:
      values.Additional_Technical_Interviewer === DefaultInterviewerLabel
        ? DefaultInterviewerValue
        : values.Additional_Technical_Interviewer,
    Date_Opened: isPreOpenPosition ? null : formatedToday,
    Date_Opened_Official: isPreOpenPosition ? null : formatedToday,
    Date_Pre_opened: isPreOpenPosition ? formatedToday : null,
    Job_Opening_Status: isPreOpenPosition
      ? JobOpeningStatus.PreOpen
      : JobOpeningStatus.InProgress,
    Account_Manager: '248544000047418646',
    Recruiter: 'Not Assigned',
  };
};

export const getSystemFieldOnJobEdit = (
  values: Partial<JobOpeningFormValues>,
  jobOpening: IJobOpeningDetails,
  countries: ICountry[],
  clients: IClient[],
  specializations: ISpecialization[],
): Pick<
  EditJobOpeningFormPayload,
  | 'isCustomClientName'
  | 'Type'
  | 'Stack_for_tech_positions'
  | 'Client_Name'
  | 'Client_Name_for_Report'
  | 'Date_Opened'
  | 'Date_Opened_Official'
  | 'Job_Post_need_update'
  | 'Date_Zoho_update_done'
  | 'Job_Post_done_update'
  | 'Date_Platforms_update_done'
  | 'Job_Opening_Status'
> => {
  const formatedToday = format(new Date(), 'yyyy-MM-dd');

  const postingDataUpdateFields = [];
  for (const field in values) {
    ZohoNeedUpdateFieldByFormField[field as keyof JobOpeningFormValues] &&
      postingDataUpdateFields.push(
        ZohoNeedUpdateFieldByFormField[field as keyof JobOpeningFormValues],
      );
  }

  const shouldUpdateJobPostingsData = postingDataUpdateFields.length;
  const isPositionTransferredToOpen =
    values.Priority &&
    jobOpening.Job_Opening_Status === JobOpeningStatus.PreOpen &&
    values.Priority !== JobOpeningStatus.PreOpen;
  const shouldUpdateDateOpened = checkShouldUpdateDateOpened(
    jobOpening,
    values,
    countries,
  );

  return removeUndefinedAttributes({
    ...baseMapper(values, clients, specializations),
    Date_Opened:
      shouldUpdateDateOpened || isPositionTransferredToOpen
        ? formatedToday
        : undefined,
    Date_Opened_Official: isPositionTransferredToOpen
      ? formatedToday
      : undefined,
    Job_Post_need_update: shouldUpdateJobPostingsData
      ? postingDataUpdateFields
      : undefined,
    Date_Zoho_update_done: shouldUpdateJobPostingsData
      ? formatedToday
      : undefined,
    Job_Post_done_update: shouldUpdateJobPostingsData ? null : undefined,
    Date_Platforms_update_done: shouldUpdateJobPostingsData ? null : undefined,
    Job_Opening_Status: values.Priority
      ? values.Priority === 'Pre-open'
        ? JobOpeningStatus.PreOpen
        : JobOpeningStatus.InProgress
      : undefined,
  });
};

const groupByValues = (
  items: string[],
  groupedItems: Record<string, string[]>,
): string[] => {
  const itemsSet = new Set<string>();

  for (const item of items) {
    const groupedItem = Object.keys(groupedItems).find((key) =>
      groupedItems[key].includes(item),
    );
    if (groupedItem) {
      itemsSet.add(groupedItem);
    }
  }

  return Array.from(itemsSet);
};

const checkShouldUpdateDateOpened = (
  initialValues: IJobOpeningDetails,
  currentValues: Partial<JobOpeningFormValues>,
  countries: ICountry[],
) => {
  if (initialValues.Job_Opening_Status === JobOpeningStatus.PreOpen) {
    return false;
  }

  const groupedCountries = mapValues(
    groupBy(countries, 'region'),
    (countries) => countries.map((cty) => cty.title),
  );

  let shouldUpdateDateOpened = false;

  if (currentValues.City_Location) {
    const initialRegions = groupByValues(
      initialValues.City_Location ?? [],
      groupedCountries,
    );
    const updatedRegions = groupByValues(
      currentValues.City_Location,
      groupedCountries,
    );

    shouldUpdateDateOpened = !initialRegions.every((e) =>
      updatedRegions.includes(e),
    );
  }

  if (currentValues.Seniority_level && !shouldUpdateDateOpened) {
    const initialGroupedSeniorities = groupByValues(
      initialValues.Seniority_level ?? [],
      GroupedJobOpeningSeniorities,
    );
    const updatedGroupedSeniorities = groupByValues(
      currentValues.Seniority_level,
      GroupedJobOpeningSeniorities,
    );

    shouldUpdateDateOpened = !initialGroupedSeniorities.every((e) =>
      updatedGroupedSeniorities.includes(e),
    );
  }

  if (currentValues.Technical_Flow && !shouldUpdateDateOpened) {
    shouldUpdateDateOpened =
      initialValues.Technical_Flow !== currentValues.Technical_Flow;
  }

  return shouldUpdateDateOpened;
};
