import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '@constants';
import { authStoreKey } from './auth.const';

export const login = createAsyncThunk(
  `${authStoreKey}/login`,
  async (body: Record<string, string>) => {
    const response = await axios.post(apiEndpoints.login(), body);
    return response.data;
  },
);

export const logout = createAsyncThunk(`${authStoreKey}/logout`, async () => {
  const response = await axios.get(apiEndpoints.logout());

  window.localStorage.clear();
  window.location.href = '/sign-in';

  return response.data;
});

export const fetchProfile = createAsyncThunk(
  `${authStoreKey}/fetchProfile`,
  async () => {
    const response = await axios.get(apiEndpoints.profile());
    return response.data;
  },
);
