import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Flex } from '@components';
import HotPipelineInviteForm from './HotPipelineInviteForm/HotPipelineInviteForm';

import { IZohoCandidate } from '@types';

export const HotPipelineInviteCandidateModal: React.FC<{
  candidate: IZohoCandidate | null;
  isOpen: boolean;
  onModalClose: () => void;
}> = ({ candidate, isOpen, onModalClose }) => {
  if (!candidate) return null;

  return (
    <Dialog open={isOpen} onClose={onModalClose} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">Send Hot Pipeline invitation</Typography>
          <CloseIcon
            onClick={onModalClose}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>

      <DialogContent>
        <Box
          display={'flex'}
          gap={1}
          alignItems={'flex-start'}
          sx={{
            backgroundColor: (theme) => theme.palette.highlight.neutral,
            padding: '10px',
            borderRadius: '4px',
          }}
        >
          <MailOutlineIcon fontSize="small" color="action" />
          <Typography variant="body1" color="text.primary">
            Candidate will receive one time invitation email.
            <br /> Feel free to adjust the message according to the situation:
          </Typography>
        </Box>
      </DialogContent>
      <DialogContent>
        <HotPipelineInviteForm closeModal={onModalClose} />
      </DialogContent>
    </Dialog>
  );
};
