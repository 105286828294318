import { useRef, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export const SplitButton: React.FC<{
  options: { label: string; onClick: () => void; disabled: boolean }[];
  loading?: boolean;
}> = ({ options, loading }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            onClick={options[0].onClick}
            disabled={options[0].disabled || loading}
            sx={{
              backgroundColor: 'text.link',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              height: 'auto',
              padding: '8px 8px 8px 16px',
              border: 'none !important',
              '&:hover': {
                backgroundColor: 'text.linkDark',
              },
            }}
          >
            {loading ? <CircularProgress size="24px" /> : options[0].label}
          </Button>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
            disabled={options[0].disabled || loading}
            sx={{
              backgroundColor: 'text.link',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              height: 'auto',
              padding: '8px 4px',
              minWidth: '0 !important',
              '&:hover': {
                backgroundColor: 'text.linkDark',
              },
            }}
          >
            <ExpandMoreIcon
              sx={{
                fontSize: '20px',
              }}
            />
          </Button>
        </ButtonGroup>
      </Box>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.slice(1).map((option) => (
                    <MenuItem
                      key={option.label}
                      onClick={() => {
                        handleToggle();
                        option.onClick();
                      }}
                      disabled={option.disabled}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
