import { isStepEqualOrHigherThan } from '@utils';
import { SubmissionStep } from '@constants';
import * as yup from 'yup';

const RequiredFieldErrorMessage = 'Can not be empty or hidden';
const MaxLengthErrorMessage = (maxLength: number) =>
  `Max length is ${maxLength} symbols`;

export const submisionFormValidationSchema = yup.object({
  profile: yup.object({
    email: yup.string().email().optional().nullable(),
    firstName: yup
      .string()
      .max(255, MaxLengthErrorMessage(255))
      .optional()
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.firstName'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema.required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    lastName: yup
      .string()
      .max(255, MaxLengthErrorMessage(255))
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.lastName'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema.required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    country: yup
      .string()
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.country'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema.required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    city: yup
      .string()
      .max(255, MaxLengthErrorMessage(255))
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.city'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema.required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    englishLevel: yup
      .string()
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.englishLevel'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema.required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    specialization: yup.string().optional().nullable(),
    technicalFlow: yup
      .string()
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.technicalFlow'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema.required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    primarySkills: yup
      .array()
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.primarySkills'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema
            .min(1, RequiredFieldErrorMessage)
            .required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    experience: yup
      .number()
      .min(0)
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.experience'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema.required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    seniority: yup
      .string()
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.seniority'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema.required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    noticePeriod: yup
      .string()
      .when(['$step', '$hiddenFields'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const hiddenFields: SubmissionStep = formData[1];

        if (hiddenFields.includes('profile.noticePeriod'))
          return schema.test(
            'hiddenField',
            RequiredFieldErrorMessage,
            () => false,
          );

        if (isStepEqualOrHigherThan(step, SubmissionStep.Profile))
          return schema.required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
    linkedInLink: yup
      .string()
      .nullable()
      .max(255, 'Max length is 255')
      .matches(
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
        'Enter correct url!',
      ),
  }),
  testTask: yup.object({
    feedback: yup
      .string()
      .max(5000, MaxLengthErrorMessage(5000))
      .optional()
      .nullable(),
  }),
  feedback: yup.object({
    feedback: yup
      .string()
      .max(5000, MaxLengthErrorMessage(5000))
      .optional()
      .nullable(),
  }),
  interview: yup.object({
    candidateSlots: yup
      .array(yup.string().required())
      .optional()
      .when(['$step', 'candidateReadyAnytime'], (formData, schema) => {
        const step: SubmissionStep = formData[0];
        const candidateReadyAnytime: boolean = formData[1];

        if (
          isStepEqualOrHigherThan(step, SubmissionStep.Interview) &&
          !candidateReadyAnytime
        )
          return schema
            .min(1, RequiredFieldErrorMessage)
            .required(RequiredFieldErrorMessage);

        return schema;
      })
      .nullable(),
  }),
  clientName: yup
    .string()
    .when(['$step', '$hiddenFields'], (formData, schema) => {
      const step: SubmissionStep = formData[0];

      if (isStepEqualOrHigherThan(step, SubmissionStep.Recipients))
        return schema.required(RequiredFieldErrorMessage);

      return schema;
    })
    .nullable(),
  positions: yup
    .array()
    .when(['$step', '$hiddenFields'], (formData, schema) => {
      const step: SubmissionStep = formData[0];

      if (isStepEqualOrHigherThan(step, SubmissionStep.Recipients))
        return schema
          .min(1, RequiredFieldErrorMessage)
          .required(RequiredFieldErrorMessage);

      return schema;
    })
    .nullable(),
  cc: yup.array(yup.string().email()).optional().nullable(),
  email: yup.object({
    subject: yup.string().optional().max(255).nullable(),
    content: yup.string().optional().max(1000).nullable(),
  }),
});
