import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { JobOpeningSourceOptions } from '@constants';

export const PositionSourceField: React.FC<{
  value: string | null;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({ value, error, touched, required, onChange, onBlur }) => {
  const sourceError = touched && error;

  return (
    <FormControl required={required} fullWidth error={!!sourceError}>
      <InputLabel id="source">Source</InputLabel>
      <Select
        labelId="source"
        value={value || ''}
        label="Source"
        onChange={(e: SelectChangeEvent<string>) =>
          onChange(e.target.value || null)
        }
        onBlur={onBlur}
      >
        {JobOpeningSourceOptions.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography>{item.label}</Typography>
              <Typography variant="body2" color="text.secondary">
                {item.subLabel}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
      {!!sourceError && <FormHelperText>{sourceError}</FormHelperText>}
    </FormControl>
  );
};
