import React from 'react';
import { Outlet } from 'react-router-dom';

type LayoutWrapperProps = {
  layout?: React.ComponentType<any>;
};
export const LayoutWrapper = ({ layout: Layout }: LayoutWrapperProps) => {
  if (!Layout) return <Outlet />;

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
