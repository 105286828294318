import React, { SyntheticEvent, useMemo, useState } from 'react';
import { keyBy } from 'lodash';

import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { AutocompleteTextfield } from '@components';
import { StatusTransition } from './StatusTransition';

import {
  isTruthy,
  getMainProfileTransition,
  formatJobOpeningName,
} from '@utils';
import {
  CandidateStatusFormTransition,
  CandidateStatusFormik,
  IDWHJobOpening,
  IZohoCandidate,
} from '@types';
import { CandidateStatus, CandidateStatusStage } from '@constants';

export const BaseCandidateStatusForm: React.FC<{
  formik: CandidateStatusFormik;
  candidate: IZohoCandidate;
  associatedJopOpenings: IDWHJobOpening[];
}> = ({ formik, candidate, associatedJopOpenings }) => {
  const [updateAllJo, setUpdateAllJo] = useState<boolean>(false);

  const jobOpeningsById = useMemo(
    () => keyBy(associatedJopOpenings, 'id'),
    [associatedJopOpenings],
  );

  const jobOpeningOptions = useMemo(
    () => associatedJopOpenings.map((e) => e.id),
    [associatedJopOpenings],
  );

  const selectedJobOpenings = useMemo(
    () => formik.values.transitions.map((e) => e.jobOpeningId).filter(isTruthy),
    [formik.values.transitions],
  );

  const mainProfileStatusTransitions = formik.values.transitions.find(
    (e) => !e.jobOpeningId,
  );
  const associationsStatusTransitions = formik.values.transitions.filter(
    (e) => e.jobOpeningId,
  );

  const handleUpdateAllJoClick = (e: SyntheticEvent, checked: boolean) => {
    setUpdateAllJo(checked);
  };

  return (
    <>
      {!!mainProfileStatusTransitions && (
        <StatusTransition
          title="Main profile"
          from={{
            status: candidate.Candidate_Status as CandidateStatus,
            stage: candidate.Stage as CandidateStatusStage,
          }}
          to={{
            status: mainProfileStatusTransitions.status as CandidateStatus,
            stage: mainProfileStatusTransitions.stage as CandidateStatusStage,
          }}
          disabled={!!associationsStatusTransitions.length}
          canidateSpecialization={candidate['Dev_-_QA-AQA']}
          onChange={(
            stage: CandidateStatusStage | '',
            status: CandidateStatus | '',
          ) => {
            formik.setValues({
              ...formik.values,
              transitions: formik.values.transitions.map((e) =>
                !e.jobOpeningId
                  ? {
                      stage,
                      status,
                      jobOpeningId: null,
                    }
                  : e,
              ),
            });
          }}
        />
      )}
      <Box width={'100%'}>
        <AutocompleteTextfield
          options={jobOpeningOptions}
          value={selectedJobOpenings}
          onChange={(e: SyntheticEvent, newValue: string[]) => {
            formik.setFieldValue('transitions', [
              mainProfileStatusTransitions,
              ...newValue?.map((jobId) => {
                const currentJobStatusChange =
                  associationsStatusTransitions.find(
                    (e) => e.jobOpeningId === jobId,
                  );

                return {
                  stage: currentJobStatusChange?.stage || '',
                  status: currentJobStatusChange?.status || '',
                  jobOpeningId: jobId,
                };
              }),
            ]);
          }}
          getOptionLabel={(option) =>
            formatJobOpeningName(
              jobOpeningsById[option]?.Job_Opening_Name,
              jobOpeningsById[option]?.Job_Opening_Id,
              jobOpeningsById[option]?.Client_Name,
            ) || option
          }
          label="Associated position(s)"
          fullWidth
          multiple
          filterSelectedOptions
        />
        <FormControlLabel
          control={<Checkbox size="small" />}
          label="Update all selected positions"
          value={updateAllJo}
          onChange={handleUpdateAllJoClick}
        />
      </Box>
      {associationsStatusTransitions.map((transition) => {
        const jobOpening = jobOpeningsById[transition.jobOpeningId as string];

        return (
          <StatusTransition
            key={`${transition.status} ${transition.jobOpeningId}`}
            title={
              formatJobOpeningName(
                jobOpening?.Job_Opening_Name,
                jobOpening?.Job_Opening_Id,
                jobOpening?.Client_Name,
              ) || ''
            }
            from={{
              stage: (jobOpening?.Stage || '') as CandidateStatusStage,
              status: (jobOpening?.Status || '') as CandidateStatus,
            }}
            to={{
              stage: transition.stage as CandidateStatusStage,
              status: transition.status as CandidateStatus,
            }}
            canidateSpecialization={candidate['Dev_-_QA-AQA']}
            onChange={(
              stage: CandidateStatusStage | '',
              status: CandidateStatus | '',
            ) => {
              const nextAssociatedTransitions = formik.values.transitions
                .filter((e) => e.jobOpeningId)
                .map((e) =>
                  e.jobOpeningId === transition.jobOpeningId || updateAllJo
                    ? {
                        stage,
                        status,
                        jobOpeningId: e.jobOpeningId,
                      }
                    : e,
                );

              const otherAssociationTransitions: CandidateStatusFormTransition[] =
                associatedJopOpenings
                  .filter(
                    (jo) =>
                      !nextAssociatedTransitions.find(
                        (association) => association.jobOpeningId === jo.id,
                      ),
                  )
                  .map((jo) => ({
                    stage: (jo.Stage as CandidateStatusStage) || '',
                    status: (jo.Status as CandidateStatus) || '',
                    jobOpeningId: jo.id,
                  }));

              formik.setFieldValue('transitions', [
                getMainProfileTransition(
                  [
                    ...nextAssociatedTransitions,
                    ...otherAssociationTransitions,
                  ],
                  candidate,
                ) || mainProfileStatusTransitions,
                ...nextAssociatedTransitions,
              ]);
            }}
          />
        );
      })}
    </>
  );
};
