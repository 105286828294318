import React, { useCallback, useState } from 'react';
import { useAppSelector } from '@redux/hooks';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { format } from 'date-fns';

import {
  Box,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { DatePicker } from '@mui/x-date-pickers';
import {
  RowLayout,
  CandidateField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CustomButton,
} from '@components';
import { MeetingNoShowModal } from './MeetingNoShowModal';
import { MeetingCancelModal } from './MeetingCancelModal';

import { authSelectors } from '@redux/auth';

import {
  checkUserCanLeaveClientInterviewFeedback,
  checkUserCanLeaveIntroCallFeedbcak,
  checkUserCanLeaveTeamLeadIntroFeedback,
  checkUserCanLeaveTechnicalInterviewFeedback,
  checkUserCanUpdateCandidateStatus,
} from '@utils';
import {
  ICandidateMeeting,
  IZohoCandidate,
  MeetingAdditionalFieldsType,
  MeetingReviewPayload,
} from '@types';
import {
  AppRoutes,
  MeetingResolution,
  MeetingResolutionOptions,
  MeetingStatus,
  MeetingType,
  MeetingTypeLabel,
} from '@constants';

import { meetingFeedbackValidationSchema } from './validationSchema';

export const MeetingItem: React.FC<{
  meeting: ICandidateMeeting;
  candidate: IZohoCandidate;
  onMeetingFeedbackSubmit: (
    meetingId: string,
    payload: MeetingReviewPayload,
  ) => void;
  isSubmittingData: boolean;
  selectedMeetingId: string | null;
  handleMeetingChange: (newValue: string) => void;
}> = ({
  meeting,
  candidate,
  isSubmittingData,
  selectedMeetingId,
  onMeetingFeedbackSubmit,
  handleMeetingChange,
}) => {
  const profile = useAppSelector(authSelectors.getProfileData);

  const [isNoShowModalOpen, setIsNoShowModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const formik = useFormik<{
    doneDate: string;
    resolution: string;
    feedback: string;
    additionalFields: MeetingAdditionalFieldsType;
  }>({
    initialValues: {
      doneDate: meeting?.doneDate || '',
      resolution: meeting?.resolution || '',
      feedback: meeting?.feedback || '',
      additionalFields: meeting?.additionalFields || {},
    },
    validationSchema: meetingFeedbackValidationSchema,
    onSubmit: async (values) => {
      if (!meeting) return;

      const payload: MeetingReviewPayload = {
        doneDate: values.doneDate,
        resolution: values.resolution as MeetingResolution,
        feedback: values.feedback,
        additionalFields: isEmpty(values.additionalFields)
          ? null
          : values.additionalFields,
      };

      onMeetingFeedbackSubmit(meeting.id, payload);
    },
    enableReinitialize: true,
  });

  const isUserAbleToChangeICStatus = checkUserCanUpdateCandidateStatus(profile);
  const isUserAbleToProvideFeedback =
    meeting.type === MeetingType.IntroCall
      ? checkUserCanLeaveIntroCallFeedbcak(profile)
      : meeting.type === MeetingType.TechnicalInterview
      ? checkUserCanLeaveTechnicalInterviewFeedback(profile)
      : meeting.type === MeetingType.ClientInterview
      ? checkUserCanLeaveClientInterviewFeedback(profile)
      : meeting.type === MeetingType.TeamLeadIntro
      ? checkUserCanLeaveTeamLeadIntroFeedback(profile)
      : false;

  const isFormDisabled = !isUserAbleToProvideFeedback || isSubmittingData;
  const isSubmitDisabled = isFormDisabled || !formik.dirty;

  const doneDateError =
    formik.submitCount && formik.errors.doneDate ? formik.errors.doneDate : '';
  const resolutionError =
    (formik.submitCount || formik.touched.resolution) &&
    formik.errors.resolution
      ? formik.errors.resolution
      : '';
  const feedbackError =
    (formik.submitCount || formik.touched.feedback) && formik.errors.feedback
      ? formik.errors.feedback
      : '';

  const getStatusColor = useCallback((status: MeetingStatus) => {
    if (status === MeetingStatus.Passed) {
      return 'text.success';
    } else if (status === MeetingStatus.Failed) {
      return 'text.danger';
    } else {
      return 'text.primary';
    }
  }, []);

  const handleAccordionClick =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      handleMeetingChange(newExpanded ? panel : '');
    };

  if (!meeting) return null;

  return (
    <React.Fragment>
      <Accordion
        key={meeting.id}
        expanded={selectedMeetingId === meeting.id}
        onChange={handleAccordionClick(meeting.id)}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id={meeting.id}
          sx={{
            '& .MuiAccordionSummary-content': {
              gap: '0.5rem',
              alignItems: 'center',
            },
          }}
        >
          <Typography variant="body1" flex={1}>
            {MeetingTypeLabel[meeting.type]}
          </Typography>
          <Typography variant="body1" flex={1}>
            {meeting.participants?.join(', ')}
          </Typography>
          <Typography variant="body1" color={getStatusColor(meeting.status)}>
            {meeting.status}
          </Typography>
          <Typography variant="body2" color={'text.secondary'}>
            {meeting.scheduledDate}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction={'column'} gap={'1rem'}>
            <Stack>
              <RowLayout
                label="Associated position"
                Component={
                  meeting.jobOpenings?.length ? (
                    <>
                      {meeting.jobOpenings.map((jo) => (
                        <Link
                          key={jo.id}
                          href={`/${AppRoutes.JOB_OPENINGS}/${jo.id}`}
                          target="_blank"
                          variant="body1"
                        >
                          <Box component="span" color="text.secondary">
                            #{jo.Job_Opening_Id}
                          </Box>{' '}
                          {jo.Client_Name}: {jo.Job_Opening_Name}
                          {'\n'}
                        </Link>
                      ))}
                    </>
                  ) : (
                    <Typography variant="body1" color="text.secondary">
                      —
                    </Typography>
                  )
                }
              />
            </Stack>
            <Stack direction={'row'} gap={'1rem'}>
              <TextField
                select
                fullWidth
                error={!!resolutionError}
                helperText={!!resolutionError && resolutionError}
                label={'Resolution'}
                value={formik.values.resolution}
                onChange={(event) =>
                  formik.setFieldValue('resolution', event.target.value)
                }
                disabled={isFormDisabled}
              >
                {MeetingResolutionOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <DatePicker
                label={'Meeting done date'}
                value={
                  formik.values.doneDate ? dayjs(formik.values.doneDate) : null
                }
                sx={{ width: '100%' }}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    'doneDate',
                    newValue?.format('YYYY-MM-DD') || '',
                  );
                }}
                disabled={isFormDisabled}
                slotProps={{
                  textField: {
                    helperText: !!doneDateError && doneDateError,
                    error: !!doneDateError,
                    fullWidth: true,
                    required: true,
                  },
                }}
              />
              <CustomButton
                isDisabled={isFormDisabled}
                size="small"
                color="secondary"
                label={'Today'}
                onClick={() => {
                  formik.setFieldValue(
                    'doneDate',
                    format(new Date(), 'yyyy-MM-dd'),
                  );
                }}
                startIcon={<EventAvailableRoundedIcon fontSize="small" />}
              />
            </Stack>
            <TextField
              name="feedback"
              label="Feedback"
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              value={formik.values.feedback}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={feedbackError}
              error={!!feedbackError}
              disabled={isFormDisabled}
            />
            {meeting.type === MeetingType.TechnicalInterview && (
              <CandidateField
                field="TI_Suggested_Seniority"
                value={
                  formik.values.additionalFields?.TI_Suggested_Seniority || null
                }
                onChange={(value) =>
                  formik.setFieldValue('additionalFields', {
                    ...(formik.values.additionalFields || {}),
                    TI_Suggested_Seniority: value || null,
                  })
                }
                disabled={isFormDisabled}
              />
            )}
            <Stack direction={'row'} gap={'1rem'}>
              <Stack flex={1} direction={'row'}>
                <CustomButton
                  size="small"
                  label={'Save'}
                  onClick={() => formik.handleSubmit()}
                  startIcon={<SaveRoundedIcon fontSize="small" />}
                  isDisabled={isSubmitDisabled}
                />
              </Stack>
              {meeting.type === MeetingType.IntroCall &&
                meeting.status === MeetingStatus.Scheduled && (
                  <CustomButton
                    size="small"
                    color="secondary"
                    label={'Mark as no-show'}
                    onClick={() => setIsNoShowModalOpen(true)}
                    startIcon={<PersonOffOutlinedIcon fontSize="small" />}
                    isDisabled={!isUserAbleToChangeICStatus}
                  />
                )}
              {meeting.type === MeetingType.IntroCall &&
                meeting.status === MeetingStatus.Scheduled && (
                  <CustomButton
                    size="small"
                    color="secondary"
                    label={'Cancel event'}
                    onClick={() => setIsCancelModalOpen(true)}
                    startIcon={<PersonOffOutlinedIcon fontSize="small" />}
                    isDisabled={!isUserAbleToChangeICStatus}
                  />
                )}
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <MeetingNoShowModal
        candidate={candidate}
        meetingType={meeting.type}
        isOpen={isNoShowModalOpen}
        onClose={() => setIsNoShowModalOpen(false)}
      />
      <MeetingCancelModal
        candidate={candidate}
        meetingType={meeting.type}
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
      />
    </React.Fragment>
  );
};
