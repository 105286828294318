import { useState } from 'react';
import { getIn } from 'formik';

import {
  HiddenBlockWrapper,
  PersonSignature,
  ReviewerSelectField,
} from '@components';
import { Box, Button, TextField, Typography } from '@mui/material';

import { SubmissionFormik } from '@types';

export const SubmissionFeedbackStep: React.FC<{
  formik: SubmissionFormik;
  onHideFieldToggle: (field: string, isHidden: boolean) => void;
}> = ({ formik, onHideFieldToggle }) => {
  const [isEditableMode, setIsEditableMode] = useState(false);
  const isHidden = formik.values.hiddenFields.includes('feedback');

  return (
    <Box display="flex" flexDirection="column" gap={2} py={2}>
      <HiddenBlockWrapper
        hidden={isHidden}
        onHideToggle={(isHidden) => onHideFieldToggle('feedback', isHidden)}
      >
        <Typography variant="h3">
          Feedback from AgileEngine interviewers
        </Typography>
      </HiddenBlockWrapper>
      {isEditableMode ? (
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            label="Feedback"
            variant="outlined"
            fullWidth
            value={formik.values.feedback?.feedback || ''}
            onChange={(e) =>
              formik.setFieldValue('feedback.feedback', e.target.value || null)
            }
            multiline
            minRows={3}
            onBlur={() => formik.setFieldTouched('feedback.feedback')}
            helperText={
              !!getIn(formik.touched, 'feedback.feedback') ||
              !!formik.submitCount
                ? getIn(formik.errors, 'feedback.feedback')
                : undefined
            }
            error={
              (!!getIn(formik.touched, 'feedback.feedback') ||
                !!formik.submitCount) &&
              !!getIn(formik.errors, 'feedback.feedback')
            }
          />
          <ReviewerSelectField
            value={formik.values.feedback?.reviewer?.email || ''}
            onChange={(person) =>
              formik.setFieldValue('feedback.reviewer', person)
            }
          />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={1}>
          {formik.values.feedback?.feedback ? (
            <Typography whiteSpace="pre-line">
              {formik.values.feedback?.feedback}
            </Typography>
          ) : (
            <Typography variant="body1" color={'text.secondary'}>
              No feedback
            </Typography>
          )}
          {!!formik.values.feedback?.reviewer && (
            <PersonSignature person={formik.values.feedback?.reviewer} />
          )}
          <Button
            variant="contained"
            onClick={() => setIsEditableMode(true)}
            sx={{
              backgroundColor: '#3F8CFF14',
              color: '#42A5F5',
              textTransform: 'none',
              display: 'flex',
            }}
            disabled={isHidden}
          >
            Edit feedback
          </Button>
        </Box>
      )}
    </Box>
  );
};
