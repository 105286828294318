import { setTitle } from '@redux/pageTitle';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const DEFAULT_TITLE = 'LP Dashboard';

export const useTitle = (): ((title: string) => void) => {
  const dispatch = useDispatch();

  const setPageTitle = useCallback(
    (title: string) => {
      dispatch(setTitle(title));
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(setTitle(DEFAULT_TITLE));
    };
  }, [dispatch]);

  return setPageTitle;
};
