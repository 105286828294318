export const LocationDetailsByCountry: Record<
  string,
  { area: string; timezone: string }
> = {
  Ukraine: {
    area: 'Ukraine',
    timezone: '+2 East',
  },
  Argentina: {
    area: 'LatAm',
    timezone: '-3 West',
  },
  Guatemala: {
    area: 'LatAm',
    timezone: '-6 West',
  },
  Colombia: {
    area: 'LatAm',
    timezone: '-5 West',
  },
  Mexico: {
    area: 'LatAm',
    timezone: '-6 West',
  },
  India: {
    area: 'India',
    timezone: '+5.30 East',
  },
  Peru: {
    area: 'LatAm',
    timezone: '-5 West',
  },
  USA: {
    area: 'USA',
    timezone: '-5 West',
  },
  Kenya: {
    area: 'Africa',
    timezone: '+3 East',
  },
  Egypt: {
    area: 'Africa',
    timezone: '+2 East',
  },
  Russia: {
    area: 'Other',
    timezone: '+3 East',
  },
  'Costa Rica': {
    area: 'LatAm',
    timezone: '-6 West',
  },
  Brazil: {
    area: 'LatAm',
    timezone: '-3 West',
  },
  Poland: {
    area: 'Europe',
    timezone: '+1 East',
  },
  Canada: {
    area: 'Other',
    timezone: '-6 West',
  },
  Europe: {
    area: 'Europe',
    timezone: '+2 East',
  },
  Venezuela: {
    area: 'LatAm',
    timezone: '-4 West',
  },
  Portugal: {
    area: 'Europe',
    timezone: '+1 West',
  },
  Spain: {
    area: 'Europe',
    timezone: '+2 West',
  },
  'Alabama, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'California, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Colorado, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Florida, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Georgia, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Illinois, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Iowa, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Maryland, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Massachusetts, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Michigan, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Minnesota, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'New Jersey, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'New York, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'North Carolina, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Ohio, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'South Carolina, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Tennessee, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Texas, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Utah, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Virginia, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  'Washington, USA': {
    area: 'USA',
    timezone: '-5 West',
  },
  Slovakia: {
    area: 'Europe',
    timezone: '+1 East',
  },
  Romania: {
    area: 'Europe',
    timezone: '+2 East',
  },
  Slovenia: {
    area: 'UkraEuropeine',
    timezone: '+1 East',
  },
  Bulgaria: {
    area: 'Europe',
    timezone: '+2 East',
  },
  Africa: {
    area: 'Africa',
    timezone: '+2 East',
  },
};
