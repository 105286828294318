import React from 'react';

import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { OverflowTooltip } from '@components';

import { getDisplayValue } from '@utils';
import { ICandidateProcess } from '@types';
import { CandidateFieldsLabels } from '@constants';

const EmptyValue = 'Empty';

const StyledTableRow = styled(TableRow)(() => ({
  ':last-of-type td, :last-of-type th': {
    borderBottom: 'none',
  },
}));

const StyledTableCell = styled(TableCell)({
  padding: '0.5rem',
});

export const CandidateTimelineActivityTable: React.FC<{
  activity: ICandidateProcess;
}> = ({ activity }) => {
  return (
    <Table sx={{ tableLayout: 'fixed' }} size="small">
      <TableBody>
        {activity.profileUpdates.length ? (
          activity.profileUpdates.map((update) => {
            const previousValue = getDisplayValue(
              JSON.parse(update.previousValue),
            );
            const nextValue = getDisplayValue(JSON.parse(update.nextValue));

            if (previousValue === nextValue) return null;

            return (
              <StyledTableRow key={update.id}>
                <StyledTableCell component="th" scope="row" width="24%">
                  <OverflowTooltip>
                    {CandidateFieldsLabels[update.fieldName]}
                  </OverflowTooltip>
                </StyledTableCell>
                <StyledTableCell
                  width="16%"
                  sx={{
                    color:
                      previousValue === EmptyValue
                        ? 'text.secondary'
                        : 'text.primary',
                  }}
                >
                  <OverflowTooltip>{previousValue}</OverflowTooltip>
                </StyledTableCell>
                <StyledTableCell width="36px">
                  <Box>
                    <EastIcon
                      fontSize="small"
                      sx={{
                        verticalAlign: 'middle',
                        color: 'background.disabled',
                      }}
                    />
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    color:
                      nextValue === EmptyValue
                        ? 'text.secondary'
                        : 'text.primary',
                  }}
                >
                  <OverflowTooltip>{nextValue}</OverflowTooltip>
                </StyledTableCell>
              </StyledTableRow>
            );
          })
        ) : (
          <StyledTableRow>
            <StyledTableCell
              component="th"
              scope="row"
              colSpan={1}
              align="center"
            >
              No details
            </StyledTableCell>
          </StyledTableRow>
        )}
      </TableBody>
    </Table>
  );
};
