import { Alert, Button, CircularProgress } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  CandidateField,
  FileInput,
  Flex,
  ModalSectionLayout,
} from '@components';
import { DuplicateCandidate } from './DuplicateCandidate';

import { CreateCandidateFormik, IDWHCandidate } from '@types';
import { RESUME_ACCEPTED_EXTENTIONS } from '@constants';

export const ContactDetailsSection: React.FC<{
  formik: CreateCandidateFormik;
  duplicates: Partial<Record<keyof IDWHCandidate, IDWHCandidate[]>> | null;
  isLoadingDuplicates: boolean;
  onCheckDuplicatesClick: (value: {
    field: keyof IDWHCandidate;
    value: string;
  }) => void;
}> = ({ formik, duplicates, isLoadingDuplicates, onCheckDuplicatesClick }) => {
  return (
    <ModalSectionLayout title="Contact details">
      <Flex flexDirection="column" gap={1} width="100%">
        <Alert
          variant="standard"
          severity="info"
          icon={<MailOutlineIcon />}
          sx={{ width: '100%' }}
        >
          Please necessarily add LinkedIn to be able to contact the candidate
        </Alert>
        <Flex flexDirection="column" gap={1}>
          <CandidateField
            field="LinkedIn_URL"
            value={formik.values.LinkedIn_URL}
            required
            touched={!!formik.touched.LinkedIn_URL || !!formik.submitCount}
            error={formik.errors.LinkedIn_URL}
            onChange={(value) => formik.setFieldValue('LinkedIn_URL', value)}
            onBlur={() => formik.setFieldTouched('LinkedIn_URL')}
            endAdornment={
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  onCheckDuplicatesClick({
                    field: 'LinkedIn_URL',
                    value: formik.values.LinkedIn_URL!,
                  })
                }
                sx={{
                  textWrap: 'nowrap',
                  paddingLeft: 1,
                  paddingRight: 1,
                  minWidth: 'auto',
                }}
                disabled={!formik.values.LinkedIn_URL || isLoadingDuplicates}
              >
                {isLoadingDuplicates ? (
                  <CircularProgress
                    size={16}
                    color="secondary"
                    sx={{ marginRight: 1 }}
                  />
                ) : (
                  'Check duplicates'
                )}
              </Button>
            }
          />
          {!!duplicates?.LinkedIn_URL?.length &&
            duplicates?.LinkedIn_URL.map((candidate) => (
              <DuplicateCandidate key={candidate.id} data={candidate} />
            ))}
        </Flex>
        <Flex flexDirection="column" gap={1}>
          <CandidateField
            field="Email"
            value={formik.values.Email}
            touched={!!formik.touched.Email || !!formik.submitCount}
            error={formik.errors.Email}
            onChange={(value) => formik.setFieldValue('Email', value)}
            onBlur={() => formik.setFieldTouched('Email')}
            endAdornment={
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  onCheckDuplicatesClick({
                    field: 'Email',
                    value: formik.values.Email!,
                  })
                }
                sx={{
                  textWrap: 'nowrap',
                  paddingLeft: 1,
                  paddingRight: 1,
                  minWidth: 'auto',
                }}
                disabled={!formik.values.Email || isLoadingDuplicates}
              >
                {isLoadingDuplicates ? (
                  <CircularProgress
                    size={16}
                    color="secondary"
                    sx={{ marginRight: 1 }}
                  />
                ) : (
                  'Check duplicates'
                )}
              </Button>
            }
          />
          {!!duplicates?.Email?.length &&
            duplicates?.Email.map((candidate) => (
              <DuplicateCandidate key={candidate.id} data={candidate} />
            ))}
        </Flex>
        <CandidateField
          field="First_Name"
          value={formik.values.First_Name}
          required
          touched={!!formik.touched.First_Name || !!formik.submitCount}
          error={formik.errors.First_Name}
          onChange={(value) => formik.setFieldValue('First_Name', value)}
          onBlur={() => formik.setFieldTouched('First_Name')}
        />
        <CandidateField
          field="Last_Name"
          value={formik.values.Last_Name}
          required
          touched={!!formik.touched.Last_Name || !!formik.submitCount}
          error={formik.errors.Last_Name}
          onChange={(value) => formik.setFieldValue('Last_Name', value)}
          onBlur={() => formik.setFieldTouched('Last_Name')}
        />
        <FileInput
          label="Attach resume"
          name="resume"
          acceptedExtensions={RESUME_ACCEPTED_EXTENTIONS}
          onChange={({ files }) =>
            formik.setValues({
              ...formik.values,
              resume: files[0] || null,
            })
          }
          value={formik.values.resume ? [formik.values.resume.name] : null}
        />
        <CandidateField
          field="Djinni_URL"
          value={formik.values.Djinni_URL}
          touched={!!formik.touched.Djinni_URL || !!formik.submitCount}
          error={formik.errors.Djinni_URL}
          onChange={(value) => formik.setFieldValue('Djinni_URL', value)}
          onBlur={() => formik.setFieldTouched('Djinni_URL')}
        />
        <CandidateField
          field="GitHub_Link"
          value={formik.values.GitHub_Link}
          touched={!!formik.touched.GitHub_Link || !!formik.submitCount}
          error={formik.errors.GitHub_Link}
          onChange={(value) => formik.setFieldValue('GitHub_Link', value)}
          onBlur={() => formik.setFieldTouched('GitHub_Link')}
        />
        <CandidateField
          field="Phone"
          value={formik.values.Phone}
          touched={!!formik.touched.Phone || !!formik.submitCount}
          error={formik.errors.Phone}
          onChange={(value) => formik.setFieldValue('Phone', value)}
          onBlur={() => formik.setFieldTouched('Phone')}
        />
      </Flex>
    </ModalSectionLayout>
  );
};
