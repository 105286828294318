import React from 'react';

import { CountryFlag } from '@components';
import { Box, Chip, TableCell } from '@mui/material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { getCandidateName } from '@utils';
import { IDWHCandidate } from '@types';
import { AppRoutes } from '@constants';

export const TableCellNewHomers: React.FC<{
  candidate: IDWHCandidate;
}> = ({ candidate }) => {
  const openCandidateDetailsInNewTab = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(
      `/${AppRoutes.CANDIDATES}/${candidate.id}`,
      '_blank',
      'noreferrer',
    );
  };

  return (
    <>
      <TableCell align="left">
        <Box display="flex">
          <CountryFlag
            country={candidate.Location_Country}
            sx={{ mr: '0.5rem' }}
          />
          {getCandidateName({
            fullName: candidate.Full_Name,
            firstName: candidate.First_Name,
            lastName: candidate.Last_Name,
            englishFirstName: candidate.English_First_Name,
            englishLastName: candidate.English_Last_Name,
          }) || '-'}
          <OpenInNewIcon
            fontSize="small"
            sx={{ color: 'text.link', ml: 1 }}
            onClick={openCandidateDetailsInNewTab}
          />
        </Box>
      </TableCell>

      <TableCell align="left">{candidate.Seniority_Level || '-'}</TableCell>
      <TableCell align="left">
        <Chip label={candidate.Technical_Flow || '-'} color="primary" />
      </TableCell>
      <TableCell align="left">{'N/A'}</TableCell>
      <TableCell align="left">{candidate.New_Home_Date_Start || '-'}</TableCell>
      <TableCell align="left">{candidate.New_Home_Date_End || '-'}</TableCell>
      <TableCell align="left">N/A</TableCell>
      <TableCell align="right">
        <NavigateNextRoundedIcon color="secondary" />
      </TableCell>
    </>
  );
};
