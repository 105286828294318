import { Dialog, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';
import { CandidateStatusForm } from './CandidateStatusForm/CandidateStatusForm';

import { IDWHJobOpening, IZohoCandidate } from '@types';
import { CandidateStatusStage } from '@constants';

export const CandidateStatusChangeModal: React.FC<{
  candidate: IZohoCandidate | null;
  selectedJobOpeningId: string | null;
  associatedJopOpenings: IDWHJobOpening[];
  isOpen: boolean;
  preselectedStage: CandidateStatusStage | null;
  onModalClose: () => void;
}> = ({
  candidate,
  selectedJobOpeningId,
  associatedJopOpenings,
  isOpen,
  preselectedStage,
  onModalClose,
}) => {
  if (!candidate) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onModalClose}
      scroll="body"
      fullWidth
      sx={{ '.MuiDialog-paper': { maxWidth: '1024px' } }}
    >
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h1">Change status</Typography>
          <CloseIcon
            onClick={onModalClose}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>

      <CandidateStatusForm
        candidate={candidate}
        selectedJobOpeningId={selectedJobOpeningId}
        associatedJopOpenings={associatedJopOpenings}
        preselectedStage={preselectedStage}
        onCancel={onModalClose}
      />
    </Dialog>
  );
};
