import React from 'react';

import {
  Box,
  Chip,
  Link,
  TableCell,
  Tooltip,
  Typography,
  styled,
  Stack,
} from '@mui/material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CountryFlag } from '@components';
import {
  getDifferenceInDays,
  isJobPreopen,
  getPriorityIconWithOutreach,
} from '@utils';
import { IDWHJobOpening } from '@types';
import { AppRoutes, JobOpeningsGroupingOptions } from '@constants';

export const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: '0.5rem',
  border: '1px dashed',
  borderRadius: '0.375rem',
  padding: '0 0.25rem',
  fontSize: '90%',
  background: theme.palette.background.warningFill,
  height: 'auto',
  color: theme.palette.text.secondary,
  '& .MuiChip-label': {
    padding: 0,
  },
}));

export const PositionRow: React.FC<{
  jobOpening: IDWHJobOpening;
  groupBy: JobOpeningsGroupingOptions | null;
}> = ({ jobOpening, groupBy }) => {
  const {
    Priority,
    Job_Opening_Id,
    Job_Opening_Name,
    Client_Name,
    Date_Opened_Official,
    Location_Area,
    TechnicalFlow,
    Seniority_Level,
    Client_Recruiter,
    Number_of_submissions,
    Date_of_stop_outreach,
  } = jobOpening;

  const {
    IconComponent,
    hasOutreachStopped,
    StyledBox,
    StyledStoppedOutreachIcon,
  } = getPriorityIconWithOutreach(
    Priority,
    false, // isOnHold
    false, // isClosed
    Date_of_stop_outreach,
  );

  const openPositionDetailsInNewTab = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(
      `/${AppRoutes.JOB_OPENINGS}/${jobOpening.id}`,
      '_blank',
      'noreferrer',
    );
  };

  return (
    <>
      <TableCell
        style={{ verticalAlign: 'top' }}
        colSpan={groupBy === JobOpeningsGroupingOptions.Priority ? 1 : 2}
      >
        <Stack gap={'0.5rem'} direction={'row'}>
          {IconComponent && (
            <Tooltip
              title={
                hasOutreachStopped ? `${Priority} (No outreach)` : Priority
              }
              placement="top"
            >
              <StyledBox>
                <IconComponent />
                {hasOutreachStopped && <StyledStoppedOutreachIcon />}
              </StyledBox>
            </Tooltip>
          )}
          <Box>
            {isJobPreopen(jobOpening) && (
              <StyledChip label={'Pre-open'} size={'small'} />
            )}
            <Typography
              variant="body2"
              color="text.secondary"
              marginRight={'0.25rem'}
              component={'span'}
            >
              {Job_Opening_Id}
            </Typography>

            <Link
              color="text.primary"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  color: (theme) => theme.palette.text.link,
                  textDecoration: 'none',
                },
              }}
            >
              {Job_Opening_Name}
            </Link>
          </Box>
          <OpenInNewIcon
            fontSize="small"
            sx={{ color: 'text.link' }}
            onClick={openPositionDetailsInNewTab}
          />
        </Stack>
      </TableCell>
      {groupBy !== JobOpeningsGroupingOptions.ACCOUNT && (
        <TableCell style={{ verticalAlign: 'top' }} align="left">
          {Client_Name}
        </TableCell>
      )}
      <TableCell style={{ verticalAlign: 'top' }} align="right">
        {getDifferenceInDays(Date_Opened_Official)}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        {Location_Area.map((country: string) => (
          <CountryFlag country={country} key={country} />
        ))}
      </TableCell>
      {groupBy !== JobOpeningsGroupingOptions.TECHNICAL_FLOW && (
        <TableCell style={{ verticalAlign: 'top' }}>{TechnicalFlow}</TableCell>
      )}
      <TableCell style={{ verticalAlign: 'top' }}>
        {Seniority_Level.join(', ')}
      </TableCell>
      {groupBy !== JobOpeningsGroupingOptions.Recruiter && (
        <TableCell style={{ verticalAlign: 'top' }}>
          {Client_Recruiter.join(', ') || '—'}
        </TableCell>
      )}
      <TableCell style={{ verticalAlign: 'top' }}>
        {Number_of_submissions ? parseInt(Number_of_submissions) : ''}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} align="right">
        <NavigateNextRoundedIcon color="secondary" />
      </TableCell>
    </>
  );
};
