import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { findKey } from 'lodash';
import { format } from 'date-fns';

import { Box, Button, Modal, Typography } from '@mui/material';
import { Flex, JobOpeningField } from '@components';
import CloseIcon from '@mui/icons-material/Close';

import {
  closeJobOpening,
  jobOpeningDetailsSelectors,
} from '@redux/jobOpeningDetails';
import {
  AppRoutes,
  GrouppedJobOpeningCLosingReasons,
  JobOpeningReasonOfClosing,
  JobOpeningStatus,
} from '@constants';
import { CloseJobOpeningFormValues, CloseJobOpeningPayload } from '@types';

const getSelectedReasonOfClosing = (reasonOfClosing: any[]) => {
  const currentReasonOfClosing =
    reasonOfClosing?.[0] as JobOpeningReasonOfClosing;
  return findKey(GrouppedJobOpeningCLosingReasons, (closingReasons) =>
    closingReasons.includes(currentReasonOfClosing),
  );
};

const validationSchema = yup.object({
  Date_Closed: yup.date().required('Date of closing is required'),
  Reason_of_Closing: yup
    .string()
    .oneOf(Object.values(JobOpeningReasonOfClosing))
    .required('Reson of closing is required'),
  Number_of_submissions: yup
    .number()
    .required('Number of submissions is required'),

  First_submission: yup
    .string()
    .optional()
    .when('Reason_of_Closing', (reasonOfClosing, schema) => {
      const jobOpeningsStatus = getSelectedReasonOfClosing(reasonOfClosing);
      if (jobOpeningsStatus === JobOpeningStatus.Filled)
        return schema.required('First submission date is required');
      return schema;
    })
    .nullable(),
  Candidate_Start_Date: yup
    .string()
    .optional()
    .when('Reason_of_Closing', (reasonOfClosing, schema) => {
      const jobOpeningsStatus = getSelectedReasonOfClosing(reasonOfClosing);
      if (jobOpeningsStatus === JobOpeningStatus.Filled)
        return schema.required('Candidate date is required');
      return schema;
    })
    .nullable(),
  Location_of_Closing: yup
    .string()
    .optional()
    .when('Reason_of_Closing', (reasonOfClosing, schema) => {
      const jobOpeningsStatus = getSelectedReasonOfClosing(reasonOfClosing);
      if (jobOpeningsStatus === JobOpeningStatus.Filled)
        return schema.required('Location of closing is required');
      return schema;
    })
    .nullable(),
  Seniority_of_closing: yup
    .string()
    .optional()
    .when('Reason_of_Closing', (reasonOfClosing, schema) => {
      const jobOpeningsStatus = getSelectedReasonOfClosing(reasonOfClosing);
      if (jobOpeningsStatus === JobOpeningStatus.Filled)
        return schema.required('Seniority of closing is required');
      return schema;
    })
    .nullable(),
  Candidate_Recruiter: yup
    .string()
    .optional()
    .when('Reason_of_Closing', (reasonOfClosing, schema) => {
      const jobOpeningsStatus = getSelectedReasonOfClosing(reasonOfClosing);
      if (jobOpeningsStatus === JobOpeningStatus.Filled)
        return schema.required('Candidate recruiter is required');
      return schema;
    })
    .nullable(),
});

export const CloseJobOpeningModal: React.FC<{
  isOpen: boolean;
  onModalClose: () => void;
}> = ({ isOpen, onModalClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const jobOpeningDetails = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsData,
  );
  const joIsPerformingAction = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningIsPerformingAction,
  );

  const formik = useFormik<CloseJobOpeningFormValues>({
    initialValues: {
      Date_Closed: null,
      Reason_of_Closing: null,
      Number_of_submissions: jobOpeningDetails?.Number_of_submissions || null,
      First_submission: null,
      Candidate_Start_Date: null,
      Location_of_Closing: null,
      Seniority_of_closing: null,
      Candidate_Recruiter: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!jobOpeningDetails) return;

      const isCurrentlyOnHold =
        jobOpeningDetails.Job_Opening_Status === JobOpeningStatus.Closed &&
        jobOpeningDetails.On_hold_date;
      const isTransferToFilled =
        nextJobOpeningStatus === JobOpeningStatus.Filled;

      const formatedToday = format(new Date(), 'yyyy-MM-dd');

      const payload: CloseJobOpeningPayload = {
        id: jobOpeningDetails.id,
        Job_Opening_Status: nextJobOpeningStatus as string,
        Date_Closed: values.Date_Closed!,
        Reason_of_Closing: values.Reason_of_Closing!,
        Number_of_submissions: values.Number_of_submissions!,
      };

      if (isTransferToFilled) {
        payload.First_submission = values.First_submission!;
        payload.Candidate_Start_Date = values.Candidate_Start_Date!;
        payload.Location_of_Closing = values.Location_of_Closing!;
        payload.Seniority_of_closing = values.Seniority_of_closing!;
        payload.Candidate_Recruiter = values.Candidate_Recruiter!;
      }

      if (isCurrentlyOnHold) {
        payload.On_hold_off = formatedToday;
      }

      await dispatch(closeJobOpening(payload));
      onModalClose();
      navigate(`/${AppRoutes.JOB_OPENINGS}`);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isOpen) formik.resetForm();
  }, [isOpen]);

  const nextJobOpeningStatus = findKey(
    GrouppedJobOpeningCLosingReasons,
    (closingReasons) =>
      closingReasons.includes(
        formik.values.Reason_of_Closing as JobOpeningReasonOfClosing,
      ),
  );

  return (
    <Modal open={isOpen} onClose={onModalClose}>
      <Box
        sx={{ width: '100%', height: '100%' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          sx={{
            maxWidth: '511px',
            background: '#FFF',
            borderRadius: '4px',
            boxShadow:
              '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)',
          }}
        >
          <Flex
            px={3}
            py={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h1">Close Job Opening</Typography>
            <CloseIcon
              onClick={onModalClose}
              sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
            />
          </Flex>
          <Flex px={3} py={2} gap={3} flexDirection="column">
            <JobOpeningField
              field="Date_Closed"
              value={formik.values.Date_Closed}
              required
              touched={formik.touched.Date_Closed}
              error={formik.errors.Date_Closed}
              onChange={(val) =>
                formik.setFieldValue('Date_Closed', val || null)
              }
              onBlur={() => formik.setFieldTouched('Date_Closed')}
            />
            <JobOpeningField
              field="Reason_of_Closing"
              value={formik.values.Reason_of_Closing}
              required
              touched={formik.touched.Reason_of_Closing}
              error={formik.errors.Reason_of_Closing}
              onChange={(val) =>
                formik.setFieldValue('Reason_of_Closing', val || null)
              }
              onBlur={() => formik.setFieldTouched('Reason_of_Closing')}
            />
            <JobOpeningField
              field="Number_of_submissions"
              value={formik.values.Number_of_submissions}
              required
              touched={formik.touched.Number_of_submissions}
              error={formik.errors.Number_of_submissions}
              onChange={(val) =>
                formik.setFieldValue('Number_of_submissions', val)
              }
              onBlur={() => formik.setFieldTouched('Number_of_submissions')}
            />
            {nextJobOpeningStatus === JobOpeningStatus.Filled && (
              <>
                <JobOpeningField
                  field="First_submission"
                  value={formik.values.First_submission}
                  required
                  touched={formik.touched.First_submission}
                  error={formik.errors.First_submission}
                  onChange={(val) =>
                    formik.setFieldValue('First_submission', val || null)
                  }
                  onBlur={() => formik.setFieldTouched('First_submission')}
                />
                <JobOpeningField
                  field="Candidate_Start_Date"
                  value={formik.values.Candidate_Start_Date}
                  required
                  touched={formik.touched.Candidate_Start_Date}
                  error={formik.errors.Candidate_Start_Date}
                  onChange={(val) =>
                    formik.setFieldValue('Candidate_Start_Date', val || null)
                  }
                  onBlur={() => formik.setFieldTouched('Candidate_Start_Date')}
                />
                <JobOpeningField
                  field="Location_of_Closing"
                  value={formik.values.Location_of_Closing}
                  required
                  touched={formik.touched.Location_of_Closing}
                  error={formik.errors.Location_of_Closing}
                  onChange={(val) =>
                    formik.setFieldValue('Location_of_Closing', val || null)
                  }
                  onBlur={() => formik.setFieldTouched('Location_of_Closing')}
                />
                <JobOpeningField
                  field="Seniority_of_closing"
                  value={formik.values.Seniority_of_closing}
                  required
                  touched={formik.touched.Seniority_of_closing}
                  error={formik.errors.Seniority_of_closing}
                  onChange={(val) =>
                    formik.setFieldValue('Seniority_of_closing', val || null)
                  }
                  onBlur={() => formik.setFieldTouched('Seniority_of_closing')}
                />
                <JobOpeningField
                  field="Candidate_Recruiter"
                  value={formik.values.Candidate_Recruiter}
                  required
                  touched={formik.touched.Candidate_Recruiter}
                  error={formik.errors.Candidate_Recruiter}
                  onChange={(val) =>
                    formik.setFieldValue('Candidate_Recruiter', val || null)
                  }
                  onBlur={() => formik.setFieldTouched('Candidate_Recruiter')}
                />
              </>
            )}
          </Flex>
          <Flex px={3} py={2} gap={1} justifyContent="end">
            <Button
              variant="contained"
              onClick={onModalClose}
              sx={{
                height: '40px',
                backgroundColor: '#3F8CFF14',
                color: '#42A5F5',
                textTransform: 'none',
                display: 'flex',
                width: '80px',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                formik.handleSubmit();
              }}
              sx={{
                height: '40px',
                backgroundColor: 'text.link',
                textTransform: 'none',
                display: 'flex',
                width: '80px',
              }}
              disabled={
                !formik.dirty || !formik.isValid || joIsPerformingAction
              }
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
