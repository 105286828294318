import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';
import { ProfileDetailsSection } from './components/ProfileDetailsSection';
import { EmailDetailsSection } from './components/EmailDetailsSection';

import {
  candidateDetailsSelectors,
  sendLPInvitation,
} from '@redux/candidateDetails';
import { authSelectors } from '@redux/auth';
import {
  fetchLaunchpodSpecializations,
  optionsSelectors,
} from '@redux/options';

import {
  checkUserCanSendLPInvite,
  getCandidateLPInvitationFormInitialValues,
  isStatusIdle,
  isStatusLoading,
  transformCandidateLPInvitationFormValuesToPayload,
} from '@utils';
import { CandidateLPInvitationFormFields } from '@types';

import { candidateLPInviteValidationSchema } from './validationSchema';

export const CandidateLPInviteModal: React.FC<{
  isOpen: boolean;
  onCloseModalClick: () => void;
}> = ({ isOpen, onCloseModalClick }) => {
  const dispatch = useAppDispatch();

  const profile = useAppSelector(authSelectors.getProfileData);
  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const candidateLPInviteAPIStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateLPinvitationAPIStatus,
  );
  const { data: lpSpecializations, apiStatus: lpSpecializationsAPIStatus } =
    useAppSelector(optionsSelectors.getLPSpecializations);

  useEffect(() => {
    if (isStatusIdle(lpSpecializationsAPIStatus)) {
      dispatch(fetchLaunchpodSpecializations());
    }
  }, [lpSpecializationsAPIStatus]);

  const initialValues = useMemo(
    () =>
      getCandidateLPInvitationFormInitialValues(
        candidateDetails,
        lpSpecializations,
      ),
    [candidateDetails, lpSpecializations],
  );

  const formik = useFormik<CandidateLPInvitationFormFields>({
    initialValues,
    validationSchema: candidateLPInviteValidationSchema,
    onSubmit: async (values) => {
      if (!candidateDetails?.id) return;

      await dispatch(
        sendLPInvitation(
          transformCandidateLPInvitationFormValuesToPayload(
            candidateDetails.id,
            values,
          ),
        ),
      );
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  const isSendDisabled =
    !checkUserCanSendLPInvite(profile) ||
    isStatusLoading(candidateLPInviteAPIStatus);

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">Send Launchpod invitation</Typography>
          <CloseIcon
            onClick={onCloseModalClick}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <ProfileDetailsSection
          formik={formik}
          lpSpecializations={lpSpecializations}
          candidateDetails={candidateDetails}
        />
        <EmailDetailsSection formik={formik} />
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onCloseModalClick}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={isSendDisabled}
          >
            Send
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
