export const utcTimezones = [
  { label: 'UTC -12:00', value: '-12:00' },
  { label: 'SST (UTC -11:00)', value: '-11:00' },
  { label: 'HST (UTC -10:00)', value: '-10:00' },
  { label: 'AKST (UTC -09:00)', value: '-09:00' },
  { label: 'PST (UTC -08:00)', value: '-08:00' },
  { label: 'MST (UTC -07:00)', value: '-07:00' },
  { label: 'CST (UTC -06:00)', value: '-06:00' },
  { label: 'EST (UTC -05:00)', value: '-05:00' },
  { label: 'AST (UTC -04:00)', value: '-04:00' },
  { label: 'UTC -03:00', value: '-03:00' },
  { label: 'UTC -02:00', value: '-02:00' },
  { label: 'UTC -01:00', value: '-01:00' },
  { label: 'GMT (UTC +00:00)', value: '+00:00' },
  { label: 'CET (UTC +01:00)', value: '+01:00' },
  { label: 'EET (UTC +02:00)', value: '+02:00' },
  { label: 'UTC +03:00', value: '+03:00' },
  { label: 'UTC +04:00', value: '+04:00' },
  { label: 'UTC +05:00', value: '+05:00' },
  { label: 'IST (UTC +05:30)', value: '+05:30' },
  { label: 'UTC +06:00', value: '+06:00' },
  { label: 'UTC +07:00', value: '+07:00' },
  { label: 'CST (UTC +08:00)', value: '+08:00' },
  { label: 'KST (UTC +09:00)', value: '+09:00' },
  { label: 'AEST (UTC +10:00)', value: '+10:00' },
  { label: 'NZST (UTC +11:00)', value: '+11:00' },
  { label: 'UTC +12:00', value: '+12:00' },
  { label: 'UTC +13:00', value: '+13:00' },
  { label: 'UTC +14:00', value: '+14:00' },
];

export const mapZohoTimezoneToUtc: Record<string, string> = {
  None: '',
  '0': '+00:00',
  '-2 West': '+14:00',
  '-1 West': '+13:00',
  '+12 East': '+12:00',
  '+11 East': '+11:00',
  '+10 East': '+10:00',
  '+9 East': '+09:00',
  '+8 East': '+08:00',
  '+7 East': '+07:00',
  '+6 East': '+06:00',
  '+5.30 East': '+05:30',
  '+5 East': '+05:00',
  '+4 East': '+04:00',
  '+3 East': '+03:00',
  '+2 East': '+02:00',
  '+1 East': '+01:00',
  '+2 West': '+01:00',
  '+1 West': '+00:00',
  '-3 West': '-03:00',
  '-4 West': '-04:00',
  '-5 West': '-05:00',
  '-6 West': '-06:00',
  '-7 West': '-07:00',
  '-8 West': '-08:00',
  '-9 West': '-09:00',
  '-10 West': '-10:00',
  '-11 West': '-11:00',
  '-12 West': '-12:00',
};

export const CountriesLocationAreas = [
  {
    Location: 'Argentina',
    Location_Area: 'LatAm',
    Time_zone: '-3 West',
    Time_zone_offset: '-03:00',
  },
  {
    Location: 'Brazil',
    Location_Area: 'LatAm',
    Time_zone: '-3 West',
    Time_zone_offset: '-03:00',
  },
  {
    Location: 'Colombia',
    Location_Area: 'LatAm',
    Time_zone: '-5 West',
    Time_zone_offset: '-05:00',
  },
  {
    Location: 'Costa Rica',
    Location_Area: 'LatAm',
    Time_zone: '-6 West',
    Time_zone_offset: '-06:00',
  },
  {
    Location: 'Guatemala',
    Location_Area: 'LatAm',
    Time_zone: '-6 West',
    Time_zone_offset: '-06:00',
  },
  {
    Location: 'Venezuela',
    Location_Area: 'LatAm',
    Time_zone: '-4 West',
    Time_zone_offset: '-04:00',
  },
  {
    Location: 'Peru',
    Location_Area: 'LatAm',
    Time_zone: '-5 West',
    Time_zone_offset: '-05:00',
  },
  {
    Location: 'Canada',
    Location_Area: 'Other',
    Time_zone: '+2 East',
    Time_zone_offset: '-06:00',
  },
  {
    Location: 'Poland',
    Location_Area: 'Europe',
    Time_zone: '+1 East',
    Time_zone_offset: '+01:00',
  },
  {
    Location: 'Portugal',
    Location_Area: 'Europe',
    Time_zone: '+1 West',
    Time_zone_offset: '+00:00',
  },
  {
    Location: 'India',
    Location_Area: 'India',
    Time_zone: '+5.30 East',
    Time_zone_offset: '+05:30',
  },
  {
    Location: 'USA',
    Location_Area: 'USA',
    Time_zone: '-5 West',
    Time_zone_offset: '-05:00',
    City_Time_zone: {
      Miami: '-3 West',
      Orlando: '-3 West',
      Tallahassee: '-3 West',
      Tampa: '-3 West',
      'West Palm Beach': '-3 West',
      Jacksonville: '-3 West',
      'Fort Lauderdale': '-3 West',
      'Boca Raton': '-3 West',
      Austin: '-5 West',
      Houston: '-5 West',
    },
  },
  {
    Location: 'Ukraine',
    Location_Area: 'Ukraine',
    Time_zone: '+2 East',
    Time_zone_offset: '+02:00',
  },
  {
    Location: 'Russia',
    Location_Area: 'Other',
    Time_zone: undefined,
    Time_zone_offset: undefined,
  },
  {
    Location: 'Mexico',
    Location_Area: 'LatAm',
    Time_zone: '-6 West',
    Time_zone_offset: '-06:00',
  },
  {
    Location: 'Spain',
    Location_Area: 'Europe',
    Time_zone: '+2 West',
    Time_zone_offset: '+01:00',
  },
  {
    Location: 'Romania',
    Location_Area: 'Europe',
    Time_zone: '+2 East',
    Time_zone_offset: '+02:00',
  },
  {
    Location: 'Bulgaria',
    Location_Area: 'Europe',
    Time_zone: '+2 East',
    Time_zone_offset: '+02:00',
  },
  {
    Location: 'Slovenia',
    Location_Area: 'Europe',
    Time_zone: '+1 East',
    Time_zone_offset: '+01:00',
  },
  {
    Location: 'Slovakia',
    Location_Area: 'Europe',
    Time_zone: '+1 East',
    Time_zone_offset: '+01:00',
  },
  {
    Location: 'Europe',
    Location_Area: 'Europe',
    Time_zone: undefined,
    Time_zone_offset: undefined,
  },
  {
    Location: 'Other',
    Location_Area: 'Other',
    Time_zone: undefined,
    Time_zone_offset: undefined,
  },
];
