import {
  AccordionSummary as MUIAccordionSummary,
  AccordionSummaryProps,
  styled,
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MUIAccordionSummary
    expandIcon={
      <ExpandMoreRoundedIcon
        sx={{
          color: 'text.link',
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
    },
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}));
