import { styled, Box } from '@mui/material';
import { getPriorityIcon } from '@utils';
import { ReactComponent as StoppedOutreachIcon } from 'assets/comment-x.svg';

const StyledBox = styled(Box)({
  position: 'relative',
  width: '24px',
  height: '24px',
});

const StyledStoppedOutreachIcon = styled(StoppedOutreachIcon)({
  position: 'absolute',
  top: '-2px',
  right: '-2px',
  width: '12px',
  height: '12px',
  zIndex: 1,
});

export interface IPriorityIconWithOutreachInfo {
  IconComponent: React.ComponentType<any> | null;
  hasOutreachStopped: boolean;
  StyledBox: typeof StyledBox;
  StyledStoppedOutreachIcon: typeof StyledStoppedOutreachIcon;
}

export const getPriorityIconWithOutreach = (
  icon: string,
  isOnHold = false,
  isClosed = false,
  dateOfStopOutreach: string | null = null,
): IPriorityIconWithOutreachInfo => {
  const IconComponent = getPriorityIcon(icon, isOnHold, isClosed);

  return {
    IconComponent,
    hasOutreachStopped: !!dateOfStopOutreach,
    StyledBox,
    StyledStoppedOutreachIcon,
  };
};
