import { SyntheticEvent } from 'react';

import { AutocompleteTextfield } from 'components';
import { createFilterOptions } from '@mui/material';

import { CandidateFieldsLabels } from '@constants';

export const SkillsField: React.FC<{
  value: string | null;
  options: string[];
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  disabled?: boolean;
  error?: string | undefined;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({
  value,
  name,
  options,
  error,
  touched,
  required,
  disabled,
  onChange,
  onBlur,
}) => {
  const parsedSkills = value?.split(';') || [];

  return (
    <AutocompleteTextfield
      name={name || 'Skill_Set'}
      label={CandidateFieldsLabels.Skill_Set}
      value={parsedSkills}
      options={options}
      required={required}
      onBlur={onBlur}
      fullWidth
      multiple
      freeSolo
      filterSelectedOptions
      clearOnBlur
      selectOnFocus
      error={touched && !!error}
      helperText={touched ? error : undefined}
      disabled={disabled}
      filterOptions={(options, params) => {
        const filtered = createFilterOptions<string>()(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
          filtered.push(`Add "${inputValue}"`);
        }

        return filtered;
      }}
      onChange={(e: SyntheticEvent, newValue: string[] | null) => {
        const newSkillNameValue = newValue?.map((value) =>
          value?.includes('Add "') ? /Add "(.*)"/gi.exec(value)?.[1] : value,
        );

        onChange(newSkillNameValue?.join(';'));
      }}
    />
  );
};
