export enum CandidateProcessActivityType {
  ReferralDecline = 'ReferralDecline',
  LPSignIn = 'LPSignIn',
  VideoInterviewScheduled = 'VideoInterviewScheduled',
  VideoInterviewSkipped = 'VideoInterviewSkipped',
  VideoInterviewReviewed = 'VideoInterviewReviewed',
  VideoInterviewReset = 'VideoInterviewReset',
  TestTaskScheduled = 'TestTaskScheduled',
  KnowledgeQuizStarted = 'KnowledgeQuizStarted',
  KnowledgeQuizFinished = 'KnowledgeQuizFinished',
  IntroCallReviewed = 'IntroCallReviewed',
  TICallReviewed = 'TICallReviewed',
  StatusChange = 'StatusChange',
  Associated = 'Associated',
  Dissociated = 'Dissociated',
  ProfileUpdate = 'ProfileUpdate',
  TestTaskUpdate = 'TestTaskUpdate',
}

export enum CandidateProcessActivitySource {
  Dashboard = 'Dashboard',
  LaunchPod = 'LaunchPod',
}

export const ActivityTypeLabel: {
  [k in CandidateProcessActivityType]: string;
} = {
  [CandidateProcessActivityType.ReferralDecline]: 'Declined referral',
  [CandidateProcessActivityType.LPSignIn]: 'Signed in into LP',
  [CandidateProcessActivityType.VideoInterviewScheduled]: 'VI scheduled',
  [CandidateProcessActivityType.VideoInterviewSkipped]: 'VI skipped',
  [CandidateProcessActivityType.VideoInterviewReviewed]: 'VI reviewed',
  [CandidateProcessActivityType.VideoInterviewReset]: 'VI reset',
  [CandidateProcessActivityType.TestTaskScheduled]: 'TT scheduled',
  [CandidateProcessActivityType.KnowledgeQuizStarted]: 'Knowledge Quiz started',
  [CandidateProcessActivityType.KnowledgeQuizFinished]:
    'Knowledge Quiz finished',
  [CandidateProcessActivityType.IntroCallReviewed]: 'IC reviewed',
  [CandidateProcessActivityType.TICallReviewed]: 'TI Call reviewed',
  [CandidateProcessActivityType.StatusChange]: 'Status change',
  [CandidateProcessActivityType.Associated]: 'Associated',
  [CandidateProcessActivityType.Dissociated]: 'Dissociated',
  [CandidateProcessActivityType.ProfileUpdate]: 'Profile Update',
  [CandidateProcessActivityType.TestTaskUpdate]: 'TT Update',
};
