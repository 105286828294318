import * as React from 'react';

import { Box, BoxProps } from '@mui/material';

interface ITabPanelProps extends BoxProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FC<ITabPanelProps> = ({
  children,
  index,
  value,
  ...rest
}) => {
  return (
    <Box role="tabpanel" hidden={value !== index} {...rest}>
      {value === index && children}
    </Box>
  );
};
