import { Box, Link, Paper, Typography } from '@mui/material';
import sunPng from '@assets/sun.png';

export const EmptyActionReportState: React.FC<{
  hasFilters: boolean;
  resetFilters: () => void;
}> = ({ hasFilters, resetFilters }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        component={Paper}
        sx={{
          margin: '0 1.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <img
          src={sunPng}
          alt="sun"
          style={{ height: '158px', width: '158px' }}
        />
        <Typography variant="h2" m="1rem" textAlign="center" fontWeight={500}>
          {hasFilters ? (
            <>No candidates matching these filters or search</>
          ) : (
            <>
              There are no pending actions <br />
              for your candidates
            </>
          )}
        </Typography>
        {hasFilters && (
          <>
            <Link
              onClick={resetFilters}
              color="text.link"
              sx={{ cursor: 'pointer' }}
            >
              Reset filters
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
};
