import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchOpenPositions, fetchJobOpenings } from './jobOpenings.thunks';
import { jobOpeningsStoreKey } from './jobOpenings.const';
import { IJobOpeningsSliceState } from './jobOpenings.types';
import { IDWHJobOpening, IJobOpeningOptions } from '@types';
import { ApiStatus } from '@constants';

const initialState: IJobOpeningsSliceState = {
  apiStatus: ApiStatus.IDLE,
  data: null,
  openPositionsApiStatus: ApiStatus.IDLE,
  openPositions: [],
  options: {
    groupBy: null,
    sortBy: null,
    order: null,
    status: null,
    search: null,
    priority: null,
    client: null,
    locations: null,
    techFlow: null,
    seniorities: null,
    clientRecruiters: null,
  },
};

export const jobOpeningsSlice = createSlice({
  name: jobOpeningsStoreKey,
  initialState,
  reducers: {
    setJobOpeningOptionField: (
      state,
      action: PayloadAction<Partial<Record<keyof IJobOpeningOptions, any[]>>>,
    ) => {
      state.options = {
        groupBy: action.payload.groupBy?.[0] || null,
        sortBy: action.payload.sortBy?.[0] || null,
        order: action.payload.order?.[0] || null,
        status: action.payload.status?.[0] || null,
        search: action.payload.search?.[0] || null,
        priority: action.payload.priority || null,
        client: action.payload.client || null,
        locations: action.payload.locations || null,
        techFlow: action.payload.techFlow || null,
        seniorities: action.payload.seniorities || null,
        clientRecruiters: action.payload.clientRecruiters || null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchJobOpenings.fulfilled,
        (state, action: PayloadAction<IDWHJobOpening[]>) => {
          state.data = action.payload;
          state.apiStatus = ApiStatus.COMPLETE;
        },
      )
      .addCase(fetchJobOpenings.pending, (state) => {
        state.apiStatus = ApiStatus.LOADING;
      })
      .addCase(
        fetchJobOpenings.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      .addCase(
        fetchOpenPositions.fulfilled,
        (state, action: PayloadAction<IDWHJobOpening[]>) => {
          state.openPositions = action.payload;
          state.openPositionsApiStatus = ApiStatus.COMPLETE;
        },
      )
      .addCase(fetchOpenPositions.pending, (state) => {
        state.openPositionsApiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchOpenPositions.rejected, (state) => {
        state.openPositionsApiStatus = ApiStatus.FAILED;
      });
  },
});

export const { setJobOpeningOptionField } = jobOpeningsSlice.actions;

export const jobOpeningsSliceReducer = jobOpeningsSlice.reducer;
