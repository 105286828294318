import { Stack, Tooltip, Typography } from '@mui/material';
import { IJobOpeningDetails } from '@types';
import { getPriorityIcon } from '@utils';

export const JobOpeningName: React.FC<Partial<IJobOpeningDetails>> = ({
  Job_Opening_ID,
  Priority,
  Job_Opening_Name,
  Client_Name_New,
  Technical_Flow,
}) => {
  const PriorityIcon = Priority && getPriorityIcon(Priority);

  if (!Job_Opening_Name) return null;

  return (
    <Stack>
      <Stack direction={'row'}>
        <Typography
          variant="h4"
          color="text.secondary"
          noWrap
          sx={{
            fontSize: {
              xs: '1.25rem',
              sm: '1.5rem',
              md: '1.125rem',
              lg: '1rem',
            },
            marginRight: '0.25rem',
            mb: '0px',
          }}
        >
          #{Job_Opening_ID}
        </Typography>
        <Typography
          variant="h4"
          color="text.primary"
          noWrap
          sx={{
            fontSize: {
              xs: '1.25rem',
              sm: '1.5rem',
              md: '1.125rem',
              lg: '1rem',
            },
            marginRight: '0.25rem',
            mb: '0px',
          }}
        >
          {Job_Opening_Name}
        </Typography>
        <Typography>
          {PriorityIcon && (
            <Tooltip title={Priority} placement="top">
              <PriorityIcon />
            </Tooltip>
          )}
        </Typography>
      </Stack>
      <Stack>
        <Typography
          color="text.primary"
          noWrap
          variant="caption"
          sx={(theme) => ({
            fontSize: theme.typography.caption,
            lineHeight: '100%',
            letterSpacing: '0.4px',
            color: 'text.secondary',
            whiteSpace: 'nowrap',
            ml: '2px',
            mt: '0px',
          })}
        >
          {Client_Name_New} • {Technical_Flow}
        </Typography>
      </Stack>
    </Stack>
  );
};
