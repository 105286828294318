import { ReactNode } from 'react';

import { Paper, Table, TableContainer } from '@mui/material';
import { ForbiddenView, LoaderSkeleton } from '@components';
import { EntityMainTableHeader } from './components/EntityMainTableHeader';
import { EntityMainTableBody } from './components/EntityMainTableBody';
import { EntityMainTablePagination } from './components/EntityMainTablePagination';

import { isStatusComplete, isStatusForbidden, isStatusLoading } from '@utils';
import { IEntityMainTableHeaderCell } from '@types';
import { ApiStatus, OrderDirection } from '@constants';

interface IProps<T extends { id: string }> {
  tableHeaders: IEntityMainTableHeaderCell[];
  apiStatus: ApiStatus;
  data: T[] | null;
  groupBy?: string | null;
  sortOptions?: {
    direction: OrderDirection | null;
    sortBy: string | null;
    onSort: (value: { field: string; direction: OrderDirection }) => void;
  };
  paginationOptions?: {
    totalCount: number;
    onFetchNextPage: () => void;
  };
  EmptyStateComponent: ReactNode;
  renderRowComponent: (value: T) => ReactNode;
  onRowClick: (value: string) => void;
}

export const EntityMainTable = <T extends { id: string }>({
  tableHeaders,
  apiStatus,
  data,
  groupBy,
  sortOptions,
  paginationOptions,
  EmptyStateComponent,
  renderRowComponent,
  onRowClick,
}: IProps<T>) => {
  const isLoading = isStatusLoading(apiStatus);

  if (!data?.length && isStatusComplete(apiStatus)) {
    return <>{EmptyStateComponent}</>;
  }

  if (isStatusForbidden(apiStatus)) {
    return <ForbiddenView />;
  }

  if (!data?.length && !isStatusComplete(apiStatus)) {
    return <LoaderSkeleton />;
  }

  if (!data) return null;

  return (
    <>
      <TableContainer component={Paper} sx={{ height: '100%' }}>
        <Table stickyHeader sx={{ tableLayout: 'auto', width: '100%' }}>
          <EntityMainTableHeader
            tableHeaders={tableHeaders}
            sortOptions={sortOptions}
            groupBy={groupBy}
          />
          <EntityMainTableBody
            data={data}
            groupBy={groupBy}
            tableHeadersCount={tableHeaders.length}
            onRowClick={onRowClick}
            renderRowComponent={renderRowComponent}
          />
        </Table>
        {!!paginationOptions && (
          <EntityMainTablePagination
            totalCount={paginationOptions.totalCount}
            currentCount={data.length}
            isLoading={isLoading}
            onFetchNextPage={paginationOptions.onFetchNextPage}
          />
        )}
      </TableContainer>
    </>
  );
};
