import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikErrors } from 'formik';
import { isEmpty, omit } from 'lodash';

import {
  AppBar,
  Box,
  Button,
  Hidden,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import {
  SubmissionStep,
  SubmissionStepsOrdered,
  AppRoutes,
  CandidateDetailsTabs,
} from '@constants';
import { getCandidateName } from '@utils';
import { IZohoCandidate, SubmissionFormValues } from '@types';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.accent,
  color: theme.palette.text.invertedPrimary,
  padding: '0.625rem',
  borderRadius: '0.5rem',
  '&:hover': {
    backgroundColor: alpha(theme.palette.brand.accent, 0.7),
  },
}));

export const SubmisionFormHeader: React.FC<{
  candidateDetails: IZohoCandidate | null;
  activeStep: SubmissionStep;
  errors: FormikErrors<SubmissionFormValues>;
  onSelectStep: (selectedStep: SubmissionStep) => void;
}> = ({ candidateDetails, activeStep, errors, onSelectStep }) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    const navigateTo = candidateDetails
      ? `/${AppRoutes.CANDIDATES}/${candidateDetails.id}/${CandidateDetailsTabs.SUBMISSIONS}`
      : `/${AppRoutes.CANDIDATES}`;

    navigate(navigateTo);
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={(theme) => ({
          boxShadow: 'none',
          backgroundColor: theme.palette.background.backgroundPrimary,
        })}
      >
        <Toolbar
          sx={{
            padding: {
              xs: '0.75rem 1rem',
            },
            justifyContent: 'space-between',
            gap: '0.5rem',
          }}
        >
          <Stack gap="0.5rem" direction="row" flex="1" alignItems="center">
            <StyledIconButton
              size="large"
              aria-label="go back"
              onClick={handleBackButtonClick}
            >
              <ArrowBackRoundedIcon />
            </StyledIconButton>
            <Hidden mdDown>
              <Stack>
                <Typography
                  variant="h2"
                  color="text.primary"
                  noWrap
                  sx={{
                    fontSize: {
                      xs: '1.5rem',
                      md: '1.125rem',
                    },
                    lineHeight: '120%',
                    marginRight: '0.25rem',
                  }}
                >
                  {getCandidateName({
                    fullName: candidateDetails?.Full_Name,
                    firstName: candidateDetails?.First_Name,
                    lastName: candidateDetails?.Last_Name,
                    englishFirstName: candidateDetails?.English_First_Name,
                    englishLastName: candidateDetails?.English_Last_Name,
                  })}
                </Typography>
              </Stack>
            </Hidden>
          </Stack>
          <Box flex="2" overflow="scroll">
            <Stepper
              activeStep={SubmissionStepsOrdered.findIndex(
                (e) => e === activeStep,
              )}
            >
              {SubmissionStepsOrdered.map((label) => {
                const hasProfileError =
                  label === SubmissionStep.Profile &&
                  label !== activeStep &&
                  !isEmpty(omit(errors.profile, 'resumeName'));
                const hasTTError =
                  label === SubmissionStep.TestTask &&
                  label !== activeStep &&
                  !isEmpty(errors.testTask);
                const hasFeedbackError =
                  label === SubmissionStep.Feedback &&
                  label !== activeStep &&
                  !isEmpty(errors.feedback);
                const hasInterviewError =
                  label === SubmissionStep.Interview &&
                  label !== activeStep &&
                  !isEmpty(errors.interview);
                const hasRecipientsError =
                  label === SubmissionStep.Recipients &&
                  label !== activeStep &&
                  (!isEmpty(errors.clientName) || !isEmpty(errors.positions));

                return (
                  <Step key={label}>
                    <StepLabel
                      error={
                        hasProfileError ||
                        hasTTError ||
                        hasFeedbackError ||
                        hasInterviewError ||
                        hasRecipientsError
                      }
                      onClick={() => onSelectStep(label)}
                      sx={{ cursor: 'pointer !important' }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <Box flex="1" display="flex" justifyContent="end" alignItems="center">
            <Button
              variant="contained"
              onClick={handleBackButtonClick}
              sx={{
                height: '40px',
                backgroundColor: '#3F8CFF14',
                color: '#42A5F5',
                textTransform: 'none',
                display: 'flex',
                width: '80px',
              }}
            >
              Cancel
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
