import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  ListSubheader,
  MenuItem,
  TextField,
} from '@mui/material';

import { fetchJobOpeningCountries, optionsSelectors } from '@redux/options';

import { isStatusIdle } from '@utils';

export const JobOpeningLocationFilter: React.FC<{
  isLoading: boolean;
  value: string[] | null;
  onChange: (value: string[] | null) => void;
}> = ({ isLoading, value, onChange }) => {
  const dispatch = useAppDispatch();

  const { data: countries, apiStatus: countriesAPIStatus } = useAppSelector(
    optionsSelectors.getJobOpeningCountries,
  );

  const countryOptions = useMemo(
    () =>
      [...countries]
        .sort((a, b) => a.region.localeCompare(b.region))
        .map(({ title, value, region }) => ({ label: title, value, region })),
    [countries],
  );

  const countryValue = useMemo(
    () => countryOptions.filter((c) => value?.includes(c.value)),
    [countryOptions, value],
  );

  const handleChangeCountry = (
    e: any,
    values: { label: string; value: string }[],
  ) => {
    const newValues = values.map((country) => country.value);
    onChange(newValues.length ? newValues : null);
  };

  const handleGroupAllClick = (groupName: string) => {
    const newValues = Array.from(
      new Set([
        ...(value ?? []),
        ...countryOptions
          ?.filter((c) => c.region === groupName)
          ?.map((c) => c.value),
      ]),
    );
    onChange(newValues.length ? newValues : null);
  };

  useEffect(() => {
    if (isStatusIdle(countriesAPIStatus)) dispatch(fetchJobOpeningCountries());
  }, [countriesAPIStatus]);

  return (
    <Autocomplete
      disablePortal
      multiple
      id="combo-box-country"
      options={countryOptions}
      onChange={handleChangeCountry}
      groupBy={(option) => option.region}
      sx={{ mb: '1rem', width: '300px' }}
      value={countryValue}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} label="Location" />}
      disabled={isLoading}
      renderGroup={(params: AutocompleteRenderGroupParams) => (
        <li key={params.key}>
          <ListSubheader id="nested-list-subheader" component="div">
            {params.group}
          </ListSubheader>
          <ul style={{ paddingLeft: 0 }}>
            {React.Children.count(params.children) > 1 ? (
              <MenuItem
                key={`all-${params.group}`}
                onClick={() => handleGroupAllClick(params.group)}
              >{`All ${params.group}`}</MenuItem>
            ) : null}

            {params.children}
          </ul>
        </li>
      )}
      ChipProps={{
        size: 'small',
      }}
    />
  );
};
