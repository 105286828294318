import { CandidateFields } from '@types';

export const TextfieldCandidateFields: Array<keyof CandidateFields> = [
  'Email',
  'First_Name',
  'Last_Name',
  'Interested_in_JO',
  'LinkedIn_URL',
  'Test_Task_Link',
  'Video_Interview_Link',
  'Phone',
  'Telegram',
  'Current_Company',
  'Djinni_URL',
  'GitHub_Link',
  'AE_Formatted_CV_Link',
];

export const TextareaCandidateFields: Array<keyof CandidateFields> = [
  "Client's_Feedback",
  'Recruiters_Feedback',
  'TL_intro_feedback',
  'Technical_Conslusion',
  'Test_Task_Feedback',
  'VI_feedback',
  'Leads_reply_2018',
];

export const NumberfieldCandidateFields: Array<keyof CandidateFields> = [
  'Hourly_Rate_Expected',
  'Hourly_Rate_Offer1',
  'Codility_Result',
  'Hourly_Rate_Current',
];

export const AutocompleteSingleselectCandidateFields: Array<
  keyof CandidateFields
> = [
  'English_level',
  'Lead_closed_because_NEW',
  'LeadGen_owner',
  'Location_City',
  'Location_Country',
  'Notice_period_from_Offer',
  'Prescreen_failed_because',
  'Leads_owner_2018',
  'Seniority_Level',
  'Source',
  'Dev_-_QA-AQA',
  'Technical_Flow',
  'Test_Task_sent_by',
  'Test_Task_Technology',
  'Type_of_channel',
  'Type_of_Developer',
  'Type_of_Inbound_channel',
  'Type_of_Outbound_channel',
  'Current_employment_type',
  'Expected_employment_type',
  'TI_Suggested_Seniority',
  'Added_by',
];

export const AutocompltereMultiselectCandidateFields: Array<
  keyof CandidateFields
> = [
  'Clients_Submission',
  'Primary_Skill_Set',
  'Secondary_Skill_Set',
  'Skill_Set',
  'Talked_with',
  'TL_Intro',
  'Test_Task_Reviewers',
  'Test_Task',
];

export const DateCandidateFields: Array<keyof CandidateFields> = [
  'Date_of_Clients_Interview',
  'Date_of_Prescreen_Planned',
  'TL_intro_date',
  'Date_of_Tech_Interview_planned',
  'SOW_signed_date',
  'Test_Task_sent_date',
  'Test_Task_Date',
  'Date_of_offer_done',
  'Final_decision_from_client',
  'Date_of_Submission',
  'VI_sent_date',
  'VI_date',
  'Date_of_Prescreen',
  'Leads_reply_Date',
  'Date_of_last_apply',
  'Self_gen_Touch_Date',
  'Hot_Pipeline_Start_Date',
  'Hot_Pipeline_End_Date',
];

export const CheckboxCandidateFields: Array<keyof CandidateFields> = [
  'Full_Stack',
  'Do_not_touch_Russia',
];
