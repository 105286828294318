import React, { useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import { useAppDispatch } from '@redux/hooks';

import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { CustomButton } from '@components';

import { setSnackbar } from '@redux/snackbar';

import { CandidateVideoInterview } from '@types';
import { SnackbarType } from '@constants';

export const VideoInterviewShareWithClient: React.FC<{
  isLoading: boolean;
  currentVideoInterview: CandidateVideoInterview;
  handleGenerateClick: () => void;
}> = ({ isLoading, currentVideoInterview, handleGenerateClick }) => {
  const dispatch = useAppDispatch();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentVideoInterview?.clientVideoLink ?? '');
    dispatch(
      setSnackbar({
        type: SnackbarType.Info,
        message: 'Link copied to clipboard',
      }),
    );
  };

  const durationText = useMemo(() => {
    if (currentVideoInterview.clientVideoLink) {
      const link = new URL(currentVideoInterview.clientVideoLink);
      const token = link.searchParams.get('token');
      const { exp: expiresAt } = JSON.parse(window.atob(token!.split('.')[1]));
      const duration = expiresAt
        ? differenceInDays(new Date(expiresAt * 1000), new Date())
        : -1;
      if (duration < 0) {
        return 'Link expired';
      } else {
        return `Will be expired in ${duration} days`;
      }
    } else {
      return '';
    }
  }, [currentVideoInterview.clientVideoLink]);

  return (
    <Stack gap={1} sx={{ mx: { xs: '1rem', sm: 0 } }}>
      <Typography variant="body1">Share with client</Typography>
      {currentVideoInterview?.clientVideoLink ? (
        <Stack>
          <Stack direction="row" gap="0.5rem">
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled
              fullWidth
              value={currentVideoInterview.clientVideoLink}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Copy to clipboard">
                      <div>
                        <IconButton
                          aria-label="copy link"
                          onClick={handleCopyLink}
                          edge="end"
                        >
                          <ContentCopyRoundedIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Tooltip title="Regenerate link">
              <div>
                <CustomButton
                  isSquare
                  size="medium"
                  color="primary"
                  isLoading={isLoading}
                  startIcon={<AutorenewRoundedIcon />}
                  onClick={() => handleGenerateClick()}
                />
              </div>
            </Tooltip>
          </Stack>
          {durationText && (
            <Typography variant="subtitle2" color="text.secondary" ml="1rem">
              {durationText}
            </Typography>
          )}
        </Stack>
      ) : (
        <div>
          <CustomButton
            size="medium"
            color="primary"
            label={'Generate link'}
            onClick={() => handleGenerateClick()}
            isLoading={isLoading}
          />
        </div>
      )}
    </Stack>
  );
};
