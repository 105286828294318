import { jobOpeningsStoreKey } from './jobOpenings.const';
import { IJobOpeningsSliceState } from './jobOpenings.types';

interface IState {
  [jobOpeningsStoreKey]: IJobOpeningsSliceState;
}

const selectJobOpeningsState = (state: IState) => {
  return state[jobOpeningsStoreKey];
};

const getJobOpeningsApiStatus = (state: IState) => {
  return selectJobOpeningsState(state).apiStatus;
};

const getJobOpeningsData = (state: IState) => {
  return selectJobOpeningsState(state).data;
};

const getJobOpeningsOptions = (state: IState) => {
  return selectJobOpeningsState(state).options;
};

const getOpenPositions = (state: IState) => {
  return selectJobOpeningsState(state).openPositions;
};

const getOpenPositionsApiStatus = (state: IState) => {
  return selectJobOpeningsState(state).openPositionsApiStatus;
};

export const jobOpeningsSelectors = {
  getJobOpeningsApiStatus,
  getJobOpeningsData,
  getJobOpeningsOptions,
  getOpenPositions,
  getOpenPositionsApiStatus,
};
