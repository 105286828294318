import { ReactNode } from 'react';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Box, IconButton } from '@mui/material';

export const HiddenBlockWrapper: React.FC<{
  hidden: boolean;
  onHideToggle: (isHidden: boolean) => void;
  disabled?: boolean;
  children: ReactNode;
}> = ({ hidden, children, disabled, onHideToggle }) => {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      {children}
      <IconButton
        onClick={() => {
          if (onHideToggle) {
            onHideToggle(!hidden);
          }
        }}
        size="small"
        sx={{ visibility: disabled ? 'hidden' : 'visible' }}
      >
        {hidden ? (
          <VisibilityOffOutlinedIcon fontSize="small" color="warning" />
        ) : (
          <RemoveRedEyeOutlinedIcon fontSize="small" />
        )}
      </IconButton>
    </Box>
  );
};
