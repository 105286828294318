import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { jobOpeningsStoreKey } from './jobOpenings.const';
import {
  JobOpeningsGroupingOptions,
  JobOpeningsSortingOptions,
  OrderDirection,
  apiEndpoints,
} from '@constants';
import { IDWHJobOpening, IJobOpeningOptions, IDWHJobOpeningRaw } from '@types';
import { transformJobOpening } from '@utils';

export const fetchJobOpenings = createAsyncThunk(
  `${jobOpeningsStoreKey}/fetchJobOpenings`,
  async (
    {
      groupBy,
      sortBy,
      order,
      status,
      search,
      priority,
      client,
      locations,
      techFlow,
      seniorities,
      clientRecruiters,
    }: Partial<IJobOpeningOptions>,
    { rejectWithValue },
  ) => {
    try {
      const params: Partial<Record<keyof IJobOpeningOptions, string>> = {};

      groupBy &&
        groupBy !== JobOpeningsGroupingOptions.NONE &&
        (params.groupBy = groupBy);
      sortBy && (params.sortBy = sortBy);
      order && (params.order = order);
      status && (params.status = status);
      search && (params.search = search);
      priority?.length && (params.priority = priority.join(','));
      client?.length && (params.client = client.join(','));
      locations?.length && (params.locations = locations.join(','));
      techFlow?.length && (params.techFlow = techFlow.join(','));
      seniorities?.length && (params.seniorities = seniorities.join(','));
      clientRecruiters?.length &&
        (params.clientRecruiters = clientRecruiters.join(','));

      const response = await axios.get<IDWHJobOpeningRaw[]>(
        apiEndpoints.jobOpenings(),
        {
          params,
        },
      );

      const data: IDWHJobOpening[] = response.data.map(transformJobOpening);

      return data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchOpenPositions = createAsyncThunk(
  `${jobOpeningsStoreKey}/fetchOpenPositions`,
  async () => {
    try {
      const params = {
        groupBy: JobOpeningsGroupingOptions.ACCOUNT,
        sortBy: JobOpeningsSortingOptions.PRIORITY,
        order: OrderDirection.DESC,
      };

      const response = await axios.get<IDWHJobOpeningRaw[]>(
        apiEndpoints.jobOpenings(),
        {
          params,
        },
      );

      const data: IDWHJobOpening[] = response.data.map(transformJobOpening);

      return data;
    } catch (error) {
      throw error;
    }
  },
);
