import * as yup from 'yup';

export const createCandidateValidationSchema = yup.object({
  LinkedIn_URL: yup
    .string()
    .required('LinkedIn link is required')
    .max(255, 'Max length is 255')
    .matches(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
      'Enter correct url!',
    ),
  First_Name: yup
    .string()
    .required('First name is required')
    .max(255, 'Max length is 255'),
  Last_Name: yup
    .string()
    .required('Last name is required')
    .max(255, 'Max length is 255'),
});
