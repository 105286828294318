import { Autocomplete, TextField } from '@mui/material';

import { CandidateStage, candidatesStageHeaders } from '@constants';

export const CandidateStageFilter: React.FC<{
  isLoading: boolean;
  value: CandidateStage | null;
  disabled?: boolean;
  onChange: (value: CandidateStage | null) => void;
}> = ({ isLoading, disabled, value, onChange }) => {
  const stageOptions = [
    { label: 'NewHome', value: CandidateStage.NEW_HOME },
    ...candidatesStageHeaders,
  ];

  const stageValueIdx = stageOptions.findIndex((stg) => {
    return stg.value === value;
  });

  const handleChangeStage = (
    e: any,
    stage: { label: string; value: CandidateStage } | null,
  ) => {
    onChange(stage?.value || null);
  };

  return (
    <Autocomplete
      disabled={disabled || isLoading}
      disablePortal
      id="combo-box-stage"
      options={stageOptions}
      onChange={handleChangeStage}
      value={stageOptions[stageValueIdx] || null}
      filterSelectedOptions
      sx={{ mb: '1rem' }}
      renderInput={(params) => <TextField {...params} label="Stage" />}
    />
  );
};
