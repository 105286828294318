import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  TableRow,
  TableCell,
  Box,
  Link,
  Tooltip,
  Typography,
  Button,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { getPriorityIcon } from 'utils';

import { IDWHJobOpening } from '@types';
import {
  JobOpeningReasonOfClosing,
  JobOpeningStatus,
  SearchParams,
} from '@constants';

export const CopyFromJobOpeningRow: React.FC<{
  jobOpening: IDWHJobOpening;
}> = ({ jobOpening }) => {
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();

  const {
    Priority,
    Job_Opening_Status,
    Reason_of_Closing,
    Job_Opening_Id,
    Job_Opening_Name,
    id,
  } = jobOpening;

  const handleCopyJobOpening = () => {
    setSearchParams((_searchParams) => {
      _searchParams.set(SearchParams.DUPLICATE_JOB_OPENING_ID, id);
      return _searchParams;
    });
  };

  const isOnHold =
    Job_Opening_Status === JobOpeningStatus.Closed &&
    Reason_of_Closing === JobOpeningReasonOfClosing.OnHold;
  const isClosed =
    Job_Opening_Status === JobOpeningStatus.Closed &&
    Reason_of_Closing !== JobOpeningReasonOfClosing.OnHold;

  const Icon = getPriorityIcon(Priority, isOnHold, isClosed);

  return (
    <TableRow>
      <TableCell style={{ verticalAlign: 'top' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {Icon && (
            <Tooltip
              title={isOnHold ? 'On-Hold' : isClosed ? 'Closed' : Priority}
              placement="top"
            >
              <Icon width="18" sx={{ width: 18 }} />
            </Tooltip>
          )}
          <Typography
            variant="body2"
            color="text.secondary"
            marginLeft={'0.25rem'}
          >
            {Job_Opening_Id}
          </Typography>
        </Box>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <Link color="text.link">{Job_Opening_Name}</Link>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} align="right">
        <Button
          variant="contained"
          size="small"
          sx={{
            height: '2.5rem',
            fontWeight: '400',
            backgroundColor: 'text.link',
            textTransform: 'none',
            whiteSpace: 'nowrap',
          }}
          onClick={handleCopyJobOpening}
          startIcon={<ContentCopyIcon />}
        >
          Copy
        </Button>
      </TableCell>
    </TableRow>
  );
};
