import { actionReportStoreKey } from './action-report.const';
import { IActionReportSliceState } from './action-report.types';

interface IState {
  [actionReportStoreKey]: IActionReportSliceState;
}

const selectActionReportState = (state: IState) => {
  return state[actionReportStoreKey];
};

const getActionReportApiStatus = (state: IState) => {
  return selectActionReportState(state).apiStatus;
};

const getActionReportData = (state: IState) => {
  return selectActionReportState(state).data;
};

const getActionReportTotal = (state: IState) => {
  return selectActionReportState(state).totalCount;
};

const getActionReportOptions = (state: IState) => {
  return selectActionReportState(state).options;
};

export const actionReportSelectors = {
  getActionReportApiStatus,
  getActionReportData,
  getActionReportTotal,
  getActionReportOptions,
};
