export enum MeetingStatus {
  Scheduled = 'Scheduled',
  Cancelled = 'Cancelled',
  NoShow = 'No-show',
  Done = 'Done',
  Passed = 'Passed',
  Failed = 'Failed',
}

export enum MeetingType {
  IntroCall = 'IntroCall',
  TechnicalInterview = 'TechnicalInterview',
  TeamLeadIntro = 'TeamLeadIntro',
  ClientInterview = 'ClientInterview',
}

export const MeetingTypeLabel: Record<MeetingType, string> = {
  [MeetingType.IntroCall]: 'Intro Call',
  [MeetingType.TechnicalInterview]: 'Technical Interview',
  [MeetingType.TeamLeadIntro]: 'Team Lead Intro',
  [MeetingType.ClientInterview]: 'Client Interview',
};

export enum MeetingResolution {
  Passed = 'Passed',
  Failed = 'Failed',
}

export const MeetingResolutionOptions: MeetingResolution[] =
  Object.values(MeetingResolution);
