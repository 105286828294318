import { useCallback } from 'react';

import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import { IEntityMainTableHeaderCell, OrderDirection } from '@types';

export const EntityMainTableHeader: React.FC<{
  tableHeaders: IEntityMainTableHeaderCell[];
  groupBy?: string | null;
  sortOptions?: {
    direction: OrderDirection | null;
    sortBy: string | null;
    onSort: (value: { field: string; direction: OrderDirection }) => void;
  };
}> = ({ tableHeaders, groupBy, sortOptions }) => {
  const handleColumnClick = useCallback(
    (fieldName: string) => {
      if (!sortOptions) return;

      const nextState = {
        field: sortOptions.sortBy,
        direction: sortOptions.direction || OrderDirection.ASC,
      };

      if (fieldName === sortOptions.sortBy) {
        const nextOrder =
          sortOptions.direction === OrderDirection.ASC
            ? OrderDirection.DESC
            : OrderDirection.ASC;
        nextState.direction = nextOrder;
      } else {
        nextState.field = fieldName;
        nextState.direction = OrderDirection.ASC;
      }

      sortOptions.onSort({
        field: nextState.field!,
        direction: nextState.direction || OrderDirection.ASC,
      });
    },
    [sortOptions],
  );

  return (
    <TableHead>
      <TableRow>
        {tableHeaders.map((cell, idx) => {
          const width = cell.width;
          const align = cell.align;

          if (groupBy === cell.value) return null;

          return (
            <TableCell
              key={idx}
              sx={(theme) => ({
                padding: '0.6rem 0 0 1rem',
                width: width,
                height: '2rem',
                lineHeight: '1.1',
                verticalAlign: 'top',
                color: theme.palette.text.secondary,
                fontSize: '0.8rem',
                textAlign: align,
              })}
              colSpan={idx === tableHeaders.length - 1 ? 2 : 1}
            >
              {cell.isSortable &&
              !!cell.value &&
              !!sortOptions?.direction &&
              !!sortOptions?.sortBy ? (
                <TableSortLabel
                  direction={sortOptions.direction}
                  active={sortOptions.sortBy == cell.value}
                  onClick={() => handleColumnClick(cell.value as string)}
                >
                  {cell.label}
                </TableSortLabel>
              ) : (
                cell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
