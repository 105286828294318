import { Button } from '@mui/material';
import { ReactNode } from 'react';

export const StageButton: React.FC<{
  amount: number | string;
  amountIcon?: ReactNode;
  active: boolean;
  onClick: () => void;
  disabled: boolean;
  variant?: string;
}> = ({
  amount,
  amountIcon,
  active,
  onClick,
  disabled,
  variant = 'medium',
}) => {
  const getButtonStyles = (theme: any) => {
    const baseStyles = {
      borderRadius: 2,
      width: '100%',
      color: active ? theme.palette.text.brand : 'rgba(0, 0, 0, 0.87)',
      background: active
        ? theme.palette.background.brandLight
        : disabled
        ? 'transparent'
        : theme.palette.highlight.actionable,
      fontWeight: 500,
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      justifyContent: 'flex-start',
      marginTop: 0.5,
      border: '2px solid white',
    };

    switch (variant) {
      case 'small':
        return {
          ...baseStyles,
          height: '30px',
          fontSize: '0.875rem',
          fontWeight: 400,
          background: active
            ? theme.palette.background.brandLight
            : theme.palette.highlight.actionable,
        };
      case 'large':
        return {
          ...baseStyles,
          height: '55px',
          fontSize: '40px',
        };
      default:
        return {
          ...baseStyles,
          height: '45px',
          fontSize: '30px',
        };
    }
  };

  return (
    <Button
      variant="text"
      disabled={disabled}
      sx={(theme) => getButtonStyles(theme)}
      onClick={onClick}
    >
      {amount}
      {amountIcon}
    </Button>
  );
};
