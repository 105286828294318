import React from 'react';

import { Box, Typography } from '@mui/material';

import {
  InternalTestTaskResolutionReviewOptions,
  LevelsReviewOptions,
} from '@constants';

const REVIEW_OPTIONS_TYPES: Array<
  Array<{ label: string; secondaryLabel?: string; value: string }>
> = [LevelsReviewOptions, InternalTestTaskResolutionReviewOptions];

export const ReviewOption: React.FC<{
  title: string;
  name: string;
  type: number;
  selected: string;
  disabled: boolean;
  onChange: (name: string, selectedValue: string) => void;
}> = ({ title, type, selected, onChange, name, disabled }) => {
  const currentOptions = REVIEW_OPTIONS_TYPES[type];

  if (!currentOptions) return null;

  return (
    <Box>
      <Typography variant="body1" mb={1}>
        {title}
      </Typography>
      <Box display="flex" gap={1}>
        {currentOptions.map((option) => (
          <Box
            key={option.value}
            onClick={() => !disabled && onChange(name, option.value)}
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '14px 24px 16px 24px',
              borderRadius: '4px',
              width: '100%',
              cursor: disabled ? 'default' : 'pointer',
              background:
                option.value === selected
                  ? theme.palette.highlight.accent
                  : theme.palette.highlight.actionable,
            })}
          >
            <Typography variant="body1">{option.label}</Typography>
            {option.secondaryLabel ? (
              <Typography variant="body2" color="text.secondary">
                {option.secondaryLabel}
              </Typography>
            ) : null}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
