import { useMemo } from 'react';
import { mapValues, values } from 'lodash';

import { Box, Typography } from '@mui/material';
import { QuizQuestion } from './components/QuizQuestion';

import { IQuizQuestion } from '@types';

export const Quiz: React.FC<{
  name: string;
  questions: Record<string, IQuizQuestion>;
  answers: Record<string, string[]> | null;
  score: number | null;
  isPassed: boolean;
  isDone: boolean;
}> = ({ name, questions, answers, isDone, isPassed, score }) => {
  const quizQuestions = useMemo(
    () =>
      questions
        ? values(
            mapValues(questions, (question, index) => {
              const candidateAnswers = (answers || {})[index];
              const { correctOptions } = question;

              return {
                questionNumber: index,
                label: question.label,
                type: question.type,
                points: question.points,
                options: question.options,
                correctOptions: question.correctOptions,
                userAnswers: candidateAnswers || [],
                isCorrect: candidateAnswers
                  ? [...candidateAnswers].sort().join() ===
                    [...correctOptions].sort().join()
                  : false,
              };
            }),
          )
        : [],
    [questions, answers],
  );

  const points = useMemo(
    () =>
      quizQuestions.reduce(
        (acc, val) => (val.isCorrect ? acc + val.points : acc),
        0,
      ),
    quizQuestions,
  );

  return (
    <Box display="flex" flexDirection="column" gap={4} width="100%">
      <Box>
        <Typography variant="h3" color="text.secondary" fontWeight={500} mb={1}>
          {name} test resolution
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '4px',
            padding: '14px 24px 16px 24px',
            background: isPassed
              ? theme.palette.highlight.success
              : !isDone
              ? theme.palette.highlight.accent
              : theme.palette.highlight.neutral,
          })}
        >
          <Typography
            variant="body1"
            color={
              isPassed
                ? 'text.invertedPrimary'
                : isDone
                ? 'text.danger'
                : 'text.secondary'
            }
            fontWeight={600}
          >
            {isPassed ? 'Passed' : !isDone ? 'In Progress' : 'Failed'}
          </Typography>

          {isDone && (
            <Typography
              variant="body1"
              color={isPassed ? 'text.invertedPrimary' : 'text.danger'}
              fontWeight={600}
            >
              {points} points and {score}% completion
            </Typography>
          )}
        </Box>
      </Box>
      {isDone &&
        quizQuestions.map((question) => (
          <QuizQuestion key={question.questionNumber} {...question} />
        ))}
    </Box>
  );
};
