import { useFormik } from 'formik';
import { useAppSelector } from '@redux/hooks';

import { MenuItem, Stack, TextField } from '@mui/material';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import { ReviewOption, CustomButton } from '@components';

import { authSelectors } from '@redux/auth';

import { checkUserCanReviewVideoInterview } from '@utils';
import { CandidateVideoInterview, ReviewVideoInterviewPayload } from '@types';
import {
  ReviewLevels,
  VIResolution,
  VIResolutionOptionsList,
} from '@constants';

import { validationSchema } from './validationSchema';

export const VideoInterviewReviewForm: React.FC<{
  isPerformingVideoInterviewAction: boolean;
  videoInterviewData: CandidateVideoInterview;
  postVideoInterviewReview: (data: ReviewVideoInterviewPayload) => void;
  handleResetVIClick: () => void;
}> = ({
  isPerformingVideoInterviewAction,
  videoInterviewData,
  postVideoInterviewReview,
  handleResetVIClick,
}) => {
  const profile = useAppSelector(authSelectors.getProfileData);

  const isReviewed = Boolean(videoInterviewData.reviewer);
  const isAbleToReview = checkUserCanReviewVideoInterview(profile);

  const formik = useFormik<{
    presentationSkillsLevel: ReviewLevels | '';
    functionalExpertiseLevel: ReviewLevels | '';
    englishLevel: ReviewLevels | '';
    feedback?: string;
    resolution: VIResolution | '';
    reviewer: string;
  }>({
    initialValues: {
      presentationSkillsLevel: videoInterviewData.presentationSkillsLevel || '',
      functionalExpertiseLevel:
        videoInterviewData.functionalExpertiseLevel || '',
      englishLevel: videoInterviewData.englishLevel || '',
      feedback: videoInterviewData.feedback || '',
      resolution: videoInterviewData.resolution ?? '',
      reviewer: videoInterviewData.reviewer || profile?.email || '',
    },
    validationSchema,
    onSubmit: (values) => {
      postVideoInterviewReview({
        presentationSkillsLevel: values.presentationSkillsLevel as ReviewLevels,
        functionalExpertiseLevel:
          values.functionalExpertiseLevel as ReviewLevels,
        englishLevel: values.englishLevel as ReviewLevels,
        feedback: values.feedback,
        resolution: values.resolution as string,
        isPassed: values.resolution === (VIResolution.PASSED as string),
      });
    },
    validateOnMount: true,
  });

  return (
    <Stack gap={'1.5rem'}>
      <ReviewOption
        type={0}
        name="presentationSkillsLevel"
        title="Presentation skills"
        selected={formik.values.presentationSkillsLevel}
        disabled={isReviewed || !isAbleToReview}
        onChange={formik.setFieldValue}
      />
      <ReviewOption
        type={0}
        name="functionalExpertiseLevel"
        title="Functional expertise"
        selected={formik.values.functionalExpertiseLevel}
        disabled={isReviewed || !isAbleToReview}
        onChange={formik.setFieldValue}
      />
      <ReviewOption
        type={0}
        name="englishLevel"
        title="English level"
        selected={formik.values.englishLevel}
        disabled={isReviewed || !isAbleToReview}
        onChange={formik.setFieldValue}
      />
      <TextField
        select
        id="select-final-resolution"
        name="resolution"
        value={formik.values.resolution}
        label={
          <>
            Final resolution<sup>*</sup>
          </>
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={isReviewed || !isAbleToReview}
        error={formik.touched.resolution && Boolean(formik.errors.resolution)}
        SelectProps={{
          notched: true,
          displayEmpty: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      >
        <MenuItem disabled value="">
          Please select
        </MenuItem>
        {VIResolutionOptionsList.map((opt) => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        name="feedback"
        label="Notes"
        type="textarea"
        placeholder="Add your feedback"
        id="feedback"
        variant="outlined"
        value={formik.values.feedback}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        multiline
        minRows={2}
        disabled={isReviewed || !isAbleToReview}
        helperText="Feedback will be added to candidates Zoho record"
      />
      <TextField
        name="reviewer"
        label="Reviewer"
        id="reviewer"
        variant="outlined"
        value={formik.values.reviewer}
        disabled
      />

      {isAbleToReview && (
        <Stack direction="row" gap="0.5rem" justifyContent={'space-between'}>
          {!isReviewed && (
            <CustomButton
              size="medium"
              color="primary"
              label={'Save feedback'}
              onClick={() => formik.handleSubmit()}
              isLoading={isPerformingVideoInterviewAction}
              isDisabled={!formik.isValid || isPerformingVideoInterviewAction}
            />
          )}
          <CustomButton
            size="medium"
            color="secondary"
            label={'Reset VI'}
            onClick={handleResetVIClick}
            startIcon={<CancelPresentationRoundedIcon fontSize="small" />}
          />
        </Stack>
      )}
    </Stack>
  );
};
