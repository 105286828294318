import { Box, Typography } from '@mui/material';

import { HiddenBlockWrapper } from '@components';
import { NoAvailableVideoInterview } from './components/NoAvailableVideoInterview';
import { VideoInterviewQuestions } from './components/VideoInterviewQuestions';

import { SubmissionFormik } from '@types';

export const SubmissionVideoInterviewStep: React.FC<{
  formik: SubmissionFormik;
  onHideFieldToggle: (field: string, isHidden: boolean) => void;
}> = ({ formik, onHideFieldToggle }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2} py={2}>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('videoInterview')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('videoInterview', isHidden)
        }
      >
        <Typography variant="h3">Candidate’s video intro</Typography>
      </HiddenBlockWrapper>
      {!formik.values.videoInterview.length ? (
        <NoAvailableVideoInterview />
      ) : (
        <VideoInterviewQuestions
          formik={formik}
          onHideFieldToggle={onHideFieldToggle}
        />
      )}
    </Box>
  );
};
