import { useAppSelector } from '@redux/hooks';

import { Box, Typography, Grid, Alert, Paper } from '@mui/material';
import { JobOpeningField, PositionHiringManagerField } from '@components';

import { optionsSelectors } from '@redux/options';
import { authSelectors } from '@redux/auth';

import { JobOpeningFormValues, JobOpeningFormik } from '@types';
import { PositionType, JobOpeningSourceOptions } from '@constants';
import {
  checkUserCanUpsertCandidateSpreadsheet,
  checkUserCanEditJobOpeningMaxCalls,
} from '@utils';

export const HiringProcessDetails: React.FC<{
  formik: JobOpeningFormik;
}> = ({ formik }) => {
  const profile = useAppSelector(authSelectors.getProfileData);
  const { data: clients } = useAppSelector(
    optionsSelectors.getJobOpeningClients,
  );

  const currentSourceOption = JobOpeningSourceOptions.find(
    (source) => source.value === formik.values.Position_type,
  );
  const isBillablePosition =
    currentSourceOption?.positionType === PositionType.BILLABLE;

  const isCustomClientName =
    formik.values.Client_Name_New &&
    !clients.some((client) => client.name === formik.values.Client_Name_New);
  const isVisibleMaxCalls = checkUserCanEditJobOpeningMaxCalls(profile);
  const showCandidateSpreadsheetField =
    checkUserCanUpsertCandidateSpreadsheet(profile);

  return (
    <Paper elevation={1} sx={{ mb: '1px', pt: 4, pb: 6, paddingX: 5 }}>
      <Typography variant="h2" mb={4}>
        Hiring process
      </Typography>
      <Grid container spacing={2} maxWidth="972px" mb={4}>
        <Grid item xs={12} md={4}>
          <PositionHiringManagerField
            name="Hiring_Manager_email"
            value={formik.values.Hiring_Manager_email}
            required
            onChange={(hm) =>
              formik.setValues({
                ...formik.values,
                Hiring_Manager_email: hm?.email_id || null,
                Hiring_M: hm?.fullName || null,
              })
            }
            onBlur={() => formik.setFieldTouched('Hiring_Manager_email')}
            touched={formik.touched.Hiring_Manager_email}
            error={formik.errors.Hiring_Manager_email}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <JobOpeningField
            field="Responsible_Recruiter"
            value={formik.values.Responsible_Recruiter}
            touched={formik.touched.Responsible_Recruiter}
            error={formik.errors.Responsible_Recruiter}
            onChange={(val) =>
              formik.setFieldValue('Responsible_Recruiter', val || null)
            }
            onBlur={() => formik.setFieldTouched('Responsible_Recruiter')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <JobOpeningField
            field="Test_Task"
            value={formik.values.Test_Task}
            required
            touched={formik.touched.Test_Task}
            error={formik.errors.Test_Task}
            onChange={(val) => formik.setFieldValue('Test_Task', val || null)}
            onBlur={() => formik.setFieldTouched('Test_Task')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <JobOpeningField
            field="Interview_process"
            value={formik.values.Interview_process}
            touched={formik.touched.Interview_process}
            error={formik.errors.Interview_process}
            onChange={(val) =>
              formik.setFieldValue('Interview_process', val || null)
            }
            onBlur={() => formik.setFieldTouched('Interview_process')}
          />
        </Grid>
        {isVisibleMaxCalls && (
          <Grid item xs={12} md={4}>
            <JobOpeningField
              field="maxCalls"
              value={formik.values.maxCalls}
              touched={formik.touched.maxCalls}
              error={formik.errors.maxCalls}
              onChange={(val) => formik.setFieldValue('maxCalls', val)}
              onBlur={() => formik.setFieldTouched('maxCalls')}
            />
          </Grid>
        )}
      </Grid>
      {isBillablePosition && (
        <>
          <Typography variant="h4" mb={2}>
            Client
          </Typography>
          {isCustomClientName && (
            <Alert variant="standard" severity="warning" sx={{ mb: 2 }}>
              Please note that a request to create a new client (
              {formik.values.Client_Name_New}) will be sent. While the request
              is being processed, this position will be created with
              "AgileEngine" Client Name.
            </Alert>
          )}
          <Grid container spacing={2} maxWidth="972px" mb={4}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2} mb="20px">
                <Grid item xs={12} md={6}>
                  <JobOpeningField
                    field="Client_Name_New"
                    value={formik.values.Client_Name_New}
                    required
                    touched={formik.touched.Client_Name_New}
                    error={formik.errors.Client_Name_New}
                    onChange={(val) => {
                      const client = clients.find((c) => c.name === val);

                      const updateObj: Partial<JobOpeningFormValues> = {
                        Client_Name_New: val,
                        Subteam_Name: null,
                      };

                      client?.deliveryManager &&
                        (updateObj.Delivery_Manager = client.deliveryManager);
                      client?.pdm && (updateObj.PDM = client.pdm);

                      formik.setValues({
                        ...formik.values,
                        ...updateObj,
                      });
                    }}
                    onBlur={() => formik.setFieldTouched('Client_Name_New')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <JobOpeningField
                    field="Subteam_Name"
                    disabled={!formik.values.Client_Name_New}
                    value={formik.values.Subteam_Name}
                    touched={formik.touched.Subteam_Name}
                    error={formik.errors.Subteam_Name}
                    onChange={(val) =>
                      formik.setFieldValue('Subteam_Name', val || null)
                    }
                    onBlur={() => formik.setFieldTouched('Subteam_Name')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb="20px">
                <Grid item xs={12} md={6}>
                  <JobOpeningField
                    field="PDM"
                    value={formik.values.PDM}
                    touched={formik.touched.PDM}
                    error={formik.errors.PDM}
                    required
                    onChange={(val) => formik.setFieldValue('PDM', val || null)}
                    onBlur={() => formik.setFieldTouched('PDM')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <JobOpeningField
                    field="Delivery_Manager"
                    value={formik.values.Delivery_Manager}
                    touched={formik.touched.Delivery_Manager}
                    error={formik.errors.Delivery_Manager}
                    required
                    onChange={(val) =>
                      formik.setFieldValue('Delivery_Manager', val || null)
                    }
                    onBlur={() => formik.setFieldTouched('Delivery_Manager')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <JobOpeningField
                field="Client_submission_emails"
                value={formik.values.Client_submission_emails}
                touched={formik.touched.Client_submission_emails}
                error={formik.errors.Client_submission_emails}
                onChange={(val) =>
                  formik.setFieldValue('Client_submission_emails', val || null)
                }
                onBlur={() =>
                  formik.setFieldTouched('Client_submission_emails')
                }
              />
            </Grid>
          </Grid>
        </>
      )}
      <Typography variant="h4" mb={2}>
        Interview
      </Typography>
      <Grid container spacing={2} maxWidth="972px">
        <Grid item xs={12} md={4}>
          <Grid container spacing={2} mb="20px">
            <Grid item xs={12} md={12}>
              <JobOpeningField
                field="Technical_Reviewer"
                value={formik.values.Technical_Reviewer}
                touched={formik.touched.Technical_Reviewer}
                error={formik.errors.Technical_Reviewer}
                onChange={(val) =>
                  formik.setFieldValue('Technical_Reviewer', val || null)
                }
                onBlur={() => formik.setFieldTouched('Technical_Reviewer')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <JobOpeningField
                field="Additional_Technical_Interviewer"
                value={formik.values.Additional_Technical_Interviewer}
                touched={formik.touched.Additional_Technical_Interviewer}
                error={formik.errors.Additional_Technical_Interviewer}
                onChange={(val) =>
                  formik.setFieldValue(
                    'Additional_Technical_Interviewer',
                    val || null,
                  )
                }
                onBlur={() =>
                  formik.setFieldTouched('Additional_Technical_Interviewer')
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <JobOpeningField
                field="Technical_Interviewers_Not_in_list"
                value={formik.values.Technical_Interviewers_Not_in_list}
                touched={formik.touched.Technical_Interviewers_Not_in_list}
                error={formik.errors.Technical_Interviewers_Not_in_list}
                onChange={(val) =>
                  formik.setFieldValue(
                    'Technical_Interviewers_Not_in_list',
                    val || null,
                  )
                }
                onBlur={() =>
                  formik.setFieldTouched('Technical_Interviewers_Not_in_list')
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={2}>
            <JobOpeningField
              field="Project_interview_type"
              value={formik.values.Project_interview_type}
              touched={formik.touched.Project_interview_type}
              error={formik.errors.Project_interview_type}
              onChange={(val) =>
                formik.setFieldValue('Project_interview_type', val || null)
              }
              onBlur={() => formik.setFieldTouched('Project_interview_type')}
            />

            <JobOpeningField
              field="Other_Comments"
              value={formik.values.Other_Comments}
              touched={formik.touched.Other_Comments}
              error={formik.errors.Other_Comments}
              onChange={(val) =>
                formik.setFieldValue('Other_Comments', val || null)
              }
              onBlur={() => formik.setFieldTouched('Other_Comments')}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <JobOpeningField
            field="Multi_Select_13"
            value={formik.values.Multi_Select_13}
            touched={formik.touched.Multi_Select_13}
            error={formik.errors.Multi_Select_13}
            onChange={(val) =>
              formik.setFieldValue('Multi_Select_13', val || null)
            }
            onBlur={() => formik.setFieldTouched('Multi_Select_13')}
          />
        </Grid>
      </Grid>
      {showCandidateSpreadsheetField && (
        <>
          <Typography variant="h4" mb={2}>
            Candidates spreadsheet
          </Typography>
          <Grid container spacing={2} maxWidth="972px">
            <Grid item xs={12} md={8}>
              <JobOpeningField
                field="Candidates_spreadsheet"
                value={formik.values.Candidates_spreadsheet}
                touched={formik.touched.Candidates_spreadsheet}
                error={formik.errors.Candidates_spreadsheet}
                onChange={(val) =>
                  formik.setFieldValue('Candidates_spreadsheet', val || null)
                }
                onBlur={() => formik.setFieldTouched('Candidates_spreadsheet')}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};
