import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiStatus } from '@constants';
import { candidatesStoreKey } from './candidates.const';
import { fetchCandidates } from './candidates.thunks';
import { ICandidatesSliceState } from './candidates.types';
import { ICandidatesList, ICandidatesOptions } from '@types';

export const DEFAULT_LIMIT = 30;
const initialState: ICandidatesSliceState = {
  apiStatus: ApiStatus.IDLE,
  data: [],
  totalCount: 0,
  options: {
    search: null,
    sortBy: null,
    order: null,
    stage: null,
    state: null,
    country: null,
    seniority: null,
    payRate: null,
    recruiter: null,
    addedBy: null,
    techFlow: null,
    typeOfOutboundChannel: null,
    offset: 0,
    limit: DEFAULT_LIMIT,
  },
};

const candidatesSlice = createSlice({
  name: candidatesStoreKey,
  initialState,
  reducers: {
    setCandidatesOptionField: (
      state,
      action: PayloadAction<Partial<Record<keyof ICandidatesOptions, any[]>>>,
    ) => {
      state.options = {
        search: action.payload.search?.[0] || null,
        sortBy: action.payload.sortBy?.[0] || null,
        order: action.payload.order?.[0] || null,
        stage: action.payload.stage?.[0] || null,
        state: action.payload.state?.[0] || null,
        typeOfOutboundChannel:
          action.payload.typeOfOutboundChannel?.[0] || null,
        country: action.payload.country || null,
        seniority: action.payload.seniority || null,
        payRate: action.payload.payRate?.map((e) => parseInt(e)) || null,
        recruiter: action.payload.recruiter || null,
        addedBy: action.payload.addedBy || null,
        techFlow: action.payload.techFlow || null,
        offset: parseInt(action.payload.offset?.[0] || '0'),
        limit: DEFAULT_LIMIT,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidates.pending, (state) => {
        state.apiStatus = ApiStatus.LOADING;
      })
      .addCase(
        fetchCandidates.fulfilled,
        (state, action: PayloadAction<ICandidatesList>) => {
          state.apiStatus = ApiStatus.COMPLETE;
          state.data = state.options.offset
            ? state.data.concat(action.payload.data)
            : action.payload.data;
          state.totalCount = action.payload.totalCount;
        },
      )
      .addCase(
        fetchCandidates.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      );
  },
});

export const { setCandidatesOptionField } = candidatesSlice.actions;

export const candidatesSliceReducer = candidatesSlice.reducer;
