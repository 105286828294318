import { mapValues, values } from 'lodash';

import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { QuizQuestionTypes } from '@constants';

export const QuizQuestion: React.FC<{
  questionNumber: string;
  label: string;
  type: QuizQuestionTypes;
  points: number;
  options: Record<string, string>;
  correctOptions: Array<string>;
  userAnswers: Array<string>;
  isCorrect: boolean;
}> = ({
  questionNumber,
  label,
  type,
  points,
  options,
  correctOptions,
  userAnswers,
  isCorrect,
}) => {
  const flattenOptions: Array<{ index: string; label: string }> = values(
    mapValues(options, (label: string, index: string) => ({ index, label })),
  );

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="body2" color="text.secondary" fontWeight={500}>
          Question {questionNumber}
        </Typography>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Typography variant="body1" color="text.primary" fontWeight={600}>
            {label}
          </Typography>
          {isCorrect && (
            <Typography
              variant="body1"
              color="text.primary"
              fontWeight={600}
              minWidth="65px"
            >
              +{points} point
            </Typography>
          )}
        </Box>
        {type === QuizQuestionTypes.MULTIPLE && (
          <Typography variant="body2" color="text.secondary">
            Select all options that apply
          </Typography>
        )}
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        {flattenOptions.map((option) => {
          const isCorrectOption = correctOptions.includes(option.index);
          const isUserAnswer = userAnswers.includes(option.index);

          return (
            <Box key={option.index} display="flex" gap={3}>
              <Typography mt="14px">{option.index.toUpperCase()}</Typography>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: '4px',
                  padding: '14px 24px 16px 24px',
                  width: '100%',
                  background:
                    isUserAnswer && isCorrectOption
                      ? theme.palette.highlight.accent
                      : isUserAnswer && !isCorrectOption
                      ? theme.palette.highlight.lightError
                      : theme.palette.highlight.neutral,
                })}
              >
                <Typography
                  variant="body1"
                  color="text.primary"
                  fontWeight={500}
                >
                  {option.label}
                </Typography>
                {isCorrectOption && <CheckIcon />}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
