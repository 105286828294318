import React from 'react';

import { Box } from '@mui/material';
import { ExpandableText } from '@components';
import { CDPersonSignature } from './CDPersonSignature';

import { SubmissionFormPayload } from '@types';

export const CDSubmissionFeedback: React.FC<{
  submission: SubmissionFormPayload;
}> = ({ submission }) => {
  if (!submission.feedback?.feedback) return null;

  return (
    <ExpandableText text={submission.feedback.feedback}>
      {submission.feedback.feedback}
      {submission.feedback?.reviewer && (
        <Box mt={3}>
          <CDPersonSignature person={submission.feedback?.reviewer} />
        </Box>
      )}
    </ExpandableText>
  );
};
