import { useNavigate } from 'react-router';

import { Box, Button, Paper, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { CustomButton } from '@components';
import puzzleSrc from '@assets/puzzle.png';

import { useAppSelector } from '@redux/hooks';
import { authSelectors } from '@redux/auth';

import { checkUserCanOpenEditJobOpening } from '@utils';
import { AppRoutes, JobOpeningStatusFilter } from '@constants';

export const EmptyJobOpeningsState: React.FC<{
  status: JobOpeningStatusFilter;
  hasFilters: boolean;
  isLoading: boolean;
  resetFilters: () => void;
}> = ({ status, hasFilters, isLoading, resetFilters }) => {
  const navigate = useNavigate();
  const profile = useAppSelector(authSelectors.getProfileData);

  const isOnHold = status === JobOpeningStatusFilter.OnHold;

  const isJOCreationDisabled = !checkUserCanOpenEditJobOpening(profile);
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        component={Paper}
        sx={{
          margin: '0 1.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <img src={puzzleSrc} alt="puzzle" />
        <Typography variant="h2" m="0.5rem">
          {hasFilters
            ? 'There are no job openings matching this search'
            : `You don’t have any ${status} positions`}
        </Typography>
        {hasFilters && (
          <Button
            variant="contained"
            onClick={resetFilters}
            sx={{
              height: '2rem',
              backgroundColor: '#007CFF14',
              color: 'text.link',
              textTransform: 'none',
              marginRight: '10px',
              fontSize: 'subtitle2.fontSize',
              padding: '0.25rem 0.5rem',
              whiteSpace: 'nowrap',
            }}
            disabled={isLoading}
          >
            <RemoveCircleOutlineIcon sx={{ mr: '0.5rem', fontSize: '1rem' }} />
            Reset all
          </Button>
        )}
        {!isOnHold && !hasFilters && (
          <>
            <Typography variant="body1" color="text.secondary" mb="2rem">
              You can open a new position using existing form
            </Typography>

            <CustomButton
              isDisabled
              size="small"
              color="secondary"
              className="ae-custom-button"
              label={'Open new position'}
              startIcon={<AddRoundedIcon />}
              onClick={() => {
                navigate(`/${AppRoutes.CREATE_JOB_OPENING}`);
              }}
              disabled={isJOCreationDisabled}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
