import { AccordionDetails as MUIAccordionDetails, styled } from '@mui/material';

export const AccordionDetails = styled(MUIAccordionDetails)((props) => ({
  [props.theme.breakpoints.up('xs')]: {
    padding: '1rem',
  },
  [props.theme.breakpoints.up('sm')]: {
    padding: '1.5rem',
  },
  borderTop: '1px solid rgba(0, 0, 0, .12)',
}));
