import { Button } from '@mui/material';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export const ResetFiltersButton: React.FC<{
  isLoading: boolean;
  hasFilters: boolean;
  onClick: () => void;
}> = ({ isLoading, hasFilters, onClick }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        height: '2rem',
        backgroundColor: '#007CFF14',
        color: 'text.link',
        textTransform: 'none',
        marginRight: '10px',
        fontSize: 'subtitle2.fontSize',
        padding: '0.25rem 0.5rem',
        whiteSpace: 'nowrap',
      }}
      disabled={isLoading || !hasFilters}
    >
      <RemoveCircleOutlineIcon sx={{ mr: '0.5rem', fontSize: '1rem' }} />
      Reset all
    </Button>
  );
};
