import React from 'react';

import { Box, Skeleton } from '@mui/material';

import { LOADER_SKELETON_TYPES } from '@constants';

export const LoaderSkeleton: React.FC<{
  type?: LOADER_SKELETON_TYPES;
}> = ({ type = LOADER_SKELETON_TYPES.MIXED }) => {
  if (type === LOADER_SKELETON_TYPES.ROWS) {
    return (
      <Box sx={{ width: '100%', height: '100%' }}>
        <Skeleton animation="wave" height="20%" />
        <Skeleton animation="wave" height="20%" />
        <Skeleton animation="wave" height="20%" />
        <Skeleton animation="wave" height="20%" />
        <Skeleton animation="wave" height="20%" />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Skeleton animation="wave" height="10%" />
      <Skeleton animation="wave" height="20%" />
      <Skeleton animation="wave" height="20%" />
      <Skeleton animation="wave" height="20%" />
      <Skeleton animation="wave" height="20%" />
    </Box>
  );
};
