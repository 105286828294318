import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useFormik } from 'formik';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';
import { CandidateTestTaskFormFields } from './components/CandidateTestTaskFormFields';

import {
  candidateDetailsSelectors,
  updateCandidateTestTask,
} from '@redux/candidateDetails';

import {
  getInitialCandidateTestTaskFormValues,
  transformCandidateTestTaskFormValuesToPayload,
} from '@utils';
import { CandidateTestTask, CandidateTestTaskFormValues } from '@types';

import { editCandidateTestTaskValidationSchema } from './validationSchema';

export const CandidateTestTaskFormModal: React.FC<{
  candidateId: string;
  candidateTestTask: CandidateTestTask;
  isOpen: boolean;
  onClose: () => void;
}> = ({ candidateId, candidateTestTask, isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(
    candidateDetailsSelectors.getIsTestTaskFormProcesing,
  );
  const initialValues = useMemo(
    () => getInitialCandidateTestTaskFormValues(candidateTestTask),
    [candidateTestTask],
  );

  const formik = useFormik<CandidateTestTaskFormValues>({
    initialValues,
    validationSchema: editCandidateTestTaskValidationSchema,
    onSubmit: async (values) => {
      const payload = transformCandidateTestTaskFormValuesToPayload(values);

      const form = new FormData();
      Object.entries(payload).forEach(([key, value]) =>
        form.append(key, value?.toString() || ''),
      );

      for (const resolutionFile of values.resolutionFiles) {
        form.append('resolutionFiles', resolutionFile);
      }

      if (values.summaryFile) {
        form.append('summaryFile', values.summaryFile);
      }

      const res = await dispatch(
        updateCandidateTestTask({
          candidateId,
          candidateTestTaskId: candidateTestTask.id,
          data: form,
        }),
      );

      if (!res?.type?.includes('rejected')) {
        onClose();
      }
    },
    enableReinitialize: true,
  });

  const isUpdateDisabled = !formik.dirty || isLoading;

  return (
    <Dialog
      open={isOpen}
      onClose={() => !isLoading && onClose()}
      scroll="body"
      fullWidth
    >
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h1">Edit Test Task</Typography>
          <CloseIcon
            onClick={() => !isLoading && onClose()}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <CandidateTestTaskFormFields formik={formik} />
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onClose()}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={isUpdateDisabled}
          >
            {isLoading && (
              <CircularProgress
                size={16}
                color="secondary"
                sx={{ marginRight: 1 }}
              />
            )}
            Update
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
