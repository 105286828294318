import { TableCell, TableRow, Typography } from '@mui/material';

export const ClientRow: React.FC<{ client: string }> = ({ client }) => {
  return (
    <TableRow>
      <TableCell
        colSpan={3}
        sx={{
          backgroundColor: (theme) => theme.palette.highlight.neutral,
          padding: '4px 1rem',
          position: 'sticky',
          top: '2rem',
          border: 'none',
        }}
      >
        <Typography variant="caption" color="text.secondary">
          {client}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
