import { ReactNode, useMemo } from 'react';
import { isNumber } from 'lodash';

import { Box, Link, Typography } from '@mui/material';
import { CDRowsDataLayout } from './CDRowsDataLayout';

import { resumeFileUrl } from 'constants/apiEndpoints';
import { SubmissionFormPayload } from '@types';

export const CDSubmissionProfile: React.FC<{
  submission: SubmissionFormPayload;
}> = ({ submission }) => {
  const mainProfileRowsData = useMemo(() => {
    const res: { label: string; value: string | ReactNode }[] = [];

    if (
      submission.profile?.primarySkills?.length ||
      submission.profile?.secondarySkills?.length
    )
      res.push({
        label: 'Skills',
        value: (
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display={'flex'} gap={1} flexWrap={'wrap'}>
              {submission.profile?.primarySkills?.map((item, index) => (
                <Typography
                  key={`skills_${index}`}
                  variant="body1"
                  color="text.primary"
                  sx={{
                    background: '#F6F8FC',
                    padding: '5px',
                    borderRadius: '4px',
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Box ml="5px">
              <Typography variant="body2" color="text.secondary">
                {submission.profile?.secondarySkills?.join(', ')}
              </Typography>
            </Box>
          </Box>
        ),
      });

    if (isNumber(submission.profile?.experience))
      res.push({
        label: 'Experience',
        value: `${submission.profile?.experience} years`,
      });
    if (submission.profile?.seniority)
      res.push({ label: 'Seniority', value: submission.profile?.seniority });
    if (submission.profile?.noticePeriod)
      res.push({
        label: 'Notice period',
        value: submission.profile?.noticePeriod,
      });

    if (submission.profile?.city || submission.profile?.country)
      res.push({
        label: 'Location',
        value: `${submission.profile?.city}, ${submission.profile.country}`,
      });
    if (submission.profile?.englishLevel)
      res.push({ label: 'English', value: submission.profile?.englishLevel });

    if (submission.profile?.resumeName)
      res.push({
        label: 'Full resume',
        value: (
          <Link
            href={resumeFileUrl(submission.profile?.resumeName)}
            component={'a'}
            target="_blank"
            color="text.link"
            sx={{ cursor: 'pointer' }}
            fontFamily="inherit"
          >
            {submission.profile?.resumeName} ↗
          </Link>
        ),
      });

    return res;
  }, [submission]);

  return <CDRowsDataLayout data={mainProfileRowsData} />;
};
