export const INVALID_FORMAT_ERROR_MESSAGE = (extensions: string) =>
  `Please attach your file in the following format: ${extensions}`;
export const EMPTY_FILE_ERROR_MESSAGE =
  'Looks like your file has no data, please attach the correct one';
export const LARGE_FILE_ERROR_MESSAGE = 'Please attach file, 20 MB max';
export const ACCEPTED_EXTENSIONS: string[] = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.webp',
  '.mov',
];
export const RESUME_ACCEPTED_EXTENTIONS: string[] = [
  '.txt',
  '.doc',
  '.docx',
  '.pdf',
  '.pages',
  '.odt',
];
export const SUMMARY_RESOLUTION_FILE_ACCEPTED_EXTENSIONS = [
  '.txt',
  '.doc',
  '.docx',
  '.pdf',
  '.pages',
  '.odt',
  '.jpg',
  '.jpeg',
  '.png',
  '.csv',
];
export const MAX_FILE_SIZE = 20 * 1048576 - 1; // 19.(9) MB
