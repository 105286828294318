import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  fetchCandidateFieldsOptions,
  fetchJobOpeningFieldsOptions,
  optionsSelectors,
} from '@redux/options';
import { isStatusIdle } from '@utils';

export const useFetchFieldsOptions = () => {
  const dispatch = useAppDispatch();

  const {
    apiStatus: candidateFieldsAPIStatus,
    requestedFetch: isCandidateFieldsRequested,
  } = useAppSelector(optionsSelectors.getCandidateFieldsOptions);

  const {
    apiStatus: jobOpeningFieldsAPIStatus,
    requestedFetch: isJobOpeningFieldsRequested,
  } = useAppSelector(optionsSelectors.getJobOpeningFieldsOptions);

  useEffect(() => {
    if (isStatusIdle(candidateFieldsAPIStatus) && isCandidateFieldsRequested) {
      dispatch(fetchCandidateFieldsOptions());
    }
  }, [candidateFieldsAPIStatus, isCandidateFieldsRequested]);

  useEffect(() => {
    if (
      isStatusIdle(jobOpeningFieldsAPIStatus) &&
      isJobOpeningFieldsRequested
    ) {
      dispatch(fetchJobOpeningFieldsOptions());
    }
  }, [jobOpeningFieldsAPIStatus, isJobOpeningFieldsRequested]);
};
