import { useCallback, useRef } from 'react';
import { useAppDispatch } from '@redux/hooks';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CandidateNotifyForm, Flex } from '@components';

import {
  CandidateStatus,
  CandidateStatusFormConfig,
  CandidateStatusStage,
  MeetingType,
  MeetingTypeLabel,
} from '@constants';

import { changeCandidateStatus } from '@redux/candidateDetails';

import { CandidateStatusFormValues, IZohoCandidate } from '@types';

export const MeetingNoShowModal: React.FC<{
  candidate: IZohoCandidate;
  meetingType: MeetingType;
  isOpen: boolean;
  onClose: () => void;
}> = ({ candidate, meetingType, isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const notifyComponentRef = useRef<{ notifyCandidate: () => void }>();

  const onSaveClick = useCallback(async () => {
    await notifyComponentRef.current?.notifyCandidate();

    const getFormData = CandidateStatusFormConfig[CandidateStatus.IC_No_Show]!;
    const { autoupdatedFields } = getFormData(
      CandidateStatusStage.IC,
      {} as CandidateStatusFormValues,
    );

    const res = await dispatch(
      changeCandidateStatus({
        candidateId: candidate.id,
        payload: {
          transitions: [
            [
              {
                stage: CandidateStatusStage.IC,
                status: CandidateStatus.IC_No_Show,
                jobOpeningId: null,
              },
            ],
          ],
          statusesData: [
            {
              stage: CandidateStatusStage.IC,
              status: CandidateStatus.IC_No_Show,
              Stage: CandidateStatusStage.IC,
              ...autoupdatedFields,
            },
          ],
        },
      }),
    );

    if (!res?.type?.includes('rejected')) {
      onClose();
    }
  }, [candidate.id]);

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">
            {MeetingTypeLabel[meetingType]} no-show
          </Typography>
          <CloseIcon
            onClick={() => onClose()}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <CandidateNotifyForm
          candidate={candidate}
          candidateStatus={CandidateStatus.IC_No_Show}
          ref={notifyComponentRef}
        />
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSaveClick}>
            Save
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
