import { ReactNode, useMemo } from 'react';
import { useAppSelector } from '@redux/hooks';

import { Typography } from '@mui/material';
import { CDRowsDataLayout } from './CDRowsDataLayout';

import { optionsSelectors } from '@redux/options';

import { SubmissionFormPayload } from '@types';

export const CDSubmissionPositions: React.FC<{
  submission: SubmissionFormPayload;
}> = ({ submission }) => {
  const { data: jobOpenings } = useAppSelector(
    optionsSelectors.getJobOpeningsOptions,
  );

  const rows = useMemo(() => {
    const res: { value: ReactNode }[] = [];

    submission.positionIds?.map((positionId) => {
      const position = jobOpenings.find((jo) => jo.id === positionId);
      res.push({
        value: (
          <Typography variant="body1" color="text.primary">
            {position?.Job_Opening_Name}
          </Typography>
        ),
      });
    });

    return res;
  }, [submission, jobOpenings]);

  return <CDRowsDataLayout data={rows} />;
};
