import React from 'react';

import { AppRouter } from './router/AppRouter';
import { GlobalSnackbar } from 'components';

import {
  useAxiosConfig,
  useFetchActionReport,
  useFetchCandidates,
  useFetchFieldsOptions,
  useFetchJobOpenings,
} from '@hooks';
import { useFetchProfile } from '@hooks/useFetchProfile';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useObserveTitle } from '@hooks/useObserveTitle';

const App: React.FC = () => {
  useAxiosConfig();
  useFetchProfile();
  useFetchFieldsOptions();
  useObserveTitle();

  useFetchJobOpenings();
  useFetchActionReport();
  useFetchCandidates();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AppRouter />
      <GlobalSnackbar />
    </LocalizationProvider>
  );
};

export default App;
