import * as yup from 'yup';

export const editCandidateTestTaskValidationSchema = yup.object({
  testTaskLink: yup
    .string()
    .nullable()
    .max(255, 'Max length is 255')
    .matches(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
      'Enter correct url!',
    ),
});
