import { useAppSelector } from '@redux/hooks';

import { Button } from '@mui/material';
import { Flex } from '@components';

import { candidateDetailsSelectors } from '@redux/candidateDetails';

import { isStatusLoading } from '@utils';
import { CandidateStatusFormik, IDWHJobOpening } from '@types';

export const CandidateStatusFormActions: React.FC<{
  formik: CandidateStatusFormik;
  associatedJopOpenings: IDWHJobOpening[];
  onCancel: () => void;
}> = ({ formik, onCancel }) => {
  const updateCandidateStatusAPIData = useAppSelector(
    candidateDetailsSelectors.getUpdateCandidateStatusAPIData,
  );

  const isUpdateDisabled =
    isStatusLoading(updateCandidateStatusAPIData.updateStatusAPIStatus) ||
    !formik.values.transitions.length ||
    formik.values.transitions.every((ts) =>
      formik.values.excludedTransitions.find(
        (ets) => ets.status === ts.status && ets.stage === ts.stage,
      ),
    ) ||
    !formik.values.transitions.length ||
    formik.values.transitions.some(
      (transition) =>
        transition.jobOpeningId && (!transition.stage || !transition.status),
    );

  return (
    <Flex justifyContent="end" gap={1}>
      <Button variant="contained" color="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={formik.handleSubmit}
        disabled={isUpdateDisabled}
      >
        Update
      </Button>
    </Flex>
  );
};
