import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useNotFoundStyles = (): SerializedStyles => {
  const { palette } = useTheme();

  return css`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    color: ${palette.text.primary};

    .nf-container {
      text-align: center;
    }

    .nf-status {
      font-size: 100px;
    }
  `;
};
