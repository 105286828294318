import { IconProps, styled } from '@mui/material';

import { JobOpeningsPriority } from '@constants';

import { ReactComponent as HighPlusIcon } from 'assets/high+.svg';
import { ReactComponent as HighIcon } from 'assets/high.svg';
import { ReactComponent as NormalPlusIcon } from 'assets/normal+.svg';
import { ReactComponent as NormalIcon } from 'assets/normal.svg';
import { ReactComponent as LowIcon } from 'assets/low.svg';
import { ReactComponent as LowestIcon } from 'assets/lowest.svg';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ClosedIcon = styled(HighlightOffIcon)<IconProps>(({ theme }) => ({
  width: 24,
  color: theme.palette.secondary.main,
}));

const OnHoldIcon = styled(PauseCircleOutlineOutlinedIcon)<IconProps>(
  ({ theme }) => ({
    width: 24,
    color: theme.palette.secondary.main,
  }),
);

const PreOpenIcon = styled(AccessTimeOutlinedIcon)<IconProps>(({ theme }) => ({
  width: 24,
  color: theme.palette.secondary.main,
}));

export const getPriorityIcon = (
  icon: string,
  isOnHold = false,
  isClosed = false,
) => {
  if (isOnHold) {
    return OnHoldIcon;
  }

  if (isClosed) {
    return ClosedIcon;
  }

  switch (icon) {
    case JobOpeningsPriority.HighPlus:
      return HighPlusIcon;
    case JobOpeningsPriority.High:
      return HighIcon;
    case JobOpeningsPriority.NormalPlus:
      return NormalPlusIcon;
    case JobOpeningsPriority.Normal:
      return NormalIcon;
    case JobOpeningsPriority.Low:
      return LowIcon;
    case JobOpeningsPriority.Lowest:
      return LowestIcon;
    case JobOpeningsPriority.OnHold:
      return OnHoldIcon;
    case JobOpeningsPriority.PreOpen:
      return PreOpenIcon;
    default:
      return null;
  }
};
