import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  JobOpeningForm,
  CopyFromJobOpeningModal,
  EntityFormPageLayout,
} from '@components';

import { authSelectors } from '@redux/auth';
import {
  clearJobOpeningDetails,
  setJobOpeningId,
} from '@redux/jobOpeningDetails';

import { JobOpeningFormMode, SearchParams } from '@constants';
import { checkUserCanOpenEditJobOpening } from '@utils';
import { useTitle } from '@hooks/useTitle';
import { PageTitle } from 'constants/pageTitle';

export const CreateJobOpeningPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.CREATE_JOB_OPENING);
  }, [setPageTitle]);

  const profile = useAppSelector(authSelectors.getProfileData);

  const copyJobOpeningId = useMemo(() => {
    return searchParams.get(SearchParams.DUPLICATE_JOB_OPENING_ID);
  }, [searchParams]);

  const [isOpenCopyJobOpeningModal, setOpenCopyJobOpeningModal] =
    useState(false);

  useEffect(() => {
    if (copyJobOpeningId) {
      handleCloseCopyJobOpeningModal();
      dispatch(setJobOpeningId(copyJobOpeningId));
    }

    return () => {
      if (copyJobOpeningId) {
        dispatch(clearJobOpeningDetails());
      }
    };
  }, [copyJobOpeningId]);

  const handleCloseCopyJobOpeningModal = useCallback(
    () => setOpenCopyJobOpeningModal(false),
    [],
  );

  // Header
  const onCancelClick = useCallback(() => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  }, []);
  const onCopyFromAnotherBtnClick = useCallback(() => {
    setOpenCopyJobOpeningModal(true);
  }, []);

  if (!checkUserCanOpenEditJobOpening(profile)) {
    return null;
  }

  return (
    <EntityFormPageLayout
      headerOptions={{
        title: 'Open new position',
        onCancelClick,
        onCopyFromAnotherBtnClick,
      }}
    >
      <JobOpeningForm
        mode={
          copyJobOpeningId
            ? JobOpeningFormMode.Duplicate
            : JobOpeningFormMode.Create
        }
      />
      <CopyFromJobOpeningModal
        isOpen={isOpenCopyJobOpeningModal}
        handleClose={handleCloseCopyJobOpeningModal}
      />
    </EntityFormPageLayout>
  );
};
