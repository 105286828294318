import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { jobOpeningDetailsStoreKey } from './jobOpeningDetails.const';
import { SnackbarType, apiEndpoints } from '@constants';
import {
  CloseJobOpeningPayload,
  CreateJobOpeningFormPayload,
  EditJobOpeningFormPayload,
  IDWHCandidateRaw,
  IJobOpeningDetails,
} from '@types';
import { transformCandidate } from '@utils';
import { setSnackbar } from '@redux/snackbar';

export const fetchJobOpeningById = createAsyncThunk(
  `${jobOpeningDetailsStoreKey}/fetchJobOpening`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<IJobOpeningDetails>(
        apiEndpoints.jobOpeningById(id),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchJobOpeningCandidates = createAsyncThunk(
  `${jobOpeningDetailsStoreKey}/fetchJobOpeningCandidates`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<IDWHCandidateRaw[]>(
        apiEndpoints.jobOpeningCandidates(id),
      );

      return response.data.map(transformCandidate);
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const createNewOpenPosition = createAsyncThunk(
  `${jobOpeningDetailsStoreKey}/createNewOpenPosition`,
  async (
    {
      data,
      onSuccess,
    }: { data: CreateJobOpeningFormPayload; onSuccess: () => void },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post(apiEndpoints.createJobOpening(), data);

      onSuccess();
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Job Opening has been created. Changes will be reflected in dashboard within 1 hour.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Job Opening hasn't been created. ${error?.message}`,
        }),
      );

      throw error;
    }
  },
);

export const editOpenPosition = createAsyncThunk(
  `${jobOpeningDetailsStoreKey}/editOpenPosition`,
  async (
    {
      id,
      data,
      onSuccess,
    }: {
      id: string;
      data: EditJobOpeningFormPayload;
      onSuccess: () => void;
    },
    thunkAPI,
  ) => {
    try {
      const response = await axios.patch(apiEndpoints.editJobOpening(id), data);

      onSuccess();
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Job Opening has been updated. Changes will be reflected in dashboard within 1 hour.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Job Opening hasn't been updated. ${error?.message}`,
        }),
      );

      throw error;
    }
  },
);

export const closeJobOpening = createAsyncThunk(
  `${jobOpeningDetailsStoreKey}/closeJobOpening`,
  async ({ id, ...payload }: CloseJobOpeningPayload, thunkAPI) => {
    try {
      await axios.post(apiEndpoints.closeJobOpeningPath(id), payload);

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Job opening has been closed. Changes will be reflected in dashboard within 1 hour.`,
        }),
      );
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Job opening hasn't been closed. ${error.message}`,
        }),
      );
      throw error;
    }
  },
);

export const putOnHoldJobOpening = createAsyncThunk(
  `${jobOpeningDetailsStoreKey}/putOnHoldJobOpening`,
  async (id: string, thunkAPI) => {
    try {
      await axios.post(apiEndpoints.putOhHoldJobOpeningPath(id));

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Job opening has been put on hold. Changes will be reflected in dashboard within 1 hour.`,
        }),
      );
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Job opening hasn't been put on hold. ${error.message}`,
        }),
      );
      throw error;
    }
  },
);

export const reopenJobOpening = createAsyncThunk(
  `${jobOpeningDetailsStoreKey}/reopenJobOpening`,
  async (id: string, thunkAPI) => {
    try {
      await axios.post(apiEndpoints.reopenJobOpeningPath(id));

      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Job opening has been reopened. Changes will be reflected in dashboard within 1 hour.`,
        }),
      );
    } catch (error: any) {
      thunkAPI.dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Job opening hasn't been reopened. ${error.message}`,
        }),
      );
      throw error;
    }
  },
);
