import ReactPlayer from 'react-player';
import { Flex } from '@components';

import { videoInterviewFileUrl } from 'constants/apiEndpoints';
import { SubmissionFormPayload } from '@types';

export const CDSubmissionVideoInterview: React.FC<{
  submission: SubmissionFormPayload;
}> = ({ submission }) => {
  const viQuestion = submission.videoInterview?.[0];

  if (!viQuestion) return null;

  return (
    <Flex
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: '10px',
        overflow: 'hidden',
        background: 'black',
        width: { xs: '100%', md: '100%' },
        height: { xs: '40%', md: '289px' },
      }}
    >
      <ReactPlayer
        controls
        playing
        width="100%"
        height="100%"
        url={videoInterviewFileUrl(viQuestion.recordFileName!)}
        style={{
          background: 'black',
          borderRadius: '4px',
        }}
      />
    </Flex>
  );
};
