import React from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';

import {
  isStatusLoading,
  checkUserCanAssociateCandidate,
  getPriorityIcon,
} from '@utils';
import { IDWHJobOpening, IProfile } from '@types';
import {
  ApiStatus,
  JobOpeningReasonOfClosing,
  JobOpeningStatus,
} from '@constants';
import { Flex } from '@components/common';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '0.25rem',
  '& svg': {
    width: '1.25rem',
    height: '1.25rem',
  },
}));

export const SuitableJobOpenings: React.FC<{
  profile: IProfile | null;
  data: IDWHJobOpening[];
  apiStatus: ApiStatus;
  openJobOpening: (id: string) => void;
  associateCanidate: (id: string) => void;
}> = ({ profile, data, apiStatus, openJobOpening, associateCanidate }) => {
  const isAbleToAssociate = checkUserCanAssociateCandidate(profile);

  if (!data.length) {
    return (
      <Typography variant="h3" textAlign="center" py={2}>
        No positions
      </Typography>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {data.map((position) => {
            const isOnHold =
              position.Job_Opening_Status === JobOpeningStatus.Closed &&
              position.Reason_of_Closing === JobOpeningReasonOfClosing.OnHold;
            const isClosed =
              position.Job_Opening_Status === JobOpeningStatus.Closed &&
              position.Reason_of_Closing !== JobOpeningReasonOfClosing.OnHold;

            const isAssociated = position.Is_Associated;

            const Icon = getPriorityIcon(position.Priority, isOnHold, isClosed);

            return (
              <TableRow key={position.id}>
                <TableCell>
                  <Flex gap={1}>
                    {Icon && (
                      <Tooltip
                        title={
                          isOnHold
                            ? 'On-Hold'
                            : isClosed
                            ? 'Closed'
                            : position.Priority
                        }
                        placement="top"
                      >
                        <Icon />
                      </Tooltip>
                    )}
                    <Typography variant="body1" color="secondary">
                      {position.Job_Opening_Id}
                    </Typography>
                    <Typography
                      onClick={() => openJobOpening(position.id)}
                      sx={(theme) => ({
                        color: theme.palette.text.primary,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      })}
                    >
                      {position.Client_Name}: {position.Job_Opening_Name}
                    </Typography>
                  </Flex>
                </TableCell>
                <TableCell>
                  <Flex>
                    <StyledIconButton
                      onClick={() => associateCanidate(position.id)}
                      disabled={
                        isStatusLoading(apiStatus) ||
                        !isAbleToAssociate ||
                        isAssociated
                      }
                    >
                      <Tooltip title="Associate candidate" placement="top">
                        <PersonAddOutlinedIcon />
                      </Tooltip>
                    </StyledIconButton>
                  </Flex>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
