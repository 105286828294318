import * as React from 'react';

import {
  IconButton,
  Tabs as MuiTabs,
  TabScrollButtonProps,
  TabsProps,
  styled,
} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITabsProps extends TabsProps {}

const StyledTabs = styled(MuiTabs)({
  minHeight: 'auto',
  '& .MuiTabs-flexContainer': {
    gap: '0.25rem',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.brand.accent,
  borderRadius: '4px',
  padding: '5px 2px',
  '&:hover': {
    backgroundColor: theme.palette.background.brandLight,
  },
}));

const CustomScrollButton = ({
  direction,
  disabled,
  onClick,
}: TabScrollButtonProps) => {
  return (
    <StyledIconButton
      onClick={onClick as unknown as React.MouseEventHandler<HTMLButtonElement>}
      aria-label={`Scroll ${direction}`}
      size="small"
      disabled={disabled}
    >
      {direction === 'left' ? (
        <ArrowBackRoundedIcon fontSize="small" />
      ) : (
        <ArrowForwardRoundedIcon fontSize="small" />
      )}
    </StyledIconButton>
  );
};

export const Tabs: React.FC<ITabsProps> = ({ children, ...rest }) => {
  return (
    <StyledTabs ScrollButtonComponent={CustomScrollButton} {...rest}>
      {children}
    </StyledTabs>
  );
};
