import { upperFirst } from 'lodash';

import { Box, Typography } from '@mui/material';
import { ScoreValue } from './ScoreValue';

import { getScoreColor, getScoreTextColor } from '@utils';
import { ScoreTypes } from '@types';

export const ScoreItem: React.FC<{
  score: number | null;
  type: ScoreTypes;
  value: string;
}> = ({ score, type, value }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.6em',
        width: '14em',
      }}
    >
      <ScoreValue
        score={score}
        bgColor={getScoreColor(score, type)}
        color={getScoreTextColor(score)}
      />
      <Box>
        <Typography variant="body2" color="secondary.main">
          {upperFirst(type)}
        </Typography>
        <Typography variant="body1">{value}</Typography>
      </Box>
    </Box>
  );
};
