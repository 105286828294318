import { CandidateField, ModalSectionLayout } from '@components';
import { CreateCandidateFormik } from '@types';

export const CommunicationDetailsSection: React.FC<{
  formik: CreateCandidateFormik;
}> = ({ formik }) => {
  return (
    <ModalSectionLayout title="Initial communication">
      <CandidateField
        field="Leads_owner_2018"
        value={formik.values.Leads_owner_2018}
        touched={!!formik.touched.Leads_owner_2018 || !!formik.submitCount}
        error={formik.errors.Leads_owner_2018}
        onChange={(value) => formik.setFieldValue('Leads_owner_2018', value)}
        onBlur={() => formik.setFieldTouched('Leads_owner_2018')}
      />
      <CandidateField
        field="Added_by"
        value={formik.values.Added_by}
        touched={!!formik.touched.Added_by || !!formik.submitCount}
        error={formik.errors.Added_by}
        onChange={(value) => formik.setFieldValue('Added_by', value)}
        onBlur={() => formik.setFieldTouched('Added_by')}
      />
      <CandidateField
        field="Leads_reply_2018"
        value={formik.values.Leads_reply_2018}
        touched={!!formik.touched.Leads_reply_2018 || !!formik.submitCount}
        error={formik.errors.Leads_reply_2018}
        onChange={(value) => formik.setFieldValue('Leads_reply_2018', value)}
        onBlur={() => formik.setFieldTouched('Leads_reply_2018')}
      />
    </ModalSectionLayout>
  );
};
