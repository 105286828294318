import { InternalTestTaskResolutionReviewOptions } from '@constants';
import * as yup from 'yup';

export const validationSchema = yup.object({
  resolution: yup
    .string()
    .oneOf(InternalTestTaskResolutionReviewOptions.map((e) => e.value))
    .required('Resolution is required'),
  feedback: yup.string().optional(),
  reviewer: yup.string().required('Reviewer is required'),
});
