import {
  CandidateProfileEditFormValues,
  CandidateProfileEditPayload,
  IZohoCandidate,
  ZohoTask,
} from '@types';
import { removeUndefinedAttributes } from './object';
import {
  differenceInDays,
  differenceInYears,
  format,
  parse,
  subYears,
} from 'date-fns';
import { CandidateStatus, LocationDetailsByCountry } from '@constants';
import { isNumber, isUndefined } from 'lodash';

export const getInitialCandidateProfileEditFormValues = (
  candidateDetails: IZohoCandidate,
): CandidateProfileEditFormValues => {
  const yearsOfExperience = candidateDetails.Seniority_Start_Date
    ? differenceInYears(
        new Date(),
        new Date(candidateDetails.Seniority_Start_Date),
      )
    : null;

  return {
    Email: candidateDetails.Email,
    First_Name: candidateDetails.First_Name,
    Last_Name: candidateDetails.Last_Name,
    Location_Country: candidateDetails.Location_Country,
    Location_City: candidateDetails.Location_City,
    English_level: candidateDetails.English_level,
    'Dev_-_QA-AQA': candidateDetails['Dev_-_QA-AQA'],
    Technical_Flow: candidateDetails.Technical_Flow,
    Primary_Skill_Set: candidateDetails.Primary_Skill_Set,
    Secondary_Skill_Set: candidateDetails.Secondary_Skill_Set,
    experience: yearsOfExperience,
    Seniority_Level: candidateDetails.Seniority_Level,
    Hourly_Rate_Expected: candidateDetails.Hourly_Rate_Expected,
    Hourly_Rate_Current: candidateDetails.Hourly_Rate_Current,
    Notice_period_from_Offer: candidateDetails.Notice_period_from_Offer,
    Do_not_touch_Russia: candidateDetails.Do_not_touch_Russia,
    Phone: candidateDetails.Phone,
    Telegram: candidateDetails.Telegram,
    LinkedIn_URL: candidateDetails.LinkedIn_URL,
    AE_Formatted_CV_Link: candidateDetails.AE_Formatted_CV_Link,
    resumeFileName: candidateDetails.CV_File_Name,
    resume: null,
  };
};

export const transformCandidateProfileEditFormValuesToPayload = (
  formData: CandidateProfileEditFormValues,
  updatedFields: Partial<CandidateProfileEditFormValues>,
): Partial<CandidateProfileEditPayload> => {
  const isLocationChanged =
    !isUndefined(updatedFields.Location_City) ||
    !isUndefined(updatedFields.Location_Country);
  const city = updatedFields.Location_City || formData.Location_City;
  const country = updatedFields.Location_Country || formData.Location_Country;

  return removeUndefinedAttributes({
    Email: updatedFields.Email,
    First_Name: updatedFields.First_Name,
    Last_Name: updatedFields.Last_Name,
    Location_City: updatedFields.Location_City,
    Location_Country: updatedFields.Location_Country,
    New_Location: isLocationChanged
      ? city === 'Other'
        ? country
        : city
      : undefined,
    Location_Area:
      isLocationChanged && country
        ? LocationDetailsByCountry[country]?.area
        : undefined,
    Time_zone:
      isLocationChanged && country
        ? LocationDetailsByCountry[country]?.timezone
        : undefined,
    'Dev_-_QA-AQA': updatedFields['Dev_-_QA-AQA'],
    Technical_Flow: updatedFields.Technical_Flow,
    Primary_Skill_Set: updatedFields.Primary_Skill_Set,
    Secondary_Skill_Set: updatedFields.Secondary_Skill_Set,
    English_level: updatedFields.English_level,
    Seniority_Level: updatedFields.Seniority_Level,
    Seniority_Start_Date: isNumber(updatedFields.experience)
      ? format(subYears(new Date(), updatedFields.experience), 'yyyy-MM-01')
      : undefined,
    Hourly_Rate_Expected: updatedFields.Hourly_Rate_Expected,
    Hourly_Rate_Current: updatedFields.Hourly_Rate_Current,
    Notice_period_from_Offer: updatedFields.Notice_period_from_Offer,
    Do_not_touch_Russia: updatedFields.Do_not_touch_Russia,
    Phone: updatedFields.Phone,
    Telegram: updatedFields.Telegram,
    LinkedIn_URL: updatedFields.LinkedIn_URL,
    AE_Formatted_CV_Link: updatedFields.AE_Formatted_CV_Link,
  });
};

export const checkIfCandidateIsClosed = (
  candidate: IZohoCandidate,
): boolean => {
  return !!(
    (candidate.Lead_month_2018 &&
      candidate.Date_of_lead_closing &&
      candidate.Lead_closed_because_NEW !== 'Intro call no-show') ||
    candidate?.Company_do_not_touch?.length ||
    candidate?.Do_not_touch_Russia
  );
};

export const checkIfCandidateIsInProcess = (
  candidate: IZohoCandidate,
): boolean => {
  const processPeriodDays = 60;

  return (
    !!candidate?.Lead_month_2018 &&
    differenceInDays(
      new Date(),
      parse(
        candidate.Lead_month_2018.split(' ').slice(1).join(' '),
        'MMMM yyyy',
        new Date(),
      ),
    ) <= processPeriodDays
  );
};

export const chackIfCandidateFromMarketing = (
  candidate: IZohoCandidate,
): boolean => {
  if (!candidate || !candidate.LeadGen_owner) return false;

  return (
    !candidate.LeadGen_owner.toLowerCase().includes('launchpod') &&
    candidate.LeadGen_owner !== 'Mariia Havrylenko'
  );
};

export const generateZohoTask = (
  candidate: IZohoCandidate,
  status: CandidateStatus,
): ZohoTask | null => {
  const subject =
    status === CandidateStatus.IC_No_Show
      ? 'Call was missed by candidate. Re - scheduled? - FU 1'
      : status === CandidateStatus.IC_Canceled_By_Candidate
      ? 'Subject Call was CANCELLED by candidate Re - scheduled? FU 1'
      : null;

  if (!subject) return null;

  return {
    Subject: subject,
    Owner: candidate.LeadGen_owner!,
    Priority: 'High',
    Due_Date: format(new Date(), 'yyyy-MM-dd'),
    Context: 'Follow up 1',
    What_Id: candidate.id,
    $se_module: 'Candidates',
  };
};

export const generateRejectionEmailByStatus = (
  candidate: IZohoCandidate,
  candidateStatus: CandidateStatus,
): { subject: string; content: string } | null => {
  if (
    [
      CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
      CandidateStatus.IC_Rejected_By_Recruiter_Location,
      CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
      CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
    ].includes(candidateStatus)
  )
    return {
      subject: 'Your interview with AgileEngine talent executive is cancelled',
      content: `${candidate.First_Name},\n\nThank you for your interest in AgileEngine!\n\nWe have good and not-so-good news for you. The not-so-good news is that unfortunately our current projects don't appear to match your profile.\n\nAs for the good news, we are still keen to see you join AgileEngine later and we may reach out to you once we have something exciting.\n\nHave a great day!`,
    };

  if (
    [
      CandidateStatus.Offer_Rejected,
      CandidateStatus.Disqualified_RejectedOffer,
      CandidateStatus.Disqualified_DidntStartWorking,
      CandidateStatus.Disqualified_NotFitSoftSkills,
    ].includes(candidateStatus)
  )
    return {
      subject: 'Thank you for your application!',
      content: `${candidate.First_Name},\n\nThank you for your interest in AgileEngine. Unfortunately, our current projects don't appear to match your profile. We will reach out to you once we have something exciting, so please stay tuned!`,
    };

  if (
    [
      CandidateStatus.Disqualified_LowEnglishLEvel,
      CandidateStatus.VI_Low_English,
    ].includes(candidateStatus)
  )
    return {
      subject: 'Thank you for your application!',
      content: `${candidate.First_Name},\n\nThank you for taking the time to apply to AgileEngine!\n\nAfter careful consideration, we want you to know we won’t be moving forward at this time. Our projects involve daily meetings with native English speakers and US companies, so an advanced English level is required.\n\nWe value transparency, and we want to ensure that all members of our team can effectively collaborate, communicate, and feel comfortable with the multicultural environment we have.\n\nWe would be happy to reconnect with you in the future!\n\nHave a great day! 🙂`,
    };

  if (
    [
      CandidateStatus.Disqualified_NotFitLocation,
      CandidateStatus.Disqualified_RelocationOnly,
    ].includes(candidateStatus)
  )
    return {
      subject: 'Thank you for your application!',
      content: `${candidate.First_Name},\n\nThank you for taking your time and applying to AgileEngine!\n\nWe don’t have offices in your city yet and can’t engage experts outside our official locations.\n\nWe would be happy to reconnect with you when the time is right!\n\nHave a great day! 🙂`,
    };

  if ([CandidateStatus.Disqualified_LeagalIssues].includes(candidateStatus))
    return {
      subject: 'Thank you for your application!',
      content: `${candidate.First_Name},\n\nThank you for taking the time to apply to AgileEngine!\n\nUnfortunately, due to some changes in the requirements, we're not able to proceed this time.\n\nIf the situation changes, we would be happy to reconnect!\n\nHave a great day! 🙂`,
    };

  if ([CandidateStatus.HighSalaryExpectations].includes(candidateStatus))
    return {
      subject: 'Thank you for your application!',
      content: `${candidate.First_Name},\n\nThank you for taking your time and applying to AgileEngine!\n\nOur team would be excited to see you join our ranks, but the compensation you've stated is outside our current range. Please, let us know if you change your mind and are willing to share new expectations with us.\n\nAlternatively, we engage with 2-3 new clients every month, so there's always a chance we will have a project matching your expectations in the future. We would be happy to reconnect once we can offer you an opportunity of this kind.\n\nLet's keep in touch!`,
    };

  if (
    [CandidateStatus.No_Suitable_Openings_Seniority].includes(candidateStatus)
  )
    return {
      subject: 'Thank you for your application!',
      content: `${candidate.First_Name},\n\nThank you for taking your time and applying to AgileEngine!\n\nOur current opportunities don't match your seniority level.\nDon't worry about that! You are our top priority candidate as soon as an exciting opportunity comes up!\n\nHave a great day! 🙂`,
    };

  if ([CandidateStatus.No_Suitable_Openings_Location].includes(candidateStatus))
    return {
      subject: 'Thank you for your application!',
      content: `${candidate.First_Name},\n\nThank you for taking your time and applying to AgileEngine!\n\nOur current opportunities don't match your location.\nDon't worry about that! You are our top priority candidate as soon as an exciting opportunity comes up!\n\nHave a great day! 🙂`,
    };

  if (
    [
      CandidateStatus.No_Suitable_Openings_CoreStack,
      CandidateStatus.No_Suitable_Openings_NotOurCoreStack,
    ].includes(candidateStatus)
  )
    return {
      subject: 'Thank you for your application!',
      content: `${candidate.First_Name},\n\nThank you for taking your time and applying to AgileEngine!\n\nOur current opportunities don't match your skill set.\nDon't worry about that! You are our top priority candidate as soon as an exciting opportunity comes up!\n\nHave a great day! 🙂`,
    };

  if (
    [
      CandidateStatus.TI_Failed_For_AE,
      CandidateStatus.TI_Failed_For_Project,
      CandidateStatus.CI_Failed_Communication,
      CandidateStatus.CI_Failed_Location,
      CandidateStatus.CI_Failed_Seniority,
      CandidateStatus.CI_Failed_Tech_Skills,
      CandidateStatus.TL_Intro_Failed_Soft_Skills,
      CandidateStatus.TL_Intro_Failed_Tech_Skills,
      CandidateStatus.TL_Intro_Failed_Teach_Soft_Skills,
    ].includes(candidateStatus)
  )
    return {
      subject:
        'Your journey with AgileEngine stops here, but we’re not saying goodbye',
      content: `${candidate.First_Name},\n\nThank you for taking your time and applying to AgileEngine!\n\nWe have good and not-so-good news for you. The not-so-good news is that unfortunately our current projects don't appear to match your profile.\n\nFeel free to take tests focusing on the skills you’re more comfortable with, and we will be happy to offer you new projects.\n\nAs for the good news, we are still keen to see you join AgileEngine later and we may reach out to you once we have something exciting.\n\nHave a great day! 🙂`,
    };

  return null;
};
