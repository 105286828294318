import React, { ReactNode } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export const CDRowsDataLayout: React.FC<{
  data: { label?: string; value: string | ReactNode }[];
}> = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Table>
      <TableBody>
        {data.map(({ label, value }, index) => (
          <TableRow
            key={label || index}
            sx={{ '&:last-child td': { borderBottom: 'none ' } }}
          >
            {label && (
              <TableCell
                sx={{
                  padding: '11px 8px 13px 0px',
                  verticalAlign: 'top',
                  width: '120px',
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  whiteSpace="nowrap"
                >
                  {label}
                </Typography>
                {isMobile && (
                  <Typography
                    variant="body2"
                    color="text.primary"
                    whiteSpace="pre-line"
                  >
                    {value}
                  </Typography>
                )}
              </TableCell>
            )}

            {(!label || !isMobile) && (
              <TableCell
                sx={{
                  padding: '8px 8px 12px 0px',
                }}
              >
                {typeof value === 'string' ? (
                  <Typography
                    variant="body1"
                    color="text.primary"
                    whiteSpace="pre-line"
                  >
                    {value}
                  </Typography>
                ) : (
                  value
                )}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
