import { AppRoutes } from '@constants';

export const isJobOpeningsPath = (pathname: string) =>
  pathname.toLowerCase().startsWith(`/${AppRoutes.JOB_OPENINGS}`);

export const isOnHoldJobOpeningsPath = (pathname: string) =>
  pathname.toLowerCase().startsWith(`/${AppRoutes.ON_HOLD_JOB_OPENINGS}`);

export const isCandidatesPath = (pathname: string) =>
  pathname.toLowerCase() === `/${AppRoutes.CANDIDATES}`;

export const isActivePath = (pathname: string, route: string) =>
  pathname.toLowerCase().includes(route);
