const STATIC_FILES_BASE_ENDPOINT =
  'https://ae-cp-static-files.s3.eu-central-1.amazonaws.com';

const LAUNCHPOD_BASE_ENDPOINT =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://launchpod.agileengine.com'
    : process.env.REACT_APP_ENV === 'staging'
    ? 'https://candidate-portal-staging.agileengine.com'
    : 'https://candidate-portal-dev.agileengine.com';

export const internalTestTaskAttachmentFileUrl = (filename: string) =>
  `${STATIC_FILES_BASE_ENDPOINT}/internal_tt_attachments/${filename}`;

export const testTaskFileUrl = (filename: string) =>
  `${LAUNCHPOD_BASE_ENDPOINT}/api/admin/open-file/test-task-summary/${filename}`;

export const videoInterviewFileUrl = (filename: string) =>
  `${LAUNCHPOD_BASE_ENDPOINT}/api/admin/stream-video/video-interview/${filename}`;

export const resumeFileUrl = (filename: string) =>
  `${LAUNCHPOD_BASE_ENDPOINT}/api/admin/open-file/cv/${filename}`;
