import { candidatesStoreKey } from './candidates.const';
import { ICandidatesSliceState } from './candidates.types';

interface IState {
  [candidatesStoreKey]: ICandidatesSliceState;
}

const selectCandidatesState = (state: IState) => {
  return state[candidatesStoreKey];
};

const getCandidatesStatus = (state: IState) => {
  return selectCandidatesState(state).apiStatus;
};

const getCandidatesData = (state: IState) => {
  return selectCandidatesState(state).data;
};

const getCandidatesTotal = (state: IState) => {
  return selectCandidatesState(state).totalCount;
};

const getCandidatesOptions = (state: IState) => {
  return selectCandidatesState(state).options;
};

export const candidatesSelectors = {
  getCandidatesStatus,
  getCandidatesData,
  getCandidatesTotal,
  getCandidatesOptions,
};
