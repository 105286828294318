export enum SubmissionStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  CallRequested = 'Call Requested',
  CallScheduled = 'Call Scheduled',
  Rejected = 'Rejected',
  Hired = 'Hired',
}

export enum SubmissionStep {
  Profile = 'Profile',
  Resume = 'Resume',
  VideoInterview = 'Video',
  TestTask = 'Test task',
  Feedback = 'Feedback',
  Interview = 'Interview',
  Recipients = 'Recipients',
  Review = 'Review',
}

export enum ClientFeedbackResolution {
  Rejected = 'Rejected',
  CallRequested = 'Call Requested',
  Hired = 'Hired',
}

export const SubmissionStepsOrdered = [
  SubmissionStep.Profile,
  SubmissionStep.Resume,
  SubmissionStep.VideoInterview,
  SubmissionStep.TestTask,
  SubmissionStep.Feedback,
  SubmissionStep.Interview,
  SubmissionStep.Recipients,
  SubmissionStep.Review,
];
