import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Box,
  List,
  ListItem,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { CustomButton } from '@components';
import { VideoInterviewSendConfirmationModal } from './VideoInterviewSendConfirmationModal';

import {
  candidateDetailsSelectors,
  postCandidateSendVideoInterview,
} from '@redux/candidateDetails';

import { isStatusLoading } from '@utils';
import { VideoInterview, VideoInterviewQuestion } from '@types';

export const VideoInterviewSendForm: React.FC<{
  availableVideoInterview: VideoInterview[];
}> = ({ availableVideoInterview }) => {
  const dispatch = useAppDispatch();

  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const sendVideoInterviewAPIStatus = useAppSelector(
    candidateDetailsSelectors.getSendVideoInterviewAPIStatus,
  );

  const [videoInterview, setVideoInterview] = useState<string>('');
  const [videoInterviewSendModal, setVideoInterviewSendModal] =
    useState<boolean>(false);

  const selectedVideoInterview = useMemo(() => {
    return availableVideoInterview.find((vi) => vi.id === videoInterview);
  }, [availableVideoInterview, videoInterview]);

  const handleOpenVideoInterviewSendModal = useCallback(
    () => setVideoInterviewSendModal(true),
    [],
  );

  const handleCloseVideoInterviewSendModal = useCallback(
    () => setVideoInterviewSendModal(false),
    [],
  );

  const sendVideoInterview = useCallback(() => {
    if (candidateId && selectedVideoInterview) {
      dispatch(
        postCandidateSendVideoInterview({
          candidateId,
          videoInterviewId: selectedVideoInterview?.id,
        }),
      );
    }
  }, [candidateId, selectedVideoInterview]);

  return (
    <>
      <Paper
        sx={(theme) => ({
          padding: {
            xs: '0.5rem 1rem',
            sm: '4rem 6.5rem',
          },
          height: '100%',
          marginTop: {
            xs: 0,
            sm: '2rem',
          },
          [theme.breakpoints.down('sm')]: {
            boxShadow: 'none',
          },
        })}
      >
        <Stack gap="2rem">
          <Typography variant="h2" textAlign={{ xs: 'start', sm: 'center' }}>
            Candidate hasn't done VI yet
          </Typography>
          <TextField
            select
            fullWidth
            id="VI_Specialization"
            label="VI questions"
            value={videoInterview}
            onChange={(e) => setVideoInterview(e.target.value)}
            InputLabelProps={{ shrink: true }}
            SelectProps={{
              displayEmpty: true,
              sx: {
                '& .MuiSelect-select .notranslate::after':
                  'Select specialization'
                    ? {
                        content: '"Select specialization"',
                        opacity: 0.42,
                      }
                    : {},
              },
            }}
          >
            <MenuItem value="">Select specialization</MenuItem>
            {availableVideoInterview.map((videoInterview) => (
              <MenuItem key={videoInterview.id} value={videoInterview.id}>
                {videoInterview.specialization}
              </MenuItem>
            ))}
          </TextField>
          <List
            sx={{
              padding: 0,
              listStyle: 'none',
            }}
          >
            {selectedVideoInterview?.questions.map(
              (question: VideoInterviewQuestion) => {
                return (
                  <ListItem
                    key={question.questionNumber}
                    sx={(theme) => ({
                      padding: '0.5rem 1rem 1rem 1rem !important',
                      marginBottom: '0.25rem !important',
                      borderRadius: '0.25rem',
                      background: theme.palette.highlight.actionable,
                    })}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Question {question.questionNumber}
                    </Typography>
                    <Typography variant="body1">{question.title}</Typography>
                  </ListItem>
                );
              },
            )}
          </List>
          {videoInterview ? (
            <Box margin="auto">
              <CustomButton
                size="medium"
                color="primary"
                label={'Send VI invitation'}
                onClick={handleOpenVideoInterviewSendModal}
                isDisabled={!videoInterview}
              />
            </Box>
          ) : (
            <></>
          )}
        </Stack>
      </Paper>
      <VideoInterviewSendConfirmationModal
        isLoading={isStatusLoading(sendVideoInterviewAPIStatus)}
        isOpen={videoInterviewSendModal}
        handleClose={handleCloseVideoInterviewSendModal}
        handleSendVI={sendVideoInterview}
      />
    </>
  );
};
