import { ScoreTypes, ICandidateScore } from '@types';

export const getScoreColor = (
  score: number | null,
  scoreType?: ScoreTypes,
): string => {
  if (score === null) {
    return 'background.disabled';
  }

  if (
    scoreType === ScoreTypes.Location ||
    scoreType === ScoreTypes.Seniority ||
    scoreType === ScoreTypes.English
  ) {
    return score > 1 // eslint-disable-line prettier/prettier
      ? 'highlight.scoreGreen'
      : score > 0
      ? 'highlight.scoreYellow'
      : 'highlight.scoreBlack';
  }

  if (scoreType === ScoreTypes.Skills) {
    return score > 6 // eslint-disable-line prettier/prettier
      ? 'highlight.scoreGreen'
      : score > 2.5
      ? 'highlight.scoreYellow'
      : 'highlight.scoreBlack';
  }

  return 'highlight.scoreBlack';
};

export const getScoreTextColor = (score: number | null): string => {
  if (score === null) {
    return 'text.disabled';
  }

  return 'text.invertedPrimary';
};

export const getTotalScoreColor = (score: ICandidateScore): string => {
  if (isScoreIncomplete(score)) return 'text.disabled';

  return 'text.invertedPrimary';
};

export const getTotalScoreBgColor = (score: ICandidateScore): string => {
  if (isScoreIncomplete(score)) return 'background.disabled';
  if (isCandidateDisqualified(score)) return 'highlight.scoreBlack';

  return score.total >= 12
    ? 'highlight.scoreGreen'
    : score.total >= 4
    ? 'highlight.scoreYellow'
    : 'highlight.scoreBlack';
};

export const getScoreHeaderText = (score: ICandidateScore): string => {
  if (isScoreIncomplete(score)) return 'Incomplete score';
  if (isCandidateDisqualified(score)) {
    return `Disqualified for ${getCandidateDisqualifyingReason(
      score,
    )} despite the results`;
  }

  return score.total >= 12
    ? 'Can go to the intro call'
    : 'Doesn’t go to intro call unless manually contacted by recruiter';
};

const getCandidateDisqualifyingReason = (score: ICandidateScore): string => {
  const isDisqualifiedByEnglish = score.list.some((score) => {
    return score.type === ScoreTypes.English && score.points === 0;
  });
  if (isDisqualifiedByEnglish) return 'English';

  const isDisqualifiedBySkills = score.list.some(
    (score) =>
      score.type === ScoreTypes.Skills && score.points && score.points <= 2.5,
  );
  if (isDisqualifiedBySkills) {
    return 'Skills';
  }

  return '';
};

export const isCandidateDisqualified = (score: ICandidateScore): boolean => {
  return Boolean(getCandidateDisqualifyingReason(score));
};

export const isScoreIncomplete = (score: ICandidateScore): boolean => {
  return score.list.some((score) => score.points === null);
};
