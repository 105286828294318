import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { isEmpty } from 'lodash';

import { Box, Stack, Typography } from '@mui/material';
import { LoaderSkeleton, ForbiddenView, Quiz } from '@components';

import {
  candidateDetailsSelectors,
  fetchCandidateKnowledgeQuiz,
} from '@redux/candidateDetails';

import { isStatusForbidden, isStatusIdle, isStatusLoading } from '@utils';
import { KnowledgeQuizStatus } from '@constants';

export const CandidateQuiz = () => {
  const dispatch = useAppDispatch();

  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const knowledgeQuizAPIStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateKnowledgeQuizApiStatus,
  );
  const knowledgeQuizData = useAppSelector(
    candidateDetailsSelectors.getCandidateKnowledgeQuizData,
  );

  useEffect(() => {
    if (candidateId && isStatusIdle(knowledgeQuizAPIStatus)) {
      dispatch(fetchCandidateKnowledgeQuiz(candidateId));
    }
  }, [candidateId, knowledgeQuizAPIStatus]);

  if (isStatusLoading(knowledgeQuizAPIStatus)) {
    return (
      <Box height="300px">
        <LoaderSkeleton />
      </Box>
    );
  }

  if (isStatusForbidden(knowledgeQuizAPIStatus)) {
    return <ForbiddenView />;
  }

  if (!knowledgeQuizData || isEmpty(knowledgeQuizData))
    return (
      <Typography variant="h2" textAlign="center">
        No Available Data
      </Typography>
    );

  return (
    <React.Fragment>
      <Stack my={'0.5rem'} gap={'1rem'}>
        <Stack
          gap={'1.5rem'}
          flexDirection={'row'}
          sx={{
            mx: {
              xs: '1rem',
              sm: '0.25rem',
            },
          }}
        >
          <Typography variant="h3" flex={1} my={'auto'}>
            Candidate quiz
          </Typography>
        </Stack>
        <Stack
          sx={{
            backgroundColor: {
              xs: 'none',
              sm: 'background.backgroundPrimary',
            },
            borderRadius: '0.5rem',
            padding: {
              xs: '0',
              sm: '1rem',
            },
            mx: {
              xs: '0',
              sm: '-1rem',
            },
          }}
        >
          <Quiz
            name={knowledgeQuizData.quiz.specialization}
            questions={knowledgeQuizData.quiz.questions}
            answers={knowledgeQuizData.answers}
            score={knowledgeQuizData.percent}
            isPassed={knowledgeQuizData.status === KnowledgeQuizStatus.Passed}
            isDone={[
              KnowledgeQuizStatus.Passed,
              KnowledgeQuizStatus.Failed,
            ].includes(knowledgeQuizData.status)}
          />
        </Stack>
      </Stack>
    </React.Fragment>
  );
};
