import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Hidden, Stack, Typography } from '@mui/material';

import {
  LoaderSkeleton,
  TabPanel,
  ForbiddenView,
  CandidateProfileData,
  CandidateMeetings,
  CandidateVideoInterview,
  CandidateTestTasks,
  CandidateSuitablePositions,
  CandidateQuiz,
  CandidateTimeline,
  CandidateMarketingInfo,
  CandidateSubmissions,
  CandidateName,
} from '@components';
import { CandidateStatus } from './components/CandidateStatus';
import { HotPipelineStatus } from './components/HotPipelineStatus';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  candidateDetailsSelectors,
  clearCandidateDetails,
  fetchCandidateDetails,
} from '@redux/candidateDetails';

import {
  isStatusFailed,
  isStatusForbidden,
  isStatusIdle,
  isStatusLoading,
} from '@utils';
import {
  AppRoutes,
  CandidateDetailsTabLabel,
  CandidateDetailsTabs,
  CandidateDetailsTabsOrder,
} from '@constants';
import { useTitle } from '@hooks/useTitle';

export const CandidateDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedTabName = CandidateDetailsTabs.PROFILE } = useParams();
  const setPageTitle = useTitle();

  const id = useAppSelector(candidateDetailsSelectors.getCandidateDetailsId);
  const candidateApiStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsApiStatus,
  );

  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );

  const selectedTabIdx = useMemo(
    () =>
      CandidateDetailsTabsOrder.indexOf(
        selectedTabName as CandidateDetailsTabs,
      ),
    [selectedTabName],
  );

  useEffect(() => {
    if (id && isStatusIdle(candidateApiStatus)) {
      dispatch(fetchCandidateDetails(id));
    }
  }, [id, candidateApiStatus]);

  useEffect(() => {
    if (candidateDetails && candidateDetails?.Full_Name) {
      setPageTitle(
        `${candidateDetails?.Full_Name} • ${
          CandidateDetailsTabLabel[selectedTabName as CandidateDetailsTabs]
        }` || '',
      );
    }
  }, [setPageTitle, selectedTabIdx, candidateDetails]);

  useEffect(() => {
    return () => {
      if (id) {
        dispatch(clearCandidateDetails());
      }
    };
  }, [id]);

  const onTabSelection = useCallback(
    (e: any, selectedTabIdx: number) => {
      navigate(
        `/${AppRoutes.CANDIDATES}/${id}/${CandidateDetailsTabsOrder[selectedTabIdx]}`,
      );
    },
    [id],
  );

  if (isStatusLoading(candidateApiStatus)) {
    return <LoaderSkeleton />;
  }

  if (isStatusFailed(candidateApiStatus)) {
    return (
      <Typography variant="h3" textAlign="center">
        Candidate not found
      </Typography>
    );
  }

  if (isStatusForbidden(candidateApiStatus)) {
    return <ForbiddenView />;
  }

  return (
    <React.Fragment>
      <Hidden mdUp>
        <CandidateName
          sx={{
            padding: '1rem',
          }}
        />
      </Hidden>
      {CandidateDetailsTabsOrder.map((tabSlug, idx) => {
        const componentByTab = {
          [CandidateDetailsTabs.PROFILE]: (
            <Stack
              direction="column"
              sx={{
                gap: {
                  xs: '1rem',
                  sm: '2rem',
                },
              }}
            >
              <CandidateStatus onTabSelect={onTabSelection} />
              <HotPipelineStatus />
              <CandidateProfileData />
            </Stack>
          ),
          [CandidateDetailsTabs.VIDEO_INTERVIEW]: <CandidateVideoInterview />,
          [CandidateDetailsTabs.TEST_TASK]: <CandidateTestTasks />,
          [CandidateDetailsTabs.QUIZ]: <CandidateQuiz />,
          [CandidateDetailsTabs.CALLS]: <CandidateMeetings />,
          [CandidateDetailsTabs.POSITIONS]: <CandidateSuitablePositions />,
          [CandidateDetailsTabs.TIMELINE]: <CandidateTimeline />,
          [CandidateDetailsTabs.MARKETING]: (
            <Stack sx={{ maxWidth: '40vw' }}>
              <CandidateMarketingInfo />
            </Stack>
          ),
          [CandidateDetailsTabs.SUBMISSIONS]: <CandidateSubmissions />,
        };
        const Component = componentByTab[tabSlug];

        if (!Component) return null;

        return (
          <TabPanel
            key={`${tabSlug}-tab-panel`}
            id={`${tabSlug}-tab-panel`}
            value={selectedTabIdx}
            index={idx}
            height="100%"
          >
            {Component}
          </TabPanel>
        );
      })}
    </React.Fragment>
  );
};
