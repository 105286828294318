import { createSlice } from '@reduxjs/toolkit';

import { AuthStatus } from '@constants';
import { authStoreKey } from './auth.const';
import { logout, login, fetchProfile } from './auth.thunks';
import { IAuthSliceState } from './auth.types';

const initialState: IAuthSliceState = {
  authStatus: AuthStatus.ANONYMOUS,
  authError: null,
  profile: null,
};

const authSlice = createSlice({
  name: authStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(login.pending, (state) => {
        state.authStatus = AuthStatus.LOADING;
        state.authError = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.authStatus = AuthStatus.LOGGEDIN;
        state.profile = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.authStatus = AuthStatus.FAILED;
        state.authError = action.error.message!;
      })
      // Logout
      .addCase(logout.pending, (state) => {
        state.authStatus = AuthStatus.LOADING;
        state.authError = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.authStatus = AuthStatus.ANONYMOUS;
      })
      .addCase(logout.rejected, (state, action) => {
        state.authStatus = AuthStatus.FAILED;
        state.authError = action.error.message!;
      })
      // Profile
      .addCase(fetchProfile.pending, (state) => {
        state.authStatus = AuthStatus.LOADING;
        state.profile = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.authStatus = AuthStatus.LOGGEDIN;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.authStatus = AuthStatus.FAILED;
        state.authError = action.error.message!;
      });
  },
});

export const authSliceReducer = authSlice.reducer;
