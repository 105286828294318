import { ReactNode } from 'react';

import { Box } from '@mui/material';
import { SlidingDrawer } from './components/SlidingDrawer';

export const SlidingDrawerLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        minHeight: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <SlidingDrawer />
      <Box component={'main'} sx={{ flex: '1 1 auto' }}>
        {children}
      </Box>
    </Box>
  );
};
