import { submissionStoreKey } from './submission.const';
import { ISubmissionSliceState } from './submission.types';

interface IState {
  [submissionStoreKey]: ISubmissionSliceState;
}

const selectSubmissionState = (state: IState) => {
  return state[submissionStoreKey];
};

const getSubmissionDetails = (state: IState) =>
  selectSubmissionState(state).submissionDetails;
const getSubmissionCandidateDetails = (state: IState) =>
  selectSubmissionState(state).candidateDetails;
const getSubmissionVideoInterview = (state: IState) =>
  selectSubmissionState(state).videoInterview;
const getSubmissionTestTask = (state: IState) =>
  selectSubmissionState(state).testTasks;
const getSubmissionIsPerformingAction = (state: IState) =>
  selectSubmissionState(state).isPerformingAction;

export const submissionSelectors = {
  getSubmissionDetails,
  getSubmissionCandidateDetails,
  getSubmissionVideoInterview,
  getSubmissionTestTask,
  getSubmissionIsPerformingAction,
};
