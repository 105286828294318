import { IAuthSliceState } from './auth.types';
import { authStoreKey } from './auth.const';

interface IState {
  [authStoreKey]: IAuthSliceState;
}

const selectAuthState = (state: IState) => {
  return state[authStoreKey];
};

const getAuthStatus = (state: IState) => {
  return selectAuthState(state).authStatus;
};

const getAuthError = (state: IState) => {
  return selectAuthState(state).authError;
};

const getProfileData = (state: IState) => {
  return selectAuthState(state).profile;
};

export const authSelectors = {
  getAuthStatus,
  getAuthError,
  getProfileData,
};
