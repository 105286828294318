import * as React from 'react';

import { Tab as MuiTab, TabProps, styled } from '@mui/material';

interface ITabProps extends TabProps {
  id: string;
}

const StyledTab = styled(MuiTab)(({ theme }) => ({
  padding: '0.25rem 0.625rem',
  textTransform: 'none',
  fontWeight: 400,
  letterSpacing: 0,
  minWidth: 'auto',
  minHeight: 'auto',
  lineHeight: '1.375rem',
  borderRadius: '0.25rem',
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.text.brand,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.brandLight,
  },
}));

export const Tab: React.FC<ITabProps> = ({ ...rest }) => {
  return <StyledTab {...rest} />;
};
