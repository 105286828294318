import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { store } from './';
import { rootReducer } from './store';

export type AppDispatch = typeof store.dispatch;
export type RootState = typeof rootReducer;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
