import React, { useRef, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';

interface IOverflowTooltip {
  children: React.ReactNode;
}

export const OverflowTooltip: React.FC<IOverflowTooltip> = ({ children }) => {
  const textElementRef = useRef<HTMLDivElement | null>(null);

  const [isOverflowed, setIsOverflow] = useState<boolean>(false);

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
      );
    }
  }, []);

  return (
    <Tooltip arrow title={children} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
