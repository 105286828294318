import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { snackbarStoreKey } from './snackbar.const';
import { ISnackbarSliceState } from './snackbar.types';
import { SnackbarType } from '@constants';

const initialState: ISnackbarSliceState = {
  type: undefined,
  message: '',
};

export const snackbarSlice = createSlice({
  name: snackbarStoreKey,
  initialState,
  reducers: {
    setSnackbar: (
      state,
      action: PayloadAction<{ type: SnackbarType; message: string }>,
    ) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
    eraseSnackbar: (state) => {
      state.type = initialState.type;
      state.message = initialState.message;
    },
  },
});

export const { setSnackbar, eraseSnackbar } = snackbarSlice.actions;

export const snackbarSliceReducer = snackbarSlice.reducer;
