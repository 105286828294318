import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';

import {
  fetchEmployees,
  optionsSelectors,
  resetEmployeeOptions,
} from '@redux/options';

import { isStatusComplete, isStatusLoading } from '@utils';
import { IEmployee } from '@types';

export const EmployeeAutocomplete: React.FC<{
  label: string;
  name?: string;
  value: string | null;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (employee: IEmployee | null) => void;
  onBlur?: () => void;
}> = ({
  label,
  name,
  value,
  disabled,
  required,
  fullWidth,
  error,
  helperText,
  onChange,
  onBlur,
}) => {
  const dispatch = useAppDispatch();

  const { apiStatus, data, search } = useAppSelector(
    optionsSelectors.getEmployeesAPIData,
  );
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    if (inputValue === value) return;

    if (inputValue) {
      dispatch(fetchEmployees(inputValue));
    } else {
      dispatch(resetEmployeeOptions());
    }
  }, [inputValue]);

  const options = useMemo(
    () => data.map((employee) => employee.email_id),
    [data],
  );

  const isLoading = isStatusLoading(apiStatus) && search === inputValue;

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
  ) => {
    const employee = data.find((e) => e.email_id === value);

    if (employee) onChange(employee);
  };
  const handleInputChange = useMemo(
    () => debounce((e: any, value: string) => setInputValue(value), 1000),
    [],
  );
  const handleClose = () => {
    dispatch(resetEmployeeOptions());
  };

  return (
    <Autocomplete
      value={value}
      options={options}
      disabled={disabled}
      onChange={handleChange}
      onClose={handleClose}
      onBlur={onBlur}
      loading={isLoading}
      onInputChange={handleInputChange}
      filterSelectedOptions
      fullWidth={fullWidth}
      noOptionsText={
        isStatusComplete(apiStatus) ? 'No options' : 'Please start typing...'
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          required={required}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
