import { SyntheticEvent, useEffect, useMemo } from 'react';
import { groupBy, keyBy, mapValues, uniq, values } from 'lodash';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material';
import { CountryFlag } from '@components';

import { optionsSelectors, fetchJobOpeningCountries } from '@redux/options';

import { isStatusIdle } from '@utils';

export const PositionLocationField: React.FC<{
  value: string[] | null;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({ value, onChange, onBlur }) => {
  const dispatch = useAppDispatch();

  const { data: countries, apiStatus } = useAppSelector(
    optionsSelectors.getJobOpeningCountries,
  );

  const countriesById = useMemo(() => keyBy(countries, 'id'), [countries]);

  const countriesOptions = useMemo(
    () => groupBy(countries, 'region'),
    [countries],
  );

  const cities = useMemo(
    () => countries.flatMap((country) => country.cities),
    [countries],
  );
  const citiesOptions = useMemo(
    () => cities.map((city) => city.value),
    [cities],
  );

  const getCountryByCity = (selectedCity: string) => {
    const countryId = cities.find(
      (city) => city.value === selectedCity,
    )?.countryId;

    if (!countryId) return '';

    return countriesById[countryId].value || '';
  };

  const selectedCities =
    value?.filter((location) => citiesOptions.includes(location)) || [];

  const selectedCountries = useMemo(
    () =>
      value?.filter((location) =>
        countries.some((country) => country.value === location),
      ) || [],
    [value, countries],
  );

  useEffect(() => {
    if (isStatusIdle(apiStatus)) dispatch(fetchJobOpeningCountries());
  }, [apiStatus]);

  return (
    <Stack gap={3}>
      <Stack gap={3} direction="row">
        {values(
          mapValues(countriesOptions, (regionCountries, region) => (
            <Stack key={region}>
              {regionCountries.length > 1 && (
                <FormControlLabel
                  label={`All in ${region}`}
                  control={
                    <Checkbox
                      checked={regionCountries.every((regionCountry) =>
                        selectedCountries.includes(regionCountry.value),
                      )}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        if (event.target.checked) {
                          onChange(
                            uniq([
                              ...(value || []),
                              ...regionCountries.map((e) => e.value),
                            ]),
                          );
                        } else {
                          onChange(
                            uniq(
                              value?.filter(
                                (selectedCountry) =>
                                  !regionCountries.find(
                                    (e) => e.value === selectedCountry,
                                  ),
                              ),
                            ),
                          );
                        }
                      }}
                    />
                  }
                />
              )}
              {regionCountries.map((country) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          if (event.target.checked) {
                            const nextValue = uniq([
                              ...(value || []),
                              event.target.name,
                            ]);
                            onChange(nextValue.length ? nextValue : null);
                          } else {
                            const nextValue = value?.filter(
                              (slectedCountry) =>
                                slectedCountry !== event.target.name,
                            );
                            onChange(nextValue?.length ? nextValue : null);
                          }
                        }}
                      />
                    }
                    checked={selectedCountries.includes(country.value) || false}
                    key={country.value}
                    name={country.value}
                    label={
                      <>
                        <CountryFlag country={country.value} />
                        {country.title}
                      </>
                    }
                    sx={{ marginBottom: -1.5 }}
                  />
                );
              })}
            </Stack>
          )),
        )}
      </Stack>
      <Autocomplete
        id="cities"
        multiple
        filterSelectedOptions
        disableCloseOnSelect
        options={citiesOptions}
        getOptionLabel={(option) =>
          cities.find((city) => city.value === option)?.title || option
        }
        value={selectedCities}
        onChange={(e: SyntheticEvent, newValue: string[]) => {
          e.preventDefault();
          const nextValue = uniq([...selectedCountries, ...newValue]);
          onChange(nextValue.length ? nextValue : null);
        }}
        sx={{ maxWidth: '480px' }}
        renderInput={(params) => <TextField {...params} label="Cities" />}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => {
            const selectedCountry = getCountryByCity(option);
            return (
              <Chip
                variant="outlined"
                color="primary"
                label={
                  <>
                    {!!selectedCountry && (
                      <CountryFlag
                        country={selectedCountry}
                        key={selectedCountry}
                      />
                    )}
                    {option}
                  </>
                }
                {...getTagProps({ index })}
                sx={{ border: 0 }}
              />
            );
          })
        }
        onBlur={onBlur}
      />
    </Stack>
  );
};
