import React, { FC, ReactElement } from 'react';

import { Box, BoxProps, Stack, Typography } from '@mui/material';

interface IProps extends Omit<BoxProps, 'children'> {
  label: string;
  Component: ReactElement;
  EndComponent?: ReactElement;
  spaceBetween?: boolean;
  isEmpty?: boolean;
  iconLabelFront?: string;
}

export const RowLayout: FC<IProps> = ({
  label,
  Component,
  EndComponent,
  spaceBetween,
  isEmpty,
  iconLabelFront,
  ...rest
}) => (
  <Stack
    {...rest}
    sx={{
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      '&:last-child': {
        borderBottom: 'none',
      },
      gap: {
        xs: '0',
        sm: '0.5rem',
      },
      padding: '0.5rem 0.25rem 0.75rem',
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      ...(rest.sx ?? {}),
    }}
  >
    <Box
      sx={{
        width: {
          md: '7.5rem',
        },
        marginTop: { sm: '0.25rem' },
        display: 'flex',
        alignItems: 'flex-start',
        gap: '5px',
      }}
    >
      {iconLabelFront && <Typography>{iconLabelFront}</Typography>}
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
    </Box>
    <Stack
      flex={1}
      direction="row"
      sx={{
        overflowWrap: 'anywhere',
        alignItems: 'flex-start',
        marginTop: { sm: '0.25rem' },
      }}
      justifyContent={spaceBetween ? 'end' : undefined}
    >
      {isEmpty ? (
        <Typography variant="body1" color={'text.secondary'}>
          —
        </Typography>
      ) : (
        Component
      )}
    </Stack>
    <Stack>{EndComponent}</Stack>
  </Stack>
);
