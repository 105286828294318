import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useTitle } from '@hooks/useTitle';

import {
  EntityListLayout,
  JobOpeningsListFilters,
  EmptyJobOpeningsState,
  PositionRow,
} from '@components';

import {
  jobOpeningsSelectors,
  setJobOpeningOptionField,
} from '@redux/jobOpenings';
import { authSelectors } from '@redux/auth';

import {
  AppRoutes,
  JobOpeningFilterFields,
  JobOpeningStatusFilter,
  JobOpeningStatusFilterLabel,
  JobOpeningsGroupOptions,
  JobOpeningsGroupingOptions,
  JobOpeningsSortingOptions,
  tableHeadersJobOpenings,
} from '@constants';
import { checkUserCanOpenEditJobOpening, isStatusLoading } from '@utils';
import { IDWHJobOpening, IJobOpeningOptions, OrderDirection } from '@types';

export const JobOpeningsDashboardPage: React.FC<{
  status: JobOpeningStatusFilter;
}> = ({ status }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(JobOpeningStatusFilterLabel[status]);
  }, [setPageTitle, status]);

  const jobOpeningAPIStatus = useAppSelector(
    jobOpeningsSelectors.getJobOpeningsApiStatus,
  );
  const jobOpeningsData = useAppSelector(
    jobOpeningsSelectors.getJobOpeningsData,
  );
  const jobOpeningsOptions = useAppSelector(
    jobOpeningsSelectors.getJobOpeningsOptions,
  );
  const profile = useAppSelector(authSelectors.getProfileData);

  const defaultJobOpeningOptions: Record<string, any> = useMemo(
    () => ({
      groupBy: JobOpeningsGroupingOptions.ACCOUNT,
      sortBy: JobOpeningsSortingOptions.PRIORITY,
      order: OrderDirection.DESC,
      status,
    }),
    [],
  );
  const blockedJobOpeningOptions: Array<keyof IJobOpeningOptions> = useMemo(
    () => ['status'],
    [],
  );
  const onChangeJobOpenigOptions = useCallback(
    (value: Partial<Record<keyof IJobOpeningOptions, any[]>>) =>
      dispatch(setJobOpeningOptionField(value)),
    [],
  );

  const renderRowComponent = useCallback(
    (rowData: IDWHJobOpening) => (
      <PositionRow jobOpening={rowData} groupBy={jobOpeningsOptions.groupBy} />
    ),
    [jobOpeningsOptions.groupBy],
  );

  const onRowClick = useCallback(
    (id: string) => {
      navigate(`/${AppRoutes.JOB_OPENINGS}/${id}`, {
        state: { from: `${location.pathname}${location.search}` },
      });
    },
    [navigate, location],
  );

  const onCreateJobOpeningClick = useCallback(
    () => navigate(`/${AppRoutes.CREATE_JOB_OPENING}`),
    [],
  );

  return (
    <EntityListLayout<IJobOpeningOptions, IDWHJobOpening>
      title={`${jobOpeningsData?.length || ''} ${
        status === JobOpeningStatusFilter.OnHold ? 'On-Hold' : 'open'
      } positions`}
      apiStatus={jobOpeningAPIStatus}
      tableProps={{
        headers: tableHeadersJobOpenings,
        data: jobOpeningsData,
        renderEmptyState: ({ hasFilters, isLoading, resetFilters }) => (
          <EmptyJobOpeningsState
            status={status}
            hasFilters={hasFilters}
            isLoading={isLoading}
            resetFilters={resetFilters}
          />
        ),
        renderRowComponent,
        onRowClick,
      }}
      dataOptions={{
        value: jobOpeningsOptions,
        default: defaultJobOpeningOptions,
        blocked: blockedJobOpeningOptions,
        filter: JobOpeningFilterFields,
        onChange: onChangeJobOpenigOptions,
      }}
      searchProps={{
        searchDataOptionKey: 'search',
        placeholder: 'Name, ID, etc...',
      }}
      filterProps={{
        renderFilters: ({ optionList, onChange }) => (
          <JobOpeningsListFilters
            isLoading={isStatusLoading(jobOpeningAPIStatus)}
            value={optionList}
            handleChange={onChange}
          />
        ),
      }}
      groupProps={{
        groupDataOptionKey: 'groupBy',
        options: JobOpeningsGroupOptions,
      }}
      createEntityProps={{
        label: 'Open new position',
        onClick: onCreateJobOpeningClick,
        disabled: !checkUserCanOpenEditJobOpening(profile),
      }}
    />
  );
};
