import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { fetchJobOpenings, jobOpeningsSelectors } from '@redux/jobOpenings';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

export const useFetchJobOpenings = () => {
  const dispatch = useAppDispatch();
  const {
    groupBy,
    sortBy,
    order,
    status,
    search,
    priority,
    client,
    locations,
    techFlow,
    seniorities,
    clientRecruiters,
  } = useAppSelector(jobOpeningsSelectors.getJobOpeningsOptions);

  useEffect(() => {
    if (
      [
        groupBy,
        sortBy,
        order,
        status,
        search,
        priority,
        client,
        locations,
        techFlow,
        seniorities,
        clientRecruiters,
      ].every(isEmpty)
    )
      return;

    dispatch(
      fetchJobOpenings({
        groupBy,
        sortBy,
        order,
        status,
        search,
        priority,
        client,
        locations,
        techFlow,
        seniorities,
        clientRecruiters,
      }),
    );
  }, [
    groupBy,
    sortBy,
    order,
    status,
    search,
    priority,
    client,
    locations,
    techFlow,
    seniorities,
    clientRecruiters,
  ]);
};
