import React from 'react';

import { TableCell, TableHead, TableRow } from '@mui/material';

import { JobOpeningsSortingOptions } from '@constants';

const tableHeaders = [
  {
    label: 'Priority',
    value: JobOpeningsSortingOptions.PRIORITY,
    width: 92,
    align: 'left',
  },
  {
    label: 'Position',
    value: JobOpeningsSortingOptions.JOB_OPENING_NAME,
    width: 300,
    align: 'left',
    colSpan: 2,
  },
];

export const TableHeader: React.FC = () => (
  <TableHead>
    <TableRow>
      {tableHeaders.map((cell, idx) => {
        const width = cell.width;
        const align = cell.align;
        return (
          <TableCell
            key={idx}
            sx={{
              padding: '0.4rem 0 0 1rem',
              width: width,
              height: '2rem',
              verticalAlign: 'top',
              border: 'none',
              textAlign: align,
            }}
            colSpan={cell.colSpan ?? 1}
          >
            {cell.label}
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);
