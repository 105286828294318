import {
  ActionReportGroupingOptions,
  ActionReportSortingOptions,
  CandidateStage,
  CandidateState,
} from '@constants';
import { IDWHCandidate } from './candidates';

export interface IActionReport {
  actionTemplate: string;
  severity: ActionReportSeverity;
  lastNotificationDate?: string | null;
}

export enum ActionReportSeverity {
  None = 'None',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IActionReportOptions {
  sortBy: ActionReportSortingOptions | null;
  order: OrderDirection | null;
  groupBy: ActionReportGroupingOptions | null;
  search: string | null;
  stage: CandidateStage | null;
  state: CandidateState | null;
  country: string[] | null;
  seniority: string[] | null;
  payRate: number[] | null;
  recruiter: string[] | null;
  addedBy: string[] | null;
  techFlow: string[] | null;
}

export interface IActionReportList {
  data: IDWHCandidate[];
  totalCount: number;
}
