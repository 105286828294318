import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useNavigate } from 'react-router-dom';

import {
  AcrionReporCandidateRow,
  ActionReportListFilters,
  EmptyActionReportState,
  EntityListLayout,
} from '@components';

import {
  actionReportSelectors,
  setActionReportOptionField,
} from '@redux/action-report';
import { authSelectors } from '@redux/auth';

import { getNameFromEmail, isStatusComplete, isStatusLoading } from '@utils';
import { IActionReportOptions, IDWHCandidate, OrderDirection } from '@types';
import {
  ActionReportGroupingOptions,
  ActionReportGroupOptions,
  ActionReportFilterFields,
  ActionReportSortingOptions,
  actionReportTableHeaders,
  AppRoutes,
  CandidateDetailsTabs,
} from '@constants';
import { useTitle } from '@hooks/useTitle';
import { PageTitle } from 'constants/pageTitle';

export const ActionReportDashboardPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const actionReportLength = useAppSelector(
    actionReportSelectors.getActionReportTotal,
  );
  const actionReportOptions = useAppSelector(
    actionReportSelectors.getActionReportOptions,
  );
  const actionReportApiStatus = useAppSelector(
    actionReportSelectors.getActionReportApiStatus,
  );
  const data = useAppSelector(actionReportSelectors.getActionReportData);
  const profile = useAppSelector(authSelectors.getProfileData);

  const renderRowComponent = useCallback(
    (rowData: IDWHCandidate) => <AcrionReporCandidateRow candidate={rowData} />,
    [],
  );

  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.ACTION_REPORT);
  }, [setPageTitle]);

  const onRowClick = useCallback((id: string) => {
    navigate(
      `/${AppRoutes.CANDIDATES}/${id}/${CandidateDetailsTabs.POSITIONS}`,
    );
  }, []);

  const fullName = getNameFromEmail(profile?.email);
  const defaultActionReportOptions: Record<string, any> = useMemo(
    () => ({
      sortBy: ActionReportSortingOptions.NAME,
      order: OrderDirection.ASC,
      groupBy: ActionReportGroupingOptions.NONE,
      recruiter: `${fullName?.firstName} ${fullName?.lastName}`,
    }),
    [],
  );

  const onChangeActionReportOptions = useCallback(
    (value: Partial<Record<keyof IActionReportOptions, any[]>>) =>
      dispatch(setActionReportOptionField(value)),
    [],
  );

  return (
    <EntityListLayout<IActionReportOptions, IDWHCandidate>
      title={
        isStatusComplete(actionReportApiStatus) && !actionReportLength
          ? 'No candidates in active stages'
          : `${actionReportLength} candidates`
      }
      apiStatus={actionReportApiStatus}
      tableProps={{
        headers:
          actionReportTableHeaders[
            actionReportOptions.groupBy || ActionReportGroupingOptions.NONE
          ],
        data,
        renderEmptyState: ({ hasFilters, resetFilters }) => (
          <EmptyActionReportState
            hasFilters={hasFilters}
            resetFilters={resetFilters}
          />
        ),
        renderRowComponent,
        onRowClick,
      }}
      dataOptions={{
        value: actionReportOptions,
        default: defaultActionReportOptions,
        filter: ActionReportFilterFields,
        onChange: onChangeActionReportOptions,
      }}
      searchProps={{
        searchDataOptionKey: 'search',
        placeholder: 'Name, email, ID, etc...',
      }}
      filterProps={{
        renderFilters: ({ optionList, onChange }) => (
          <ActionReportListFilters
            isLoading={isStatusLoading(actionReportApiStatus)}
            value={optionList}
            handleChange={onChange}
          />
        ),
      }}
      groupProps={{
        groupDataOptionKey: 'groupBy',
        options: ActionReportGroupOptions,
      }}
    />
  );
};
