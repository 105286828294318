import { Fragment, useMemo } from 'react';
import { groupBy, mapValues, values } from 'lodash';

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { getPriorityIcon } from '@utils';
import { IDWHJobOpening } from '@types';
import { JobOpeningReasonOfClosing, JobOpeningStatus } from '@constants';

const tableHeaders = [
  {
    label: 'Priority',
    value: 'Priority',
    width: 92,
    align: 'left',
  },
  {
    label: 'Position',
    value: 'Job_Opening_Name',
    width: 300,
    align: 'left',
    colSpan: 1,
  },
  {
    label: 'Technical Flow',
    value: 'Technical_Flow',
    width: 100,
    align: 'left',
    colSpan: 2,
  },
];

export const JobOpeningsTable: React.FC<{
  jobOpenings: IDWHJobOpening[];
  onAssociate: (jobOpeningId: string) => void;
  isLoading: boolean;
}> = ({ jobOpenings, onAssociate, isLoading }) => {
  const groupedData = useMemo<Record<string, IDWHJobOpening[]>>(
    () => groupBy(jobOpenings, 'Client_Name'),
    [jobOpenings],
  );

  return (
    <TableContainer>
      <Table stickyHeader sx={{ tableLayout: 'auto', width: '100%' }}>
        <TableHead>
          <TableRow>
            {tableHeaders.map((cell, idx) => {
              const width = cell.width;
              const align = cell.align;
              return (
                <TableCell
                  key={idx}
                  sx={{
                    padding: '0.4rem 0 0 1rem',
                    width: width,
                    height: '2rem',
                    verticalAlign: 'top',
                    border: 'none',
                    textAlign: align,
                  }}
                  colSpan={cell.colSpan ?? 1}
                >
                  {cell.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {values(
            mapValues(groupedData, (groupedJobOpenings, groupTitle) => (
              <Fragment key={groupTitle}>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.highlight.neutral,
                      padding: '4px 1rem',
                      position: 'sticky',
                      top: '2rem',
                      border: 'none',
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {groupTitle}
                    </Typography>
                  </TableCell>
                </TableRow>
                {groupedJobOpenings.map((position) => {
                  const isOnHold =
                    position.Job_Opening_Status === JobOpeningStatus.Closed &&
                    position.Reason_of_Closing ===
                      JobOpeningReasonOfClosing.OnHold;
                  const isClosed =
                    position.Job_Opening_Status === JobOpeningStatus.Closed &&
                    position.Reason_of_Closing !==
                      JobOpeningReasonOfClosing.OnHold;

                  const Icon = getPriorityIcon(
                    position.Priority,
                    isOnHold,
                    isClosed,
                  );

                  return (
                    <TableRow key={position.id}>
                      <TableCell style={{ verticalAlign: 'top' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {Icon && (
                            <Tooltip
                              title={
                                isOnHold
                                  ? 'On-Hold'
                                  : isClosed
                                  ? 'Closed'
                                  : position.Priority
                              }
                              placement="top"
                            >
                              <Icon width="18" sx={{ width: 18 }} />
                            </Tooltip>
                          )}
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            marginLeft={'0.25rem'}
                          >
                            {position.Job_Opening_Id}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'top' }}>
                        <Typography>{position.Job_Opening_Name}</Typography>
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'top' }}>
                        <Typography>{position.TechnicalFlow}</Typography>
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'top' }} align="right">
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            height: '2.5rem',
                            fontWeight: '400',
                            backgroundColor: 'text.link',
                            textTransform: 'none',
                            whiteSpace: 'nowrap',
                          }}
                          disabled={isLoading}
                          onClick={() => onAssociate(position.id)}
                        >
                          Associate
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Fragment>
            )),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
