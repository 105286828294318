import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { AutocompleteTextfield } from '@components';

import {
  fetchJobOpeningSpecializations,
  optionsSelectors,
} from '@redux/options';

import { SpecializationType } from '@constants';

import { isStatusIdle } from '@utils';

export const PositionSpecializationField: React.FC<{
  value: string | null;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({ value, name, error, touched, required, onChange, onBlur }) => {
  const dispatch = useAppDispatch();

  const { data: specializations, apiStatus } = useAppSelector(
    optionsSelectors.getJobOpeningSpecializations,
  );

  const [specializationType, setSpecializationType] =
    useState<SpecializationType>(SpecializationType.TECH);

  const specializationOptions = useMemo(() => {
    return specializations
      .filter((e) => e.type === specializationType)
      .map((e) => e.value);
  }, [specializationType, specializations]);

  useEffect(() => {
    if (isStatusIdle(apiStatus)) dispatch(fetchJobOpeningSpecializations());
  }, [apiStatus]);

  useEffect(() => {
    const selectedSpecialization = specializations.find(
      (specialization) => value === specialization.value,
    );

    if (selectedSpecialization) {
      setSpecializationType(selectedSpecialization.type);
    }
  }, [specializations]);

  return (
    <Stack>
      <RadioGroup
        value={specializationType}
        onChange={(e: SelectChangeEvent<string>) => {
          onChange(null);
          setSpecializationType(e.target.value as SpecializationType);
        }}
        name="specializationType"
        row
        sx={{
          marginBottom: '16px',
        }}
      >
        <FormControlLabel
          value={SpecializationType.TECH}
          control={<Radio disableRipple />}
          label="Engineering"
        />
        <FormControlLabel
          value={SpecializationType.NON_TECH}
          control={<Radio disableRipple />}
          label="Non-engineering"
        />
      </RadioGroup>
      <AutocompleteTextfield
        name={name || 'Specialization'}
        label={'Specialization'}
        options={specializationOptions}
        value={value}
        required={required}
        onChange={(_, val) => onChange(val)}
        onBlur={onBlur}
        fullWidth
        filterSelectedOptions
        error={touched && !!error}
        helperText={touched ? error : undefined}
      />
    </Stack>
  );
};
