import React from 'react';
import { useNavigate } from 'react-router';

import { Typography, Button, Paper, Stack } from '@mui/material';

import { AppRoutes } from '@constants';
import { JobOpeningFormik } from '@types';

export const Footer: React.FC<{
  formik: JobOpeningFormik;
  isFormSubmitting: boolean;
}> = ({ formik, isFormSubmitting }) => {
  const navigate = useNavigate();

  return (
    <Paper elevation={1} sx={{ mb: '1px', pt: 4, pb: 6, paddingX: 5 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button
          variant="contained"
          onClick={() => formik.handleSubmit()}
          sx={{
            height: '40px',
            backgroundColor: 'text.link',
            textTransform: 'none',
            display: 'flex',
            width: '80px',
          }}
          disabled={!formik.dirty || isFormSubmitting}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            navigate(`/${AppRoutes.JOB_OPENINGS}`);
          }}
          sx={{
            height: '40px',
            backgroundColor: '#3F8CFF14',
            color: '#42A5F5',
            textTransform: 'none',
            display: 'flex',
            width: '80px',
          }}
        >
          Back
        </Button>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          ✻ fill-in required fields
        </Typography>
      </Stack>
    </Paper>
  );
};
