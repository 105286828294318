import { useState } from 'react';
import { getIn } from 'formik';
import { isNumber } from 'lodash';

import { RowLayout, PersonSignature, ReviewerSelectField } from '@components';
import { Box, Button, Link, TextField, Typography } from '@mui/material';

import { SubmissionFormik } from '@types';
import { TestTaskStatus, apiEndpoints } from '@constants';

export const TestTaskData: React.FC<{
  formik: SubmissionFormik;
}> = ({ formik }) => {
  const [isEditableMode, setIsEditableMode] = useState(false);
  const testTask = formik.values.testTask!;

  return (
    <Box
      sx={{
        background: '#F6F8FC',
        width: '100%',
        borderRadius: '8px',
        padding: '0 4px 16px 4px',
      }}
    >
      <RowLayout
        label="Status"
        Component={
          <>
            <Typography
              variant="body1"
              flex={1}
              color={
                testTask.status === TestTaskStatus.PASSED
                  ? 'text.success'
                  : testTask.status === TestTaskStatus.FAILED
                  ? 'text.danger'
                  : 'text.primary'
              }
            >
              {testTask.status}
            </Typography>
          </>
        }
      />
      <RowLayout
        label="Score"
        isEmpty={!isNumber(testTask.score)}
        Component={<Typography variant="body1">{testTask.score}%</Typography>}
      />
      <RowLayout
        label="Summary File"
        isEmpty={!testTask?.summaryFileName}
        Component={
          <>
            {testTask?.summaryFileName ? (
              <Link
                href={apiEndpoints.testTaskFileUrl(testTask.summaryFileName)}
                component={'a'}
                color="text.link"
                sx={{ cursor: 'pointer' }}
                fontFamily="inherit"
                target="_blank"
              >
                {testTask.summaryFileName}
              </Link>
            ) : (
              ''
            )}
          </>
        }
      />
      {isEditableMode ? (
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            label="Feedback"
            variant="outlined"
            fullWidth
            value={testTask.feedback || ''}
            onChange={(e) =>
              formik.setFieldValue('testTask.feedback', e.target.value || null)
            }
            multiline
            minRows={3}
            sx={{ background: '#FFF' }}
            onBlur={() => formik.setFieldTouched('testTask.feedback')}
            helperText={
              !!getIn(formik.touched, 'testTask.feedback') ||
              !!formik.submitCount
                ? getIn(formik.errors, 'testTask.feedback')
                : undefined
            }
            error={
              (!!getIn(formik.touched, 'testTask.feedback') ||
                !!formik.submitCount) &&
              !!getIn(formik.errors, 'testTask.feedback')
            }
          />
          <ReviewerSelectField
            value={testTask.reviewer?.email || ''}
            onChange={(person) =>
              formik.setFieldValue('testTask.reviewer', person)
            }
          />
        </Box>
      ) : (
        <RowLayout
          label="Feedback"
          Component={
            <Box display="flex" flexDirection="column" gap={1}>
              {testTask.feedback ? (
                <Typography whiteSpace="pre-line">
                  {testTask.feedback}
                </Typography>
              ) : (
                <Typography variant="body1" color={'text.secondary'}>
                  —
                </Typography>
              )}
              {!!testTask.reviewer && (
                <PersonSignature person={testTask.reviewer} />
              )}
              <Button
                variant="contained"
                onClick={() => setIsEditableMode(true)}
                sx={{
                  backgroundColor: '#3F8CFF14',
                  color: '#42A5F5',
                  textTransform: 'none',
                  display: 'flex',
                }}
              >
                Edit TT feedback
              </Button>
            </Box>
          }
        />
      )}
    </Box>
  );
};
