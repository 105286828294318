import { Alert, Box } from '@mui/material';
import React from 'react';
export const LoadingSnackbar: React.FC = () => {
  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'flex-start'}>
      <Alert icon={false} sx={{ backgroundColor: 'black', color: 'white' }}>
        Loading •••
      </Alert>
    </Box>
  );
};
