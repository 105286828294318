import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GroupOption } from '@types';
import { useCallback } from 'react';

type GroupFilterProps = {
  options: GroupOption[];
  selectedValue: string | null;
  onSelect: (value: string | null) => void;
};

const getLabelByValue = (options: GroupOption[], value: string) =>
  options.find((option) => option.value === value)?.label || '';

export const GroupFilter = ({
  options,
  selectedValue,
  onSelect,
}: GroupFilterProps) => {
  const onSelectGrouping = useCallback(
    (e: SelectChangeEvent<string>) => {
      onSelect(e.target.value);
    },
    [onSelect],
  );

  return (
    <Select
      id="group-by-filter"
      value={selectedValue || ''}
      onChange={onSelectGrouping}
      sx={{
        background: '#E6F2FC',
        padding: '0.25rem 0.5rem',
        borderRadius: '0.25rem',
        height: '2rem',
        '.MuiSelect-select': {
          paddingY: 0,
          paddingLeft: 0,
          ':focus': {
            background: '#E6F2FC',
          },
        },
      }}
      disableUnderline={true}
      variant="standard"
      size="small"
      renderValue={(value) => `Group by: ${getLabelByValue(options, value)}`}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
