import { AutocompleteTextfield } from '@components';

export const QuantitiesOptions = ['1', '2', '3', '4', '5'];

export const PositionQuantityField: React.FC<{
  value: string | null;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({ value, name, error, touched, required, onChange, onBlur }) => {
  return (
    <AutocompleteTextfield
      name={name || 'Number_of_Positions'}
      label={'Quantity'}
      options={QuantitiesOptions}
      value={value}
      required={required}
      onChange={(_, val) => onChange(val)}
      onBlur={onBlur}
      fullWidth
      filterSelectedOptions
      error={touched && !!error}
      helperText={touched ? error : undefined}
    />
  );
};
