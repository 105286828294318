import { CustomButton } from '@components';

export const EditCandidateProfileButton: React.FC<{
  isDisabled: boolean;
  onClick: () => void;
}> = ({ isDisabled, onClick }) => (
  <CustomButton
    isDisabled={isDisabled}
    size="small"
    color="secondary"
    label={'Edit Profile'}
    onClick={onClick}
  />
);
