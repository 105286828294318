import { CountriesLocationAreas, mapZohoTimezoneToUtc } from '@constants';
import { IZohoCandidate } from '@types';

export const getTimezoneData = (candidate: IZohoCandidate | null) => {
  const candidateTimezone = candidate?.Time_zone?.replace(/[/]/g, '');
  const utcTimezone = mapZohoTimezoneToUtc[candidateTimezone ?? ''];
  if (utcTimezone) {
    return utcTimezone;
  }

  const country = CountriesLocationAreas.find((e) =>
    candidate?.Location_Country
      ? e.Location === 'Other' ||
        new RegExp(candidate?.Location_Country, 'gi').test(e.Location)
      : e.Location === 'Other',
  );

  return country?.Time_zone_offset || '';
};

export const convertOffsetToHours = (offset: string) => {
  const sign = offset.startsWith('-') ? -1 : 1;
  const [hours, minutes] = offset.slice(1).split(':').map(Number);
  return sign * (hours + minutes / 60);
};
