import React, { useCallback } from 'react';
import { useAppSelector } from '@redux/hooks';
import { format } from 'date-fns';

import { Box, Link, Stack, Typography } from '@mui/material';

import { Quiz, RowLayout, CustomButton } from '@components';
import { CodilityTestTask } from './CodilityTestTask';
import { InternalTestTaskReview } from './InternalTestTask/InternalTestTaskReview';
import { InternalTestTask as InternalTestTaskComponent } from './InternalTestTask/InternalTestTask';
import { InternalTestTaskPreview } from './InternalTestTask/InternalTestTaskPreview';

import { authSelectors } from '@redux/auth';
import { candidateDetailsSelectors } from '@redux/candidateDetails';

import {
  checkUserCanEditTestTask,
  checkUserCanSendTestTask,
  isTestTaskDone,
  isTestTaskFailed,
  isTestTaskPassed,
} from '@utils';
import {
  CandidateTestTask,
  InternalTestTask,
  TestTaskReviewPayload,
} from '@types';
import { TestTaskType, apiEndpoints } from '@constants';

export const TestTaskDetails: React.FC<{
  selectedTTFromCandidateTT: CandidateTestTask | undefined;
  selectedTTFromAvailableList: InternalTestTask | undefined;
  handleSendTestTask?: () => void | null;
  handleEditTestTask: () => void | null;
  handleReviewTT: (
    payload: {
      userTestTaskId: string;
    } & TestTaskReviewPayload,
  ) => void;
}> = ({
  selectedTTFromCandidateTT,
  selectedTTFromAvailableList,
  handleSendTestTask,
  handleEditTestTask,
  handleReviewTT,
}) => {
  const profile = useAppSelector(authSelectors.getProfileData);
  const isPerformingTestTaskAction = useAppSelector(
    candidateDetailsSelectors.getCandidateTestTaskActionPerforming,
  );

  const getStatusColor = useCallback((tt: CandidateTestTask | undefined) => {
    if (tt && isTestTaskPassed(tt.status)) {
      return 'text.success';
    } else if (tt && isTestTaskFailed(tt.status)) {
      return 'text.danger';
    } else if (tt && isTestTaskDone(tt.status)) {
      return 'text.primary';
    } else {
      return 'text.primary';
    }
  }, []);

  const testTaskStatus =
    (selectedTTFromCandidateTT?.status || []).at(-1) || ' Not sent';
  const isAbleToSendTT = checkUserCanSendTestTask(profile);
  const isAbleToEditTT = checkUserCanEditTestTask(profile);

  return (
    <React.Fragment>
      <Stack>
        <RowLayout
          label="Technology"
          isEmpty={!selectedTTFromCandidateTT?.stack}
          Component={
            <Typography variant="body1">
              {selectedTTFromCandidateTT?.stack}
            </Typography>
          }
        />
        <RowLayout
          label="Status"
          Component={
            <>
              <Typography
                variant="body1"
                flex={1}
                color={getStatusColor(selectedTTFromCandidateTT)}
              >
                {testTaskStatus}
              </Typography>
              {selectedTTFromAvailableList && isAbleToSendTT && (
                <CustomButton
                  size="small"
                  color="secondary"
                  label={'Send TT'}
                  onClick={handleSendTestTask}
                  isDisabled={isPerformingTestTaskAction}
                  isLoading={isPerformingTestTaskAction}
                />
              )}
            </>
          }
        />
        <RowLayout
          label="Score"
          isEmpty={!selectedTTFromCandidateTT?.score}
          Component={
            <Typography variant="body1">
              {selectedTTFromCandidateTT?.score}
            </Typography>
          }
        />
        <RowLayout
          label="Link"
          isEmpty={!selectedTTFromCandidateTT}
          Component={
            <>
              {selectedTTFromCandidateTT ? (
                <Link
                  href={selectedTTFromCandidateTT.link ?? undefined}
                  target="_blank"
                  variant="body1"
                  color="text.link"
                >
                  {selectedTTFromCandidateTT.link}
                </Link>
              ) : (
                ''
              )}
            </>
          }
        />
        <RowLayout
          label="Sent by"
          isEmpty={
            !(
              selectedTTFromCandidateTT?.sentBy ||
              selectedTTFromCandidateTT?.origin
            )
          }
          Component={
            <Typography variant="body1">
              {selectedTTFromCandidateTT?.sentBy ||
                selectedTTFromCandidateTT?.origin}
            </Typography>
          }
        />
        <RowLayout
          label="Sent date"
          isEmpty={!selectedTTFromCandidateTT?.sentDate}
          Component={
            <Typography variant="body1">
              {selectedTTFromCandidateTT
                ? format(
                    new Date(selectedTTFromCandidateTT.sentDate),
                    'yyyy-MM-dd',
                  )
                : '—'}
            </Typography>
          }
        />
        <RowLayout
          label="Result date"
          isEmpty={!selectedTTFromCandidateTT?.resultDate}
          Component={
            <Typography variant="body1">
              {selectedTTFromCandidateTT?.resultDate
                ? format(
                    new Date(selectedTTFromCandidateTT.resultDate),
                    'yyyy-MM-dd',
                  )
                : '—'}
            </Typography>
          }
        />
        <RowLayout
          label="Resolution Files"
          isEmpty={!selectedTTFromCandidateTT?.resolutionFileNames?.length}
          Component={
            <>
              {selectedTTFromCandidateTT?.resolutionFileNames?.length ? (
                <Box display="flex" flexDirection="column">
                  {selectedTTFromCandidateTT.resolutionFileNames.map(
                    (fileName) => (
                      <Link
                        key={fileName}
                        href={apiEndpoints.testTaskFileUrl(fileName)}
                        component={'a'}
                        color="text.link"
                        sx={{ cursor: 'pointer' }}
                        fontFamily="inherit"
                        target="_blank"
                        variant="body1"
                      >
                        {fileName}
                      </Link>
                    ),
                  )}
                </Box>
              ) : (
                ''
              )}
            </>
          }
        />
        <RowLayout
          label="Summary File"
          isEmpty={!selectedTTFromCandidateTT?.summaryFileName}
          Component={
            <>
              {selectedTTFromCandidateTT?.summaryFileName ? (
                <Link
                  href={apiEndpoints.testTaskFileUrl(
                    selectedTTFromCandidateTT.summaryFileName,
                  )}
                  component={'a'}
                  color="text.link"
                  sx={{ cursor: 'pointer' }}
                  fontFamily="inherit"
                  target="_blank"
                >
                  {selectedTTFromCandidateTT.summaryFileName}
                </Link>
              ) : (
                ''
              )}
            </>
          }
        />
        <RowLayout
          label="Feedback"
          isEmpty={!selectedTTFromCandidateTT?.reviewerFeedback}
          Component={
            <Typography variant="body1">
              {selectedTTFromCandidateTT?.reviewerFeedback}
            </Typography>
          }
        />
      </Stack>
      {!selectedTTFromAvailableList && (
        <Box my={'0.5rem'}>
          <CustomButton
            size="small"
            color="secondary"
            label={'Edit'}
            onClick={handleEditTestTask}
            isDisabled={!isAbleToEditTT}
          />
        </Box>
      )}
      <Stack direction={'column'} gap={'1rem'}>
        {!!selectedTTFromAvailableList && (
          <Box
            bgcolor="background.backgroundPrimary"
            padding="1rem"
            borderRadius="0.5rem"
          >
            <InternalTestTaskPreview data={selectedTTFromAvailableList} />
          </Box>
        )}
        {!!selectedTTFromCandidateTT &&
          (() => {
            if (
              selectedTTFromCandidateTT.testTaskType === TestTaskType.CODILITY
            )
              return (
                <Box
                  bgcolor="background.backgroundPrimary"
                  padding="1rem"
                  borderRadius="0.5rem"
                >
                  <CodilityTestTask
                    key={selectedTTFromCandidateTT.id}
                    data={selectedTTFromCandidateTT}
                  />
                </Box>
              );

            if (
              selectedTTFromCandidateTT.testTaskType === TestTaskType.QUIZ &&
              !selectedTTFromCandidateTT.preventDisqualification
            )
              return (
                <Box
                  bgcolor="background.backgroundPrimary"
                  padding="1rem"
                  borderRadius="0.5rem"
                >
                  <Quiz
                    key={selectedTTFromCandidateTT.id}
                    name={
                      selectedTTFromCandidateTT.quizTestTask?.specialization ||
                      ''
                    }
                    questions={
                      selectedTTFromCandidateTT.quizTestTask?.quiz || {}
                    }
                    answers={selectedTTFromCandidateTT.quizAnswers}
                    score={selectedTTFromCandidateTT.score}
                    isPassed={isTestTaskPassed(
                      selectedTTFromCandidateTT.status,
                    )}
                    isDone={isTestTaskDone(selectedTTFromCandidateTT.status)}
                  />
                </Box>
              );

            if (
              selectedTTFromCandidateTT.testTaskType === TestTaskType.INTERNAL
            )
              return (
                <>
                  <Box
                    padding="1rem"
                    bgcolor="background.backgroundPrimary"
                    borderRadius="0.5rem"
                  >
                    <InternalTestTaskComponent
                      key={selectedTTFromCandidateTT.id}
                      data={selectedTTFromCandidateTT}
                    />
                  </Box>
                  <Box width="100%">
                    <InternalTestTaskReview
                      data={selectedTTFromCandidateTT}
                      isLoading={isPerformingTestTaskAction}
                      onReviewClick={handleReviewTT}
                    />
                  </Box>
                </>
              );
            return null;
          })()}
      </Stack>
    </React.Fragment>
  );
};
