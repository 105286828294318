import {
  CandidateStage,
  CandidateStatus,
  CandidateUpcomingEvents,
} from '@constants';
import { ICandidateByStage, IDWHCandidate, IDWHCandidateRaw } from '@types';
import { startCase } from 'lodash';
import { LocalStorageService } from 'services/localStorageService';
const parseField = (field: string | null) =>
  !field
    ? []
    : field.startsWith('[') && field.endsWith(']')
    ? JSON.parse(field)
    : field
        .replace(/[{}]/g, '')
        .split(/[,;]+/)
        .map((e: string) => e.trim());

export const transformCandidate = (data: IDWHCandidateRaw): IDWHCandidate => {
  const parsedWasInterestedMonth = parseField(data.Was_interested_month);
  const parsedTestTaskStatus = parseField(data.Test_Task);
  const parsedPrimarySkills = parseField(data.Primary_Skill_Set);
  const parsedSecondarySkills = parseField(data.Secondary_Skill_Set);
  const parsedTestTaskReviewers = parseField(data.Test_Task_Reviewers);

  return {
    ...data,
    Was_interested_month: parsedWasInterestedMonth,
    Skill_Set: (data.Skill_Set || '')
      .split(/[,;]+/)
      .map((e: string) => e.trim())
      .filter(Boolean),
    Test_Task: parsedTestTaskStatus.filter(Boolean),
    Primary_Skill_Set: parsedPrimarySkills,
    Secondary_Skill_Set: parsedSecondarySkills,
    Test_Task_Reviewers: parsedTestTaskReviewers,
  };
};

export const getCandidateName = ({
  fullName,
  firstName,
  lastName,
  englishFirstName,
  englishLastName,
}: {
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  englishFirstName?: string | null;
  englishLastName?: string | null;
}): string => {
  if (
    !(fullName || firstName || lastName || englishFirstName || englishLastName)
  )
    return '';

  const fullNameToUse = fullName?.trim();
  if (fullNameToUse) return fullNameToUse;

  const firstNameToUse = firstName?.trim() || englishFirstName?.trim();
  const lastNameToUse = lastName?.trim() || englishLastName?.trim();

  if (firstNameToUse || lastNameToUse)
    return `${firstNameToUse} ${lastNameToUse}`;

  return '';
};

export const getNameFromEmail = (
  email?: string | null,
): { firstName: string; lastName: string } | null => {
  if (!email) return null;

  const emailPrefix = email.split('@')[0];

  const nameParts = emailPrefix.split('.');

  const firstName = nameParts[0];
  const lastName = nameParts[nameParts.length - 1];

  return {
    firstName: startCase(firstName),
    lastName: startCase(lastName),
  };
};

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const transormCandidateStatus = (
  status: string | null | undefined,
): string => (!status ? '' : status.replace(/LP: /g, ''));

export const formatEmailMessage = (rawMessage: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  let formattedMessage = rawMessage.replace(urlRegex, (url: string) => {
    if (url.includes('calendly.com/')) {
      LocalStorageService.setState('user_calendly_link', url);
    }
    return `<a style="color: #3F8CFF; text-decoration: underline;" href="${url}" target="_blank">${url}</a>`;
  });
  formattedMessage = formattedMessage.replace(/\n/g, '<br/>');
  return formattedMessage;
};

const getStageDate = (
  candidate: IDWHCandidate,
  stage: CandidateStage | CandidateUpcomingEvents,
): string | null => {
  const map: Record<CandidateStage | CandidateUpcomingEvents, string | null> = {
    [CandidateStage.APPLIED]:
      candidate.Date_Of_Last_Apply || candidate.Interested_Info_Date || null,
    [CandidateStage.TT_PASSED]: candidate.Test_Task_Date || null,
    [CandidateStage.IC_SCHEDULED]: candidate.Date_of_Prescreen_Planned || null,
    [CandidateStage.IC_DONE]: candidate.Date_Of_Prescreen || null,
    [CandidateStage.IC_PASSED]: candidate.Date_Of_Prescreen || null,
    [CandidateStage.TI_PASSED]:
      candidate.associationStatuses?.find((sc) =>
        [
          CandidateStatus.TI_Passed,
          CandidateStatus.TI_Passed_Lower_Seniority,
        ].includes(sc.status),
      )?.date ||
      candidate.Date_Of_Tech_Interview_Planned ||
      null,
    [CandidateStage.SUBMITTED]:
      candidate.associationStatuses?.find((sc) =>
        [CandidateStatus.Submitted].includes(sc.status),
      )?.date ||
      candidate.Date_Of_Submission ||
      null,
    [CandidateStage.NEW_HOME]: candidate.New_Home_Date_Start || null,
    [CandidateStage.IC_WAITLIST]:
      candidate.associationStatuses?.find((sc) =>
        [CandidateStatus.IC_Waitlist].includes(sc.status),
      )?.date || null,
    [CandidateUpcomingEvents.IC]: candidate.Date_of_Prescreen_Planned || null,
    [CandidateUpcomingEvents.TI]:
      candidate.Date_Of_Tech_Interview_Planned || null,
  };

  return map[stage];
};

export const transformDWHCandidateToCandidatesByStage = (
  candidate: IDWHCandidate,
  stage: CandidateStage | CandidateUpcomingEvents,
): ICandidateByStage => {
  return {
    id: candidate.id,
    fullName: candidate.Full_Name,
    firstName: candidate.First_Name,
    lastName: candidate.Last_Name,
    englishFirstName: candidate.English_First_Name,
    englishLastName: candidate.English_Last_Name,
    seniority: candidate.Seniority_Level,
    technicalFlow: candidate.Technical_Flow,
    country: candidate.Location_Country,
    date: getStageDate(candidate, stage),
    status: candidate.associationStatuses?.at(-1)?.status || null,
    score: candidate.score,
    candidateStatusUpdatedAt:
      candidate.associationStatuses?.at(-1)?.date || null,
  };
};

export const getDateColumnTitleBasedOnStage = (
  stage: CandidateStage | CandidateUpcomingEvents,
): string => {
  const map: Record<CandidateStage | CandidateUpcomingEvents, string> = {
    [CandidateStage.APPLIED]: 'Date of apply',
    [CandidateStage.TT_PASSED]: 'Date of TT passed',
    [CandidateStage.IC_SCHEDULED]: 'Date of IC scheduled',
    [CandidateStage.IC_DONE]: 'Date of IC done',
    [CandidateStage.IC_PASSED]: 'Date of IC passed',
    [CandidateStage.TI_PASSED]: 'Date of TI passed',
    [CandidateStage.SUBMITTED]: 'Date of submission',
    [CandidateStage.NEW_HOME]: 'New Home start date',
    [CandidateStage.IC_WAITLIST]: 'Date of IC waitlist',
    [CandidateUpcomingEvents.IC]: 'Date of upcoming IC',
    [CandidateUpcomingEvents.TI]: 'Date of upcoming TI',
  };

  return map[stage];
};
