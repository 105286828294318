import { useNavigate } from 'react-router-dom';

import { Flex } from '@components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import {
  AppRoutes,
  CandidateDetailsTabs,
  CandidateStatusStage,
} from '@constants';

export const NextStepsAfterReview: React.FC<{
  candidateId: string;
  isOpen: boolean;
}> = ({ candidateId, isOpen }) => {
  const navigate = useNavigate();

  const onYesClick = () =>
    navigate(
      `/${AppRoutes.CANDIDATES}/${candidateId}/${CandidateDetailsTabs.POSITIONS}`,
    );
  const onNoClick = () =>
    navigate(
      `/${AppRoutes.CANDIDATES}/${candidateId}/${CandidateDetailsTabs.PROFILE}`,
      { state: { nextCandidateStage: CandidateStatusStage.IC } },
    );

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h1">Next Steps</Typography>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Do you want to associate candidate with Job Opening?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={onNoClick}>
            No
          </Button>
          <Button variant="contained" onClick={onYesClick}>
            Yes
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
