import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { TestTaskStatus } from '@constants';
import { CandidateTestTask, SubmissionFormik } from '@types';

export const TestTasksSelector: React.FC<{
  testTasks: CandidateTestTask[];
  formik: SubmissionFormik;
  disabled?: boolean;
}> = ({ testTasks, formik, disabled }) => {
  const testTaskOptions = testTasks
    .filter((tt) =>
      tt.status.some((status) =>
        [TestTaskStatus.PASSED, TestTaskStatus.FAILED].includes(status),
      ),
    )
    .map((tt) => tt.stack!);

  return (
    <FormControl fullWidth>
      <InputLabel id="selectTestTask">Select test task</InputLabel>
      <Select
        labelId="selectTestTask"
        value={formik.values.testTask?.technology || ''}
        disabled={disabled}
        label="Select test task"
        onChange={(e: SelectChangeEvent<string>) => {
          const selectedTestTask = testTasks.find(
            (tt) => tt.stack === e.target.value,
          );

          if (selectedTestTask) {
            formik.setValues({
              ...formik.values,
              testTask: {
                status: selectedTestTask.status.includes(TestTaskStatus.PASSED)
                  ? TestTaskStatus.PASSED
                  : TestTaskStatus.FAILED,
                technology: selectedTestTask.stack!,
                score: selectedTestTask.score!,
                summaryFileName: selectedTestTask.summaryFileName!,
                feedback: selectedTestTask.reviewerFeedback!,
                reviewer: null,
              },
            });
          }
        }}
      >
        {testTaskOptions.map((item) => (
          <MenuItem value={item} key={item}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography>{item}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
