import { differenceInYears } from 'date-fns';
import {
  CandidateLPInvitationAPIPayload,
  CandidateLPInvitationFormFields,
  ILPSkill,
  ILPSpecializations,
  IZohoCandidate,
} from '@types';
import { LPSkillType } from '@constants';
import { sortBy } from 'lodash';

const generateLPInvitationEmailSubject = () =>
  'Proceed with your AgileEngine job application';

export const generateLPInvitationEmailMessage = ({
  firstName,
}: {
  firstName: string;
}) =>
  `${firstName},\n\nClick the button below to tell us more about you and proceed with your AgileEngine job application:`;

export const getLPSkillsBySpecialization = ({
  specialization,
  zohoSkills,
  lpSkills,
}: {
  specialization: string | null;
  zohoSkills: string[] | null;
  lpSkills: ILPSkill[] | null;
}): string[] | null => {
  if (!specialization || !zohoSkills?.length || !lpSkills?.length) return null;

  return lpSkills
    .filter((skill) => {
      const specializations =
        specialization === 'Full Stack'
          ? ['Front End', 'Back End']
          : [specialization];

      return (
        skill.type === LPSkillType.Primary &&
        zohoSkills.includes(skill.name) &&
        specializations.includes(skill.specialization)
      );
    })
    .map((skill) => skill.name)
    .slice(0, 3);
};

export const getCandidateLPInvitationFormInitialValues = (
  candidateDetails: IZohoCandidate | null,
  lpSpecializations: ILPSpecializations | null,
): CandidateLPInvitationFormFields => {
  const specialization =
    sortBy(lpSpecializations?.specializations, 'type').find(
      (specialization) => {
        if (candidateDetails?.['Dev_-_QA-AQA'] === 'AQA')
          return specialization.value === 'Automation testing';

        if (candidateDetails?.['Dev_-_QA-AQA'] === 'Developer') {
          return specialization.value === candidateDetails.Type_of_Developer;
        }

        return candidateDetails?.['Dev_-_QA-AQA'] === specialization.zohoValue;
      },
    )?.value || null;
  const primarySkills = getLPSkillsBySpecialization({
    specialization,
    zohoSkills: candidateDetails?.Primary_Skill_Set || null,
    lpSkills: lpSpecializations?.skills || null,
  });
  const yearsOfExperience = candidateDetails?.Seniority_Start_Date
    ? differenceInYears(
        new Date(),
        new Date(candidateDetails.Seniority_Start_Date),
      )
    : null;

  return {
    firstName: candidateDetails?.First_Name || null,
    lastName: candidateDetails?.Last_Name || null,
    country: candidateDetails?.Location_Country || null,
    city: candidateDetails?.Location_City || null,
    specialization,
    skills: primarySkills?.length ? primarySkills : null,
    yearsOfExperience,
    seniority: candidateDetails?.Seniority_Level || null,
    email: candidateDetails?.Email || null,
    emailSubject: generateLPInvitationEmailSubject(),
    emailMessage: generateLPInvitationEmailMessage({
      firstName: candidateDetails?.First_Name || '[CANDIDATE NAME]',
    }),
  };
};

export const transformCandidateLPInvitationFormValuesToPayload = (
  candidateId: string,
  formData: CandidateLPInvitationFormFields,
): CandidateLPInvitationAPIPayload => {
  return {
    candidateId,
    firstName: formData.firstName!,
    lastName: formData.lastName!,
    country: formData.country!,
    city: formData.city!,
    specialization: formData.specialization!,
    skills: formData.skills!,
    yearsOfExperience: formData.yearsOfExperience!,
    seniority: formData.seniority!,
    email: formData.email!,
    emailSubject: formData.emailSubject!,
    emailMessage: formData.emailMessage!,
  };
};
