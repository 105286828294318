import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  CSSObject,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemText,
  ListSubheader,
  Stack,
  styled,
  Theme,
  Typography,
} from '@mui/material';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import InboxRoundedIcon from '@mui/icons-material/InboxRounded';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import TransferWithinAStationRoundedIcon from '@mui/icons-material/TransferWithinAStationRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import { ReportIssue } from '@components';
import { LinkDrawer } from './LinkDrawer';
import { HMProfileCard } from './HMProfileCard';

import { useAppSelector } from '@redux/hooks';
import { authSelectors } from '@redux/auth';

import { AppRoutes } from '@constants';
import {
  isActivePath,
  isCandidatesPath,
  isJobOpeningsPath,
  isOnHoldJobOpeningsPath,
  checkUserCanOpenEditJobOpening,
  checkUserCanSeeCandidatesList,
} from '@utils';

const drawerWidth = 320;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.up('xs')]: {
    width: `calc(74px + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: '44px !important',
  flexShrink: 0,
}));

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      padding: '16px 8px',
      gap: '16px',
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      padding: '16px 0 16px 8px',
      gap: '16px',
    },
  }),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.brandAccent,
  padding: '10px',
  borderRadius: '8px',
  marginLeft: '8px',
  marginRight: '8px',
  color: theme.palette.common.white,
  ': hover': {
    backgroundColor: theme.palette.background.brandAccent,
  },
}));

interface IProps {
  isOpen: boolean;
  toggleDrawer?: () => void;
}

const releaseNotesDocumentLink =
  'https://docs.google.com/document/d/1ehhdCaWQnJKFj4lnzB4jbmdxZerGaAo6Uwex2MJ1oDU';

export const NavDrawer: React.FC<IProps> = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const profile = useAppSelector(authSelectors.getProfileData);

  const isJOCreationDisabled = !checkUserCanOpenEditJobOpening(profile);
  const canSeeCandidatesList = checkUserCanSeeCandidatesList(profile);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  return (
    <StyledDrawer variant="permanent" open={isOpen} onClose={toggleDrawer}>
      <DrawerHeader>
        <Box
          width={'100%'}
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'flex-start',
          }}
        >
          <StyledIconButton onClick={toggleDrawer}>
            {isOpen ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
          </StyledIconButton>
          <Stack
            direction="column"
            sx={{ opacity: isOpen ? '100%' : '0', mr: '18%' }}
          >
            <Typography
              variant="caption"
              fontSize={'0.688rem'}
              color={'text.secondary'}
            >
              LaunchPod
            </Typography>
            <Typography variant="h3" whiteSpace="nowrap">
              Hiring Dashboard
            </Typography>
          </Stack>
          <Box> {profile && <HMProfileCard />}</Box>
        </Box>
      </DrawerHeader>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          variant="contained"
          size="medium"
          sx={{
            margin: '-2px 10px',
            width: '40px',
            minWidth: '40px',
            borderRadius: '100px',
            padding: '8px',
          }}
          onClick={() => {
            isOpen && toggleDrawer && toggleDrawer();
            navigate(`/${AppRoutes.CREATE_JOB_OPENING}`);
          }}
          disabled={isJOCreationDisabled}
        >
          <AddRoundedIcon />
        </Button>
        {isOpen && (
          <ListItemText
            primary={'New position'}
            sx={(theme) => ({
              fontWeight: 800,
              color: theme.palette.text.link,
              cursor: 'pointer',
            })}
            onClick={() => {
              isOpen && toggleDrawer && toggleDrawer();
              navigate(`/${AppRoutes.CREATE_JOB_OPENING}`);
            }}
          />
        )}
      </Box>

      <List component="nav" aria-labelledby="nested-list-subheader">
        <LinkDrawer
          Icon={InboxRoundedIcon}
          isActive={isJobOpeningsPath(location.pathname)}
          label={'Open positions'}
          href={`/${AppRoutes.JOB_OPENINGS}`}
          onClick={toggleDrawer}
          isOpen={isOpen}
        />
        <LinkDrawer
          Icon={PauseCircleOutlineRoundedIcon}
          isActive={isOnHoldJobOpeningsPath(location.pathname)}
          label={'Positions on hold'}
          href={`/${AppRoutes.ON_HOLD_JOB_OPENINGS}`}
          onClick={toggleDrawer}
          isOpen={isOpen}
        />
        {canSeeCandidatesList && (
          <>
            {isOpen ? (
              <ListSubheader
                component="p"
                id="nav-drawer-subheader-candidates"
                sx={{
                  fontSize: '0.688rem',
                  color: 'text.secondary',
                  padding: '3px 19px',
                  lineHeight: '100%',
                  margin: '0',
                }}
              >
                Candidates
              </ListSubheader>
            ) : (
              <Divider sx={{ margin: '8px' }} />
            )}
            <LinkDrawer
              Icon={AutorenewRoundedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.ACTION_REPORT,
              )}
              label="Action needed"
              href={`/${AppRoutes.ACTION_REPORT}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <LinkDrawer
              Icon={TransferWithinAStationRoundedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.NEW_HOME_CANDIDATES,
              )}
              label="NewHome"
              href={`/${AppRoutes.NEW_HOME_CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <LinkDrawer
              Icon={ContactEmergencyOutlinedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.SELFGEN_CANDIDATES,
              )}
              label="Self-gen"
              href={`/${AppRoutes.SELFGEN_CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <LinkDrawer
              Icon={PersonAddRoundedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.APPLIED_CANDIDATES,
              )}
              label="Applied"
              href={`/${AppRoutes.APPLIED_CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <LinkDrawer
              Icon={TaskOutlinedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.TT_PASSED_CANDIDATES,
              )}
              label="TT passed"
              href={`/${AppRoutes.TT_PASSED_CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <LinkDrawer
              Icon={ScheduleRoundedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.IC_SCHEDULED_CANDIDATES,
              )}
              label="IC scheduled"
              href={`/${AppRoutes.IC_SCHEDULED_CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <LinkDrawer
              Icon={EventAvailableRoundedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.IC_DONE_CANDIDATES,
              )}
              label="IC done"
              href={`/${AppRoutes.IC_DONE_CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <LinkDrawer
              Icon={FreeBreakfastOutlinedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.IC_PASSED_CANDIDATES,
              )}
              label="IC passed"
              href={`/${AppRoutes.IC_PASSED_CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <LinkDrawer
              Icon={QuestionAnswerOutlinedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.TI_PASSED_CANDIDATES,
              )}
              label="TI passed"
              href={`/${AppRoutes.TI_PASSED_CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <LinkDrawer
              Icon={LocalActivityOutlinedIcon}
              isActive={isActivePath(
                location.pathname,
                AppRoutes.SUBMITTED_CANDIDATES,
              )}
              label="Submitted"
              href={`/${AppRoutes.SUBMITTED_CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />

            <LinkDrawer
              Icon={ContactsRoundedIcon}
              isActive={isCandidatesPath(location.pathname)}
              label="All candidates"
              href={`/${AppRoutes.CANDIDATES}`}
              onClick={toggleDrawer}
              isOpen={isOpen}
            />
            <Divider sx={{ margin: '8px' }} />

            {/* expandable more drawer */}

            <LinkDrawer
              Icon={isExpanded ? KeyboardArrowUpIcon : MenuIcon}
              isActive={false}
              label="More"
              onClick={() => setIsExpanded((prev) => !prev)}
              isOpen={isOpen}
              isExpandableMenu={true}
            />

            {isExpanded && (
              <>
                <LinkDrawer
                  Icon={NewReleasesOutlinedIcon}
                  isActive={false}
                  label="What's new"
                  onClick={toggleDrawer}
                  isOpen={isOpen}
                  newTabLink={releaseNotesDocumentLink}
                  ExternalLinkIcon={true}
                />

                <LinkDrawer
                  Icon={BugReportOutlinedIcon}
                  isActive={false}
                  label="Report issue"
                  onClick={() => setIsReportModalOpen(!isReportModalOpen)}
                  isOpen={isOpen}
                  isExpandableMenu={true}
                />
              </>
            )}
          </>
        )}
      </List>
      <ReportIssue
        isReportModalOpen={isReportModalOpen}
        setReportModalState={setIsReportModalOpen}
      />
    </StyledDrawer>
  );
};
