import { useEffect } from 'react';
import { useAppDispatch } from '@redux/hooks';
import { fetchProfile } from '@redux/auth';

export const useFetchProfile = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);
};
