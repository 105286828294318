import React, { useCallback } from 'react';

import {
  Flex,
  JobOpeningClientFilter,
  JobOpeningClientRecruiterFilter,
  JobOpeningLocationFilter,
  JobOpeningPriorityFilter,
  JobOpeningSeniorityFilter,
  JobOpeningTechnicalFlowFilter,
} from '@components';

import { IJobOpeningOptions } from '@types';

export const JobOpeningsListFilters: React.FC<{
  isLoading: boolean;
  value: Partial<IJobOpeningOptions>;
  handleChange: (payload: Partial<IJobOpeningOptions>) => void;
}> = ({ isLoading, value, handleChange }) => {
  const onPriorityFilterChange = useCallback(
    (value: string[] | null) => handleChange({ priority: value }),
    [],
  );
  const onClientFilterChange = useCallback(
    (value: string[] | null) => handleChange({ client: value }),
    [],
  );
  const onLocationFilterChange = useCallback(
    (value: string[] | null) => handleChange({ locations: value }),
    [],
  );
  const onTechFlowFilterChange = useCallback(
    (value: string[] | null) => handleChange({ techFlow: value }),
    [],
  );
  const onSeniorityFilterChange = useCallback(
    (value: string[] | null) => handleChange({ seniorities: value }),
    [],
  );
  const onClientRecruiterFilterChange = useCallback(
    (value: string[] | null) => handleChange({ clientRecruiters: value }),
    [],
  );

  return (
    <Flex flexDirection="column" mt={3}>
      <JobOpeningPriorityFilter
        isLoading={isLoading}
        value={value.priority!}
        onChange={onPriorityFilterChange}
      />
      <JobOpeningClientFilter
        isLoading={isLoading}
        value={value.client!}
        onChange={onClientFilterChange}
      />
      <JobOpeningLocationFilter
        isLoading={isLoading}
        value={value.locations!}
        onChange={onLocationFilterChange}
      />
      <JobOpeningTechnicalFlowFilter
        isLoading={isLoading}
        value={value.techFlow!}
        onChange={onTechFlowFilterChange}
      />
      <JobOpeningSeniorityFilter
        isLoading={isLoading}
        value={value.seniorities!}
        onChange={onSeniorityFilterChange}
      />
      <JobOpeningClientRecruiterFilter
        isLoading={isLoading}
        value={value.clientRecruiters!}
        onChange={onClientRecruiterFilterChange}
      />
    </Flex>
  );
};
