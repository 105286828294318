/* eslint-disable prettier/prettier */
import { css, SerializedStyles } from '@emotion/react';
import palette from 'theme/palette';

export const useAttachmentButtonStyles = (
  variant: 'primary' | 'secondary',
): SerializedStyles => {
  const sharedCss = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    border-radius: 8px;
    padding: 1.5rem 1.5rem;
    width: 100%;
    transition: 0.25s all ease-in-out;
    cursor: pointer;
  `;

  switch (variant) {
    case 'secondary': {
      return css`
        ${sharedCss};
        color: ${palette.highlight.fileSelection};
        background: ${palette.highlight.actionable};

        &:hover {
          background: ${palette.highlight.actionableHover};
        }
      `;
    }
    default: {
      return css`
        ${sharedCss};
        color: ${palette.highlight.white};
        background: ${palette.highlight.fileSelection};
      `;
    }
  }
};
