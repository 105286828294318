import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useAppDispatch } from '@redux/hooks';
import { useFormik } from 'formik';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CommentsDisabledOutlinedIcon from '@mui/icons-material/CommentsDisabledOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { createZohoTask, notifyCandidate } from '@redux/candidateDetails';

import {
  chackIfCandidateFromMarketing,
  generateRejectionEmailByStatus,
  generateZohoTask,
} from '@utils';
import { IZohoCandidate } from '@types';
import { CandidateStatus } from '@constants';

export const CandidateNotifyForm: React.FC<{
  candidate: IZohoCandidate;
  candidateStatus: CandidateStatus;
  ref: any;
}> = forwardRef(({ candidateStatus, candidate }, ref) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<{
    defaultFollowUps: boolean;
    createZohoTask: boolean;
    sendRejectionEmail: boolean;
    notifyEmailSubject: string | null;
    notifyEmailContent: string | null;
  }>({
    initialValues: {
      defaultFollowUps: false,
      createZohoTask: false,
      sendRejectionEmail: false,
      notifyEmailSubject: null,
      notifyEmailContent: null,
    },
    onSubmit: async (values) => {
      const zohoTask =
        values.createZohoTask && generateZohoTask(candidate, candidateStatus);
      if (zohoTask) {
        await dispatch(createZohoTask(zohoTask));
      }

      if (
        values.defaultFollowUps ||
        (values.sendRejectionEmail &&
          values.notifyEmailSubject &&
          values.notifyEmailContent)
      ) {
        await dispatch(
          notifyCandidate({
            candidateId: candidate.id,
            defaultFollowUps: values.defaultFollowUps,
            emailSubject: values.sendRejectionEmail
              ? values.notifyEmailSubject
              : null,
            emailContent: values.sendRejectionEmail
              ? values.notifyEmailContent
              : null,
          }),
        );
      }
    },
    validateOnMount: true,
    enableReinitialize: false,
  });

  useImperativeHandle(ref, () => ({
    notifyCandidate: async () => {
      await formik.handleSubmit();
    },
  }));

  const isCandidateFromMarketing = chackIfCandidateFromMarketing(candidate);

  const hasDefaultFollowUps = [
    CandidateStatus.IC_No_Show,
    CandidateStatus.IC_Canceled_By_Candidate,
    CandidateStatus.IC_Canceled_By_Recruiter,
  ].includes(candidateStatus);
  const hasZohoTask =
    [
      CandidateStatus.IC_No_Show,
      CandidateStatus.IC_Canceled_By_Candidate,
    ].includes(candidateStatus) && isCandidateFromMarketing;
  const defaultCustomEmail = generateRejectionEmailByStatus(
    candidate,
    candidateStatus,
  );

  useEffect(() => {
    formik.setValues({
      defaultFollowUps: hasDefaultFollowUps,
      createZohoTask: hasZohoTask,
      sendRejectionEmail: !!defaultCustomEmail,
      notifyEmailSubject: defaultCustomEmail?.subject || null,
      notifyEmailContent: defaultCustomEmail?.content || null,
    });
  }, [candidateStatus]);

  if (!hasDefaultFollowUps && !hasZohoTask && !defaultCustomEmail) return null;

  return (
    <Box
      width="100%"
      sx={{ background: '#FFF', padding: '16px', marginBottom: 2 }}
    >
      {hasDefaultFollowUps && (
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.defaultFollowUps}
              onChange={(_, val) =>
                formik.setFieldValue('defaultFollowUps', val)
              }
            />
          }
          label="Send email followups with a Calendly link to reschedule"
        />
      )}
      {hasZohoTask && (
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.createZohoTask}
              onChange={(_, val) => formik.setFieldValue('createZohoTask', val)}
            />
          }
          label="Create a Zoho’s To-Do task for the LeadGen Owner to follow up with the
                  candidate and ensure the call is booked"
        />
      )}
      {!!defaultCustomEmail && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Alert
            variant="standard"
            severity={formik.values.sendRejectionEmail ? 'info' : 'warning'}
            icon={
              formik.values.sendRejectionEmail ? (
                <MailOutlineIcon />
              ) : (
                <CommentsDisabledOutlinedIcon />
              )
            }
            action={
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={
                  formik.values.sendRejectionEmail ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )
                }
                onClick={() =>
                  formik.setFieldValue(
                    'sendRejectionEmail',
                    !formik.values.sendRejectionEmail,
                  )
                }
              >
                {formik.values.sendRejectionEmail
                  ? "Don't send"
                  : 'Turn on sending'}
              </Button>
            }
          >
            {formik.values.sendRejectionEmail
              ? 'Candidate will receive one time rejection email. Feel free to adjust the message according to the situation:'
              : 'Candidate will NOT receive a rejection email'}
          </Alert>
          {formik.values.sendRejectionEmail && (
            <>
              <TextField
                name="notifyEmailSubject"
                label="Subject"
                variant="outlined"
                fullWidth
                value={formik.values.notifyEmailSubject || ''}
                required
                onChange={(e) =>
                  formik.setFieldValue(
                    'notifyEmailSubject',
                    e.target.value || null,
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.notifyEmailSubject &&
                  !!formik.errors.notifyEmailSubject
                }
                helperText={
                  formik.touched.notifyEmailSubject
                    ? formik.errors.notifyEmailSubject
                    : undefined
                }
              />
              <TextField
                name="notifyEmailContent"
                label="Rejection email"
                variant="outlined"
                fullWidth
                value={formik.values.notifyEmailContent || ''}
                required
                onChange={(e) =>
                  formik.setFieldValue(
                    'notifyEmailContent',
                    e.target.value || null,
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.notifyEmailContent &&
                  !!formik.errors.notifyEmailContent
                }
                helperText={
                  formik.touched.notifyEmailContent
                    ? formik.errors.notifyEmailContent
                    : undefined
                }
                multiline
                minRows={6}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
});
