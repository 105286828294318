import React from 'react';
import { alpha, ButtonBase, ButtonBaseProps, styled } from '@mui/material';
import { isKeyClick } from '@utils';

const ButtonStyled = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    !['variant', 'underlined'].includes(String(prop)),
})<{ variant: 'primary' | 'secondary' | 'grey'; underlined: boolean }>(
  ({ theme, variant = 'primary', underlined = false }) => {
    const variants = {
      primary: {
        color: theme.palette.brand.accent,
        textDecoration: underlined ? `underline solid 1px` : 'none',
        ':hover': {
          textDecoration: underlined ? `none` : `underline`,
        },
      },
      secondary: {
        color: theme.palette.text.link,
        textDecoration: underlined
          ? `underline solid ${alpha(theme.palette.text.link, 0.3)} 1px`
          : 'none',
        ':hover': {
          color: theme.palette.brand.accent,
          textDecoration: underlined
            ? `underline solid ${theme.palette.brand.accent} 1px`
            : `underline solid ${theme.palette.brand.accent} 1px`,
        },
      },
      grey: {
        color: theme.palette.text.secondary,
        textDecoration: underlined
          ? `underline solid ${alpha(theme.palette.text.secondary, 0.3)} 1px`
          : 'none',
        ':hover': {
          color: theme.palette.brand.accent,
          textDecoration: underlined
            ? `underline solid ${theme.palette.brand.accent} 1px`
            : `underline solid ${theme.palette.brand.accent} 1px`,
        },
      },
    };

    return {
      display: 'inline-flex',
      gap: 4,
      textUnderlineOffset: '4px',
      fontSize: '1rem',
      fontFamily: 'inherit',
      transition: '0.5s color',
      ':disabled': {
        opacity: 0.6,
      },
      ...variants[variant],
    };
  },
);

interface IProps extends ButtonBaseProps {
  variant?: 'primary' | 'secondary' | 'grey';
  underlined?: boolean;
  onClick?: () => void;
  startIcon?: React.ReactElement;
}

export const TextButton: React.FC<IProps> = ({
  onClick,
  variant = 'primary',
  underlined = false,
  children,
  startIcon,
  ...props
}) => {
  return (
    <ButtonStyled
      variant={variant}
      underlined={underlined}
      onClick={onClick}
      onKeyUp={(e: React.KeyboardEvent<any>) => {
        if (isKeyClick(e)) {
          e.stopPropagation();
          onClick && onClick();
        }
      }}
      {...props}
    >
      {startIcon && startIcon}
      <span>{children}</span>
    </ButtonStyled>
  );
};
