import { Box, Typography } from '@mui/material';

import { HiddenBlockWrapper } from '@components';
import { NoAvailableTestTasks } from './components/NoAvailableTestTasks';
import { TestTasksSelector } from './components/TestTasksSelector';
import { TestTaskData } from './components/TestTaskData';

import { CandidateTestTask, SubmissionFormik } from '@types';

export const SubmissionTestTaskStep: React.FC<{
  formik: SubmissionFormik;
  testTasks: CandidateTestTask[];
  onHideFieldToggle: (field: string, isHidden: boolean) => void;
}> = ({ formik, testTasks, onHideFieldToggle }) => {
  const isHidden = formik.values.hiddenFields.includes('testTask');

  return (
    <Box display="flex" flexDirection="column" gap={2} py={2}>
      <HiddenBlockWrapper
        hidden={isHidden}
        onHideToggle={(isHidden) => onHideFieldToggle('testTask', isHidden)}
      >
        <Typography variant="h3">Candidate’s test task</Typography>
      </HiddenBlockWrapper>
      {!testTasks.length ? (
        <NoAvailableTestTasks />
      ) : (
        <Box display="flex" flexDirection="column" gap={3}>
          <TestTasksSelector
            testTasks={testTasks}
            formik={formik}
            disabled={isHidden}
          />
          {!!formik.values.testTask.technology && !isHidden && (
            <TestTaskData formik={formik} />
          )}
        </Box>
      )}
    </Box>
  );
};
