import { useMemo } from 'react';
import { isNumber } from 'lodash';

import { Box, Paper, TextField, Typography } from '@mui/material';
import { AutocompleteTextfield, CandidateField, Flex } from '@components';

import {
  CandidateLPInvitationFormik,
  ILPSpecializations,
  IZohoCandidate,
} from '@types';
import {
  generateLPInvitationEmailMessage,
  getLPSkillsBySpecialization,
} from '@utils';
import { LPSkillType } from '@constants';

export const ProfileDetailsSection: React.FC<{
  formik: CandidateLPInvitationFormik;
  lpSpecializations: ILPSpecializations | null;
  candidateDetails: IZohoCandidate | null;
}> = ({ formik, lpSpecializations, candidateDetails }) => {
  const specializationOptions = useMemo(
    () =>
      lpSpecializations?.specializations
        .filter((e) => !e.parentSpecialization)
        .map((e) => e.value) || [],
    [lpSpecializations?.specializations],
  );
  const skillOptions = useMemo(
    () =>
      lpSpecializations?.skills
        .filter((e) => {
          const specializations =
            formik.values.specialization === 'Full Stack'
              ? ['Front End', 'Back End']
              : [formik.values.specialization];
          return (
            e.type === LPSkillType.Primary &&
            specializations.includes(e.specialization)
          );
        })
        .map((e) => e.name) || [],
    [lpSpecializations?.skills, formik.values.specialization],
  );

  const specializationError =
    formik.errors.specialization &&
    (formik.touched.specialization || formik.submitCount)
      ? formik.errors.specialization
      : null;
  const skillsError =
    formik.errors.skills && (formik.touched.skills || formik.submitCount)
      ? formik.errors.skills
      : null;
  const experienceError =
    formik.errors.yearsOfExperience &&
    (formik.touched.yearsOfExperience || formik.submitCount)
      ? formik.errors.yearsOfExperience
      : null;

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.highlight.neutral,
        padding: 2,
      })}
    >
      <Paper>
        <Box
          sx={{
            padding: '16px 24px 24px 24px',
            borderBottom: '1px solid #0000001F',
          }}
        >
          <Typography variant="h3">Update profile details</Typography>
        </Box>
        <Flex p={3} flexDirection="column" gap={3}>
          <CandidateField
            field="First_Name"
            value={formik.values.firstName}
            required
            touched={!!formik.touched.firstName || !!formik.submitCount}
            error={formik.errors.firstName}
            onChange={(value) => {
              formik.setValues({
                ...formik.values,
                firstName: value,
                emailMessage: generateLPInvitationEmailMessage(value),
              });
            }}
            onBlur={() => formik.setFieldTouched('firstName')}
          />
          <CandidateField
            field="Last_Name"
            value={formik.values.lastName}
            required
            touched={!!formik.touched.lastName || !!formik.submitCount}
            error={formik.errors.lastName}
            onChange={(value) => formik.setFieldValue('lastName', value)}
            onBlur={() => formik.setFieldTouched('lastName')}
          />
          <CandidateField
            field="Location_Country"
            value={formik.values.country}
            required
            touched={!!formik.touched.country || !!formik.submitCount}
            error={formik.errors.country}
            onChange={(value) => formik.setFieldValue('country', value)}
            onBlur={() => formik.setFieldTouched('country')}
          />
          <CandidateField
            field="Location_City"
            value={formik.values.city}
            required
            touched={!!formik.touched.city || !!formik.submitCount}
            error={formik.errors.city}
            onChange={(value) => formik.setFieldValue('city', value)}
            onBlur={() => formik.setFieldTouched('city')}
          />
          <AutocompleteTextfield
            name="specialization"
            label="Specialization"
            options={specializationOptions}
            value={formik.values.specialization}
            required
            onChange={(_, value) => {
              formik.setValues({
                ...formik.values,
                specialization: value || null,
                skills: getLPSkillsBySpecialization({
                  specialization: value || null,
                  zohoSkills: candidateDetails?.Primary_Skill_Set || null,
                  lpSkills: lpSpecializations?.skills || null,
                }),
              });
            }}
            onBlur={() => formik.setFieldTouched('specialization')}
            fullWidth
            helperText={specializationError || undefined}
            error={!!specializationError}
          />
          <AutocompleteTextfield
            name="skills"
            label="Primary skills"
            options={skillOptions}
            value={formik.values.skills || []}
            onChange={(_, val) =>
              formik.setFieldValue('skills', val.length ? val : null)
            }
            onBlur={() => formik.setFieldTouched('skills')}
            fullWidth
            multiple
            filterSelectedOptions
            helperText={skillsError || undefined}
            error={!!skillsError}
          />
          <TextField
            name="yearsOfExperience"
            label="Experience"
            placeholder="Candidate's experience"
            variant="outlined"
            type="number"
            fullWidth
            required
            value={
              isNumber(formik.values.yearsOfExperience)
                ? formik.values.yearsOfExperience
                : ''
            }
            onChange={(e) =>
              formik.setFieldValue(
                'yearsOfExperience',
                e.target.value ? parseInt(e.target.value) : null,
              )
            }
            onBlur={formik.handleBlur}
            helperText={experienceError || undefined}
            error={!!experienceError}
          />
          <CandidateField
            field="Seniority_Level"
            value={formik.values.seniority}
            required
            touched={!!formik.touched.seniority || !!formik.submitCount}
            error={formik.errors.seniority}
            onChange={(value) => formik.setFieldValue('seniority', value)}
            onBlur={() => formik.setFieldTouched('seniority')}
          />
        </Flex>
      </Paper>
    </Box>
  );
};
