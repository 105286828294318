import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Box,
  Button,
  DialogActions,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { Flex, AutocompleteTextfield } from '@components';

import { fetchJobOpeningsOptions, optionsSelectors } from '@redux/options';
import {
  candidateDetailsSelectors,
  sendInviteCandidateInHotpipeline,
} from '@redux/candidateDetails';

import {
  generateHotPipelineCandidateEmailInto,
  generateHotPipelineCandidateSubject,
  formatEmailMessage,
  formatJobOpeningWithHash,
  isStatusComplete,
  isStatusIdle,
} from '@utils';
import {
  IHotPipelineCandidateFormik,
  IHotPipelineCandidateInvite,
} from '@types';
import { JobOpeningState } from '@constants';

import { LocalStorageService } from '@services/localStorageService';

import { sendEmailInviteCandidateInHotPipeline } from './validationSchema';

const HotPipelineInviteForm: React.FC<{
  closeModal: () => void;
}> = ({ closeModal }) => {
  const dispatch = useAppDispatch();

  const jobOpeningsOptions = useAppSelector(
    optionsSelectors.getJobOpeningsOptions,
  );

  useEffect(() => {
    if (isStatusIdle(jobOpeningsOptions.apiStatus)) {
      dispatch(
        fetchJobOpeningsOptions({
          states: [
            JobOpeningState.Open,
            JobOpeningState.PreOpen,
            JobOpeningState.OnHold,
          ],
        }),
      );
    }
  }, [jobOpeningsOptions.apiStatus]);

  const { data: jobOpenings } = useAppSelector(
    optionsSelectors.getJobOpeningsOptions,
  );

  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );

  const apiStatusHotPipelineInvite = useAppSelector(
    candidateDetailsSelectors.getHotPipelineCandidateInviteAPIStatus,
  );

  const userCalendlyLinkSaved: string | null =
    LocalStorageService.getState('user_calendly_link');

  const formik = useFormik<IHotPipelineCandidateFormik>({
    initialValues: {
      jobOpeningsToDiscuss: [],
      emailSubject: generateHotPipelineCandidateSubject(),
      emailMessage: generateHotPipelineCandidateEmailInto(
        candidateDetails?.First_Name || 'Candidate Name',
        userCalendlyLinkSaved ? userCalendlyLinkSaved : '',
      ),
    },
    validationSchema: sendEmailInviteCandidateInHotPipeline,

    onSubmit: async (values) => {
      const payload: IHotPipelineCandidateInvite = {
        jobOpeningsToDiscuss: values.jobOpeningsToDiscuss,
        emailSubject: values.emailSubject,
        emailMessage: formatEmailMessage(values.emailMessage),
        candidateEmail: candidateDetails?.Email || '',
      };
      await dispatch(
        sendInviteCandidateInHotpipeline({
          payload,
        }),
      );
      if (
        formik.isValid &&
        (isStatusComplete(apiStatusHotPipelineInvite) ||
          isStatusIdle(apiStatusHotPipelineInvite))
      ) {
        closeModal();
      }
    },
  });

  const jobOpeningOptions: string[] = useMemo(
    () => jobOpenings.map((jo) => jo.id),
    [jobOpenings],
  );

  return (
    <div>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <AutocompleteTextfield
            freeSolo
            label="Job openings to discuss"
            options={jobOpeningOptions}
            getOptionLabel={(option) => {
              const jo = jobOpenings.find((position) => position.id === option);
              return formatJobOpeningWithHash(
                jo?.Job_Opening_Name,
                jo?.Job_Opening_Id,
                jo?.Client_Name,
              );
            }}
            fullWidth
            value={formik.values.jobOpeningsToDiscuss.map((item) => item.id)}
            multiple
            filterSelectedOptions
            onChange={(_, val) => {
              const submissionJOs = jobOpenings
                .filter((jo) => val.includes(jo.id))
                .map((selectedJO) => ({
                  id: selectedJO.id,
                  jobId: selectedJO.Job_Opening_Id,
                  name: selectedJO.Job_Opening_Name,
                  client: selectedJO.Client_Name,
                  joURL: selectedJO.Careers_Site_Link,
                }));

              formik.setFieldValue('jobOpeningsToDiscuss', submissionJOs);
            }}
            onBlur={() => formik.setFieldTouched('jobOpeningsToDiscuss')}
            error={
              !!formik.touched.jobOpeningsToDiscuss &&
              !!formik.errors.jobOpeningsToDiscuss
            }
          />
          {formik.touched.jobOpeningsToDiscuss &&
            formik.errors.jobOpeningsToDiscuss && (
              <FormHelperText error sx={{ ml: 2 }}>
                {Array.isArray(formik.errors.jobOpeningsToDiscuss)
                  ? formik.errors.jobOpeningsToDiscuss.join(', ')
                  : formik.errors.jobOpeningsToDiscuss}
              </FormHelperText>
            )}
        </Box>

        <TextField
          name="emailSubject"
          label="Email subject"
          variant="outlined"
          fullWidth
          value={formik.values.emailSubject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.touched.emailSubject && !!formik.errors.emailSubject}
          helperText={formik.touched.emailSubject && formik.errors.emailSubject}
        />
        <Box>
          <TextField
            name="emailMessage"
            label="Email message"
            fullWidth
            value={formik.values.emailMessage}
            onChange={(e) =>
              formik.setFieldValue('emailMessage', e.target.value || null)
            }
            onBlur={formik.handleBlur}
            error={
              !!formik.touched.emailMessage && !!formik.errors.emailMessage
            }
            helperText={
              formik.touched.emailMessage && formik.errors.emailMessage
            }
            multiline
            rows={9}
            sx={{ position: 'relative' }}
          />

          {formik.values.jobOpeningsToDiscuss.length > 0 && (
            <Box
              borderTop="2px dashed gray"
              sx={{
                position: 'relative',
                padding: '10px',
                paddingBottom: '20px',
                marginTop: '10px',
                backgroundColor: (theme) => theme.palette.highlight.neutral,
              }}
            >
              <Typography color="text.secondary" mb={1} fontSize={'small'}>
                Opportunities for you
              </Typography>

              {formik.values.jobOpeningsToDiscuss.map((item) => (
                <>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      textDecoration: 'underline',
                      textTransform: 'unset',
                      fontSize: '0.8125rem',
                    }}
                  >
                    {item.name}
                  </Typography>
                </>
              ))}
            </Box>
          )}
        </Box>
      </Box>

      <DialogActions>
        <Flex justifyContent="end" gap={1} mt={3}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => formik.handleSubmit()}
            disabled={!formik.isValid}
          >
            Send
          </Button>
        </Flex>
      </DialogActions>
    </div>
  );
};

export default HotPipelineInviteForm;
