import { AppRoles } from '@constants';
import { IProfile } from '@types';

export const checkUserCanOpenEditJobOpening = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [
    AppRoles.SuperUser,
    AppRoles.DepartmentLead,
    AppRoles.TOM,
    AppRoles.DeliveryManager,
    AppRoles.PortfolioDeliveryManager,
    AppRoles.ClientPartner,
    AppRoles.ClientPartnerAdmin,
    AppRoles.Recruiter,
    AppRoles.Marketing,
    AppRoles.ProjectManager,
    AppRoles.DesignStudioLead,
    AppRoles.DesignStudioMember,
    AppRoles.HiringManager,
  ];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanSeeCandidatesList = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [
    AppRoles.SuperUser,
    AppRoles.Recruiter,
    AppRoles.Marketing,
    AppRoles.TOM,
  ];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanSendTestTask = (user: IProfile | null): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanEditTestTask = (user: IProfile | null): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanReviewTestTask = (user: IProfile | null): boolean => {
  if (!user) return false;

  const allowedRoles = [
    AppRoles.SuperUser,
    AppRoles.DepartmentLead,
    AppRoles.TOM,
    AppRoles.DeliveryManager,
    AppRoles.PortfolioDeliveryManager,
    AppRoles.ClientPartner,
    AppRoles.ClientPartnerAdmin,
    AppRoles.Marketing,
    AppRoles.Interviewers,
    AppRoles.ProjectManager,
    AppRoles.HiringManager,
  ];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanAssociateCandidate = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanReviewVideoInterview = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanLeaveIntroCallFeedbcak = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanScheduleTechnicalInterview = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanLeaveTechnicalInterviewFeedback = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [
    AppRoles.SuperUser,
    AppRoles.DepartmentLead,
    AppRoles.TOM,
    AppRoles.DeliveryManager,
    AppRoles.PortfolioDeliveryManager,
    AppRoles.ClientPartner,
    AppRoles.ClientPartnerAdmin,
    AppRoles.Marketing,
    AppRoles.Interviewers,
    AppRoles.ProjectManager,
    AppRoles.HiringManager,
  ];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanLeaveClientInterviewFeedback = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanLeaveTeamLeadIntroFeedback = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanUpdateSuitablePositionsInZoho = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Marketing, AppRoles.TOM];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanUpdateCandidateStatus = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [
    AppRoles.SuperUser,
    AppRoles.Recruiter,
    AppRoles.Marketing,
  ];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanGenerateTILink = (user: IProfile | null): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanGenerateTTLink = (user: IProfile | null): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanUpdateCandidateProfile = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [
    AppRoles.SuperUser,
    AppRoles.Recruiter,
    AppRoles.Marketing,
  ];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanEditJobOpeningMaxCalls = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [
    AppRoles.SuperUser,
    AppRoles.Recruiter,
    AppRoles.Marketing,
  ];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanCreateCandidate = (user: IProfile | null): boolean => {
  if (!user) return false;

  const allowedRoles = [
    AppRoles.SuperUser,
    AppRoles.Recruiter,
    AppRoles.Marketing,
  ];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanSendLPInvite = (user: IProfile | null): boolean => {
  if (!user) return false;

  const allowedRoles = [
    AppRoles.SuperUser,
    AppRoles.Recruiter,
    AppRoles.Marketing,
  ];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};

export const checkUserCanUpsertCandidateSpreadsheet = (
  user: IProfile | null,
): boolean => {
  if (!user) return false;

  const allowedRoles = [AppRoles.SuperUser, AppRoles.Recruiter];

  return user.roles.some((role) => allowedRoles.includes(role as AppRoles));
};
