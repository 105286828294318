import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

const CB_BORDER_RADIUS = '0.25rem';

const customSizes = {
  small: {
    squarePadding: '10px',
    padding: '0.25rem 0.625rem',
    fontSize: '0.813rem',
    lineHeight: '1.375rem',
  },
  medium: {
    squarePadding: '16px',
    padding: '0.375rem 1rem',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
};

export const useCustomButtonStyles = (
  color: 'primary' | 'secondary',
  fullWidth: boolean,
  isSquare: boolean,
  maxWidth?: string,
  size: 'small' | 'medium' = 'medium',
): SerializedStyles => {
  const theme = useTheme();

  return css`
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    outline-offset: 4px;
    transition: filter 250ms;
    width: ${fullWidth ? '100%' : 'initial'};
    max-width: ${maxWidth ? maxWidth : 'initial'};

    .ae-custom-button_front {
      display: flex;
      justify-content: center;
      position: relative;
      padding: ${isSquare
        ? customSizes[size].squarePadding
        : customSizes[size].padding};
      border-radius: ${CB_BORDER_RADIUS};
      background: ${theme.palette.button[color].front};
      white-space: nowrap;
    }

    .ae-custom-button_text {
      font-size: ${customSizes[size].fontSize};
      line-height: ${customSizes[size].lineHeight};
      font-weight: 500;
      font-family: ${theme.typography.fontFamily};
      color: ${theme.palette.button[color].text};
      display: flex;
      flex-direction: row;
      justify-content: ${fullWidth ? 'center' : 'initial'};
      align-items: center;
    }

    &.is-loading .ae-custom-button_text {
      visibility: hidden;
    }

    &.is-custom-loading .ae-custom-button_text {
      visibility: visible;
    }

    .ae-custom-button_start-icon {
      display: flex;
      padding-right: ${isSquare ? 'initial' : '0.5rem'};
    }

    .ae-custom-button_end-icon {
      display: flex;
      padding-left: ${isSquare ? 'initial' : '0.5rem'};
    }

    .ae-custom-button_loader {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

    &:hover:not(.is-loading) .ae-custom-button_front {
      background-color: ${theme.palette.button[color].frontHover};
    }

    &:focus:not(.is-loading) .ae-custom-button_front {
      background-color: ${theme.palette.button[color].frontFocus};
    }

    &:focus-visible {
      outline: none;
    }

    &:active:not(.is-loading) .ae-custom-button_front,
    &.is-loading .ae-custom-button_front {
      background-color: ${theme.palette.button[color].frontActive};
    }

    &:disabled,
    &.is-loading {
      pointer-events: none;
    }

    &:disabled .ae-custom-button_front {
      background-color: ${theme.palette.button[color].frontDisabled};
    }

    &:disabled .ae-custom-button_text {
      color: ${theme.palette.button[color].textDisabled};
    }
  `;
};
