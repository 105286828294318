import {
  CandidateSortingOptions,
  CandidateStage,
  CandidateState,
  CandidateStatus,
  CandidateStatusStage,
  CandidateTypeOfOutboundChannel,
  OrderDirection,
} from '@constants';
import { IActionReport } from './actionReport';

export interface IDWHCandidateBase {
  id: string;
  Email: string;
  Email_from_CP?: string;
  Second_Email?: string;
  Email_from_Linkedin?: string;
  Lead_closed_because_NEW: string;
  Lead_month_2018?: string;
  Leads_reply_2018?: string;
  Technical_Flow: string;
  Notice_Period_From_Offer: string;
  Additional_Info?: string;
  Test_Task_Technology: string;
  Test_Task_Date: string;
  Test_Task_sent_date: string;
  Codility_Result: number;
  Test_Task_Link: string;
  Test_Task_sent_by: string;
  Test_Task_Feedback: string;
  Ti_Result: string | null;
  Date_of_lead_closing?: string;
  Assignee_Persona_2018: string | null;
  All_Assignees_Personas: string | null;
  LinkedIn_URL: string | null;
  Ex_Contractor: string | null;
  Launch_Pod_Sign_in_date?: string | null;
  State_of_Candidate_NEW?: string | null;
  Interested_Info_Date?: string | null;
  Lead_Month_Date_auto: string | null;
  Date_of_processing?: string | null;
  English_First_Name: string | null;
  English_Last_Name: string | null;
  First_Name: string | null;
  Last_Name: string | null;
  Full_Name: string | null;
  Seniority_Level: string;
  Seniority_Start_Date: string;
  Tl_Intro_Date?: string;
  Prescreen_Failed_Because: string | null;
  Talked_With: string | null;
  Date_Of_Prescreen: string | null;
  Date_of_Prescreen_Planned: string | null;
  Date_Of_Tech_Interview_Planned: string | null;
  Date_Of_Submission: string | null;
  Date_Of_Last_Apply: string | null;
  candidateStage: CandidateStage;
  Location_Country: string;
  Location_City: string;
  Hourly_Rate_Expected: number | null;
  Source: string | null;
  Leads_Owner_2018: string | null;
  Recruiters_Feedback: string | null;
  Clients_Feedback: string | null;
  Tl_Intro_Feedback: string | null;
  Dev_Qaaqa: string | null;
  LP_CV_link: string | null;
  AE_Formatted_CV_Link: string | null;
  English_Level: string | null;
  Type_of_Inbound_Channel: string | null;
  New_Home_Date_Start: string | null;
  New_Home_Date_End: string | null;
  Technical_Conslusion: string | null;
  Interview: string | null;
  Candidate_Status: string | null;
  Stage: string | null;
  Added_by: string | null;
  associationStatuses?: {
    stage: CandidateStatusStage;
    status: CandidateStatus;
    date: string;
  }[];
  Candidate_Status_Updated_At: string | null;
}

export interface IDWHCandidateRaw extends IDWHCandidateBase, ICandidate {
  Was_interested_month: string | null;
  Skill_Set: string | null;
  Test_Task: string | null;
  Primary_Skill_Set: string | null;
  Secondary_Skill_Set: string | null;
  Test_Task_Reviewers: string | null;
}

export interface IDWHCandidate extends IDWHCandidateBase, ICandidate {
  Was_interested_month: string[];
  Skill_Set: string[];
  Test_Task: string[];
  Primary_Skill_Set: string[];
  Secondary_Skill_Set: string[];
  Test_Task_Reviewers: string[];
}
export interface ICandidatesList {
  data: IDWHCandidate[];
  totalCount: number;
}

export interface ICandidatesOptions {
  search: string | null;
  sortBy: CandidateSortingOptions | null;
  order: OrderDirection | null;
  stage: CandidateStage | null;
  state: CandidateState | null;
  country: string[] | null;
  seniority: string[] | null;
  payRate: number[] | null;
  recruiter: string[] | null;
  addedBy: string[] | null;
  techFlow: string[] | null;
  typeOfOutboundChannel: CandidateTypeOfOutboundChannel | null;
  offset: number;
  limit: number;
}
export interface IZohoCandidate extends ICandidate {
  id: string;
  Email: string | null;
  Email_from_CP: string | null;
  Second_Email: string | null;
  Email_from_Linkedin: string | null;
  Lead_closed_because_NEW: string | null;
  Lead_month_2018: string | null;
  Was_interested_month: string[] | null;
  Leads_reply_2018: string | null;
  Technical_Flow: string | null;
  Notice_period_from_Offer: string | null;
  Additional_Info: string | null;
  Test_Task_Technology: string | null;
  Test_Task_Date: string | null;
  Test_Task_sent_date: string | null;
  Codility_Result: number | null;
  Tech_Quiz_Persent: number | null;
  Test_Task: string[] | null;
  Test_Task_Link: string | null;
  Test_Task_sent_by: string | null;
  Test_Task_Feedback: string | null;
  TI_result: string | null;
  Date_of_lead_closing: string | null;
  Assignee_Persona_2018: string | null;
  All_Assignees_Personas: string | null;
  LinkedIn_URL: string | null;
  Ex_Contractor: string | null;
  Launch_Pod_Sign_in_date: string | null;
  State_of_Candidate_NEW: string | null;
  Interested_Info_Date: string | null;
  Lead_Month_Date_auto: string | null;
  Date_of_processing: string | null;
  English_First_Name: string | null;
  English_Last_Name: string | null;
  First_Name: string | null;
  Last_Name: string | null;
  Full_Name: string | null;
  English_level: string | null;
  Seniority_Level: string | null;
  Seniority_Start_Date: string | null;
  TL_intro_date: string | null;
  Prescreen_failed_because: string | null;
  Primary_Skill_Set: string[] | null;
  Secondary_Skill_Set: string[] | null;
  Skill_Set: string | null;
  Full_Stack: boolean | null;
  Type_of_Developer: string | null;
  Date_of_Prescreen: string | null;
  Date_of_Prescreen_Planned: string | null;
  Date_of_Tech_Interview_planned: string | null;
  Date_of_Submission: string | null;
  Date_of_last_apply: string | null;
  Location_City: string | null;
  Location_Country: string | null;
  New_Location: string | null;
  Location_Area: string | null;
  Time_zone: string | null;
  'Dev_-_QA-AQA': string | null;
  Number_of_suitable_positions: string | null;
  Hourly_Rate_Expected: number | null;
  Source: string | null;
  Leads_owner_2018: string | null;
  Recruiters_Feedback: string | null;
  Talked_with: string[] | null;
  Clients_Submission: string[] | null;
  Date_of_Clients_Interview: string | null;
  "Client's_Feedback": string | null;
  TL_Intro: string[] | null;
  TL_intro_feedback: string | null;
  LP_CV_link: string | null;
  CV_File_Name: string | null;
  AE_Formatted_CV_Link: string | null;
  Type_of_channel: string | null;
  Type_of_Inbound_channel: string | null;
  Type_of_Outbound_channel: string | null;
  Interested_in_JO: string | null;
  LeadGen_owner: string | null;
  New_Home_Date_Start: string | null;
  New_Home_Date_End: string | null;
  Test_Task_Reviewers: string[] | null;
  Interview: string[] | null;
  Technical_Conslusion: string | null;
  Candidate_Status: string;
  LP_match_Positions_Date: string | null;
  LP_application_match_Positions_IDs: string[] | null;
  Compensation_Expected: number | null;
  Hourly_Rate_Current: number | null;
  Hourly_Rate_Offer1: number | null;
  Compensation_Current: number | null;
  Video_Interview_Link: string | null;
  VI_result: string[] | null;
  VI_feedback: string | null;
  Updated_Date: string | null;
  Leads_reply_Date: string | null;
  Calendly_calendar: string | null;
  Stage_of_closing: string | null;
  Replied_to_template: string | null;
  Brand_replied_Interested: string | null;
  VI_date: string | null;
  Submitted_to_client: boolean | null;
  Clients_Interview: string[] | null;
  Final_decision_from_client: string | null;
  VI_sent_date: string | null;
  Date_of_offer_done: string | null;
  SOW_signed_date: string | null;
  Current_Company: string | null;
  Current_employment_type: string | null;
  Expected_employment_type: string | null;
  Stage: string | null;
  LP_application_Date: string | null;
  Added_by: string | null;
  Interested_Info_Owner: string | null;
  Nickname_Launch_Pod: string | null;
  Location_Grabb: string | null;
  Company_do_not_touch: string | null;
  Do_not_touch_Russia: boolean | null;
  TI_Suggested_Seniority: string | null;
  Phone: string | null;
  Telegram: string | null;
  'Facebook_/_VK_URL': string | null;
  Instagram: string | null;
  Twitter: string | null;
  Whatsapp: string | null;
  Candidate_Status_Updated_At: string | null;
  Self_gen_Touch_Date: string | null;
  Hot_Pipeline: boolean | null;
  Hot_Pipeline_Start_Date: string | null;
  Hot_Pipeline_End_Date: string | null;
  Djinni_URL: string | null;
  GitHub_Link: string | null;
  LP_Invitation_Sent: boolean | null;
}

export interface ICandidate {
  score: ICandidateScore;
  actionReport: IActionReport;
  matchedJobOpenings: IJobOpeningsToDiscuss[];
}

export interface ICandidateScore {
  list: IScore[];
  total: number;
}

export interface IScore {
  type: ScoreTypes;
  points: number | null;
  value: string;
}

export enum ScoreTypes {
  Location = 'location',
  Seniority = 'seniority',
  English = 'english',
  Skills = 'skills',
}

interface IJobOpeningsToDiscuss {
  id: string;
  jobId: string;
  name: string;
  client: string;
}
export interface IHotPipelineCandidateInvite {
  jobOpeningsToDiscuss: IJobOpeningsToDiscuss[];
  emailSubject: string;
  emailMessage: any;
  candidateEmail: string;
}

export interface IHotPipelineCandidatePayload {
  payload: IHotPipelineCandidateInvite;
}

export interface IHotPipelineCandidateFormik {
  jobOpeningsToDiscuss: {
    id: string;
    jobId: string;
    name: string;
    client: string;
    joURL: string;
  }[];
  emailSubject: string;
  emailMessage: string;
}

export interface ICandidateByStage {
  id: string;
  fullName: string | null;
  firstName: string | null;
  lastName: string | null;
  englishFirstName: string | null;
  englishLastName: string | null;
  seniority: string | null;
  technicalFlow: string | null;
  country: string | null;
  date: string | null;
  status: CandidateStatus | null;
  score: ICandidateScore;
  candidateStatusUpdatedAt: string | null;
}
