import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMobileView } from '@hooks/useIsMobileView';

import { Hidden, Stack, Typography } from '@mui/material';
import {
  ForbiddenView,
  LoaderSkeleton,
  ScheduleEngineeringTIButton,
} from '@components';
import { MeetingItem } from './components/MeetingItem';
import { NextStepsAfterReview } from './components/NextStepsAfterReview';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  candidateDetailsSelectors,
  fetchCandidateMeetings,
  postCandidateMeetingReview,
} from '@redux/candidateDetails';

import { isStatusForbidden, isStatusIdle, isStatusLoading } from '@utils';
import { MeetingReviewPayload } from '@types';
import { MeetingStatus, MeetingType } from '@constants';

export const CandidateMeetings: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobileView = useIsMobileView();

  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const { meetingsAPIStatus, meetings } = useAppSelector(
    candidateDetailsSelectors.getMeetings,
  );
  const isMeetingReviewLoading = useAppSelector(
    candidateDetailsSelectors.getMeetingsReviewLoading,
  );

  const [isNextStepsModalOpen, setIsNextStepsModalOpen] = useState(false);
  const [selectedMeetingId, setSelectedMeetingId] = useState<string | null>(
    null,
  );

  const onMeetingFeedbackSubmit = useCallback(
    async (meetingId: string, payload: MeetingReviewPayload) => {
      const selectedMeeting = meetings.find(
        (meeting) => meeting.id === meetingId,
      );
      const shouldOpenNextStepsModal =
        selectedMeeting?.status === MeetingStatus.Scheduled &&
        selectedMeeting?.type === MeetingType.IntroCall;

      if (candidateDetails?.id) {
        await dispatch(
          postCandidateMeetingReview({
            candidateId: candidateDetails.id,
            meetingId,
            ...payload,
          }),
        );

        if (shouldOpenNextStepsModal) {
          setIsNextStepsModalOpen(true);
        }
      }
    },
    [candidateDetails?.id, meetings],
  );

  const meetingsToShow = useMemo(
    () =>
      meetings.filter(
        (meeting) =>
          !(
            meeting.type === MeetingType.TechnicalInterview &&
            meeting.status === MeetingStatus.Cancelled
          ),
      ),
    [meetings],
  );

  useEffect(() => {
    if (!isMobileView) {
      setSelectedMeetingId(meetingsToShow[0]?.id || null);
    }
  }, [meetingsToShow, isMobileView]);

  useEffect(() => {
    if (isStatusIdle(meetingsAPIStatus) && candidateDetails?.id) {
      dispatch(fetchCandidateMeetings(candidateDetails.id));
    }
  }, [meetingsAPIStatus, candidateDetails?.id]);

  const handleMeetingChange = useCallback((newValue: string) => {
    setSelectedMeetingId(newValue);
  }, []);

  if (!candidateDetails) return null;

  if (isStatusLoading(meetingsAPIStatus)) {
    return <LoaderSkeleton />;
  }

  if (isStatusForbidden(meetingsAPIStatus)) {
    return <ForbiddenView />;
  }

  if (!meetingsToShow.length)
    return (
      <Typography variant="h3" textAlign="center">
        No data
      </Typography>
    );

  return (
    <React.Fragment>
      <Stack my={'0.25rem'} gap={'1rem'}>
        <Stack
          gap={'0.5rem'}
          flexDirection={'row'}
          sx={{
            mx: {
              xs: '1rem',
              sm: '0.25rem',
            },
          }}
        >
          <Typography variant="h3" flex={1} my={'auto'}>
            {meetingsToShow.length} interviews
          </Typography>
          <Hidden smDown>
            <ScheduleEngineeringTIButton zohoId={candidateId!} />
          </Hidden>
        </Stack>
        <Stack
          sx={{
            backgroundColor: {
              xs: 'none',
              sm: 'background.backgroundPrimary',
            },
            borderRadius: '0.5rem',
            padding: {
              xs: '0',
              sm: '1rem',
            },
            mx: {
              xs: '0',
              sm: '-1rem',
            },
          }}
        >
          {meetingsToShow.map((meeting) => (
            <MeetingItem
              key={meeting.id}
              candidate={candidateDetails}
              meeting={meeting}
              onMeetingFeedbackSubmit={onMeetingFeedbackSubmit}
              isSubmittingData={isMeetingReviewLoading}
              selectedMeetingId={selectedMeetingId}
              handleMeetingChange={handleMeetingChange}
            />
          ))}
        </Stack>
        <Hidden smUp>
          <Stack
            direction={'row'}
            sx={{
              mx: {
                xs: '1rem',
                sm: 0,
              },
            }}
          >
            <ScheduleEngineeringTIButton zohoId={candidateId!} />
          </Stack>
        </Hidden>
      </Stack>
      <NextStepsAfterReview
        isOpen={isNextStepsModalOpen}
        candidateId={candidateDetails?.id}
      />
    </React.Fragment>
  );
};
