import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store } from '@redux';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { muiTheme } from '@theme';

import App from './App';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://169fd3db0e9c4348b18e30feefd2228f@o1193549.ingest.sentry.io/6567309',
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],
  enabled: !/(local|test)/gi.test(process.env.REACT_APP_ENV || 'local'),
  tracesSampler: () =>
    !/(local|test)/.test(process.env.REACT_APP_ENV || 'local') ? 1 : 0,
});
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);
