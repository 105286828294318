import React from 'react';

import { JobOpeningField } from '@components';
import { Typography, Grid, Alert, Link, Stack, Paper } from '@mui/material';

import { JobOpeningFormik } from '@types';
import { JobOpeningsPriority } from '@constants';

export const JobDescription: React.FC<{
  formik: JobOpeningFormik;
}> = ({ formik }) => {
  return (
    <Paper elevation={1} sx={{ mb: '1px', pt: 4, pb: 6, paddingX: 5 }}>
      <Typography variant="h2" mb={1}>
        Job description
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Alert
              variant="standard"
              severity="info"
              sx={{ boxShadow: 'none' }}
            >
              Please include the text of the job description document here using{' '}
              <Link
                href="https://docs.google.com/document/d/1TtwWEj3O4HxyvGOPwcNgmk2NMClRl-xfx65mqBE8C4E/edit"
                target="_blank"
              >
                this format
              </Link>
            </Alert>
            <JobOpeningField
              field="Must_Haves1"
              value={formik.values.Must_Haves1}
              required
              touched={formik.touched.Must_Haves1}
              error={formik.errors.Must_Haves1}
              onChange={(val) =>
                formik.setFieldValue('Must_Haves1', val || null)
              }
              onBlur={() => formik.setFieldTouched('Must_Haves1')}
            />
            <JobOpeningField
              field="Multi_Line_4"
              value={formik.values.Multi_Line_4}
              required
              touched={formik.touched.Multi_Line_4}
              error={formik.errors.Multi_Line_4}
              onChange={(val) =>
                formik.setFieldValue('Multi_Line_4', val || null)
              }
              onBlur={() => formik.setFieldTouched('Multi_Line_4')}
            />
            <JobOpeningField
              field="Multi_Line_5"
              value={formik.values.Multi_Line_5}
              touched={formik.touched.Multi_Line_5}
              error={formik.errors.Multi_Line_5}
              onChange={(val) =>
                formik.setFieldValue('Multi_Line_5', val || null)
              }
              onBlur={() => formik.setFieldTouched('Multi_Line_5')}
            />
            <JobOpeningField
              field="Google_Drive_Folder"
              value={formik.values.Google_Drive_Folder}
              required={formik.values.Priority !== JobOpeningsPriority.PreOpen}
              touched={formik.touched.Google_Drive_Folder}
              error={formik.errors.Google_Drive_Folder}
              onChange={(val) =>
                formik.setFieldValue('Google_Drive_Folder', val || null)
              }
              onBlur={() => formik.setFieldTouched('Google_Drive_Folder')}
            />
            <JobOpeningField
              field="TI_Requirements_Link"
              value={formik.values.TI_Requirements_Link}
              touched={formik.touched.TI_Requirements_Link}
              error={formik.errors.TI_Requirements_Link}
              onChange={(val) =>
                formik.setFieldValue('TI_Requirements_Link', val || null)
              }
              onBlur={() => formik.setFieldTouched('TI_Requirements_Link')}
            />
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
