import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { pageTitleSelectors } from '@redux/pageTitle';

const DEFAULT_TITLE = 'Launchpod Hiring Dashboard';
const LP_TITLE_POSTFIX = ' | LP Dashboard';

export const useObserveTitle = (): string => {
  const title = useSelector(pageTitleSelectors.titleSelector);

  useEffect(() => {
    document.title =
      title !== DEFAULT_TITLE ? `${title} ${LP_TITLE_POSTFIX}` : DEFAULT_TITLE;
  }, [title]);

  return title;
};
