import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { candidateDetailsStoreKey } from './candidateDetails.const';
import { ICandidateDetailsSliceState } from './candidateDetails.types';
import { ApiStatus } from '@constants';
import {
  associateCandidateWithJobOpening,
  fetchCandidateDetails,
  fetchCandidateJobOpenings,
  fetchCandidateTestTasks,
  fetchCandidateUserVideoInterview,
  postTestTaskReview,
  postCandidateMeetingReview,
  postUserVideoInterviewReview,
  unAssociateCandidateWithJobOpening,
  fetchCandidateKnowledgeQuiz,
  sendTestTaskToCandidate,
  changeCandidateStatus,
  fetchCandidateProcessActivity,
  fetchCandidateMeetings,
  fetchAvailableVideoInterview,
  postCandidateResetVideoInterview,
  postCandidateSendVideoInterview,
  updateCandidateProfile,
  updateCandidateTestTask,
  generateClientVideoLink,
  fetchCandidateSubmissions,
  sendInviteCandidateInHotpipeline,
  createCandidate,
  fetchCandidateDuplicates,
  sendLPInvitation,
} from './candidateDetails.thunks';

const initialState: ICandidateDetailsSliceState = {
  apiStatus: ApiStatus.IDLE,
  id: null,
  data: null,

  videoInterviewAPIStatus: ApiStatus.IDLE,
  videoInterviewData: [],
  isPerformingVideoInterviewAction: false,

  knowledgeQuizAPIStatus: ApiStatus.IDLE,
  knowledgeQuizData: null,

  testTasksAPIStatus: ApiStatus.IDLE,
  testTasksData: [],
  isPerformingTestTaskAction: false,

  isMeetingReviewLoading: false,

  suitablePositionsAPIStatus: ApiStatus.IDLE,
  suitablePositionsData: [],

  associationAPIStatus: ApiStatus.IDLE,
  associationErrorMessage: null,

  updateStatusAPIStatus: ApiStatus.IDLE,
  updateStatusErrorMessage: null,

  processActivityAPIStatus: ApiStatus.IDLE,
  processActivity: [],

  meetingsAPIStatus: ApiStatus.IDLE,
  meetings: [],

  availableVideoInterviewAPIStatus: ApiStatus.IDLE,
  availableVideoInterview: [],

  resetVideoInterviewAPIStatus: ApiStatus.IDLE,

  sendVideoInterviewAPIStatus: ApiStatus.IDLE,

  isProfileUpdating: false,

  isTestTaskFormProcesing: false,

  generateClientVideoLinkAPIStatus: ApiStatus.IDLE,

  submissionsAPIStatus: ApiStatus.IDLE,
  submissions: [],

  hotPipelineCandidateInviteAPIStatus: ApiStatus.IDLE,

  createCandidateAPIStatus: ApiStatus.IDLE,

  duplicateCandidatesAPIStatus: ApiStatus.IDLE,
  duplicateCandidates: null,

  sendLPInvitationAPIStatus: ApiStatus.IDLE,
};

export const candidateDetailsSlice = createSlice({
  name: candidateDetailsStoreKey,
  initialState,
  reducers: {
    setCandidateId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    clearCandidateDetails: () => initialState,
    clearCandidateDuplicates: (state) => {
      state.duplicateCandidates = null;
      state.duplicateCandidatesAPIStatus = ApiStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateDetails.pending, (state) => {
        state.apiStatus = ApiStatus.LOADING;
      })
      .addCase(
        fetchCandidateDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.apiStatus = ApiStatus.COMPLETE;
          state.data = action.payload;
        },
      )
      .addCase(
        fetchCandidateDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Get Suitable Positions
      .addCase(fetchCandidateJobOpenings.pending, (state) => {
        state.suitablePositionsAPIStatus = ApiStatus.LOADING;
      })
      .addCase(fetchCandidateJobOpenings.fulfilled, (state, action) => {
        state.suitablePositionsAPIStatus = ApiStatus.COMPLETE;
        state.suitablePositionsData = action.payload;
      })
      .addCase(
        fetchCandidateJobOpenings.rejected,
        (state, action: PayloadAction<any>) => {
          state.suitablePositionsAPIStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Get Video Interview
      .addCase(fetchCandidateUserVideoInterview.pending, (state) => {
        state.videoInterviewAPIStatus = ApiStatus.LOADING;
      })
      .addCase(fetchCandidateUserVideoInterview.fulfilled, (state, action) => {
        state.videoInterviewAPIStatus = ApiStatus.COMPLETE;
        state.videoInterviewData = action.payload;
      })
      .addCase(
        fetchCandidateUserVideoInterview.rejected,
        (state, action: PayloadAction<any>) => {
          state.videoInterviewAPIStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Review Video Interview
      .addCase(postUserVideoInterviewReview.pending, (state) => {
        state.isPerformingVideoInterviewAction = true;
      })
      .addCase(postUserVideoInterviewReview.fulfilled, (state) => {
        state.isPerformingVideoInterviewAction = false;

        state.apiStatus = initialState.apiStatus;
        state.data = initialState.data;

        state.videoInterviewAPIStatus = initialState.videoInterviewAPIStatus;
        state.videoInterviewData = initialState.videoInterviewData;
      })
      .addCase(postUserVideoInterviewReview.rejected, (state) => {
        state.isPerformingVideoInterviewAction = false;
      })
      // Get Knowledge Quiz
      .addCase(fetchCandidateKnowledgeQuiz.pending, (state) => {
        state.knowledgeQuizAPIStatus = ApiStatus.LOADING;
      })
      .addCase(fetchCandidateKnowledgeQuiz.fulfilled, (state, action) => {
        state.knowledgeQuizAPIStatus = ApiStatus.COMPLETE;
        state.knowledgeQuizData = action.payload;
      })
      .addCase(
        fetchCandidateKnowledgeQuiz.rejected,
        (state, action: PayloadAction<any>) => {
          state.knowledgeQuizAPIStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Get Test Tasks
      .addCase(fetchCandidateTestTasks.pending, (state) => {
        state.testTasksAPIStatus = ApiStatus.LOADING;
      })
      .addCase(fetchCandidateTestTasks.fulfilled, (state, action) => {
        state.testTasksAPIStatus = ApiStatus.COMPLETE;
        state.testTasksData = action.payload;
      })
      .addCase(
        fetchCandidateTestTasks.rejected,
        (state, action: PayloadAction<any>) => {
          state.testTasksAPIStatus = state.testTasksAPIStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Update Test Task
      .addCase(updateCandidateTestTask.pending, (state) => {
        state.isTestTaskFormProcesing = true;
      })
      .addCase(updateCandidateTestTask.fulfilled, (state, action) => {
        state.isTestTaskFormProcesing = false;
        state.testTasksData = state.testTasksData.map((tt) =>
          tt.id === action.payload.id ? action.payload : tt,
        );
      })
      .addCase(updateCandidateTestTask.rejected, (state) => {
        state.isTestTaskFormProcesing = false;
      })
      // Send Test Task To Candidate
      .addCase(sendTestTaskToCandidate.pending, (state) => {
        state.isPerformingTestTaskAction = true;
      })
      .addCase(sendTestTaskToCandidate.fulfilled, (state) => {
        state.isPerformingTestTaskAction = false;

        state.apiStatus = initialState.apiStatus;
        state.data = initialState.data;

        state.testTasksAPIStatus = initialState.testTasksAPIStatus;
        state.testTasksData = initialState.testTasksData;
      })
      .addCase(sendTestTaskToCandidate.rejected, (state) => {
        state.isPerformingTestTaskAction = false;
      })
      // Review Test Task
      .addCase(postTestTaskReview.pending, (state) => {
        state.isPerformingTestTaskAction = true;
      })
      .addCase(postTestTaskReview.fulfilled, (state) => {
        state.isPerformingTestTaskAction = false;

        state.apiStatus = initialState.apiStatus;
        state.data = initialState.data;

        state.testTasksAPIStatus = initialState.testTasksAPIStatus;
        state.testTasksData = initialState.testTasksData;
      })
      .addCase(postTestTaskReview.rejected, (state) => {
        state.isPerformingTestTaskAction = false;
      })
      // Review Candidate Meeting
      .addCase(postCandidateMeetingReview.pending, (state) => {
        state.isMeetingReviewLoading = true;
      })
      .addCase(postCandidateMeetingReview.fulfilled, (state) => {
        state.isMeetingReviewLoading = false;

        state.apiStatus = initialState.apiStatus;
        state.data = initialState.data;

        state.meetingsAPIStatus = initialState.meetingsAPIStatus;
        state.meetings = initialState.meetings;
      })
      .addCase(postCandidateMeetingReview.rejected, (state) => {
        state.isMeetingReviewLoading = false;
      })
      // Associations
      .addCase(associateCandidateWithJobOpening.pending, (state) => {
        state.associationAPIStatus = ApiStatus.LOADING;
        state.associationErrorMessage = null;
      })
      .addCase(associateCandidateWithJobOpening.fulfilled, (state) => {
        state.associationAPIStatus = ApiStatus.COMPLETE;

        state.suitablePositionsAPIStatus =
          initialState.suitablePositionsAPIStatus;
        state.suitablePositionsData = initialState.suitablePositionsData;
      })
      .addCase(associateCandidateWithJobOpening.rejected, (state, action) => {
        state.associationAPIStatus = ApiStatus.FAILED;
        state.associationErrorMessage = action.payload as string;
      })
      .addCase(unAssociateCandidateWithJobOpening.pending, (state) => {
        state.associationAPIStatus = ApiStatus.LOADING;
        state.associationErrorMessage = null;
      })
      .addCase(
        unAssociateCandidateWithJobOpening.fulfilled,
        (state, action) => {
          state.associationAPIStatus = ApiStatus.COMPLETE;
          state.suitablePositionsData = state.suitablePositionsData.map(
            (position) => ({
              ...position,
              Is_Associated:
                position.id === action.meta.arg.jobOpeningId
                  ? false
                  : position.Is_Associated,
              Association_Date:
                position.id === action.meta.arg.jobOpeningId
                  ? null
                  : position.Association_Date,
            }),
          );
        },
      )
      .addCase(unAssociateCandidateWithJobOpening.rejected, (state, action) => {
        state.associationAPIStatus = ApiStatus.FAILED;
        state.associationErrorMessage = action.payload as string;
      })
      // Candidate status
      .addCase(changeCandidateStatus.pending, (state) => {
        state.updateStatusAPIStatus = ApiStatus.LOADING;
        state.updateStatusErrorMessage = null;
      })
      .addCase(changeCandidateStatus.fulfilled, (state) => {
        state.updateStatusAPIStatus = ApiStatus.COMPLETE;

        state.apiStatus = initialState.apiStatus;
        state.data = initialState.data;

        state.suitablePositionsAPIStatus =
          initialState.suitablePositionsAPIStatus;
        state.suitablePositionsData = initialState.suitablePositionsData;

        state.processActivityAPIStatus = initialState.processActivityAPIStatus;
        state.processActivity = initialState.processActivity;

        state.meetingsAPIStatus = initialState.meetingsAPIStatus;
        state.meetings = initialState.meetings;

        state.testTasksAPIStatus = initialState.testTasksAPIStatus;
        state.testTasksData = initialState.testTasksData;
      })
      .addCase(changeCandidateStatus.rejected, (state, action) => {
        state.updateStatusAPIStatus = ApiStatus.FAILED;
        state.updateStatusErrorMessage = action.payload as string;
      })
      // Candidate process activity
      .addCase(fetchCandidateProcessActivity.pending, (state) => {
        state.processActivityAPIStatus = ApiStatus.LOADING;
        state.processActivity = [];
      })
      .addCase(fetchCandidateProcessActivity.fulfilled, (state, action) => {
        state.processActivityAPIStatus = ApiStatus.COMPLETE;
        state.processActivity = action.payload;
      })
      .addCase(
        fetchCandidateProcessActivity.rejected,
        (state, action: PayloadAction<any>) => {
          state.processActivityAPIStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Meetings
      .addCase(fetchCandidateMeetings.pending, (state) => {
        state.meetingsAPIStatus = ApiStatus.LOADING;
        state.meetings = [];
      })
      .addCase(fetchCandidateMeetings.fulfilled, (state, action) => {
        state.meetingsAPIStatus = ApiStatus.COMPLETE;
        state.meetings = action.payload;
      })
      .addCase(
        fetchCandidateMeetings.rejected,
        (state, action: PayloadAction<any>) => {
          state.meetingsAPIStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Get available video interview
      .addCase(fetchAvailableVideoInterview.pending, (state) => {
        state.availableVideoInterviewAPIStatus = ApiStatus.LOADING;
      })
      .addCase(fetchAvailableVideoInterview.fulfilled, (state, action) => {
        state.availableVideoInterviewAPIStatus = ApiStatus.COMPLETE;
        state.availableVideoInterview = action.payload;
      })
      .addCase(fetchAvailableVideoInterview.rejected, (state) => {
        state.availableVideoInterviewAPIStatus = ApiStatus.FAILED;
      })
      // reset video interview
      .addCase(postCandidateResetVideoInterview.pending, (state) => {
        state.resetVideoInterviewAPIStatus = ApiStatus.LOADING;
      })
      .addCase(postCandidateResetVideoInterview.fulfilled, (state) => {
        state.resetVideoInterviewAPIStatus = ApiStatus.COMPLETE;

        state.videoInterviewAPIStatus = initialState.videoInterviewAPIStatus;
        state.videoInterviewData = initialState.videoInterviewData;
      })
      .addCase(postCandidateResetVideoInterview.rejected, (state) => {
        state.resetVideoInterviewAPIStatus = ApiStatus.FAILED;
      })
      // send video interview
      .addCase(postCandidateSendVideoInterview.pending, (state) => {
        state.sendVideoInterviewAPIStatus = ApiStatus.LOADING;
      })
      .addCase(postCandidateSendVideoInterview.fulfilled, (state) => {
        state.sendVideoInterviewAPIStatus = ApiStatus.COMPLETE;

        state.videoInterviewAPIStatus = initialState.videoInterviewAPIStatus;
        state.videoInterviewData = initialState.videoInterviewData;
      })
      .addCase(postCandidateSendVideoInterview.rejected, (state) => {
        state.sendVideoInterviewAPIStatus = ApiStatus.FAILED;
      })
      // Update Candidate Profile
      .addCase(updateCandidateProfile.pending, (state) => {
        state.isProfileUpdating = true;
      })
      .addCase(updateCandidateProfile.fulfilled, (state) => {
        state.isProfileUpdating = false;

        state.apiStatus = initialState.apiStatus;
        state.data = initialState.data;
      })
      .addCase(updateCandidateProfile.rejected, (state) => {
        state.isProfileUpdating = false;
      })
      // Client video interview link
      .addCase(generateClientVideoLink.pending, (state) => {
        state.generateClientVideoLinkAPIStatus = ApiStatus.LOADING;
      })
      .addCase(generateClientVideoLink.fulfilled, (state) => {
        state.generateClientVideoLinkAPIStatus = ApiStatus.COMPLETE;

        state.videoInterviewAPIStatus = initialState.videoInterviewAPIStatus;
        state.videoInterviewData = initialState.videoInterviewData;
      })
      .addCase(generateClientVideoLink.rejected, (state) => {
        state.generateClientVideoLinkAPIStatus = ApiStatus.FAILED;
      })
      // candidate submission
      .addCase(fetchCandidateSubmissions.pending, (state) => {
        state.submissionsAPIStatus = ApiStatus.LOADING;
        state.submissions = [];
      })
      .addCase(fetchCandidateSubmissions.fulfilled, (state, action) => {
        state.submissionsAPIStatus = ApiStatus.COMPLETE;
        state.submissions = action.payload;
      })
      .addCase(
        fetchCandidateSubmissions.rejected,
        (state, action: PayloadAction<any>) => {
          state.submissionsAPIStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // hot pipeline email invite
      .addCase(sendInviteCandidateInHotpipeline.pending, (state) => {
        state.hotPipelineCandidateInviteAPIStatus = ApiStatus.LOADING;
      })
      .addCase(sendInviteCandidateInHotpipeline.fulfilled, (state) => {
        state.hotPipelineCandidateInviteAPIStatus = ApiStatus.COMPLETE;
      })
      .addCase(sendInviteCandidateInHotpipeline.rejected, (state) => {
        state.hotPipelineCandidateInviteAPIStatus = ApiStatus.FAILED;
      })
      // Create Candidate Profile
      .addCase(createCandidate.pending, (state) => {
        state.createCandidateAPIStatus = ApiStatus.LOADING;
      })
      .addCase(createCandidate.fulfilled, (state) => {
        state.createCandidateAPIStatus = ApiStatus.COMPLETE;
      })
      .addCase(createCandidate.rejected, (state) => {
        state.createCandidateAPIStatus = ApiStatus.FAILED;
      })
      // Get Duplicate Candidates
      .addCase(fetchCandidateDuplicates.pending, (state) => {
        state.duplicateCandidatesAPIStatus = ApiStatus.LOADING;
      })
      .addCase(fetchCandidateDuplicates.fulfilled, (state, action) => {
        state.duplicateCandidatesAPIStatus = ApiStatus.COMPLETE;
        state.duplicateCandidates = action.payload || null;
      })
      .addCase(fetchCandidateDuplicates.rejected, (state) => {
        state.duplicateCandidatesAPIStatus = ApiStatus.FAILED;
      })
      // Send LP Invitation
      .addCase(sendLPInvitation.pending, (state) => {
        state.sendLPInvitationAPIStatus = ApiStatus.LOADING;
      })
      .addCase(sendLPInvitation.fulfilled, (state) => {
        state.sendLPInvitationAPIStatus = ApiStatus.COMPLETE;

        state.apiStatus = initialState.apiStatus;
        state.data = initialState.data;
      })
      .addCase(sendLPInvitation.rejected, (state) => {
        state.sendLPInvitationAPIStatus = ApiStatus.FAILED;
      });
  },
});

export const {
  clearCandidateDetails,
  setCandidateId,
  clearCandidateDuplicates,
} = candidateDetailsSlice.actions;
export const candidateDetailsSliceReducer = candidateDetailsSlice.reducer;
