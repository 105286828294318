import { CandidateStatus } from './candidateStatus';

export enum UserVideoInterviewStatus {
  TODO = 'ToDo',
  STARTED = 'Started',
  SKIPPED = 'Skipped',
  FINISHED = 'Finished',
}

export enum ReviewLevels {
  POOR = 'poor',
  GOOD = 'good',
  GREAT = 'great',
}

export enum VIResolution {
  PASSED = 'passed',
  FAILED_SOFT_SKILLS = 'failed: soft skills',
  FAILED_WRONG_ANSWER = 'failed: wrong answer',
  FAILED_INCOMPLETE = 'failed: inclomplete',
  FAILED_LOW_ENGLISH = 'failed: low english',
}

export const CandidateStatusByVIResolution: Partial<
  Record<VIResolution, CandidateStatus>
> = {
  [VIResolution.FAILED_SOFT_SKILLS]: CandidateStatus.VI_Bad_Soft_Skills,
  [VIResolution.FAILED_WRONG_ANSWER]: CandidateStatus.VI_Wrong_Answers,
  [VIResolution.FAILED_INCOMPLETE]: CandidateStatus.VI_Incomplete,
  [VIResolution.FAILED_LOW_ENGLISH]: CandidateStatus.VI_Low_English,
};

export const LevelsReviewOptions = [
  {
    label: '🚷 Poor',
    value: 'poor',
  },
  {
    label: '👌 Good',
    value: 'good',
  },
  {
    label: '✅ Great',
    value: 'great',
  },
];

export const VIResolutionOptionsList = [
  {
    label: '✅ Passed',
    value: VIResolution.PASSED,
  },
  {
    label: '❌ Failed: Soft skills',
    value: VIResolution.FAILED_SOFT_SKILLS,
  },
  {
    label: '❌ Failed: Wrong answers',
    value: VIResolution.FAILED_WRONG_ANSWER,
  },
  {
    label: '❌ Failed: Incomplete',
    value: VIResolution.FAILED_INCOMPLETE,
  },
  {
    label: '❌ Failed: Low English',
    value: VIResolution.FAILED_LOW_ENGLISH,
  },
];
