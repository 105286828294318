/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';

import { useNotFoundStyles } from './NotFound.styles';
import { useTitle } from '@hooks/useTitle';
import { useEffect } from 'react';
import { PageTitle } from 'constants/pageTitle';

export const NotFoundPage: React.FC = () => {
  const styles = useNotFoundStyles();

  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.NOT_FOUND);
  }, [setPageTitle]);

  return (
    <div className="not-found-wrapper" css={styles}>
      <div className="nf-container">
        <Typography typography="h1" p={2} className="nf-status">
          404
        </Typography>
        <Typography typography="h2" p={1}>
          Page Not Found
        </Typography>
        <div className="nf-link">Go back to the previous page</div>
      </div>
    </div>
  );
};
