import axios from 'axios';
import { isNumber } from 'lodash';

import { apiEndpoints } from '@constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { candidatesStoreKey } from './candidates.const';
import { IDWHCandidateRaw, ICandidatesOptions } from '@types';
import { transformCandidate } from '@utils';

export const fetchCandidates = createAsyncThunk(
  `${candidatesStoreKey}/fetchCandidates`,
  async (
    {
      search,
      sortBy,
      order,
      offset,
      limit,
      stage,
      country,
      seniority,
      payRate,
      recruiter,
      addedBy,
      techFlow,
      state,
      typeOfOutboundChannel,
    }: Partial<ICandidatesOptions>,
    { rejectWithValue },
  ) => {
    try {
      const queryParams: Partial<Record<keyof ICandidatesOptions, string>> = {};

      search && (queryParams.search = search);
      sortBy && (queryParams.sortBy = sortBy);
      order && (queryParams.order = order);
      isNumber(offset) && (queryParams.offset = offset.toString());
      isNumber(limit) && (queryParams.limit = limit.toString());
      stage && (queryParams.stage = stage);
      typeOfOutboundChannel &&
        (queryParams.typeOfOutboundChannel = typeOfOutboundChannel);
      country?.length && (queryParams.country = country.join(','));
      seniority?.length && (queryParams.seniority = seniority.join(','));
      payRate && (queryParams.payRate = payRate.join(','));
      recruiter?.length && (queryParams.recruiter = recruiter.join(','));
      addedBy?.length && (queryParams.addedBy = addedBy.join(','));
      techFlow?.length && (queryParams.techFlow = techFlow.join(','));
      state && (queryParams.state = state);

      const response = await axios.get<{
        data: IDWHCandidateRaw[];
        totalCount: number;
      }>(apiEndpoints.candidates(), {
        params: queryParams,
      });

      return {
        ...response.data,
        data: response.data.data.map(transformCandidate),
      };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
