const optionsApi = '/options';

// Candidate
export const candidateFieldsOptionsAPI = () =>
  `${optionsApi}/candidates/fields`;

export const candidateResponsibleRecruitersAPI = () =>
  `${optionsApi}/candidates/responsible-recruiters`;

export const candidateAddedByOptionsAPI = () =>
  `${optionsApi}/candidates/added-by`;

// Job Opening
export const jobOpeningsOptionsAPI = () => `${optionsApi}/job-openings`;
export const closedJobOpeningsOptionsAPI = () =>
  `${optionsApi}/job-openings/closed`;

export const jobOpeningFieldsOptionsAPI = () =>
  `${optionsApi}/job-openings/fields`;

export const getJobOpeningCountriesAPI = () =>
  `${optionsApi}/job-openings/locations/countries`;

export const getJobOpeningSpecializationsAPI = () =>
  `${optionsApi}/job-openings/specializations`;

export const getJobOpeningClientsAPI = () =>
  `${optionsApi}/job-openings/clients`;

export const getJobOpeningSubteamsAPI = () =>
  `${optionsApi}/job-openings/subteams`;

// Test Task
export const lpTestTasksAPI = () => `${optionsApi}/test-tasks`;

// Clients
export const submissionClientsAPI = () => `${optionsApi}/submission-clients`;

// Launchpod
export const launchpodSpecializationsAPIPath = () =>
  `${optionsApi}/launchpod-specializations`;
