export const getCountryCode = (icon: string) => {
  switch (icon) {
    case 'Argentina':
      return 'ar';
    case 'Brazil':
      return 'br';
    case 'Colombia':
      return 'co';
    case 'Guatemala':
      return 'gt';
    case 'Poland':
      return 'pl';
    case 'India':
      return 'in';
    case 'Mexico':
      return 'mx';
    case 'Ukraine':
      return 'ua';
    case 'USA':
      return 'us';
    case 'United States':
      return 'us';
    case 'Portugal':
      return 'pt';
    case 'Europe':
      return 'eu';
    case 'Peru':
      return 'pe';
    case 'Costa Rica':
      return 'cr';
    case 'Venezuela':
      return 've';
    case 'Spain':
      return 'es';
    case 'Slovakia':
      return 'sk';
    case 'Romania':
      return 'ro';
    case 'Slovenia':
      return 'si';
    case 'Bulgaria':
      return 'bg';
    case 'Kenya':
    case 'Kenia':
      return 'ke';
    case 'Egypt':
      return 'eg';
    case 'Armenia':
      return 'am';
    case 'Georgia':
      return 'ge';
    case 'Kazakhstan':
      return 'kz';
    case 'Uzbekistan':
      return 'uz';
    default:
      return null;
  }
};
