import { ReactNode } from 'react';

import { Box } from '@mui/material';
import { EntityFormPageHeader } from './components/Header';

export const EntityFormPageLayout: React.FC<{
  headerOptions?: {
    title: string;
    onCopyFromAnotherBtnClick?: () => void;
    onCancelClick: () => void;
  };
  children: ReactNode;
}> = ({ headerOptions, children }) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.backgroundPrimary,
        minHeight: '100vh',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <EntityFormPageHeader headerOptions={headerOptions} />
      <Box component={'main'} sx={{ flex: '1 1 auto' }}>
        {children}
      </Box>
    </Box>
  );
};
