import { IZohoCandidate } from '@types';

export const getUserCompensationInfo = (
  candidateData: IZohoCandidate | null,
) => {
  return {
    compensation: candidateData?.Hourly_Rate_Expected ?? 0,
    compensationType: 'hourly',
  };
};
