import { EmployeeAutocomplete } from '@components';
import { IEmployee } from '@types';

export const PositionHiringManagerField: React.FC<{
  value: string | null;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: IEmployee | null) => void;
  onBlur?: () => void;
}> = ({ value, name, error, touched, required, onChange, onBlur }) => {
  return (
    <EmployeeAutocomplete
      label="Hiring Manager"
      name={name || 'Hiring_Manager_email'}
      value={value}
      required={required}
      onChange={onChange}
      onBlur={onBlur}
      fullWidth
      error={touched && !!error}
      helperText={touched ? error : undefined}
    />
  );
};
