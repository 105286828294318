import { Alert, Box, TextField } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Flex } from '@components';

import { CandidateLPInvitationFormik } from '@types';

export const EmailDetailsSection: React.FC<{
  formik: CandidateLPInvitationFormik;
}> = ({ formik }) => {
  const emailError =
    formik.errors.email && (formik.touched.email || formik.submitCount)
      ? formik.errors.email
      : null;
  const emailSubjectError =
    formik.errors.emailSubject &&
    (formik.touched.emailSubject || formik.submitCount)
      ? formik.errors.emailSubject
      : null;
  const emailMessageError =
    formik.errors.emailMessage &&
    (formik.touched.emailMessage || formik.submitCount)
      ? formik.errors.emailMessage
      : null;

  return (
    <Box px={3} py={2}>
      <Flex flexDirection="column" gap={3}>
        <Alert
          variant="standard"
          severity="info"
          icon={<MailOutlineIcon />}
          sx={{ width: '100%' }}
        >
          Candidate will receive one time invitation email. Feel free to adjust
          the message according to the case:
        </Alert>
        <TextField
          name="email"
          label="Candidate’s email"
          placeholder="Add email to send the invitation"
          variant="outlined"
          fullWidth
          value={formik.values.email || ''}
          required
          onChange={(e) =>
            formik.setFieldValue('email', e.target.value || null)
          }
          onBlur={formik.handleBlur}
          helperText={emailError || undefined}
          error={!!emailError}
        />
        <TextField
          name="emailSubject"
          label="Email subject"
          variant="outlined"
          fullWidth
          value={formik.values.emailSubject || ''}
          onChange={(e) =>
            formik.setFieldValue('emailSubject', e.target.value || null)
          }
          onBlur={formik.handleBlur}
          helperText={emailSubjectError || undefined}
          error={!!emailSubjectError}
        />
        <TextField
          name="emailMessage"
          label="Email message"
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          value={formik.values.emailMessage || ''}
          onChange={(e) =>
            formik.setFieldValue('emailMessage', e.target.value || null)
          }
          onBlur={formik.handleBlur}
          helperText={
            emailMessageError ||
            '“Continue” magic link to Launchpod will be attached after this text'
          }
          error={!!emailMessageError}
        />
      </Flex>
    </Box>
  );
};
