const jobOpeningsApi = '/job-openings';

export const jobOpenings = () => `${jobOpeningsApi}`;
export const jobOpeningById = (id: string) => `${jobOpeningsApi}/${id}`;
export const jobOpeningCandidates = (id: string) =>
  `${jobOpeningById(id)}/candidates`;
export const closeJobOpeningPath = (id: string) =>
  `${jobOpeningsApi}/${id}/close`;
export const putOhHoldJobOpeningPath = (id: string) =>
  `${jobOpeningsApi}/${id}/on-hold`;
export const reopenJobOpeningPath = (id: string) =>
  `${jobOpeningsApi}/${id}/reopen`;
