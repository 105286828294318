import React from 'react';

import { Box, Typography } from '@mui/material';

import { Person } from '@types';

export const PersonSignature: React.FC<{ person: Person }> = ({ person }) => {
  return (
    <Box>
      <Typography variant="body1" color="text.primary">
        {person.name}
      </Typography>
      {!!person.role && (
        <Typography variant="body1" color="text.secondary">
          {`${person.role}${person.company ? `, ${person.company}` : ''}`}
        </Typography>
      )}
      {!!person.email && (
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ textDecoration: 'underline' }}
        >
          {person.email}
        </Typography>
      )}
    </Box>
  );
};
