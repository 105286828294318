import { useCallback, useMemo } from 'react';
import { isArray } from 'lodash';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';
import { ContactDetailsSection } from './components/ContactDetailsSection';
import { ProfileDetailsSection } from './components/ProfileDetailsSection';
import { CommunicationDetailsSection } from './components/CommunicationDetailsSection';
import { ChannelDetailsSection } from './components/ChannelDetailsSection';

import {
  candidateDetailsSelectors,
  clearCandidateDuplicates,
  createCandidate,
  fetchCandidateDuplicates,
} from '@redux/candidateDetails';
import { authSelectors } from '@redux/auth';

import {
  checkUserCanCreateCandidate,
  getCreateCandidateFormInitialValues,
  isStatusLoading,
  transformCrateCandidateFormValuesToPayload,
} from '@utils';
import { CreateCandidateFormFields, IDWHCandidate } from '@types';

import { createCandidateValidationSchema } from './validationSchema';

export const CreateCandidateModal: React.FC<{
  isOpen: boolean;
  onCloseModalClick: () => void;
}> = ({ isOpen, onCloseModalClick }) => {
  const dispatch = useAppDispatch();

  const profile = useAppSelector(authSelectors.getProfileData);
  const createCandidateAPIStatus = useAppSelector(
    candidateDetailsSelectors.getCreateCandidateAPIStatus,
  );
  const { duplicateCandidates, duplicateCandidatesAPIStatus } = useAppSelector(
    candidateDetailsSelectors.getCandidateDuplicatesAPIData,
  );

  const initialValues = useMemo(
    () => getCreateCandidateFormInitialValues(profile),
    [],
  );

  const formik = useFormik<CreateCandidateFormFields>({
    initialValues,
    validationSchema: createCandidateValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = transformCrateCandidateFormValuesToPayload(values);

      const form = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (isArray(value)) {
          value.forEach((item) => form.append(`${key}[]`, item || ''));
        } else if (value) {
          form.append(key, value.toString() || '');
        }
      });

      if (values.resume) {
        form.append('resume', values.resume);
      }

      const res = await dispatch(
        createCandidate({
          data: form,
        }),
      );

      if (createCandidate.fulfilled.match(res)) {
        resetForm();
        dispatch(clearCandidateDuplicates());
        onCloseModalClick();
      }
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  const isCreateDisabled =
    !checkUserCanCreateCandidate(profile) ||
    isStatusLoading(createCandidateAPIStatus);

  const onCheckDuplicatesClick = useCallback(
    (value: { field: keyof IDWHCandidate; value: string }) => {
      dispatch(fetchCandidateDuplicates(value));
    },
    [],
  );

  const closeModal = useCallback(() => {
    formik.resetForm();
    dispatch(clearCandidateDuplicates());
    onCloseModalClick();
  }, []);

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">Add Candidate</Typography>
          <CloseIcon
            onClick={closeModal}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Flex flexDirection="column" gap={3}>
          <ContactDetailsSection
            formik={formik}
            duplicates={duplicateCandidates}
            isLoadingDuplicates={isStatusLoading(duplicateCandidatesAPIStatus)}
            onCheckDuplicatesClick={onCheckDuplicatesClick}
          />
          <ProfileDetailsSection formik={formik} />
          <CommunicationDetailsSection formik={formik} />
          <ChannelDetailsSection formik={formik} />
        </Flex>
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={isCreateDisabled}
          >
            Add candidate
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
