import React from 'react';
import { Typography, Box } from '@mui/material';
import { AppRoutes } from '@constants';

export const JobOpeningLink: React.FC<{
  jobOpeningId: string;
  jobId: string;
}> = React.memo(({ jobOpeningId, jobId }) => {
  return (
    <Typography
      component="a"
      href={`/${AppRoutes.JOB_OPENINGS}/${jobId}`}
      color="primary"
      sx={{
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
      }}
    >
      <Box component="span" color="text.secondary">
        #{jobOpeningId}
      </Box>{' '}
    </Typography>
  );
});
