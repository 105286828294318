import { useCallback, useState } from 'react';

import { TextButton } from '@components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const ShowMoreButton: React.FC<{
  onClick: (isExpanded: boolean) => void;
}> = ({ onClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onBtnClick = useCallback(() => {
    setIsExpanded(!isExpanded);
    onClick(!isExpanded);
  }, [isExpanded]);

  return (
    <TextButton
      variant="secondary"
      startIcon={
        <ArrowDownwardIcon
          sx={{
            transform: isExpanded ? 'rotate(180deg)' : 'none',
            transition: '0.25s transform ease-in-out',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        />
      }
      onClick={onBtnClick}
      sx={{ fontSize: '13px', fontWeight: '500' }}
    >
      Show {isExpanded ? 'less' : 'more'}
    </TextButton>
  );
};
