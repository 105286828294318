import { useCallback } from 'react';

import { EmployeeAutocomplete } from '@components';

import { IEmployee, Person } from '@types';
import { Box } from '@mui/material';

export const ReviewerSelectField: React.FC<{
  name?: string;
  label?: string;
  required?: boolean;
  value: string;
  onChange: (person: Person | null) => void;
}> = ({ name, label, required, value, onChange }) => {
  const onSelectReviewer = useCallback((selectedEmployee: IEmployee | null) => {
    onChange(
      selectedEmployee && {
        name: selectedEmployee.fullName,
        role: selectedEmployee.role,
        email: selectedEmployee.email_id,
      },
    );
  }, []);

  return (
    <Box sx={{ background: '#FFF' }} width="100%">
      <EmployeeAutocomplete
        label={label || 'Reviewer'}
        name={name}
        value={value}
        required={required}
        onChange={(val) => onSelectReviewer(val || null)}
        fullWidth
      />
    </Box>
  );
};
