import React, { useCallback, useEffect, useState } from 'react';

import {
  Hidden,
  IconButton,
  InputAdornment,
  InputBase,
  Popover,
  PopoverProps,
  styled,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import { SearchInputMode } from '@constants';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '0.25rem',
  outlineWidth: '1px',
  outlineStyle: 'solid',
  outlineColor: '#00000023',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  '&:focus-within': {
    outlineColor: '#3F8CFF',
    outlineWidth: '2px',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: '0 0.75rem',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.grey,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: '0.5rem 0.75rem',
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('all'),
    width: '100%',
  },
}));

const StyledPopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    {...props}
  />
))({
  '& .MuiPaper-root': {
    padding: '2px',
    boxShadow: 'none',
    top: '12px !important',
  },
});

export const SearchInput: React.FC<{
  isLoading: boolean;
  placeholder: string;
  value?: string | null;
  mode: SearchInputMode;
  onChange: (value: string | null) => void;
}> = ({ isLoading, placeholder, value, mode, onChange }) => {
  const anchorEl = React.useRef<HTMLButtonElement | null>(null);

  const [search, setSearch] = useState('');
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      setSearch(value);
    }
  }, [value]);

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [],
  );

  const onSearchErase = () => {
    setSearch('');
    onChange(null);
  };

  const onSearchClick = () => {
    onChange(search || null);
  };

  const handlePopoverOpen = useCallback(() => {
    setOpenPopover(true);
  }, [setOpenPopover]);

  const handlePopoverClose = useCallback(() => {
    setOpenPopover(false);
  }, [setOpenPopover]);

  const Input = () => (
    <Search
      sx={{
        width: openPopover ? 'calc(100vw - 2.25rem)' : '100%',
      }}
    >
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        autoComplete="off"
        id="search"
        name="search"
        fullWidth
        autoFocus={openPopover}
        placeholder={placeholder}
        value={search}
        onChange={onSearchChange}
        disabled={isLoading}
        onKeyUp={(e: React.KeyboardEvent<any>) => {
          if (e.code === 'Enter' && !isLoading) {
            onSearchClick();
          }
        }}
        sx={{
          '& .MuiInputBase-input': {
            ...(openPopover
              ? {
                  paddingTop: '0.625rem',
                  paddingBottom: '0.625rem',
                }
              : {}),
          },
        }}
        endAdornment={
          <React.Fragment>
            {search && value !== search && (
              <InputAdornment position="end">
                <Tooltip title="Search">
                  <IconButton disabled={isLoading} onClick={onSearchClick}>
                    <ArrowForwardRoundedIcon fontSize="small" color="info" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )}
            {search && value === search && (
              <InputAdornment position="end">
                <Tooltip title="Clear">
                  <IconButton disabled={isLoading} onClick={onSearchErase}>
                    <ClearRoundedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )}
          </React.Fragment>
        }
      />
    </Search>
  );

  if (mode === SearchInputMode.FULL) return Input();

  return (
    <React.Fragment>
      <Hidden lgDown>{Input()}</Hidden>
      <Hidden lgUp>
        <IconButton
          ref={anchorEl}
          sx={(theme) => ({
            color: theme.palette.text.grey,
            padding: '0.625rem',
          })}
          onClick={handlePopoverOpen}
        >
          <SearchIcon />
        </IconButton>
      </Hidden>
      <StyledPopover
        open={openPopover}
        anchorEl={anchorEl.current}
        onClose={handlePopoverClose}
        PaperProps={{
          onMouseEnter: () => handlePopoverOpen(),
          onMouseLeave: () => handlePopoverClose(),
        }}
      >
        {Input()}
      </StyledPopover>
    </React.Fragment>
  );
};
