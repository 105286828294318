import React from 'react';

import { Typography, Popover, Stack, Box, SxProps } from '@mui/material';
import { ScoreItem } from './components/ScoreItem';
import { ScoreValue } from './components/ScoreValue';

import {
  getScoreHeaderText,
  getTotalScoreBgColor,
  getTotalScoreColor,
} from '@utils';
import { ICandidateScore } from '@types';

type CandidateScoreSize = 'small' | 'medium' | 'large';

interface ICandidateScoreProps {
  score: ICandidateScore;
  size?: CandidateScoreSize;
}

const CandidateScoreSizeCss: {
  [x in CandidateScoreSize]: SxProps;
} = {
  small: {
    width: '1rem',
    height: '1rem',
    fontSize: '0.625rem',
  },
  medium: {
    width: '1.25rem',
    height: '1.25rem',
    fontSize: '0.625rem',
  },
  large: {},
};

export const CandidateScore: React.FC<ICandidateScoreProps> = ({
  score,
  size = 'large',
}: ICandidateScoreProps) => {
  const anchorEl = React.useRef<HTMLElement | null>(null);

  const [openPopover, setOpenPopover] = React.useState<boolean>(false);

  const handlePopoverOpen = () => {
    setOpenPopover(true);
  };

  const handlePopoverClose = () => {
    setOpenPopover(false);
  };

  const scoresList = score.list.map(({ points, type, value }) => {
    return <ScoreItem score={points} type={type} value={value} key={type} />;
  });

  const scoreColor = getTotalScoreColor(score);
  const scoreBgColor = getTotalScoreBgColor(score);

  return (
    <>
      <Box
        ref={anchorEl}
        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ScoreValue
          score={score.total}
          bgColor={scoreBgColor}
          color={scoreColor}
          sx={{ ...CandidateScoreSizeCss[size] }}
        />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={openPopover}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: () => handlePopoverOpen(),
          onMouseLeave: () => handlePopoverClose(),
          sx: {
            pointerEvents: 'auto',
            borderRadius: '0.5rem',
            width: '15.625rem',
          },
          elevation: 4,
        }}
      >
        <Box
          padding="1rem 1.5rem"
          bgcolor={getTotalScoreBgColor(score)}
          color={getTotalScoreColor(score)}
        >
          <Typography variant="body2">{getScoreHeaderText(score)}</Typography>
        </Box>
        <Stack direction="column" gap="1.5rem" padding="1rem 1.5rem">
          <Box>
            <Typography variant="body2" color="secondary.main">
              AE score
            </Typography>
            <Typography fontSize="1.25rem" fontWeight={500}>
              {score.total} points
            </Typography>
          </Box>
          <Stack direction="column" gap="0.75rem">
            {scoresList}
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
