import React from 'react';

import { AppBar, Button, Stack, Toolbar, Typography } from '@mui/material';

export const EntityFormPageHeader: React.FC<{
  headerOptions?: {
    title: string;
    onCopyFromAnotherBtnClick?: () => void;
    onCancelClick: () => void;
  };
}> = ({ headerOptions }) => {
  return (
    <AppBar
      position="static"
      sx={(theme) => ({
        boxShadow: 'none',
        backgroundColor: theme.palette.background.backgroundPrimary,
      })}
    >
      <Toolbar
        sx={{
          padding: {
            xs: '0.75rem 1rem',
          },
          justifyContent: 'space-between',
          gap: '0.5rem',
        }}
      >
        <Stack flex="0 1 auto">
          <Typography variant="body2" color="text.secondary">
            LaunchPod Hiring Dashboard
          </Typography>
          <Typography variant="h2" color="text.primary">
            {headerOptions?.title}
          </Typography>
        </Stack>

        <Stack flexDirection="row" gap={1}>
          {!!headerOptions?.onCopyFromAnotherBtnClick && (
            <Button
              variant="contained"
              size="medium"
              onClick={headerOptions?.onCopyFromAnotherBtnClick}
            >
              Copy from another
            </Button>
          )}
          {!!headerOptions?.onCancelClick && (
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={headerOptions?.onCancelClick}
            >
              Cancel
            </Button>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
