import { useMemo } from 'react';
import { useAppDispatch } from '@redux/hooks';
import { useFormik } from 'formik';
import { get, isArray, set } from 'lodash';

import { Flex } from '@components';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EditCandidateProfileFields } from './EditCandidateProfileFields';

import { CandidateProfileEditFormValues, IZohoCandidate } from '@types';
import {
  getInitialCandidateProfileEditFormValues,
  transformCandidateProfileEditFormValuesToPayload,
} from '@utils';
import { updateCandidateProfile } from '@redux/candidateDetails';

import { editCandidateProfileValidationSchema } from './validationSchema';

export const EditCandidateProfileModal: React.FC<{
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  isLoading: boolean;
  candidateDetails: IZohoCandidate;
}> = ({ isModalOpen, setIsModalOpen, isLoading, candidateDetails }) => {
  const dispatch = useAppDispatch();

  const initialValues = useMemo(
    () => getInitialCandidateProfileEditFormValues(candidateDetails),
    [candidateDetails],
  );
  const formik = useFormik<CandidateProfileEditFormValues>({
    initialValues,
    validationSchema: editCandidateProfileValidationSchema,
    onSubmit: async (values) => {
      const resultObject: Partial<CandidateProfileEditFormValues> = {};

      Object.entries(initialValues)?.map((entry) => {
        const [key, oldVal] = entry;
        const newVal = get(values, key);

        newVal !== oldVal && set(resultObject, key, newVal);
      });

      const payload = transformCandidateProfileEditFormValuesToPayload(
        values,
        resultObject,
      );

      const form = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (isArray(value)) {
          value.forEach((item) => form.append(`${key}[]`, item || ''));
        } else {
          form.append(key, value?.toString() || '');
        }
      });

      if (values.resume) {
        form.append('resume', values.resume);
      } else if (!values.resumeFileName && initialValues.resumeFileName) {
        form.append('LP_CV_link', '');
      }

      dispatch(
        updateCandidateProfile({
          candidateId: candidateDetails.id,
          data: form,
        }),
      );
    },
    validateOnMount: true,
    enableReinitialize: false,
  });

  const isUpdateDisabled = !formik.isValid || !formik.dirty || isLoading;

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => !isLoading && setIsModalOpen(false)}
      scroll="body"
      fullWidth
    >
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h1">Edit Profile</Typography>
          <CloseIcon
            onClick={() => !isLoading && setIsModalOpen(false)}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <EditCandidateProfileFields formik={formik} />
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsModalOpen(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={isUpdateDisabled}
          >
            {isLoading && (
              <CircularProgress
                size={16}
                color="secondary"
                sx={{ marginRight: 1 }}
              />
            )}
            Update
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
