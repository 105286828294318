import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import {
  closeJobOpening,
  createNewOpenPosition,
  editOpenPosition,
  fetchJobOpeningById,
  putOnHoldJobOpening,
  reopenJobOpening,
} from './jobOpeningDetails.thunks';
import { jobOpeningDetailsStoreKey } from './jobOpeningDetails.const';
import { IJobOpeningDetailsSliceState } from './jobOpeningDetails.types';
import { IJobOpeningDetails } from '@types';
import { fetchJobOpeningCandidates } from '@redux/jobOpeningDetails';
import { ApiStatus } from '@constants';

const initialState: IJobOpeningDetailsSliceState = {
  apiStatus: ApiStatus.IDLE,
  detailsData: null,
  candidatesData: {
    apiStatus: ApiStatus.IDLE,
    data: [],
  },
  id: null,
  isPerformingAction: false,
};

export const jobOpeningDetailsSlice = createSlice({
  name: jobOpeningDetailsStoreKey,
  initialState,
  reducers: {
    setJobOpeningId: (state, action: PayloadAction<string | null>) => {
      state.id = action.payload;
    },
    clearJobOpeningDetails: (state) => {
      state.apiStatus = initialState.apiStatus;
      state.detailsData = initialState.detailsData;
      state.candidatesData = initialState.candidatesData;
      state.id = initialState.id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchJobOpeningById.fulfilled,
        (state, action: PayloadAction<IJobOpeningDetails>) => {
          state.detailsData = action.payload;
          state.apiStatus = ApiStatus.COMPLETE;
        },
      )
      .addCase(fetchJobOpeningById.pending, (state) => {
        state.apiStatus = ApiStatus.LOADING;
      })
      .addCase(
        fetchJobOpeningById.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      .addCase(fetchJobOpeningCandidates.pending, (state) => {
        state.candidatesData.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchJobOpeningCandidates.fulfilled, (state, action) => {
        state.candidatesData = {
          data: action.payload,
          apiStatus: ApiStatus.COMPLETE,
        };
      })
      .addCase(
        fetchJobOpeningCandidates.rejected,
        (state, action: PayloadAction<any>) => {
          state.candidatesData.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      .addCase(createNewOpenPosition.fulfilled, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(createNewOpenPosition.pending, (state) => {
        state.isPerformingAction = true;
      })
      .addCase(createNewOpenPosition.rejected, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(editOpenPosition.fulfilled, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(editOpenPosition.pending, (state) => {
        state.isPerformingAction = true;
      })
      .addCase(editOpenPosition.rejected, (state) => {
        state.isPerformingAction = false;
      })
      .addMatcher(
        isAnyOf(
          closeJobOpening.pending,
          putOnHoldJobOpening.pending,
          reopenJobOpening.pending,
        ),
        (state) => {
          state.isPerformingAction = true;
        },
      )
      .addMatcher(
        isAnyOf(
          closeJobOpening.fulfilled,
          putOnHoldJobOpening.fulfilled,
          reopenJobOpening.fulfilled,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      )
      .addMatcher(
        isAnyOf(
          closeJobOpening.rejected,
          putOnHoldJobOpening.rejected,
          reopenJobOpening.rejected,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      );
  },
});

export const { setJobOpeningId, clearJobOpeningDetails } =
  jobOpeningDetailsSlice.actions;
export const jobOpeningDetailsSliceReducer = jobOpeningDetailsSlice.reducer;
