import { Box, Typography } from '@mui/material';

import lockSrc from '@assets/lock.png';

export const ForbiddenView: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          margin: '1rem 1.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <img src={lockSrc} alt="lock" width="172px" height="172px" />
        <Typography variant="h2" m="0.5rem">
          You are not allowed to see this info
        </Typography>
        <Typography variant="body1" color="text.secondary" mb="2rem">
          Sorry, you probably don’t have such permissions
        </Typography>
      </Box>
    </Box>
  );
};
