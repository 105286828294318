import { ReactNode } from 'react';
import { Box, Paper, Typography } from '@mui/material';

export const CDCandidateDetailsSectionLayout: React.FC<{
  title: string;
  content: ReactNode;
  isHidden: boolean;
}> = ({ title, content, isHidden }) => (
  <Paper
    elevation={5}
    sx={{
      padding: '20px',
      marginX: 1,
      marginY: 0.5,
      marginTop: 1,
      marginBottom: 0,
      flexDirection: { xs: 'column', md: 'row ' },
      width: '100%',
    }}
    hidden={isHidden}
  >
    <Box>
      <Typography variant="h3" mt={2} mb={2}>
        {title}
      </Typography>
      {content}
    </Box>
  </Paper>
);
