import { useMemo } from 'react';
import { isString, mapValues, values } from 'lodash';

import { AutocompleteTextfield } from '@components';

import { GrouppedJobOpeningCLosingReasons, JobOpeningStatus } from '@constants';

export const PositionClosingReasonField: React.FC<{
  value: string | null;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({ value, name, error, touched, required, onChange, onBlur }) => {
  const closingReasonOptions = useMemo(
    () =>
      values(
        mapValues(GrouppedJobOpeningCLosingReasons, (closingReasons, groubBy) =>
          closingReasons.map((e) => ({
            label: e,
            groubBy: groubBy === JobOpeningStatus.Closed ? 'Closed' : groubBy,
          })),
        ),
      ).flat(),
    [],
  );

  return (
    <AutocompleteTextfield
      name={name || 'Reason_of_Closing'}
      label={'Reason of closing'}
      options={closingReasonOptions}
      groupBy={(option) => option.groubBy}
      getOptionLabel={(option) => (isString(option) ? option : option.label)}
      value={value}
      required={required}
      onChange={(_, val) => onChange(val.label)}
      onBlur={onBlur}
      fullWidth
      filterSelectedOptions
      error={touched && !!error}
      helperText={touched ? error : undefined}
    />
  );
};
