import { Box, Button, Paper, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import teamSrc from '@assets/team.png';

export const EmptyStateCandidates: React.FC<{
  hasFilters: boolean;
  isLoading: boolean;
  resetFilters: () => void;
}> = ({ hasFilters, isLoading, resetFilters }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        component={Paper}
        sx={{
          margin: '0 1.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <img
          src={teamSrc}
          alt="team"
          style={{ height: '172px', width: '172px' }}
        />
        <Typography variant="h2" m="0.5rem">
          {hasFilters
            ? 'There are no candidates matching this search'
            : 'There are no candidates here'}
        </Typography>
        {hasFilters && (
          <Button
            variant="contained"
            onClick={resetFilters}
            sx={{
              height: '2rem',
              backgroundColor: '#007CFF14',
              color: 'text.link',
              textTransform: 'none',
              marginRight: '10px',
              fontSize: 'subtitle2.fontSize',
              padding: '0.25rem 0.5rem',
              whiteSpace: 'nowrap',
            }}
            disabled={isLoading}
          >
            <RemoveCircleOutlineIcon sx={{ mr: '0.5rem', fontSize: '1rem' }} />
            Reset all
          </Button>
        )}
      </Box>
    </Box>
  );
};
