import { CandidateField, Flex, ModalSectionLayout } from '@components';

import { CreateCandidateFormik } from '@types';

export const ProfileDetailsSection: React.FC<{
  formik: CreateCandidateFormik;
}> = ({ formik }) => {
  return (
    <ModalSectionLayout title="Profile details">
      <Flex flexDirection="column" gap={1}>
        <CandidateField
          field="English_level"
          value={formik.values.English_level}
          touched={!!formik.touched.English_level || !!formik.submitCount}
          error={formik.errors.English_level}
          onChange={(value) => formik.setFieldValue('English_level', value)}
          onBlur={() => formik.setFieldTouched('English_level')}
        />
        <CandidateField
          field="Seniority_Level"
          value={formik.values.Seniority_Level}
          touched={!!formik.touched.Seniority_Level || !!formik.submitCount}
          error={formik.errors.Seniority_Level}
          onChange={(value) => formik.setFieldValue('Seniority_Level', value)}
          onBlur={() => formik.setFieldTouched('Seniority_Level')}
        />
        <CandidateField
          field="Dev_-_QA-AQA"
          value={formik.values['Dev_-_QA-AQA']}
          touched={!!formik.touched['Dev_-_QA-AQA'] || !!formik.submitCount}
          error={formik.errors.Seniority_Level}
          onChange={(value) => formik.setFieldValue('Dev_-_QA-AQA', value)}
          onBlur={() => formik.setFieldTouched('Dev_-_QA-AQA')}
        />
        <CandidateField
          field="Type_of_Developer"
          value={formik.values.Type_of_Developer}
          touched={!!formik.touched.Type_of_Developer || !!formik.submitCount}
          error={formik.errors.Type_of_Developer}
          onChange={(value) => formik.setFieldValue('Type_of_Developer', value)}
          onBlur={() => formik.setFieldTouched('Type_of_Developer')}
        />
        <CandidateField
          field="Technical_Flow"
          value={formik.values.Technical_Flow}
          touched={!!formik.touched.Technical_Flow || !!formik.submitCount}
          error={formik.errors.Technical_Flow}
          onChange={(value) => formik.setFieldValue('Technical_Flow', value)}
          onBlur={() => formik.setFieldTouched('Technical_Flow')}
        />
        <CandidateField
          field="Primary_Skill_Set"
          value={formik.values.Primary_Skill_Set}
          touched={!!formik.touched.Primary_Skill_Set || !!formik.submitCount}
          error={formik.errors.Primary_Skill_Set}
          onChange={(value) => formik.setFieldValue('Primary_Skill_Set', value)}
          onBlur={() => formik.setFieldTouched('Primary_Skill_Set')}
        />
        <CandidateField
          field="Secondary_Skill_Set"
          value={formik.values.Secondary_Skill_Set}
          touched={!!formik.touched.Secondary_Skill_Set || !!formik.submitCount}
          error={formik.errors.Secondary_Skill_Set}
          onChange={(value) =>
            formik.setFieldValue('Secondary_Skill_Set', value)
          }
          onBlur={() => formik.setFieldTouched('Secondary_Skill_Set')}
        />
        <CandidateField
          field="Location_Country"
          value={formik.values.Location_Country}
          touched={!!formik.touched.Location_Country || !!formik.submitCount}
          error={formik.errors.Location_Country}
          onChange={(value) => formik.setFieldValue('Location_Country', value)}
          onBlur={() => formik.setFieldTouched('Location_Country')}
        />
        <CandidateField
          field="Hourly_Rate_Expected"
          value={formik.values.Hourly_Rate_Expected}
          touched={
            !!formik.touched.Hourly_Rate_Expected || !!formik.submitCount
          }
          error={formik.errors.Hourly_Rate_Expected}
          onChange={(value) =>
            formik.setFieldValue('Hourly_Rate_Expected', value)
          }
          onBlur={() => formik.setFieldTouched('Hourly_Rate_Expected')}
        />
      </Flex>
    </ModalSectionLayout>
  );
};
