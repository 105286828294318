import { useMemo } from 'react';

import { AutocompleteTextfield } from '@components';

import { useAppSelector } from '@redux/hooks';
import { optionsSelectors } from '@redux/options';

export const PositionSubteamField: React.FC<{
  value: string | null;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
  disabled?: boolean;
}> = ({
  value,
  name,
  error,
  touched,
  required,
  onChange,
  onBlur,
  disabled,
}) => {
  const { data: subteams } = useAppSelector(
    optionsSelectors.getJobOpeningSubteams,
  );

  const subteamOptions = useMemo(
    () => subteams.map((subteam) => subteam.name),
    [subteams],
  );

  return (
    <AutocompleteTextfield
      name={name || 'Subteam_Name'}
      label={
        !subteamOptions.length ? 'No subteams in this account' : 'Subteam name'
      }
      options={subteamOptions}
      value={value}
      required={required}
      onChange={(_, val) => onChange(val)}
      onBlur={onBlur}
      fullWidth
      filterSelectedOptions
      error={touched && !!error}
      helperText={touched ? error : undefined}
      disabled={!subteamOptions.length || disabled}
    />
  );
};
