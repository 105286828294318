import { Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

export const FilterButton: React.FC<{
  filtersAmount: number;
  onClick: () => void;
}> = ({ filtersAmount, onClick }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={(theme) => ({
        backgroundColor: Boolean(filtersAmount)
          ? theme.palette.brand.secondary
          : theme.palette.button.contained.primary,
        textTransform: 'none',
        display: 'flex',
        height: '2rem',
        fontSize: 'subtitle2.fontSize',
        padding: '0.25rem 0.5rem',
        whiteSpace: 'nowrap',
      })}
    >
      <FilterListIcon sx={{ mr: '0.5rem', fontSize: '1rem' }} />
      Filters: {filtersAmount}
    </Button>
  );
};
