import { EmployeeAutocomplete } from '@components';

export const PositionInterviewerField: React.FC<{
  label?: string;
  value: string | null;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({ label, value, name, error, touched, required, onChange, onBlur }) => {
  return (
    <EmployeeAutocomplete
      label={label || 'Technical Interviewer'}
      name={name || 'Technical_Reviewer'}
      value={value}
      required={required}
      onChange={(val) => onChange(val?.email_id || null)}
      onBlur={onBlur}
      fullWidth
      error={touched && !!error}
      helperText={touched ? error : undefined}
    />
  );
};
