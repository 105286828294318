import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';

import {
  alpha,
  Box,
  Collapse,
  styled,
  TableCell,
  TableRow,
} from '@mui/material';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import EastIcon from '@mui/icons-material/East';
import { OverflowTooltip } from '@components';
import { CandidateTimelineActivityTable } from './CandidateTimelineActivityTable';

import { getActivityHeaderDetails, getActivityHeaderSubDetails } from '@utils';
import { ICandidateProcess } from '@types';
import { ActivityTypeLabel, CandidateProcessActivityType } from '@constants';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ':hover': {
    backgroundColor: alpha(theme.palette.highlight.section, 0.6),
  },
  ':first-of-type': {
    'th:first-of-type': { borderRadius: '0.25rem 0 0 0' },
    'td:last-of-type': { borderRadius: '0 0.25rem 0 0' },
  },
  cursor: 'pointer',
  '&.open': {
    backgroundColor: alpha(theme.palette.highlight.section, 0.6),
  },
}));

const StyledTableCell = styled(TableCell)({
  padding: '0.5rem',
});

export const CandidateTimelineActivityRow: React.FC<{
  activity: ICandidateProcess;
  previousActivity: ICandidateProcess | null;
}> = ({ activity, previousActivity }) => {
  const headerDetails = getActivityHeaderDetails(activity, previousActivity);
  const positionDetail = getActivityHeaderSubDetails(activity);

  const [open, setOpen] = useState(false);

  const handleRowClick = () => {
    setOpen(!open);
  };

  const isStatusChange = useMemo(() => {
    return activity.activityType === CandidateProcessActivityType.StatusChange;
  }, [activity]);

  return (
    <>
      <StyledTableRow
        key={activity.id}
        className={classNames({ 'sticky open': open })}
        onClick={handleRowClick}
      >
        <StyledTableCell
          component="th"
          scope="row"
          sx={{ color: 'text.secondary' }}
        >
          {format(new Date(activity.createdAt), 'yyyy-MM-dd')}
          &nbsp;&nbsp;
          {format(new Date(activity.createdAt), 'hh:mm aaa')}
        </StyledTableCell>
        <StyledTableCell>
          <OverflowTooltip>
            {ActivityTypeLabel[activity.activityType]}
          </OverflowTooltip>
        </StyledTableCell>
        <StyledTableCell
          colSpan={isStatusChange ? 1 : 3}
          sx={{
            color: headerDetails[0] ? 'text.primary' : 'text.secondary',
          }}
        >
          <OverflowTooltip>
            {headerDetails[0] || (isStatusChange ? 'No status' : '—')}
          </OverflowTooltip>
        </StyledTableCell>
        {isStatusChange && (
          <>
            <StyledTableCell>
              <Box>
                <EastIcon
                  fontSize="small"
                  color="secondary"
                  sx={{ verticalAlign: 'middle' }}
                />
              </Box>
            </StyledTableCell>
            <StyledTableCell>
              <OverflowTooltip>{headerDetails[1] ?? ''}</OverflowTooltip>
            </StyledTableCell>
          </>
        )}
        <StyledTableCell sx={{ color: 'text.secondary' }}>
          <OverflowTooltip>{positionDetail || '—'}</OverflowTooltip>
        </StyledTableCell>
        <StyledTableCell>
          <OverflowTooltip>{activity.producer}</OverflowTooltip>
        </StyledTableCell>
        <StyledTableCell>
          <Box>
            {open ? (
              <KeyboardArrowUpRoundedIcon
                fontSize="small"
                color="secondary"
                sx={{ verticalAlign: 'middle' }}
              />
            ) : (
              <KeyboardArrowDownRoundedIcon
                fontSize="small"
                color="secondary"
                sx={{ verticalAlign: 'middle' }}
              />
            )}
          </Box>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell
          style={{
            padding: 0,
            borderBottom: open ? '1px solid rgb(0 0 0 / 8%)' : 'none',
          }}
          colSpan={8}
        >
          <Collapse in={open} timeout="auto">
            <Box py="1rem">
              <CandidateTimelineActivityTable activity={activity} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
