import { FileButton, HiddenBlockWrapper } from '@components';
import { Box, Typography } from '@mui/material';

import { RESUME_ACCEPTED_EXTENTIONS } from '@constants';
import { SubmissionFormik } from '@types';
import { Attachment } from './components/Attachment';

export const SubmissionResumeStep: React.FC<{
  formik: SubmissionFormik;
  defaultResumeName: string | null;
  onHideFieldToggle: (field: string, isHidden: boolean) => void;
}> = ({ formik, defaultResumeName, onHideFieldToggle }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1} py={2}>
      <Typography variant="h3">Candidate’s resume</Typography>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.resumeName')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.resumeName', isHidden)
        }
      >
        {!!formik.values.profile.resumeName && (
          <Attachment
            label={formik.values.profile.resumeName}
            clearable={!!formik.values.resumeFile}
            disabled={formik.values.hiddenFields.includes('profile.resumeName')}
            onClear={() => {
              formik.setValues({
                ...formik.values,
                profile: {
                  ...formik.values.profile,
                  resumeName: defaultResumeName,
                },
                resumeFile: null,
              });
            }}
          />
        )}
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.resumeName')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.resumeName', isHidden)
        }
        disabled
      >
        <FileButton
          label="Attach new resume"
          hint="PDF or DOC, 20 MB"
          acceptedExtensions={RESUME_ACCEPTED_EXTENTIONS}
          onChange={(files) => {
            formik.setValues({
              ...formik.values,
              profile: {
                ...formik.values.profile,
                resumeName: files[0].file.name || null,
              },
              resumeFile: files[0].file || null,
            });
          }}
          disabled={formik.values.hiddenFields.includes('profile.resumeName')}
        />
      </HiddenBlockWrapper>
    </Box>
  );
};
