import React, { useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { isStatusIdle, isStatusLoading } from '@utils';
import { fetchCandidateAddedByOptions, optionsSelectors } from '@redux/options';

export const CandidateAddedByFilter: React.FC<{
  isLoading: boolean;
  value: string[] | null;
  onChange: (value: string[] | null) => void;
}> = ({ isLoading, value, onChange }) => {
  const dispatch = useAppDispatch();

  const { apiStatus, data: addedByOptions } = useAppSelector(
    optionsSelectors.getCandidateAddedByOptions,
  );

  const handleChange = (e: any, values: string[]) => {
    onChange(values.length ? values : null);
  };

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchCandidateAddedByOptions());
    }
  }, [apiStatus]);

  return (
    <Autocomplete
      disablePortal
      multiple
      id="combo-box-recruiter"
      options={addedByOptions}
      onChange={handleChange}
      value={value || []}
      filterSelectedOptions
      sx={{ mb: '1rem', width: '300px' }}
      renderInput={(params) => <TextField {...params} label="Added by" />}
      disabled={isLoading || isStatusLoading(apiStatus)}
      ChipProps={{
        size: 'small',
      }}
    />
  );
};
