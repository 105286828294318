import React from 'react';
import { useAppSelector } from '@redux/hooks';

import { Flex, ForbiddenView } from '@components';
import { PotentialCandidates } from './components/PotentialCandidates';
import { WeeklyPipeline } from './components/WeeklyPipeline';

import { jobOpeningDetailsSelectors } from '@redux/jobOpeningDetails';

import { isStatusForbidden } from '@utils';

export const JobOpeningCandidates: React.FC = () => {
  const candidatesData = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningCandidatesData,
  );

  const candidatesAPIStatus = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningCandidatesApiStatus,
  );
  const jobOpeningData = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsData,
  );
  const candidatesStatus = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsApiStatus,
  );

  if (isStatusForbidden(candidatesAPIStatus)) {
    return <ForbiddenView />;
  }

  return (
    <Flex flexDirection="column" gap={5}>
      <PotentialCandidates
        candidatesData={candidatesData}
        candidatesAPIStatus={candidatesAPIStatus}
      />
      <WeeklyPipeline
        candidatesData={candidatesData}
        candidateAPIStatus={candidatesAPIStatus}
        jobOpeningData={jobOpeningData}
        candidatesStatus={candidatesStatus}
      />
    </Flex>
  );
};
