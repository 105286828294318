import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone.js';
import { getIn } from 'formik';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import EventIcon from '@mui/icons-material/Event';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';

import { displayDateTimeInTimezone } from '@utils';
import { utcTimezones } from '@constants';
import { SubmissionFormik } from '@types';

dayjs.extend(utc);
dayjs.extend(timezone);

export const SubmissionInterviewStep: React.FC<{
  formik: SubmissionFormik;
}> = ({ formik }) => {
  const slots = formik.values.interview?.candidateSlots;
  const candidateReadyAnytime = formik.values.interview?.candidateReadyAnytime;
  const hasSlots = !!slots?.length;
  const isDisabledAdding =
    (hasSlots && slots.some((slot) => !slot)) ||
    candidateReadyAnytime ||
    !formik.values.interview.candidateTimezone ||
    !formik.values.interview.clientTimezone;

  const handleDuplicateSlot = (date: string, index: number) => {
    const dateTime = dayjs(date).add(1, 'hour');

    const newSlots = [...slots];
    newSlots.splice(index + 1, 0, dateTime.toISOString());

    formik.setFieldValue('interview.candidateSlots', newSlots);
  };

  const hasSectionError =
    (!!getIn(formik.touched, 'interview.candidateSlots') ||
      !!formik.submitCount) &&
    !!getIn(formik.errors, 'interview.candidateSlots');

  return (
    <Box display="flex" flexDirection="column" gap={2} py={2}>
      <Box>
        <Typography variant="h3">
          Select candidate’s call availability
        </Typography>
        {!!hasSectionError && (
          <Typography variant="subtitle2" color="text.danger">
            Candidate's call availability is required
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          p={2}
          sx={{ background: '#F6F9FD', borderRadius: '4px' }}
          display="flex"
          gap={2}
        >
          <DoneAllIcon
            sx={(theme) => ({ color: theme.palette.text.secondary })}
          />
          <Box>
            <Typography variant="body1" color="text.secondary" fontWeight={500}>
              Candidate’s slots for client interview
            </Typography>
            <Typography variant="body2" color="text.primary">
              Client will be able to select predefined CI slots or suggest their
              own slots or Calendly.
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <TextField
              fullWidth
              select
              id="candidate-timezone"
              name="interview.candidateTimezone"
              label="Candidate timezone"
              value={getIn(formik.values, 'interview.candidateTimezone') ?? ''}
              SelectProps={{
                displayEmpty: true,
                notched: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={formik.handleChange}
            >
              <MenuItem key="empty-candidate-timezone" value="" disabled>
                Select timezone
              </MenuItem>
              {utcTimezones.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={7}>
            <TextField
              fullWidth
              select
              id="client-timezone"
              name="interview.clientTimezone"
              label="Client timezone"
              value={getIn(formik.values, 'interview.clientTimezone') ?? ''}
              SelectProps={{
                displayEmpty: true,
                notched: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={formik.handleChange}
            >
              <MenuItem key="empty-client-timezone" value="" disabled>
                Select timezone
              </MenuItem>
              {utcTimezones.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {!!formik.values.interview.candidateTimezone &&
            !!formik.values.interview.clientTimezone &&
            slots.map((date, dateIdx) => (
              <React.Fragment key={dateIdx}>
                <Grid item xs={5}>
                  <DateTimePicker
                    timezone={formik.values.interview.candidateTimezone!}
                    format="MMM DD, hh:mm a"
                    value={date ? dayjs(date) : null}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'outlined',
                        label: 'Candidate slot',
                      },
                    }}
                    onChange={(newValue) =>
                      formik.setFieldValue(
                        'interview.candidateSlots',
                        slots.map((slot, idx) =>
                          idx === dateIdx
                            ? newValue?.toISOString() || null
                            : slot,
                        ),
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography fontSize="0.75rem" color="text.secondary">
                    Client time
                  </Typography>
                  <Typography variant="body1">
                    {date
                      ? displayDateTimeInTimezone(
                          date,
                          formik.values.interview.clientTimezone!,
                        )
                      : '—'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => handleDuplicateSlot(date, dateIdx)}
                    size="small"
                    disabled={!Boolean(date)}
                  >
                    <MoreTimeRoundedIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      formik.setFieldValue(
                        'interview.candidateSlots',
                        slots.filter((slot, idx) => idx !== dateIdx),
                      )
                    }
                    size="small"
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.interview?.candidateReadyAnytime ?? false}
              onChange={(_, val) => {
                formik.setValues({
                  ...formik.values,
                  interview: {
                    ...formik.values.interview,
                    candidateReadyAnytime: val,
                    candidateSlots: [],
                  },
                });
              }}
            />
          }
          label="Candidate is available anytime, client to provide meeting slots."
        />
        <Box display="flex" alignItems="center" gap={1}>
          <EventIcon
            sx={{ color: !isDisabledAdding ? 'text.link' : 'text.secondary' }}
          />
          <Typography
            variant="body1"
            color={!isDisabledAdding ? 'text.link' : 'text.secondary'}
            sx={{ cursor: !isDisabledAdding ? 'pointer' : 'default' }}
            onClick={() =>
              !isDisabledAdding &&
              formik.setFieldValue('interview.candidateSlots', [
                ...(formik.values.interview?.candidateSlots || []),
                null,
              ])
            }
          >
            Add candidate’s call availability
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
