import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { debounce } from 'lodash';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import { Flex } from '@components';
import { CopyFromJobOpeningListing } from './components/CopyFromJobOpeningListing';

import {
  cleanupOptionsData,
  fetchClosedJobOpeningsOptions,
  fetchJobOpeningsOptions,
  optionsSelectors,
} from '@redux/options';

import { isStatusIdle } from '@utils';
import { JobOpeningState } from '@constants';

export const CopyFromJobOpeningModal: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
}> = ({ isOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const jobOpeningsOptions = useAppSelector(
    optionsSelectors.getJobOpeningsOptions,
  );

  const [searchString, setSearchString] = useState('');
  const [debouncedSearchString, setDebouncedSearchString] = useState('');
  const [includeClosedPositions, setIncludeCLosedPositions] = useState(false);

  useEffect(() => {
    if (isStatusIdle(jobOpeningsOptions.apiStatus)) {
      dispatch(
        fetchJobOpeningsOptions({
          states: [
            JobOpeningState.Open,
            JobOpeningState.PreOpen,
            JobOpeningState.OnHold,
          ],
        }),
      );
    }
  }, [jobOpeningsOptions.apiStatus]);

  useEffect(() => {
    if (includeClosedPositions && debouncedSearchString.length > 3) {
      dispatch(
        fetchClosedJobOpeningsOptions({ search: debouncedSearchString }),
      );
    }

    return () => {
      dispatch(cleanupOptionsData('closedJobOpenings'));
    };
  }, [debouncedSearchString, includeClosedPositions]);

  const debouncedSearch = useMemo(
    () => debounce((value: string) => setDebouncedSearchString(value), 1000),
    [],
  );
  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;

      setSearchString(value);
      debouncedSearch(value);
    },
    [],
  );
  const onCloseModal = useCallback(() => {
    setSearchString('');
    debouncedSearch('');
    setIncludeCLosedPositions(false);
    handleClose();
  }, []);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onCloseModal}
      PaperProps={{
        sx: { maxWidth: '46.5rem', height: '34rem' },
      }}
    >
      <DialogTitle variant="h2" fontWeight={500} fontFamily="Inter var">
        Copy from another position
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCloseModal}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
        }}
      >
        <CloseRoundedIcon color="secondary" fontSize="small" />
      </IconButton>
      <DialogContent>
        <Flex flexDirection="column" gap="1rem">
          <Box display="flex" gap={2} width="100%">
            <TextField
              fullWidth
              placeholder="Search by title, client, ID"
              type="search"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
              value={searchString}
              onChange={onSearch}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeClosedPositions}
                  onChange={(_, val) => setIncludeCLosedPositions(val)}
                />
              }
              label="Include closed positions"
            />
          </Box>
          <CopyFromJobOpeningListing
            searchString={searchString}
            includeClosedPositions={includeClosedPositions}
          />
        </Flex>
      </DialogContent>
    </Dialog>
  );
};
