import { useState } from 'react';

import { EditCandidateProfileButton } from './components/EditCandidateProfileButton';
import { EditCandidateProfileModal } from './components/EditCandidateProfileModal/EditCandidateProfileModal';

import { useAppSelector } from '@redux/hooks';
import { authSelectors } from '@redux/auth';
import { candidateDetailsSelectors } from '@redux/candidateDetails';

import { IZohoCandidate } from '@types';

import { checkUserCanUpdateCandidateProfile } from '@utils';

export const EditCandidateProfile: React.FC<{
  candidateDetails: IZohoCandidate | null;
}> = ({ candidateDetails }) => {
  const profile = useAppSelector(authSelectors.getProfileData);
  const isProfileUpdating = useAppSelector(
    candidateDetailsSelectors.getIsProfileUpdating,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isDisabled = !checkUserCanUpdateCandidateProfile(profile);

  if (!candidateDetails) return null;

  return (
    <>
      <EditCandidateProfileButton
        onClick={() => setIsModalOpen(true)}
        isDisabled={isDisabled}
      />
      <EditCandidateProfileModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isLoading={isProfileUpdating}
        candidateDetails={candidateDetails}
      />
    </>
  );
};
