const candidatesApi = '/candidates';

export const candidates = () => `${candidatesApi}`;

export const duplicateCadndiatesAPIPath = () => `${candidatesApi}/duplicates`;

export const candidateById = (id: string) => `${candidatesApi}/${id}`;

export const candidateJobOpenings = (id: string) =>
  `${candidatesApi}/${id}/job-openings`;

// Video Interview
export const candidateVideoInterview = (id: string) =>
  `${candidatesApi}/${id}/video-interview`;

export const candidateGetAvailableVideoInterview = (id: string) =>
  `${candidatesApi}/${id}/video-interview/list`;

export const candidateResetVideoInterview = (
  id: string,
  videoInterviewId: string,
) => `${candidatesApi}/${id}/video-interview/${videoInterviewId}/reset`;

export const candidateGenerateClientVideoLink = (
  id: string,
  videoInterviewId: string,
) =>
  `${candidatesApi}/${id}/video-interview/${videoInterviewId}/generate-client-link`;

export const candidateSendVideoInterview = (
  id: string,
  videoInterviewId: string,
) => `${candidatesApi}/${id}/video-interview/${videoInterviewId}/send`;

export const candidateVideoInterviewReview = (
  id: string,
  candidateVideoInterviewId: string,
) =>
  `${candidatesApi}/${id}/video-interview/${candidateVideoInterviewId}/review`;

// Knowledge quiz
export const candidateKnowledgeQuiz = (id: string) =>
  `${candidatesApi}/${id}/knowledge-quiz`;

// Test Tasks
export const getUserTestTasksAPIPath = (candidateId: string) =>
  `${candidatesApi}/${candidateId}/user-test-tasks`;

export const updateUserTestTaskAPIPath = (
  candidateId: string,
  userTestTaskId: string,
) => `${candidatesApi}/${candidateId}/user-test-tasks/${userTestTaskId}`;

export const sendUserTestTaskAPIPath = (candidateId: string) =>
  `${candidatesApi}/${candidateId}/user-test-tasks/send`;

export const reviewUserTestTaskAPIPath = (
  candidateId: string,
  userTestTaskId: string,
) => `${candidatesApi}/${candidateId}/user-test-tasks/${userTestTaskId}/review`;

// Associations
export const candidateJobOpeningAssociation = (id: string) =>
  `${candidatesApi}/${id}/associations`;

export const candidateJobOpeningUnAssociation = (id: string, jobId: string) =>
  `${candidatesApi}/${id}/associations/${jobId}`;

// Meetings
export const candidateMeetings = (id: string) =>
  `${candidatesApi}/${id}/meetings`;

export const candidateMeetingReview = (id: string, meetingId: string) =>
  `${candidatesApi}/${id}/meetings/${meetingId}/review`;

export const candidateTechnicalInterview = (candidateId: string) =>
  `${candidatesApi}/${candidateId}/meeting/technical-interview`;

// Other
export const candidateZohoSuitablePositions = (id: string) =>
  `${candidatesApi}/${id}/zoho-suitable-positions`;

export const candidateStatus = (id: string) => `${candidatesApi}/${id}/status`;

export const notifyCandidateAPIPath = (id: string) =>
  `${candidatesApi}/${id}/notify`;

export const candidateProcessActivity = (id: string) =>
  `${candidatesApi}/${id}/process-activity`;

export const candidateSubmissions = (id: string) =>
  `${candidatesApi}/${id}/submissions`;

export const candidateInviteInHotpipeline = () =>
  `${candidatesApi}/invite-hotpipeline`;

export const candidateLPInvitationAPIPath = (id: string) =>
  `${candidatesApi}/${id}/lp-invite`;
