import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { AutocompleteTextfield } from '@components';

import {
  fetchJobOpeningSpecializations,
  optionsSelectors,
} from '@redux/options';

import { isStatusIdle } from '@utils';

export const PositionTechnicalFlowField: React.FC<{
  value: string | null;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  filterOptions?: (options: any[]) => any[];
  onChange: (newValue: any) => void;
  onBlur?: () => void;
}> = ({
  value,
  name,
  error,
  touched,
  required,
  filterOptions = (data: any) => data,
  onChange,
  onBlur,
}) => {
  const dispatch = useAppDispatch();

  const { data: specializations, apiStatus } = useAppSelector(
    optionsSelectors.getJobOpeningSpecializations,
  );

  const technicalFlowOptions = filterOptions(
    specializations.flatMap((specialization) =>
      specialization.skills.map((skill) => ({
        technicalFlow: skill.value,
        specialization: specialization.value,
      })),
    ),
  ).map((e: any) => e.technicalFlow);

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchJobOpeningSpecializations());
    }
  }, [apiStatus]);

  if (!technicalFlowOptions.length) return null;

  return (
    <AutocompleteTextfield
      name={name || 'Technical_Flow'}
      label={'Technical Flow'}
      options={technicalFlowOptions}
      value={value}
      required={required}
      onChange={(_, val) => onChange(val)}
      onBlur={onBlur}
      fullWidth
      filterSelectedOptions
      error={touched && !!error}
      helperText={touched ? error : undefined}
    />
  );
};
