import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { EntityFormPageLayout, JobOpeningForm } from '@components';

import {
  clearJobOpeningDetails,
  setJobOpeningId,
} from '@redux/jobOpeningDetails';
import { authSelectors } from '@redux/auth';

import { JobOpeningFormMode } from '@constants';
import { checkUserCanOpenEditJobOpening } from '@utils';
import { useTitle } from '@hooks/useTitle';
import { PageTitle } from 'constants/pageTitle';

export const EditJobOpeningPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { jobOpeningId } = useParams();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.EDIT_JOB_OPENING);
  }, [setPageTitle]);
  const profile = useAppSelector(authSelectors.getProfileData);

  useEffect(() => {
    if (jobOpeningId) {
      dispatch(setJobOpeningId(jobOpeningId));
    }

    return () => {
      if (jobOpeningId) {
        dispatch(clearJobOpeningDetails());
      }
    };
  }, [jobOpeningId]);

  // Header
  const onCancelClick = useCallback(() => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  }, []);

  if (!checkUserCanOpenEditJobOpening(profile)) {
    return null;
  }

  return (
    <EntityFormPageLayout
      headerOptions={{
        title: 'Edit position',
        onCancelClick,
      }}
    >
      <JobOpeningForm mode={JobOpeningFormMode.Edit} />
    </EntityFormPageLayout>
  );
};
