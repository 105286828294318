import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { orderBy } from 'lodash';

import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import {
  LoaderSkeleton,
  ForbiddenView,
  CandidateStatusChangeModal,
  CustomButton,
  Flex,
  ShowMoreButton,
} from '@components';
import { CandidatePositionsForAssociation } from './components/CandidatePositionsForAssociation';
import { AssociatedJobOpenings } from './components/AssociatedJobOpenings';
import { SuitableJobOpenings } from './components/SuitableJobOpenings';

import {
  associateCandidateWithJobOpening,
  candidateDetailsSelectors,
  fetchCandidateJobOpenings,
  sendSuitablePositionsToZohoProfile,
  unAssociateCandidateWithJobOpening,
} from '@redux/candidateDetails';
import { authSelectors } from '@redux/auth';

import {
  isStatusForbidden,
  isStatusIdle,
  isStatusLoading,
  checkUserCanUpdateSuitablePositionsInZoho,
} from '@utils';
import { AppRoutes, JobOpeningStatus } from '@constants';

const DEFAULT_VISIBLE_ASSOCIATED_POSITIONS = 5;

export const CandidateSuitablePositions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector(authSelectors.getProfileData);
  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const candidateDetails = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const apiStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateSuitablePositionsApiStatus,
  );
  const allPositions = useAppSelector(
    candidateDetailsSelectors.getCandidateSuitablePositionsData,
  );
  const associationsAPIData = useAppSelector(
    candidateDetailsSelectors.getCandidateAssociationsAPIData,
  );
  const [isClosedPositionsIncluded, setIsClosedPositionsIncluded] =
    useState(true);

  const [
    isLoadingUpdateSuitablePosiitons,
    setIsLoadingUpdateSuitablePosiitons,
  ] = useState(false);

  const [jobOpeningId, setJobOpeningId] = useState<string | null>(null);
  const [isChangeStatusModalOpen, setChangeStatusModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (candidateId && isStatusIdle(apiStatus)) {
      dispatch(fetchCandidateJobOpenings(candidateId));
    }
  }, [candidateId, apiStatus]);

  const openJobOpening = (id: string) =>
    navigate(`/${AppRoutes.JOB_OPENINGS}/${id}`);

  const associateCanidate = useCallback(
    async (jobOpeningId: string) => {
      if (!candidateDetails) return null;
      setJobOpeningId(jobOpeningId);
      const res = await dispatch(
        associateCandidateWithJobOpening({
          candidateId: candidateDetails.id,
          jobOpeningId,
        }),
      );
      if (associateCandidateWithJobOpening.fulfilled.match(res)) {
        setChangeStatusModalOpen(true);
      }
    },
    [candidateDetails],
  );

  const unAssociateCanidate = useCallback(
    (jobOpeningId: string) => {
      if (!candidateId) return null;
      dispatch(
        unAssociateCandidateWithJobOpening({ candidateId, jobOpeningId }),
      );
    },
    [candidateId],
  );

  const updateCandidatesSuitablePosiitons = useCallback(async () => {
    if (!candidateId) return null;
    setIsLoadingUpdateSuitablePosiitons(true);
    await dispatch(sendSuitablePositionsToZohoProfile(candidateId));
    setIsLoadingUpdateSuitablePosiitons(false);
  }, [candidateId]);

  const handleChangeStatus = useCallback((id: string) => {
    setJobOpeningId(id);
    setChangeStatusModalOpen(true);
  }, []);

  const handleChangeStatusClose = useCallback(() => {
    setJobOpeningId(null);
    setChangeStatusModalOpen(false);
  }, []);

  const [visibleAssociatedPositionsCount, setVisibleAssociatedPositionsCount] =
    useState(DEFAULT_VISIBLE_ASSOCIATED_POSITIONS);

  const associatedPositions = useMemo(
    () =>
      orderBy(allPositions, 'Association_Date', 'desc').filter(
        (e) =>
          e.Is_Associated &&
          (isClosedPositionsIncluded ||
            (e.Job_Opening_Status === JobOpeningStatus.Closed &&
              e.Reason_of_Closing === 'On-Hold') ||
            [JobOpeningStatus.InProgress, JobOpeningStatus.PreOpen].includes(
              e.Job_Opening_Status as JobOpeningStatus,
            )),
      ),
    [allPositions, isClosedPositionsIncluded],
  );

  const suitablePositions = useMemo(
    () => allPositions.filter((e) => e.Is_Suitable),
    [allPositions],
  );

  if (isStatusLoading(apiStatus)) {
    return <LoaderSkeleton />;
  }

  if (isStatusForbidden(apiStatus)) {
    return <ForbiddenView />;
  }

  return (
    <React.Fragment>
      <Stack gap={'2rem'} my={'0.5rem'}>
        <Stack gap={'1.5rem'}>
          <Typography variant="h3" pl={'1rem'}>
            Recent associated positions
          </Typography>
          <AssociatedJobOpenings
            candidateDetails={candidateDetails}
            profile={profile}
            data={associatedPositions}
            apiStatus={associationsAPIData.associationAPIStatus}
            visiblePositionsCount={visibleAssociatedPositionsCount}
            openJobOpening={openJobOpening}
            unAssociateCanidate={unAssociateCanidate}
            handleChangeStatus={handleChangeStatus}
          />
          <Flex pl={'1rem'} alignItems="center" gap={2}>
            <Flex gap={2}>
              <CandidatePositionsForAssociation
                candidateDetails={candidateDetails!}
              />
              {associatedPositions.length >
                DEFAULT_VISIBLE_ASSOCIATED_POSITIONS && (
                <ShowMoreButton
                  onClick={(isExpanded) => {
                    setVisibleAssociatedPositionsCount(
                      isExpanded
                        ? associatedPositions.length
                        : DEFAULT_VISIBLE_ASSOCIATED_POSITIONS,
                    );
                  }}
                />
              )}
            </Flex>
            <FormControlLabel
              control={
                <Switch
                  color="info"
                  checked={isClosedPositionsIncluded}
                  onChange={(e) =>
                    setIsClosedPositionsIncluded(e.target.checked)
                  }
                />
              }
              sx={{ textWrap: 'nowrap' }}
              label="Include closed positions"
            />
          </Flex>
        </Stack>
        <Stack gap={'0.75rem'}>
          <Typography variant="h3" pl={'1rem'}>
            Suitable positions
          </Typography>
          <SuitableJobOpenings
            profile={profile}
            data={suitablePositions}
            apiStatus={associationsAPIData.associationAPIStatus}
            openJobOpening={openJobOpening}
            associateCanidate={associateCanidate}
          />
          <Box pl={'1rem'}>
            <CustomButton
              isDisabled={
                !checkUserCanUpdateSuitablePositionsInZoho(profile) ||
                isLoadingUpdateSuitablePosiitons
              }
              size="small"
              color="secondary"
              label={'Save positions to Zoho'}
              onClick={updateCandidatesSuitablePosiitons}
            />
          </Box>
        </Stack>
      </Stack>
      <CandidateStatusChangeModal
        isOpen={isChangeStatusModalOpen}
        onModalClose={handleChangeStatusClose}
        selectedJobOpeningId={jobOpeningId}
        candidate={candidateDetails}
        associatedJopOpenings={associatedPositions}
        preselectedStage={null}
      />
    </React.Fragment>
  );
};
