import { useMemo } from 'react';

import { Box, Link, Typography } from '@mui/material';
import { CDPersonSignature } from './CDPersonSignature';
import { CDRowsDataLayout } from './CDRowsDataLayout';

import { getTTScoreColor } from '@utils';
import { testTaskFileUrl } from 'constants/apiEndpoints';
import { SubmissionFormPayload } from '@types';

export const CDSubmissionTestTask: React.FC<{
  submission: SubmissionFormPayload;
}> = ({ submission }) => {
  const singleTestTaskDefault = submission.testTasks?.[0];
  const mainProfileRowsData = useMemo(
    () =>
      submission.testTasks?.length && singleTestTaskDefault
        ? [
            {
              label: 'Technology',
              value: singleTestTaskDefault.technology,
            },
            {
              label: 'Score',
              value: (
                <Typography
                  variant="body1"
                  color={getTTScoreColor(singleTestTaskDefault)}
                >
                  {singleTestTaskDefault.score}%
                </Typography>
              ),
            },
            {
              label: 'Summary file',
              value: singleTestTaskDefault.summaryFileName ? (
                <Link
                  href={testTaskFileUrl(singleTestTaskDefault.summaryFileName)}
                  component={'a'}
                  target="_blank"
                  color="text.link"
                  sx={{ cursor: 'pointer' }}
                  fontFamily="inherit"
                >
                  {singleTestTaskDefault.summaryFileName} ↗
                </Link>
              ) : (
                'N/A'
              ),
            },
            {
              label: 'Feedback',
              value: (
                <>
                  <Typography whiteSpace="pre-line">
                    {singleTestTaskDefault.feedback}
                  </Typography>
                  <Box mt={3}>
                    <CDPersonSignature
                      person={singleTestTaskDefault.reviewer}
                    />
                  </Box>
                </>
              ),
            },
          ]
        : [],
    [submission],
  );

  return <CDRowsDataLayout data={mainProfileRowsData} />;
};
