import { Box, Typography } from '@mui/material';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { Flex } from '@components';

import { SubmissionFormPayload } from '@types';

export const CDSubmissionHeader: React.FC<{
  submission: SubmissionFormPayload;
}> = ({ submission }) => {
  return (
    <Flex gap={2} mb={2}>
      <Flex
        justifyContent="center"
        width="45px"
        height="45px"
        sx={(theme) => ({
          background: theme.palette.brand.accent,
          borderRadius: '0.5rem',
        })}
      >
        <PersonAddOutlinedIcon
          sx={{
            color: 'white',
          }}
        />
      </Flex>

      <Box>
        <Typography variant="h2">{`${submission?.profile?.firstName} ${submission?.profile?.lastName}`}</Typography>
        <Typography variant="body2" color="text.secondary">
          {`${submission.profile?.city}, ${submission.profile?.country}`}
        </Typography>
      </Box>
    </Flex>
  );
};
