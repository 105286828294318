import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '@constants';
import { isLocalhost } from '@utils';

export const useAxiosConfig = () => {
  const navigate = useNavigate();

  axios.defaults.baseURL = isLocalhost
    ? `${process.env.REACT_APP_API_URL}/api`
    : '/api';
  axios.interceptors.request.use((config) => {
    const newConfig = {
      withCredentials: true,
      ...config,
    };
    return newConfig;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        navigate(`/${AppRoutes.SIGN_IN}`);
      }

      return Promise.reject(error);
    },
  );
};
