export enum TestTaskType {
  CODILITY = 'CODILITY',
  QUIZ = 'QUIZ',
  INTERNAL = 'INTERNAL',
}

export enum TestTaskStatus {
  SENT = 'Sent',
  DONE = 'Done',
  FAILED = 'Failed',
  PASSED = 'Passed',
  TODO = 'Todo',
}

export enum TestTaskOrigin {
  LAUNCHPOD = 'LAUNCHPOD',
  DATAWAREHOUSE = 'DATAWAREHOUSE',
}

export enum QuizQuestionTypes {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export const InternalTestTaskResolutionReviewOptions = [
  {
    label: '🚷 Poor',
    secondaryLabel: 'Failed',
    value: 'poor',
    minScore: 0,
    maxScore: 24,
  },
  {
    label: '👌 Average',
    secondaryLabel: 'Passed',
    value: 'average',
    minScore: 25,
    maxScore: 49,
  },
  {
    label: '👍 Good',
    secondaryLabel: 'Passed',
    value: 'good',
    minScore: 50,
    maxScore: 74,
  },
  {
    label: '🤌 Excellent',
    secondaryLabel: 'Passed',
    value: 'excellent',
    minScore: 75,
    maxScore: 100,
  },
];
