import React, { FC } from 'react';
import { Box, BoxProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

interface IProps extends BoxProps {
  sx?: SxProps<Theme>;
}
export const Flex: FC<IProps> = ({ children, sx, ...rest }) => {
  return (
    <Box display="flex" alignItems="center" width="100%" sx={sx} {...rest}>
      {children}
    </Box>
  );
};
