import { useFormik } from 'formik';
import { useAppSelector } from '@redux/hooks';

import { Box, TextField } from '@mui/material';
import { ReviewOption, CustomButton } from '@components';

import { authSelectors } from '@redux/auth';

import {
  getInternalTTResolutionByScore,
  getInternalTTScoreByResolution,
  checkUserCanReviewTestTask,
} from '@utils';
import { CandidateTestTask, TestTaskReviewPayload } from '@types';

import { validationSchema } from './validationSchema';

export const InternalTestTaskReview: React.FC<{
  data: CandidateTestTask;
  isLoading: boolean;
  onReviewClick: (
    payload: { userTestTaskId: string } & TestTaskReviewPayload,
  ) => void;
}> = ({ isLoading, data, onReviewClick }) => {
  const profile = useAppSelector(authSelectors.getProfileData);

  const isReviewed = Boolean(data.reviewerName);
  const isAbleToReview = checkUserCanReviewTestTask(profile);

  const formik = useFormik<{
    feedback?: string;
    resolution: string;
    reviewer: string;
  }>({
    initialValues: {
      feedback: data.reviewerFeedback || '',
      resolution: getInternalTTResolutionByScore(data.score),
      reviewer: data.reviewerName || profile?.email || '',
    },
    validationSchema,
    onSubmit: (values) => {
      onReviewClick({
        userTestTaskId: data.id,
        score: getInternalTTScoreByResolution(values.resolution),
        feedback: values.feedback,
        reviewer: values.reviewer,
      });
    },
    validateOnMount: true,
  });

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <ReviewOption
        type={1}
        name="resolution"
        title="Final resolution"
        selected={formik.values.resolution}
        disabled={isReviewed || !isAbleToReview}
        onChange={formik.setFieldValue}
      />
      <TextField
        name="feedback"
        label="Notes"
        type="textarea"
        placeholder="Add your feedback"
        id="feedback"
        variant="outlined"
        helperText="Feedback will be added to candidates Zoho record"
        value={formik.values.feedback}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        multiline
        rows={3}
        disabled={isReviewed || !isAbleToReview}
      />
      <TextField
        name="reviewer"
        label="Reviewer"
        id="reviewer"
        variant="outlined"
        value={formik.values.reviewer}
        disabled
      />
      {!isReviewed && isAbleToReview && (
        <Box>
          <CustomButton
            onClick={() => {
              formik.handleSubmit();
            }}
            label="Save feedback"
            color="primary"
            isLoading={isLoading}
            isDisabled={!formik.isValid}
          />
        </Box>
      )}
    </Box>
  );
};
