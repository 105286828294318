import { TextButton } from '@components';
import { Box, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SendIcon from '@mui/icons-material/Send';

import {
  SubmissionStatus,
  SubmissionStep,
  SubmissionStepsOrdered,
} from '@constants';

export const SubmissionFooter: React.FC<{
  activeStep: SubmissionStep;
  isPerformingAction: boolean;
  submissionStatus: SubmissionStatus | null;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onSubmitClick: () => void;
  onSaveDraftClick: () => void;
}> = ({
  activeStep,
  isPerformingAction,
  submissionStatus,
  onPreviousClick,
  onNextClick,
  onSubmitClick,
  onSaveDraftClick,
}) => {
  const isFirstStep = SubmissionStepsOrdered[0] === activeStep;
  const isLastStep = SubmissionStepsOrdered.at(-1) === activeStep;

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box display="flex" gap={2}>
        {!isFirstStep && (
          <Button
            variant="contained"
            onClick={onPreviousClick}
            sx={{
              height: '40px',
              backgroundColor: '#3F8CFF14',
              color: '#42A5F5',
              textTransform: 'none',
              display: 'flex',
            }}
          >
            Previous
          </Button>
        )}
        <Button
          variant="contained"
          onClick={isLastStep ? onSubmitClick : onNextClick}
          sx={{
            height: '40px',
            backgroundColor: 'text.link',
            textTransform: 'none',
            display: 'flex',
          }}
          disabled={isPerformingAction}
          endIcon={isLastStep ? <SendIcon /> : <ArrowForwardIosIcon />}
        >
          {isLastStep ? 'Submit' : 'Next'}
        </Button>
      </Box>

      {(!submissionStatus || submissionStatus === SubmissionStatus.Draft) && (
        <TextButton
          onClick={onSaveDraftClick}
          variant="secondary"
          underlined
          sx={{ fontSize: '14px' }}
          disabled={isPerformingAction}
        >
          Save as draft
        </TextButton>
      )}
    </Box>
  );
};
