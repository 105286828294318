import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { JOB_OPENING_SENIORITY_LEVELS } from '@constants';

export const JobOpeningSeniorityFilter: React.FC<{
  isLoading: boolean;
  value: string[] | null;
  onChange: (value: string[] | null) => void;
}> = ({ isLoading, value, onChange }) => {
  const handleChange = (e: any, values: string[]) => {
    onChange(values.length ? values : null);
  };

  return (
    <Autocomplete
      disablePortal
      multiple
      id="combo-box-recruiter"
      options={JOB_OPENING_SENIORITY_LEVELS}
      onChange={handleChange}
      value={value || []}
      filterSelectedOptions
      sx={{ mb: '1rem', width: '300px' }}
      renderInput={(params) => <TextField {...params} label="Seniority" />}
      disabled={isLoading}
      ChipProps={{
        size: 'small',
      }}
    />
  );
};
