import { IZohoCandidate } from '@types';
import { differenceInYears } from 'date-fns';

export const getUserExperienceInfo = (candidateData: IZohoCandidate | null) => {
  return {
    yearsOfExperience:
      differenceInYears(
        new Date(),
        candidateData?.Seniority_Start_Date
          ? new Date(candidateData?.Seniority_Start_Date)
          : new Date(),
      ) + ' years',
  };
};
