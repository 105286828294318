import React from 'react';

import { Box, Link, Typography } from '@mui/material';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import HourglassIcon from '@mui/icons-material/HourglassTop';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import BlockIcon from '@mui/icons-material/Block';

import { isTestTaskDone, isTestTaskPassed } from '@utils';
import { CandidateTestTask } from '@types';
import { apiEndpoints } from '@constants';

export const CodilityTestTask: React.FC<{
  data: CandidateTestTask;
}> = ({ data }) => {
  const { status: statuses, stack, score, summaryFileName } = data;

  const isPassed = isTestTaskPassed(statuses);
  const isDone = isTestTaskDone(statuses);

  return (
    <Box display="flex" justifyContent="space-between" gap={3} width="100%">
      <Box
        sx={(theme) => ({
          display: 'flex',
          flex: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '14px 24px 16px 24px',
          borderRadius: '4px',
          background: isPassed
            ? theme.palette.highlight.success
            : !isDone
            ? theme.palette.highlight.accent
            : theme.palette.highlight.neutral,
        })}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {isPassed ? (
            <CheckIcon sx={{ color: '#FFF' }} />
          ) : !isDone ? (
            <HourglassIcon sx={{ color: 'text.secondary' }} />
          ) : (
            <BlockIcon sx={{ color: 'text.secondary' }} />
          )}
          <Typography
            variant="body1"
            color={isPassed ? 'text.invertedPrimary' : 'text.secondary'}
            fontWeight={500}
          >
            {stack}
          </Typography>
        </Box>
        <Box>
          {isDone ? (
            <Typography
              variant="body1"
              color={isPassed ? 'text.invertedPrimary' : 'text.danger'}
              fontWeight={500}
            >
              {score}%
            </Typography>
          ) : (
            <Typography variant="body1" color="text.secondary" fontWeight={500}>
              In Progress
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" flex={1} gap={1}>
        {summaryFileName && (
          <>
            <PdfIcon sx={{ color: '#DD2025' }} />
            <Link
              href={apiEndpoints.testTaskFileUrl(summaryFileName)}
              target="_blank"
            >
              Summary
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
};
