import { optionsStoreKey } from './options.const';
import { IOptionsSliceState } from './options.types';

interface IState {
  [optionsStoreKey]: IOptionsSliceState;
}

const selectOptionsState = (state: IState) => {
  return state[optionsStoreKey];
};

export const getJobOpeningsOptions = (state: IState) => {
  return selectOptionsState(state).jobOpenings;
};

export const getClosedJobOpeningsOptions = (state: IState) => {
  return selectOptionsState(state).closedJobOpenings;
};

export const getJobOpeningFieldsOptions = (state: IState) => {
  return selectOptionsState(state).jobOpeningFields;
};

const getJobOpeningClients = (state: IState) => {
  return selectOptionsState(state).jobOpeningClients;
};

const getJobOpeningSubteams = (state: IState) => {
  return selectOptionsState(state).jobOpeningSubteams;
};

const getJobOpeningSpecializations = (state: IState) => {
  return selectOptionsState(state).jobOpeningSpecializations;
};

const getJobOpeningCountries = (state: IState) => {
  return selectOptionsState(state).jobOpeningCountries;
};

export const getCandidateFieldsOptions = (state: IState) => {
  return selectOptionsState(state).candidateFields;
};

export const getCandidateResponsibleRecruitersOptions = (state: IState) => {
  return selectOptionsState(state).candidateResponsibleRecruiters;
};

export const getCandidateAddedByOptions = (state: IState) => {
  return selectOptionsState(state).candidateAddedBy;
};

export const getLPTestTasks = (state: IState) => {
  return selectOptionsState(state).lpTestTasks;
};

export const getSubmissionClients = (state: IState) => {
  return selectOptionsState(state).submissionClients;
};

export const getLPSpecializations = (state: IState) => {
  return selectOptionsState(state).lpSpecializations;
};

export const getEmployeesAPIData = (state: IState) => {
  return selectOptionsState(state).employees;
};

export const optionsSelectors = {
  getJobOpeningsOptions,
  getClosedJobOpeningsOptions,
  getJobOpeningFieldsOptions,
  getJobOpeningClients,
  getJobOpeningSubteams,
  getJobOpeningSpecializations,
  getJobOpeningCountries,
  getCandidateFieldsOptions,
  getCandidateResponsibleRecruitersOptions,
  getCandidateAddedByOptions,
  getLPTestTasks,
  getSubmissionClients,
  getLPSpecializations,
  getEmployeesAPIData,
};
