import { isNumber } from 'lodash';

import { FileInput } from 'components';
import { Box, TextField } from '@mui/material';
import { CandidateField } from '@components';

import { CandidateProfileEditFormik } from '@types';
import { RESUME_ACCEPTED_EXTENTIONS } from '@constants';

export const EditCandidateProfileFields: React.FC<{
  formik: CandidateProfileEditFormik;
}> = ({ formik }) => (
  <Box display="flex" flexDirection="column" gap={2} py={2}>
    <CandidateField
      field="Email"
      value={formik.values.Email}
      touched={!!formik.touched.Email || !!formik.submitCount}
      error={formik.errors.Email}
      onChange={(value) => formik.setFieldValue('Email', value || null)}
      onBlur={() => formik.setFieldTouched('Email')}
      isProtected
    />
    <CandidateField
      field="First_Name"
      value={formik.values.First_Name}
      touched={!!formik.touched.First_Name || !!formik.submitCount}
      error={formik.errors.First_Name}
      onChange={(value) => formik.setFieldValue('First_Name', value || null)}
      onBlur={() => formik.setFieldTouched('First_Name')}
    />
    <CandidateField
      field="Last_Name"
      value={formik.values.Last_Name}
      touched={!!formik.touched.Last_Name || !!formik.submitCount}
      error={formik.errors.Last_Name}
      onChange={(value) => formik.setFieldValue('Last_Name', value || null)}
      onBlur={() => formik.setFieldTouched('Last_Name')}
    />
    <CandidateField
      field="Location_Country"
      value={formik.values.Location_Country}
      touched={!!formik.touched.Location_Country || !!formik.submitCount}
      error={formik.errors.Location_Country}
      onChange={(value) =>
        formik.setFieldValue('Location_Country', value || null)
      }
      onBlur={() => formik.setFieldTouched('Location_Country')}
    />
    <CandidateField
      field="Location_City"
      value={formik.values.Location_City}
      touched={!!formik.touched.Location_City || !!formik.submitCount}
      error={formik.errors.Location_City}
      onChange={(value) => formik.setFieldValue('Location_City', value || null)}
      onBlur={() => formik.setFieldTouched('Location_City')}
    />
    <CandidateField
      field="English_level"
      value={formik.values.English_level}
      touched={!!formik.touched.English_level || !!formik.submitCount}
      error={formik.errors.English_level}
      onChange={(value) => formik.setFieldValue('English_level', value || null)}
      onBlur={() => formik.setFieldTouched('English_level')}
    />
    <CandidateField
      field="Dev_-_QA-AQA"
      value={formik.values['Dev_-_QA-AQA']}
      touched={!!formik.touched['Dev_-_QA-AQA'] || !!formik.submitCount}
      error={formik.errors['Dev_-_QA-AQA']}
      onChange={(value) => formik.setFieldValue('Dev_-_QA-AQA', value || null)}
      onBlur={() => formik.setFieldTouched('Dev_-_QA-AQA')}
    />
    <CandidateField
      field="Technical_Flow"
      value={formik.values.Technical_Flow}
      touched={!!formik.touched.Technical_Flow || !!formik.submitCount}
      error={formik.errors.Technical_Flow}
      onChange={(value) =>
        formik.setFieldValue('Technical_Flow', value || null)
      }
      onBlur={() => formik.setFieldTouched('Technical_Flow')}
    />
    <CandidateField
      field="Primary_Skill_Set"
      value={formik.values.Primary_Skill_Set}
      touched={!!formik.touched.Primary_Skill_Set || !!formik.submitCount}
      error={formik.errors.Primary_Skill_Set}
      onChange={(value) =>
        formik.setFieldValue('Primary_Skill_Set', value || null)
      }
      onBlur={() => formik.setFieldTouched('Primary_Skill_Set')}
    />
    <CandidateField
      field="Secondary_Skill_Set"
      value={formik.values.Secondary_Skill_Set}
      touched={!!formik.touched.Secondary_Skill_Set || !!formik.submitCount}
      error={formik.errors.Secondary_Skill_Set}
      onChange={(value) =>
        formik.setFieldValue('Secondary_Skill_Set', value || null)
      }
      onBlur={() => formik.setFieldTouched('Secondary_Skill_Set')}
    />
    <TextField
      name="experience"
      label="Experience"
      placeholder="Candidate's experience"
      variant="outlined"
      type="number"
      fullWidth
      value={isNumber(formik.values.experience) ? formik.values.experience : ''}
      onChange={(e) =>
        formik.setFieldValue(
          'experience',
          e.target.value ? parseInt(e.target.value) : null,
        )
      }
      onBlur={formik.handleBlur}
      helperText={
        !!formik.touched.experience || !!formik.submitCount
          ? formik.errors.experience
          : undefined
      }
      error={
        !!formik.touched.experience &&
        !!formik.submitCount &&
        !!formik.errors.experience
      }
    />
    <CandidateField
      field="Seniority_Level"
      value={formik.values.Seniority_Level}
      touched={!!formik.touched.Seniority_Level || !!formik.submitCount}
      error={formik.errors.Seniority_Level}
      onChange={(value) =>
        formik.setFieldValue('Seniority_Level', value || null)
      }
      onBlur={() => formik.setFieldTouched('Seniority_Level')}
    />
    <CandidateField
      field="Hourly_Rate_Current"
      value={formik.values.Hourly_Rate_Current}
      touched={!!formik.touched.Hourly_Rate_Current || !!formik.submitCount}
      error={formik.errors.Hourly_Rate_Current}
      onChange={(value) => formik.setFieldValue('Hourly_Rate_Current', value)}
      onBlur={() => formik.setFieldTouched('Hourly_Rate_Current')}
    />
    <CandidateField
      field="Hourly_Rate_Expected"
      value={formik.values.Hourly_Rate_Expected}
      touched={!!formik.touched.Hourly_Rate_Expected || !!formik.submitCount}
      error={formik.errors.Hourly_Rate_Expected}
      onChange={(value) => formik.setFieldValue('Hourly_Rate_Expected', value)}
      onBlur={() => formik.setFieldTouched('Hourly_Rate_Expected')}
    />
    <CandidateField
      field="Notice_period_from_Offer"
      value={formik.values.Notice_period_from_Offer}
      touched={
        !!formik.touched.Notice_period_from_Offer || !!formik.submitCount
      }
      error={formik.errors.Notice_period_from_Offer}
      onChange={(value) =>
        formik.setFieldValue('Notice_period_from_Offer', value || null)
      }
      onBlur={() => formik.setFieldTouched('Notice_period_from_Offer')}
    />
    <CandidateField
      field="Do_not_touch_Russia"
      value={formik.values.Do_not_touch_Russia || false}
      onChange={(value) => formik.setFieldValue('Do_not_touch_Russia', value)}
    />
    <CandidateField
      field="Phone"
      value={formik.values.Phone}
      touched={!!formik.touched.Phone || !!formik.submitCount}
      error={formik.errors.Phone}
      onChange={(value) => formik.setFieldValue('Phone', value || null)}
      onBlur={() => formik.setFieldTouched('Phone')}
    />
    <CandidateField
      field="Telegram"
      value={formik.values.Telegram}
      touched={!!formik.touched.Telegram || !!formik.submitCount}
      error={formik.errors.Telegram}
      onChange={(value) => formik.setFieldValue('Telegram', value || null)}
      onBlur={() => formik.setFieldTouched('Telegram')}
    />
    <CandidateField
      field="LinkedIn_URL"
      value={formik.values.LinkedIn_URL}
      touched={!!formik.touched.LinkedIn_URL || !!formik.submitCount}
      error={formik.errors.LinkedIn_URL}
      onChange={(value) => formik.setFieldValue('LinkedIn_URL', value || null)}
      onBlur={() => formik.setFieldTouched('LinkedIn_URL')}
    />
    <CandidateField
      field="AE_Formatted_CV_Link"
      value={formik.values.AE_Formatted_CV_Link}
      touched={!!formik.touched.AE_Formatted_CV_Link || !!formik.submitCount}
      error={formik.errors.AE_Formatted_CV_Link}
      onChange={(value) =>
        formik.setFieldValue('AE_Formatted_CV_Link', value || null)
      }
      onBlur={() => formik.setFieldTouched('AE_Formatted_CV_Link')}
    />
    <FileInput
      label="Attach resume"
      name="resume"
      acceptedExtensions={RESUME_ACCEPTED_EXTENTIONS}
      onChange={({ files, fileNames }) =>
        formik.setValues({
          ...formik.values,
          resume: files[0] || null,
          resumeFileName: fileNames[0] || null,
        })
      }
      value={
        formik.values.resumeFileName ? [formik.values.resumeFileName] : null
      }
    />
  </Box>
);
