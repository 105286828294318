import { useEffect, useRef, useState } from 'react';
import { NavDrawer } from '@components';
import { Theme, CSSObject, Box } from '@mui/material';

const drawerWidth = 340;

const slidingMixin = (isOpen: boolean, theme: Theme): CSSObject => ({
  transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
  transition: theme.transitions.create('transform', {
    easing: isOpen
      ? theme.transitions.easing.easeIn
      : theme.transitions.easing.easeOut,
    duration: isOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
  top: `${window.scrollY}px`,
});

export const SlidingDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (e.clientX < 10 && !isOpen) {
        hoverTimeout.current = setTimeout(() => setIsOpen(true), 500);
      } else if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
      }
    };

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
      if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
    };
  }, [isOpen]);

  const handleMouseLeave = () => {
    setIsOpen(false);
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
  };

  const handleMouseEnter = () => {
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
  };

  return (
    <Box
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      sx={(theme) => ({
        width: drawerWidth,
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'absolute',
        top: `${scrollPosition}px`,
        left: 0,
        height: '100vh',
        alignItems: 'flex-start',
        overflow: 'hidden',
        zIndex: 1200,
        ...slidingMixin(isOpen, theme),
      })}
    >
      <NavDrawer isOpen={isOpen} toggleDrawer={() => setIsOpen(!isOpen)} />
    </Box>
  );
};
