import { snackbarStoreKey } from './snackbar.const';
import { ISnackbarSliceState } from './snackbar.types';

interface IState {
  [snackbarStoreKey]: ISnackbarSliceState;
}

const selectSnackbarState = (state: IState) => {
  return state[snackbarStoreKey];
};

const getSanckbarData = (state: IState) => {
  return selectSnackbarState(state);
};

export const snackbarSelectors = {
  getSanckbarData,
};
