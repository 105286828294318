import { Typography, TypographyProps } from '@mui/material';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

interface IScoreValueProps extends TypographyProps {
  score: number | null;
  bgColor: string;
  color?: string;
}

export const ScoreValue: React.FC<IScoreValueProps> = ({
  score,
  bgColor,
  color = 'text.invertedPrimary',
  ...rest
}: IScoreValueProps) => {
  return (
    <Typography
      {...rest}
      sx={{
        bgcolor: bgColor,
        height: '38px',
        width: '38px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        color: color,
        ...(rest.sx ?? {}),
      }}
    >
      {score === null ? <AccessTimeRoundedIcon /> : score}
    </Typography>
  );
};
