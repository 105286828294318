import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { ISubmissionSliceState } from './submission.types';
import { ApiStatus } from '@constants';
import { submissionStoreKey } from './submission.const';
import {
  createSubmission,
  fetchSubmissionCandidateDetails,
  fetchSubmissionDetails,
  fetchSubmissionTestTasks,
  fetchSubmissionVideoInterview,
  updateSubmission,
  uploadSubmissionResume,
} from './submission.thunks';

const initialState: ISubmissionSliceState = {
  submissionDetails: {
    apiStatus: ApiStatus.IDLE,
    data: null,
  },
  candidateDetails: {
    apiStatus: ApiStatus.IDLE,
    data: null,
  },
  videoInterview: {
    apiStatus: ApiStatus.IDLE,
    data: null,
  },
  testTasks: {
    apiStatus: ApiStatus.IDLE,
    data: [],
  },
  isPerformingAction: false,
};

export const submissionSlice = createSlice({
  name: submissionStoreKey,
  initialState,
  reducers: {
    clearSubmissionState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Fetch submission details
      .addCase(fetchSubmissionDetails.pending, (state) => {
        state.submissionDetails.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchSubmissionDetails.fulfilled, (state, action) => {
        state.submissionDetails.apiStatus = ApiStatus.COMPLETE;
        state.submissionDetails.data = action.payload;
      })
      .addCase(
        fetchSubmissionDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.submissionDetails.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Fetch submission candidate details
      .addCase(fetchSubmissionCandidateDetails.pending, (state) => {
        state.candidateDetails.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchSubmissionCandidateDetails.fulfilled, (state, action) => {
        state.candidateDetails.apiStatus = ApiStatus.COMPLETE;
        state.candidateDetails.data = action.payload;
      })
      .addCase(
        fetchSubmissionCandidateDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.candidateDetails.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Fetch submission video interview
      .addCase(fetchSubmissionVideoInterview.pending, (state) => {
        state.videoInterview.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchSubmissionVideoInterview.fulfilled, (state, action) => {
        state.videoInterview.apiStatus = ApiStatus.COMPLETE;
        state.videoInterview.data = action.payload;
      })
      .addCase(
        fetchSubmissionVideoInterview.rejected,
        (state, action: PayloadAction<any>) => {
          state.videoInterview.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      // Fetch submission test tasks
      .addCase(fetchSubmissionTestTasks.pending, (state) => {
        state.testTasks.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchSubmissionTestTasks.fulfilled, (state, action) => {
        state.testTasks.apiStatus = ApiStatus.COMPLETE;
        state.testTasks.data = action.payload;
      })
      .addCase(
        fetchSubmissionTestTasks.rejected,
        (state, action: PayloadAction<any>) => {
          state.testTasks.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      .addMatcher(
        isAnyOf(
          createSubmission.pending,
          updateSubmission.pending,
          uploadSubmissionResume.pending,
        ),
        (state) => {
          state.isPerformingAction = true;
        },
      )
      .addMatcher(
        isAnyOf(
          createSubmission.fulfilled,
          updateSubmission.fulfilled,
          uploadSubmissionResume.fulfilled,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      )
      .addMatcher(
        isAnyOf(
          createSubmission.rejected,
          updateSubmission.rejected,
          uploadSubmissionResume.rejected,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      );
  },
});

export const { clearSubmissionState } = submissionSlice.actions;
export const submissionSliceReducer = submissionSlice.reducer;
