/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import overrides from './overrides';
import './global-styles.scss';

export * from './variables';

interface IButtonColorPalette {
  front: string;
  frontHover: string;
  frontFocus: string;
  frontActive: string;
  frontDisabled: string;
  text: string;
  textDisabled: string;
}

interface IButtonPallete {
  text: string;
  border: string;
  background: string;
  textHover: string;
  borderHover: string;
  backgroundHover: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    brand: {
      accent: string;
      secondary: string;
    };
    button: {
      outlined: { primary: IButtonPallete };
      contained: { primary: IButtonPallete; secondary: IButtonPallete };
      primary: IButtonColorPalette;
      secondary: IButtonColorPalette;
      text: IButtonColorPalette;
    };
    highlight: {
      section: string;
      sectionLight: string;
      accent: string;
      actionable: string;
      actionableHover: string;
      neutral: string;
      hover: string;
      white: string;
      success: string;
      error: string;
      lightError: string;
      textAccentShadow: string;
      boxShadowAccent: string;
      fileSelection: string;
      fileSelectionHover: string;
      lowSeverity: string;
      mediumSeverity: string;
      highSeverity: string;
    };
  }
  interface PaletteOptions {
    brand: {
      accent: string;
    };
    button: {
      primary: IButtonColorPalette;
      secondary: IButtonColorPalette;
    };
    highlight: {
      accent: string;
      actionable: string;
      actionableHover: string;
      neutral: string;
      hover: string;
      white: string;
      success: string;
      error: string;
      lightError: string;
      textAccentShadow: string;
      boxShadowAccent: string;
      fileSelection: string;
      fileSelectionHover: string;
      lowSeverity: string;
      mediumSeverity: string;
      highSeverity: string;
    };
  }
  interface TypeBackground {
    brandAccent: string;
    brandSecondary: string;
    brandTransparent: string;
    brandLinearGradient: string;
    brandRadialGradient: string;
    buttonHover: string;
    iconButtonHover: string;
    buttonHoverWhite: string;
    inputDisabled: string;
    positionLink: string;
    socialLink: string;
    stepsCircle: string;
    backgroundPrimary: string;
    brandLight: string;
    whiteLinearGradient: string;
    disabled: string;
    warningFill: string;
  }
  interface TypeText {
    invertedPrimary: string;
    invertedSecondary: string;
    purple: string;
    brand: string;
    danger: string;
    link: string;
    linkDark: string;
    linkLight: string;
    grey: string;
    disabled: string;
  }
}

const theme = createTheme({
  palette,
  breakpoints,
  typography,
  shape: { borderRadius: 4 },
  spacing: (factor: number) => factor * 8,
  components: overrides,
});

export const muiTheme = responsiveFontSizes(theme);
