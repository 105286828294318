import { ChangeEvent } from 'react';

import { Button, Typography, styled } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Flex } from '@components';

import { LARGE_FILE_ERROR_MESSAGE, MAX_FILE_SIZE } from '@constants';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const FileButton: React.FC<{
  label: string;
  hint?: string;
  acceptedExtensions: string[];
  maxFileSize?: number;
  multiple?: boolean;
  disabled?: boolean;
  onChange: (files: { file: File; error: string | null }[]) => void;
}> = ({
  label,
  hint,
  acceptedExtensions,
  maxFileSize = MAX_FILE_SIZE,
  multiple,
  disabled,
  onChange,
}) => {
  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const rawFiles = Array.from(e.target.files);

    onChange(
      rawFiles.map((file) => {
        const maxSizeError =
          file.size > maxFileSize ? LARGE_FILE_ERROR_MESSAGE : null;
        return { file, error: maxSizeError };
      }),
    );
  };

  return (
    <Button
      component="label"
      role={undefined}
      variant="outlined"
      tabIndex={-1}
      fullWidth
      startIcon={<AttachFileIcon />}
      sx={{ padding: '16px 24px 16px 24px' }}
      disabled={disabled}
    >
      <Flex justifyContent="space-between" gap={3}>
        <Typography variant="body1">{label}</Typography>
        {!!hint && (
          <Typography
            variant="body2"
            color={disabled ? 'text.disabled' : 'text.secondary'}
          >
            {hint}
          </Typography>
        )}
      </Flex>
      <VisuallyHiddenInput
        type="file"
        accept={acceptedExtensions.toString()}
        multiple={multiple}
        onChange={onChangeFile}
      />
    </Button>
  );
};
