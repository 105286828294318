import React, { Fragment, useMemo } from 'react';
import { values, mapValues, groupBy as groupByFn, orderBy } from 'lodash';

import { Table, TableBody, TableContainer } from '@mui/material';

import { LoaderSkeleton } from '@components';
import { TableHeader } from './TableHeader';
import { ClientRow } from './ClientRow';
import { CopyFromJobOpeningRow } from './CopyFromJobOpeningRow';

import { useAppSelector } from '@redux/hooks';
import { optionsSelectors } from '@redux/options';

import { IDWHJobOpening } from '@types';
import {
  ApiStatus,
  JobOpeningsGroupingOptions,
  OrderDirection,
  LOADER_SKELETON_TYPES,
} from '@constants';

export const CopyFromJobOpeningListing: React.FC<{
  searchString: string;
  includeClosedPositions: boolean;
}> = ({ searchString, includeClosedPositions }) => {
  const jobOpeningsOptions = useAppSelector(
    optionsSelectors.getJobOpeningsOptions,
  );
  const closedJobOpeningsOptions = useAppSelector(
    optionsSelectors.getClosedJobOpeningsOptions,
  );

  const filteredData = useMemo(() => {
    let data = jobOpeningsOptions.data;

    if (includeClosedPositions) {
      data = data.concat(closedJobOpeningsOptions.data);
    }

    return data.filter(
      (op) =>
        op.Job_Opening_Name?.toLowerCase().includes(
          searchString.toLowerCase(),
        ) ||
        op.Job_Opening_Id?.includes(searchString) ||
        op.Client_Name?.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [
    jobOpeningsOptions.data,
    closedJobOpeningsOptions.data,
    includeClosedPositions,
    searchString,
  ]);

  const groupedData = useMemo<Record<string, IDWHJobOpening[]>>(
    () =>
      groupByFn(
        orderBy(
          filteredData,
          [JobOpeningsGroupingOptions.ACCOUNT],
          [OrderDirection.ASC],
        ),
        JobOpeningsGroupingOptions.ACCOUNT,
      ),
    [filteredData],
  );

  if (
    [ApiStatus.IDLE, ApiStatus.LOADING].includes(
      jobOpeningsOptions.apiStatus,
    ) ||
    [ApiStatus.LOADING].includes(closedJobOpeningsOptions.apiStatus)
  ) {
    return <LoaderSkeleton type={LOADER_SKELETON_TYPES.ROWS} />;
  }

  if (!filteredData?.length) {
    return <>No positions found</>;
  }

  return (
    <TableContainer>
      <Table stickyHeader sx={{ tableLayout: 'auto', width: '100%' }}>
        <TableHeader />
        <TableBody>
          {values(
            mapValues(groupedData, (groupedJobOpenings, groupTitle) => (
              <Fragment key={groupTitle}>
                <ClientRow client={groupTitle} />
                {groupedJobOpenings.map((position) => (
                  <CopyFromJobOpeningRow
                    key={position.id}
                    jobOpening={position}
                  />
                ))}
              </Fragment>
            )),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
