import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  debounce,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { LoaderSkeleton, CustomButton } from '@components';
import { JobOpeningsTable } from './JobOpeningsTable';

import { authSelectors } from '@redux/auth';
import {
  cleanupOptionsData,
  fetchClosedJobOpeningsOptions,
  fetchJobOpeningsOptions,
  optionsSelectors,
} from '@redux/options';
import {
  associateCandidateWithJobOpening,
  candidateDetailsSelectors,
} from '@redux/candidateDetails';

import {
  checkUserCanAssociateCandidate,
  isStatusIdle,
  isStatusLoading,
} from '@utils';
import { IZohoCandidate } from '@types';
import { JobOpeningState, LOADER_SKELETON_TYPES } from '@constants';

export const CandidatePositionsForAssociation: React.FC<{
  candidateDetails: IZohoCandidate;
}> = ({ candidateDetails }) => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(authSelectors.getProfileData);
  const jobOpeningsOptions = useAppSelector(
    optionsSelectors.getJobOpeningsOptions,
  );
  const closedJobOpeningsOptions = useAppSelector(
    optionsSelectors.getClosedJobOpeningsOptions,
  );
  const suitablePositions = useAppSelector(
    candidateDetailsSelectors.getCandidateSuitablePositionsData,
  );
  const { associationAPIStatus } = useAppSelector(
    candidateDetailsSelectors.getCandidateAssociationsAPIData,
  );

  const [search, setSearch] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [includeClosedPositions, setIncludeCLosedPositions] = useState(false);

  useEffect(() => {
    if (isStatusIdle(jobOpeningsOptions.apiStatus)) {
      dispatch(
        fetchJobOpeningsOptions({
          states: [
            JobOpeningState.Open,
            JobOpeningState.PreOpen,
            JobOpeningState.OnHold,
          ],
        }),
      );
    }
  }, [jobOpeningsOptions.apiStatus]);

  useEffect(() => {
    if (includeClosedPositions && debouncedSearchValue.length > 3) {
      dispatch(fetchClosedJobOpeningsOptions({ search: debouncedSearchValue }));
    }

    return () => {
      dispatch(cleanupOptionsData('closedJobOpenings'));
    };
  }, [debouncedSearchValue, includeClosedPositions]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const debouncedSearch = useMemo(
    () => debounce((value: string) => setDebouncedSearchValue(value), 1000),
    [],
  );
  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;

      setSearch(value);
      debouncedSearch(value);
    },
    [],
  );

  const jobOpenings = useMemo(() => {
    let data = jobOpeningsOptions.data;

    if (includeClosedPositions) {
      data = data.concat(closedJobOpeningsOptions.data);
    }

    return data.filter(
      (op) =>
        !suitablePositions.find((sp) => sp.id === op.id) &&
        (op.Job_Opening_Name?.toLowerCase().includes(search.toLowerCase()) ||
          op.Job_Opening_Id?.includes(search) ||
          op.Client_Name?.toLowerCase().includes(search.toLowerCase()) ||
          op.TechnicalFlow?.toLowerCase().includes(search.toLowerCase())),
    );
  }, [
    suitablePositions,
    jobOpeningsOptions.data,
    search,
    includeClosedPositions,
    closedJobOpeningsOptions.data,
  ]);

  const onAssociate = useCallback(
    async (jobOpeningId: string) => {
      if (!candidateDetails) return null;
      await dispatch(
        associateCandidateWithJobOpening({
          candidateId: candidateDetails.id,
          jobOpeningId,
        }),
      );

      closeModal();
    },
    [candidateDetails],
  );

  const closeModal = () => {
    setIsModalOpen(false);
    setSearch('');
    setDebouncedSearchValue('');
    setIncludeCLosedPositions(false);
  };

  const isLoading =
    isStatusLoading(jobOpeningsOptions.apiStatus) ||
    isStatusLoading(closedJobOpeningsOptions.apiStatus);
  const isAbleToAssociate =
    checkUserCanAssociateCandidate(profile) && !!jobOpenings.length;

  return (
    <>
      <CustomButton
        isDisabled={!isAbleToAssociate}
        size="small"
        color="secondary"
        label={'Associate another position'}
        startIcon={<PersonAddOutlinedIcon fontSize="small" />}
        onClick={() => setIsModalOpen(true)}
      />
      <Dialog
        fullWidth
        open={isModalOpen}
        onClose={() => closeModal()}
        PaperProps={{
          sx: { maxWidth: '46.5rem', height: '34rem' },
        }}
      >
        <DialogTitle variant="h2" fontWeight={500} fontFamily="Inter var">
          Associate candidate with position
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => closeModal()}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
          }}
        >
          <CloseRoundedIcon color="secondary" fontSize="small" />
        </IconButton>
        <DialogContent>
          <Box display="flex" gap={2}>
            <TextField
              label="Search"
              placeholder="Search by Job Opening ID or Name"
              value={search}
              onChange={onSearch}
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeClosedPositions}
                  onChange={(_, val) => setIncludeCLosedPositions(val)}
                />
              }
              label="Include closed positions"
            />
          </Box>

          {isLoading ? (
            <Box height="85%">
              <LoaderSkeleton type={LOADER_SKELETON_TYPES.ROWS} />
            </Box>
          ) : (
            <JobOpeningsTable
              jobOpenings={jobOpenings}
              onAssociate={onAssociate}
              isLoading={isStatusLoading(associationAPIStatus)}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
