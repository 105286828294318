import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadGsiScript } from '@hooks/useLoadGsiScript';

import { Container, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { authSelectors, login } from '@redux/auth';

import { AuthStatus } from '@constants';
import { useTitle } from '@hooks/useTitle';
import { PageTitle } from 'constants/pageTitle';

export const SignInPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setPageTitle = useTitle();
  useLoadGsiScript();

  const authStatus = useAppSelector(authSelectors.getAuthStatus);

  const handleGAuthCallback = (response: any) => {
    const payload = { credential: response.credential };
    dispatch(login(payload));
  };

  useEffect(() => {
    setPageTitle(PageTitle.SIGN_IN);
  }, [setPageTitle]);

  // data-callback value must be globally scoped function
  useEffect(() => {
    (window as any).handleGAuthCallback = handleGAuthCallback;

    return () => {
      delete (window as any).handleGAuthCallback;
    };
  }, []);

  useEffect(() => {
    if (authStatus === AuthStatus.LOGGEDIN) {
      navigate('/');
    }
  }, [authStatus]);

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column nowrap',
        height: '100vh',
        width: '64rem',
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Login with your AgileEngine email
      </Typography>
      <div
        id="g_id_onload"
        data-client_id="280360483379-3ud0lpeofnbkg9qd27nrq1jbfou3ef41.apps.googleusercontent.com"
        data-context="signin"
        data-ux_mode="popup"
        data-callback="handleGAuthCallback"
        data-auto_prompt="false"
      ></div>

      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="pill"
        data-theme="filled_blue"
        data-text="signin_with"
        data-size="large"
        data-locale="en-US"
        data-logo_alignment="left"
      ></div>
    </Container>
  );
};
