import {
  Accordion as MUIAccordion,
  AccordionProps,
  styled,
} from '@mui/material';

export const Accordion = styled((props: AccordionProps) => (
  <MUIAccordion disableGutters {...props} />
))((props) => ({
  '&:not(:last-of-type)': {
    [props.theme.breakpoints.up('sm')]: {
      marginBottom: '0.5rem',
    },
  },
  '&::before': {
    display: 'none',
  },
  [props.theme.breakpoints.up('xs')]: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  [props.theme.breakpoints.up('sm')]: {
    borderRadius: '0.25rem',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
  },
}));
