import React, { ReactNode } from 'react';

import { Box, Drawer, IconButton, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Flex, CustomButton } from '@components';
import { ResetFiltersButton } from './components/ResetFiltersButton';

export const FilterPanel: React.FC<{
  isOpen: boolean;
  hasFilters: boolean;
  isApplyDisabled: boolean;
  isLoading: boolean;
  children: ReactNode;
  onClosePanel: () => void;
  handleResetClick: () => void;
  handleApplyClick: () => void;
}> = ({
  isOpen,
  hasFilters,
  isApplyDisabled,
  isLoading,
  children,
  onClosePanel,
  handleResetClick,
  handleApplyClick,
}) => {
  return (
    <Drawer anchor={'right'} open={isOpen} onClose={onClosePanel}>
      <Box
        sx={{
          minWidth: '20rem',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          padding: '2rem',
        }}
      >
        <Flex>
          <Typography variant="h2" mr="0.875rem">
            Filters
          </Typography>
          {hasFilters ? (
            <ResetFiltersButton
              isLoading={isLoading}
              hasFilters={hasFilters}
              onClick={handleResetClick}
            />
          ) : null}
        </Flex>
        <IconButton
          onClick={onClosePanel}
          aria-label="Toggle drawer"
          sx={{
            position: 'fixed',
            right: '2rem',
            top: '2rem',
            zIndex: 10,
            background: 'white',
          }}
        >
          <CloseRoundedIcon color="action" />
        </IconButton>

        {children}

        <Box>
          <CustomButton
            size="medium"
            color="primary"
            label={'Apply'}
            onClick={handleApplyClick}
            isDisabled={isApplyDisabled}
          />
        </Box>
      </Box>
    </Drawer>
  );
};
